/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';

// UI Components
import Icon from '@ant-design/icons';

// assets
import { ReactComponent as foodIcon } from '../assets/fruits.svg';
import { ReactComponent as sieveIcon } from '../assets/sieve.svg';

/* -------------------------------------------------------------------------- */
/*             An iteratable array listing all nextLarvae actions.            */
/* -------------------------------------------------------------------------- */
export const nextLarvaeActions = () => [
  { label: 'Feed', context: 'nextLarvae-feed', icon: <Icon component={foodIcon} /> },
  { label: 'Sieving', context: 'nextLarvae-sieving', icon: <Icon component={sieveIcon} /> },
];

export const NLBiometricDataFields = ['meanLarvaeWeight', 'frass'];
