
  export const killedBatchesForNextProcess = (data,onFinish) =>{ 

    const structuredData = data.map((item) => {
    const { process_second_drying: dryingfields, batch,...rest } = item;
    const mountedItem = {
      ...rest,
      ...dryingfields,
      identifier: batch.identifier,
      batchId: batch.id,
      operation : 'Killing/Drying'
    };
    return mountedItem;
    });
    onFinish(structuredData);
  }

  export const endOfBatchesForNextProcess = (data,onFinish) =>{
    const structuredData = data.map((item) => {
      const {batch,sievingDate,larvalWeight,...rest} = item;
      const mountedItem = {
        ...rest,
        identifier: batch.identifier,
        batchId: batch.id,
        date: sievingDate,
        sievingWaste :  larvalWeight, 
        operation : 'End of batch'
      };
      return mountedItem;
  
      });

    onFinish(structuredData)
  }