/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';


// Redux
import { useDispatch } from 'react-redux';

// Redux toolKit
import { unwrapResult } from '@reduxjs/toolkit';

// Slices
import { createNextGrow, updateNextGrow } from '../../../reducers/NextProcess.slice';




/* -------------------------------------------------------------------------- */
/*                                Weight Form                                */
/* -------------------------------------------------------------------------- */
const WeightForm = ({ onSubmit,initialValues,clearValues,onlyFormItems}) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();    
  const forceUpdate = FormBuilder.useForceUpdate();
  const dispatch = useDispatch();
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const onFinish = (data) => {
      dispatch(
        initialValues ?
          updateNextGrow({
              id: initialValues.id,
              ...data
          })
        :
          createNextGrow(data)
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Add Big bag',
            description: 'Big bag added successfully' 
          });
          onSubmit();
          if(initialValues)
           clearValues();
        })
        .catch(() =>
          notification.error({
            message: 'Add Big bag',
            description: 'An error occured',
          }),
        );

      
  }


  const fields = [
    {
      key: 'bigBagId',
      label: 'NextGrow id :',
      dataIndex: 'bigBagId',
      placeholder: 'nG1234',
      initialValue: initialValues?.bigBagId,
      rules: [
        {
          required: true,
          message: 'Big bag id is required',
        },
        {
            pattern: /[A-Z]{2}[0-9]*/i,
            message: 'id must contain two consecutive characters then numbers ex : nG1234 ',
        }
      ],

    },
    {
      key: 'weight',
      label: 'weight (kg)',
      widget: 'number',
      initialValue: initialValues?.weight,
      rules: [
        {
          required: true,
          message: 'Weight (kg) is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
      initialValue: initialValues?.comment,
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onFinish} form={form} onValuesChange={forceUpdate}>
      <FormBuilder 
        form={form} 
        meta={fields}
     />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

WeightForm.propTypes = {
  onSubmit: PropTypes.func,
  isOutputForm: PropTypes.number.isRequired,
  bigBags: PropTypes.array.isRequired,
  onlyFormItems: PropTypes.bool,
  initialValues: PropTypes.object,
  clearValues: PropTypes.func.isRequired,
};

export default WeightForm;