/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../common/config';

/* -------------------------------------------------------------------------- */
/*                               NEXT INCUBATOR                               */
/* -------------------------------------------------------------------------- */

// Create a new incubation measurment
export const createIncubatorMeasurment = createAsyncThunk(
  'nextIncubator/createIncubatorMeasurment',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/incbuator-measurements`,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update incubator input
export const updateIncubatorMeasurement = createAsyncThunk(
  'nextIncubator/updateIncubatorMeasurement',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/incbuator-measurements/${data.id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Remove incubator input
export const deleteIncubatorMeasurement = createAsyncThunk(
  'nextIncubator/deleteIncubatorMeasurement',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/incbuator-measurements/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
// Create a new incubation measurment
export const bulkIncubatorMeasurment = createAsyncThunk(
  'nextIncubator/bulkIncubatorMeasurment',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/bulk-incbuator-measurements`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Create a new incubation measurment
export const createAeration = createAsyncThunk('nextIncubator/createAeration', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/aerations`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Update incubator input
export const updateAeration = createAsyncThunk('nextIncubator/updateAeration', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/aerations/${data.id}`,
      data: data.fields,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                              incubator inputs                              */
/* -------------------------------------------------------------------------- */

// Create a new incubator input
export const createIncubatorInput = createAsyncThunk(
  'nextIncubator/createIncubatorInput',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/incubator-inputs`,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update incubator input
export const updateIncubatorInput = createAsyncThunk(
  'nextIncubator/updateIncubatorInput',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/incubator-inputs/${data.id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Remove incubator input
export const deleteIncubatorInput = createAsyncThunk(
  'nextIncubator/deleteIncubatorInput',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/incubator-inputs/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// bulk incubator input
export const bulkIncubatorInput = createAsyncThunk(
  'nextIncubator/bulkIncubatorInput',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/bulk-incubator-inputs`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// fetch feed types
export const fetchIncubatorFeedTypes = createAsyncThunk(
  'nextIncubator/fetchIncubatorFeedTypes',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/feed-types`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Create a new incubation measurment
export const createComment = createAsyncThunk('nextIncubator/createComment', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/comments`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
// NextIncubator Slice
const NextIncubator = createSlice({
  name: 'NextIncubator',
  initialState: {
    feedTypes: [],
  },
  reducers: {},
  extraReducers: {
    [fetchIncubatorFeedTypes.fulfilled]: (state, action) => {
      state.feedTypes = action.payload;
    },
  },
});

export default NextIncubator.reducer;

// Selectors
export const selectFeedTypes = (state) => state.NextIncubator.feedTypes;
