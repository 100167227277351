/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../common/config';

export const addProductInfo = createAsyncThunk('nextProcess/addProductInfo',
async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/product-informations`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const fetchProductInfos = createAsyncThunk('nextProcess/fetchProductInfo',
async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/product-informations/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


const Product = createSlice({
  name: 'Product',
  initialState: {
    productUpdateList: [],
  },
  reducers: {},
  extraReducers: {
    [fetchProductInfos.fulfilled]: (state, action) => {
      state.breedingSievings = action.payload;
    },
  },
});


export const selectProductUpdateList = (state) => state.Product.productUpdateList;

export default Product.reducer;


