/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// Reducers
import { fetchAllBatches } from '../../../reducers/Batch.slice';
import { createAeration } from '../../../reducers/NextIncubator.slice';

// Local Components
import AerationForm from './AerationForm';

/* -------------------------------------------------------------------------- */
/*                         Breading Measurement Action                        */
/* -------------------------------------------------------------------------- */
function AerationAction({ batch, measure }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      createAeration({
        ...data,
        batch: batch.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Aeration',
          description: 'Aeration added successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Aeration',
          description: 'An error occured',
        }),
      );
  }

  const hasHatchingDate = (batch.hatchingDate !== null || batch.transferred !== null) && !batch.isForBreeding
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        className={hasHatchingDate ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={hasHatchingDate}
        onClick={() => setShowModal(!showModal)}
      >
        Aeration
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Aeration Form"
        width={1000}
        destroyOnClose
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        onCancel={() => setShowModal(!showModal)}
      >
        <AerationForm batch={batch} onSubmit={onClickSubmit} measure={measure} />
      </Modal>
    </div>
  );
}

export default AerationAction;
