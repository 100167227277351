/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Form, Button, notification, Modal } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  fetchSuppliers,
  updateFeedEntry,
  fetchCategories,
  fetchFeedEntries,
} from '../../../reducers/NextFeed.slice';



/* -------------------------------------------------------------------------- */
/*                               Feed Entry Form                              */
/* -------------------------------------------------------------------------- */
function UpdateAvailableWeight({ feed, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [updateMixModal, setUpdateMixModal] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();


  useEffect(() => {
    dispatch(fetchSuppliers());
    dispatch(fetchCategories());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const mixFields = [
    {
      key: 'identifier',
      label: 'N° Mix',
      widget: 'number',
      initialValue: feed.identifier && feed.identifier,
      disabled: true,
    },
    {
      key: 'availableWeight',
      label: 'Available weight (kg):',
      placeholder: 'Available weight (kg):',
      widget: 'number',
      extra: `max Value is ${feed.weight}`,
      initialValue: feed.availableWeight && feed.availableWeight,
      widgetProps: {
        min: 0,
        max: feed?.weight
      },
    },
  ];

  const handleFinish = useCallback((values) => {
    dispatch(
      updateFeedEntry({
        ...values,
        id: feed.id
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Update Feed Entry',
          description: 'Feed Entry was Updated successfully',
        });
        setUpdateMixModal(!updateMixModal);
        dispatch(fetchFeedEntries());
      })
      .catch(() => {
        setUpdateMixModal(!updateMixModal);
        notification.error({
          message: 'Update Feed Entry',
          description: 'An error occured',
        });
      });
  });


  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <Button className="mb-20" type="primary" onClick={() => setUpdateMixModal(!updateMixModal)}>
        Update Weight
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Update Available Weight"
        width={1000}
        visible={updateMixModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setUpdateMixModal(!updateMixModal)}
      >
        <Form layout="horizontal" onFinish={handleFinish} onChangeValues={forceUpdate} form={form}>
          <FormBuilder form={form} meta={mixFields} />

          {!onlyFormItems && (
            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

UpdateAvailableWeight.propTypes = {
  onlyFormItems: PropTypes.bool,
};

export default UpdateAvailableWeight;
