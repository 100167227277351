/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// UI Components
import { List, Col, Modal, Button, Row, notification } from 'antd';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  selectPresses,
  createNextOil,
  fetchAllNextOil,
  createNextMeal,
  fetchAllNextMeal
} from '../../../reducers/NextProcess.slice';

// Local components
import PressForm from './PressForm';
import PressItem from './PressItem';
import NextOilForm from './NextOilForm';
import NextMealForm from './NextMealForm'


/* -------------------------------------------------------------------------- */
/*                                 Press List                                 */
/* -------------------------------------------------------------------------- */
function PressList() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const [showNextOilModal, setNextOilShowModal] = useState(false);
  const [showNextMealModal, setNextMealShowModal] = useState(false);
  const dispatch = useDispatch();
  const presses = useSelector(selectPresses);
  const onSubmit = () => {
    setShowModal(false);
  };
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  // onClickSubmitNextOil
  const onClickSubmitNextOil = (data) => {
    dispatch(createNextOil({
      ...data
    }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'nextOil',
          description: 'nextOil Id Added successfully',
        });
        setNextOilShowModal(!showNextOilModal);
        dispatch(fetchAllNextOil())
      })
      .catch(() =>
        notification.error({
          message: 'nextOil',
          description: 'An error occured',
        }),
      );
  };

  // onClickSubmitNextMeal
  const onClickSubmitNextMeal = (data) => {
    dispatch(createNextMeal({
      ...data
    }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'nextMeal',
          description: 'nextMeal Id Added successfully',
        });
        setNextMealShowModal(!showNextMealModal);
        dispatch(fetchAllNextMeal())
      })
      .catch(() =>
        notification.error({
          message: 'nextMeal',
          description: 'An error occured',
        }),
      );
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="batch-list">
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={presses}
        renderItem={(item) => (
          <List.Item>
            <div className="card-item-container">
              <PressItem item={item} />
            </div>
          </List.Item>
        )}
      />
      <Row >
        {/* ADD PRESS */}
        <Col>
          <Button
            className="plus-button"
            type="primary"
            shape="square"
            onClick={() => setShowModal(!showModal)}

          >
            Add Press
          </Button>
          <Modal
            style={{ minHeight: '1500px !important' }}
            title="New Press"
            width={1000}
            visible={showModal}
            maskClosable={false}
            footer={null}
            destroyOnClose
            onCancel={() => setShowModal(false)}
          >
            <PressForm onSubmit={onSubmit} />
          </Modal>
        </Col>

        {/* ADD NEXT OIL */}
        <Col className="ml-20">
          <Button
            className="plus-button ml-20"
            type="primary"
            shape="square"
            onClick={() => setNextOilShowModal(!showNextOilModal)}
          >
            ADD nextOil
          </Button>
          <Modal
            style={{ minHeight: '1500px !important' }}
            title="Add new nextOil Id"
            width={1000}
            visible={showNextOilModal}
            maskClosable={false}
            footer={null}
            destroyOnClose
            onCancel={() => setNextOilShowModal(false)}
          >
            <NextOilForm onSubmit={onClickSubmitNextOil} />
          </Modal>
        </Col>

        {/* ADD NEXT Meal */}
        <Col className="ml-20">
          <Button
            className="plus-button ml-20"
            type="primary"
            shape="square"
            onClick={() => setNextMealShowModal(!showNextMealModal)}
          >
            ADD nextMeal
          </Button>
          
          <Modal
            style={{ minHeight: '1500px !important' }}
            title="Add new nextMeal Id"
            width={1000}
            visible={showNextMealModal}
            maskClosable={false}
            footer={null}
            destroyOnClose
            onCancel={() => setNextMealShowModal(false)}
          >
            <NextMealForm onSubmit={onClickSubmitNextMeal} />
          </Modal>
        </Col>

      </Row>

    </div>
  );
}

export default PressList;
