/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'antd-form-builder';

// Redux 
import { useSelector, useDispatch } from 'react-redux'; 

// UI Components
import { Button, Modal, notification } from 'antd';
import EditableTable from '../../../../sharedComponents/EditableTable';
import { createCageIntorducing, fetchCages, selectCages } from '../../../../reducers/NextFly.slice';

/* -------------------------------------------------------------------------- */
/*                               PupaeStock Actions                           */
/* -------------------------------------------------------------------------- */
function PupaeStockAction({batch,stepTwoFinished}) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const cages = useSelector(selectCages);

  const forceUpdate = FormBuilder.useForceUpdate();

  const [list,setList] = useState(cages.map((item) => ({
    id: item.id,
    batches: batch.batchId,
    chamber: item.chambre,
    name: item.ref,
    cage: item.id,
    pupaeTotalWeight: item.cage_intorducings[0] && item.cage_intorducings[0].pupaeTotalWeight,
  })));

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const head = [
    {
      title: 'Cage',
      dataIndex: 'chamber',
      key: 'chamber',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Quantity reintroduced (kg)',
      dataIndex: 'pupaeTotalWeight',
      key: 'pupaeTotalWeight',
      editable: true,
      type: 'number'
    },
  ];

  const reintroStepTwoAction = ()=>{
      dispatch(createCageIntorducing(list))
      .then(()=> {
        notification.success({
          message: 'Pupae stock Reintroduction Step 2',
          description: 'Reintroduction Step 2 added successfully',
        });
        dispatch(fetchCages());
        setShowModal(false);
      })
      .catch(()=>{
        notification.success({
          message: 'Pupae stock Reintroduction Step 2',
          description: 'An error occured',
        });
      });  
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>  
        <Button
          className={(!batch.reintroduced || stepTwoFinished) ? 
          'card-item-action-btn-disable' : 'card-item-action-btn'}
          disabled={!batch.reintroduced}
          onClick={() => setShowModal(!showModal)}
        >     
          Reintro Step 2
        </Button>
      <Modal
        style={{ minHeight: '1500px !important'}}
        title={'Pupae stock - Reintroduction Step 2'}
        width={1000}
        visible={showModal}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
        onOk={reintroStepTwoAction}
      >
        <EditableTable
          isPupaeStock={true}
          list={[...list]}
          getFiltered={true}
          columns={head}
          number={500}
          pagination={false}
          onChange={(values) => {
            setList(values);
            forceUpdate();
          }}
        />
      </Modal>
    </div>
  );
}
PupaeStockAction.propTypes = {
  disabled: PropTypes.bool,
  batch: PropTypes.object,
  stepTwoFinished : PropTypes.bool
};
export default PupaeStockAction;