/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';

// components UI
import { Button, Modal, notification } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  createDryingPhaseSecond,
  fetchKilledBatches,
  fetchDriedLarvaeBatches,
  selectBigBag,
  fetchAllBigBag,
} from '../../../reducers/NextProcess.slice';

// Local components
import DryingForm from './DryingForm';

// Style
import './DryingStyle.less';

/* -------------------------------------------------------------------------- */
/*                           Drying Phase Two Action                          */
/* -------------------------------------------------------------------------- */
function DryingAction({ batch, disabled }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const bigBags = useSelector(selectBigBag);
  const dispatch = useDispatch();

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      createDryingPhaseSecond({
        ...data,
        killed_batch: batch.id,
        big_bag: data.big_bag,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Drying',
          description: 'Drying successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchDriedLarvaeBatches());
        dispatch(fetchKilledBatches());
        dispatch(fetchAllBigBag());
      })
      .catch(() =>
        notification.error({
          message: 'Drying',
          description: 'An error occured',
        }),
      );
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="drying-phase-two-action">
      <Button
        className={disabled ? 'action-button-disabled' : 'action-button'}
        disabled={disabled}
        onClick={() => setShowModal(!showModal)}
      >
        Drying
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Drying"
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <DryingForm batch={batch} onSubmit={onClickSubmit} bigBags={bigBags} />
      </Modal>
    </div>
  );
}

export default DryingAction;
