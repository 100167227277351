/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */

import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import { fetchKilledBatches, updateDryingPhaseSecond, selectBigBag } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                             Update Drying Form                             */
/* -------------------------------------------------------------------------- */
function UpdateDryingForm({ onSubmit, batch, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const equipment = ['S1', 'S2', 'S3', 'S4'];
  const bigBags = useSelector(selectBigBag);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const toHHMMSS = (secs) => {
    const SEC_NUM = parseInt(secs, 10);
    const hours = Math.floor(SEC_NUM / 3600);
    const minutes = Math.floor(SEC_NUM / 60) % 60;
    const seconds = SEC_NUM % 60;

    return (
      [hours, minutes, seconds]
        // eslint-disable-next-line prefer-template
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':')
    );
  };
  const getBigBagById =
    batch.process_second_drying && bigBags.find((b) => b.id === batch.process_second_drying.big_bag);

  const handleFinish = (values) => {
    dispatch(
      updateDryingPhaseSecond({
        ...values,
        id: batch.process_second_drying.id,
        big_bag: values.bigBag,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Update Batch',
          description: 'Updated successfully',
        });
        onSubmit();
        dispatch(fetchKilledBatches());
      })
      .catch((e) =>
        notification.error({
          message: 'Update Batch',
          description: 'An error occured',
        }),
      );
  };

  const killingEditFormFields = [
    {
      key: 'period',
      label: 'Drying period (h) ',
      widget: 'number',
      initialValue: batch.process_second_drying.period ? batch.process_second_drying.period : 0,
      extra: `${toHHMMSS(form.getFieldValue('batch.process_second_drying.period'))}`,
      rules: [
        {
          required: true,
          message: 'Drying period (h) is required',
        },
      ],
    },
    {
      key: 'equipement',
      label: 'equipment',
      placeholder: 'equipment',
      widget: 'select',
      initialValue: batch.process_second_drying.equipement ? batch.process_second_drying.equipement : '',
      options: equipment.map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'equipment is required',
        },
      ],
    },
    {
      key: 'finalWeight',
      label: ' Dried larvae weight (kg)',
      placeholder: 'Dried larvae weight (kg)',
      widget: 'number',
      initialValue: batch.process_second_drying.finalWeight ? batch.process_second_drying.finalWeight : 0,
      rules: [
        {
          required: true,
          message: 'Dried larvae weight (kg) is required',
        },
      ],
    },
    {
      key: 'dryingWaste',
      label: ' Drying waste (kg)',
      placeholder: 'Drying waste (kg)',
      widget: 'number',
      initialValue: batch.process_second_drying.dryingWaste ? batch.process_second_drying.dryingWaste : 0,
    },
    {
      key: 'bigBag',
      label: 'Big bag id',
      placeholder: 'Big bag id',
      widget: 'select',
      initialValue: getBigBagById && getBigBagById.id,
      options: bigBags && bigBags.map((item) => ({ label: item.bigBagId, value: item.id })),
      rules: [
        {
          required: true,
          message: 'Big bag id is required',
        },
      ],
    },
    {
      key: 'finalHumidity',
      label: ' Final humidity',
      placeholder: 'Final humidity',
      widget: 'number',
      initialValue: batch.process_second_drying.finalHumidity ? batch.process_second_drying.finalHumidity : 0,
    },
    {
      key: 'tempAfterDrying',
      label: 'Temp after drying (°C)',
      placeholder: 'temperature',
      widget: 'number',
      initialValue: batch.process_second_drying.tempAfterDrying || 0
    },
  ];

  const forceUpdate = FormBuilder.useForceUpdate();

  return (
    <Form layout="horizontal" onFinish={handleFinish} onValuesChange={forceUpdate} form={form}>
      <FormBuilder form={form} meta={killingEditFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

UpdateDryingForm.propTypes = {
  onSubmit: PropTypes.func,
  batch: PropTypes.object,
};

export default UpdateDryingForm;
