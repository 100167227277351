/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

import { Line, Bar } from 'react-chartjs-2';
import { Col } from 'antd';

import { EBRPerWeek, FCRPerWeek, HatchingRatePerWeek, MeanLarvalWeightPerWeek } from './allMeasurements';

/* -------------------------------------------------------------------------- */
/*                             Next Incubator Charts                          */
/* -------------------------------------------------------------------------- */
export default function ChartsPerWeek({ data, year }) {
  /* ---------------------------------- CONSTANTS --------------------------------- */

  const hatchingRateData = {
    labels: [
      'w1',
      'w2',
      'w3',
      'w4',
      'w5',
      'w6',
      'w7',
      'w8',
      'w9',
      'w10',
      'w11',
      'w12',
      'w13',
      'w14',
      'w15',
      'w16',
      'w17',
      'w18',
      'w19',
      'w20',
      'w21',
      'w22',
      'w23',
      'w24',
      'w25',
      'w26',
      'w27',
      'w28',
      'w29',
      'w30',
      'w31',
      'w32',
      'w33',
      'w34',
      'w35',
      'w36',
      'w37',
      'w38',
      'w39',
      'w40',
      'w41',
      'w42',
      'w43',
      'w44',
      'w45',
      'w46',
      'w47',
      'w48',
      'w49',
      'w50',
      'w51',
      'w52',
      'w53',
      'w54',
      'w55',
      'w56',
    ],
    datasets: [
      {
        label: 'Hatching Rate',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        data: [
          HatchingRatePerWeek(data, 1, year),
          HatchingRatePerWeek(data, 2, year),
          HatchingRatePerWeek(data, 3, year),
          HatchingRatePerWeek(data, 4, year),
          HatchingRatePerWeek(data, 5, year),
          HatchingRatePerWeek(data, 6, year),
          HatchingRatePerWeek(data, 7, year),
          HatchingRatePerWeek(data, 8, year),
          HatchingRatePerWeek(data, 8, year),
          HatchingRatePerWeek(data, 9, year),
          HatchingRatePerWeek(data, 10, year),
          HatchingRatePerWeek(data, 11, year),
          HatchingRatePerWeek(data, 12, year),
          HatchingRatePerWeek(data, 13, year),
          HatchingRatePerWeek(data, 14, year),
          HatchingRatePerWeek(data, 15, year),
          HatchingRatePerWeek(data, 16, year),
          HatchingRatePerWeek(data, 17, year),
          HatchingRatePerWeek(data, 18, year),
          HatchingRatePerWeek(data, 19, year),
          HatchingRatePerWeek(data, 20, year),
          HatchingRatePerWeek(data, 21, year),
          HatchingRatePerWeek(data, 22, year),
          HatchingRatePerWeek(data, 23, year),
          HatchingRatePerWeek(data, 24, year),
          HatchingRatePerWeek(data, 25, year),
          HatchingRatePerWeek(data, 25, year),
          HatchingRatePerWeek(data, 26, year),
          HatchingRatePerWeek(data, 27, year),
          HatchingRatePerWeek(data, 28, year),
          HatchingRatePerWeek(data, 29, year),
          HatchingRatePerWeek(data, 30, year),
          HatchingRatePerWeek(data, 31, year),
          HatchingRatePerWeek(data, 32, year),
          HatchingRatePerWeek(data, 33, year),
          HatchingRatePerWeek(data, 34, year),
          HatchingRatePerWeek(data, 35, year),
          HatchingRatePerWeek(data, 36, year),
          HatchingRatePerWeek(data, 37, year),
          HatchingRatePerWeek(data, 38, year),
          HatchingRatePerWeek(data, 39, year),
          HatchingRatePerWeek(data, 40, year),
          HatchingRatePerWeek(data, 41, year),
          HatchingRatePerWeek(data, 42, year),
          HatchingRatePerWeek(data, 43, year),
          HatchingRatePerWeek(data, 44, year),
          HatchingRatePerWeek(data, 45, year),
          HatchingRatePerWeek(data, 46, year),
          HatchingRatePerWeek(data, 47, year),
          HatchingRatePerWeek(data, 48, year),
          HatchingRatePerWeek(data, 49, year),
          HatchingRatePerWeek(data, 50, year),
          HatchingRatePerWeek(data, 51, year),
          HatchingRatePerWeek(data, 52, year),
          HatchingRatePerWeek(data, 53, year),
          HatchingRatePerWeek(data, 54, year),
          HatchingRatePerWeek(data, 55, year),
          HatchingRatePerWeek(data, 56, year),
        ],
      },
    ],
  };

  const larvalRate = {
    labels: [
      'w1',
      'w2',
      'w3',
      'w4',
      'w5',
      'w6',
      'w7',
      'w8',
      'w9',
      'w10',
      'w11',
      'w12',
      'w13',
      'w14',
      'w15',
      'w16',
      'w17',
      'w18',
      'w19',
      'w20',
      'w21',
      'w22',
      'w23',
      'w24',
      'w25',
      'w26',
      'w27',
      'w28',
      'w29',
      'w30',
      'w31',
      'w32',
      'w33',
      'w34',
      'w35',
      'w36',
      'w37',
      'w38',
      'w39',
      'w40',
      'w41',
      'w42',
      'w43',
      'w44',
      'w45',
      'w46',
      'w47',
      'w48',
      'w49',
      'w50',
      'w51',
      'w52',
      'w53',
      'w54',
      'w55',
      'w56',
    ],
    datasets: [
      {
        label: 'Mean Larval Weight (mg)',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        data: [
          MeanLarvalWeightPerWeek(data, 1, year),
          MeanLarvalWeightPerWeek(data, 2, year),
          MeanLarvalWeightPerWeek(data, 3, year),
          MeanLarvalWeightPerWeek(data, 4, year),
          MeanLarvalWeightPerWeek(data, 5, year),
          MeanLarvalWeightPerWeek(data, 6, year),
          MeanLarvalWeightPerWeek(data, 7, year),
          MeanLarvalWeightPerWeek(data, 8, year),
          MeanLarvalWeightPerWeek(data, 8, year),
          MeanLarvalWeightPerWeek(data, 9, year),
          MeanLarvalWeightPerWeek(data, 10, year),
          MeanLarvalWeightPerWeek(data, 11, year),
          MeanLarvalWeightPerWeek(data, 12, year),
          MeanLarvalWeightPerWeek(data, 13, year),
          MeanLarvalWeightPerWeek(data, 14, year),
          MeanLarvalWeightPerWeek(data, 15, year),
          MeanLarvalWeightPerWeek(data, 16, year),
          MeanLarvalWeightPerWeek(data, 17, year),
          MeanLarvalWeightPerWeek(data, 18, year),
          MeanLarvalWeightPerWeek(data, 19, year),
          MeanLarvalWeightPerWeek(data, 20, year),
          MeanLarvalWeightPerWeek(data, 21, year),
          MeanLarvalWeightPerWeek(data, 22, year),
          MeanLarvalWeightPerWeek(data, 23, year),
          MeanLarvalWeightPerWeek(data, 24, year),
          MeanLarvalWeightPerWeek(data, 25, year),
          MeanLarvalWeightPerWeek(data, 25, year),
          MeanLarvalWeightPerWeek(data, 26, year),
          MeanLarvalWeightPerWeek(data, 27, year),
          MeanLarvalWeightPerWeek(data, 28, year),
          MeanLarvalWeightPerWeek(data, 29, year),
          MeanLarvalWeightPerWeek(data, 30, year),
          MeanLarvalWeightPerWeek(data, 31, year),
          MeanLarvalWeightPerWeek(data, 32, year),
          MeanLarvalWeightPerWeek(data, 33, year),
          MeanLarvalWeightPerWeek(data, 34, year),
          MeanLarvalWeightPerWeek(data, 35, year),
          MeanLarvalWeightPerWeek(data, 36, year),
          MeanLarvalWeightPerWeek(data, 37, year),
          MeanLarvalWeightPerWeek(data, 38, year),
          MeanLarvalWeightPerWeek(data, 39, year),
          MeanLarvalWeightPerWeek(data, 40, year),
          MeanLarvalWeightPerWeek(data, 41, year),
          MeanLarvalWeightPerWeek(data, 42, year),
          MeanLarvalWeightPerWeek(data, 43, year),
          MeanLarvalWeightPerWeek(data, 44, year),
          MeanLarvalWeightPerWeek(data, 45, year),
          MeanLarvalWeightPerWeek(data, 46, year),
          MeanLarvalWeightPerWeek(data, 47, year),
          MeanLarvalWeightPerWeek(data, 48, year),
          MeanLarvalWeightPerWeek(data, 49, year),
          MeanLarvalWeightPerWeek(data, 50, year),
          MeanLarvalWeightPerWeek(data, 51, year),
          MeanLarvalWeightPerWeek(data, 52, year),
          MeanLarvalWeightPerWeek(data, 53, year),
          MeanLarvalWeightPerWeek(data, 54, year),
          MeanLarvalWeightPerWeek(data, 55, year),
          MeanLarvalWeightPerWeek(data, 56, year),
        ],
      },
    ],
  };

  const EBRRate = {
    labels: [
      'w1',
      'w2',
      'w3',
      'w4',
      'w5',
      'w6',
      'w7',
      'w8',
      'w9',
      'w10',
      'w11',
      'w12',
      'w13',
      'w14',
      'w15',
      'w16',
      'w17',
      'w18',
      'w19',
      'w20',
      'w21',
      'w22',
      'w23',
      'w24',
      'w25',
      'w26',
      'w27',
      'w28',
      'w29',
      'w30',
      'w31',
      'w32',
      'w33',
      'w34',
      'w35',
      'w36',
      'w37',
      'w38',
      'w39',
      'w40',
      'w41',
      'w42',
      'w43',
      'w44',
      'w45',
      'w46',
      'w47',
      'w48',
      'w49',
      'w50',
      'w51',
      'w52',
      'w53',
      'w54',
      'w55',
      'w56',
    ],
    datasets: [
      {
        label: 'EBR (g of eggs/bin)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        data: [
          EBRPerWeek(data, 1, year),
          EBRPerWeek(data, 2, year),
          EBRPerWeek(data, 3, year),
          EBRPerWeek(data, 4, year),
          EBRPerWeek(data, 5, year),
          EBRPerWeek(data, 6, year),
          EBRPerWeek(data, 7, year),
          EBRPerWeek(data, 8, year),
          EBRPerWeek(data, 8, year),
          EBRPerWeek(data, 9, year),
          EBRPerWeek(data, 10, year),
          EBRPerWeek(data, 11, year),
          EBRPerWeek(data, 12, year),
          EBRPerWeek(data, 13, year),
          EBRPerWeek(data, 14, year),
          EBRPerWeek(data, 15, year),
          EBRPerWeek(data, 16, year),
          EBRPerWeek(data, 17, year),
          EBRPerWeek(data, 18, year),
          EBRPerWeek(data, 19, year),
          EBRPerWeek(data, 20, year),
          EBRPerWeek(data, 21, year),
          EBRPerWeek(data, 22, year),
          EBRPerWeek(data, 23, year),
          EBRPerWeek(data, 24, year),
          EBRPerWeek(data, 25, year),
          EBRPerWeek(data, 25, year),
          EBRPerWeek(data, 26, year),
          EBRPerWeek(data, 27, year),
          EBRPerWeek(data, 28, year),
          EBRPerWeek(data, 29, year),
          EBRPerWeek(data, 30, year),
          EBRPerWeek(data, 31, year),
          EBRPerWeek(data, 32, year),
          EBRPerWeek(data, 33, year),
          EBRPerWeek(data, 34, year),
          EBRPerWeek(data, 35, year),
          EBRPerWeek(data, 36, year),
          EBRPerWeek(data, 37, year),
          EBRPerWeek(data, 38, year),
          EBRPerWeek(data, 39, year),
          EBRPerWeek(data, 40, year),
          EBRPerWeek(data, 41, year),
          EBRPerWeek(data, 42, year),
          EBRPerWeek(data, 43, year),
          EBRPerWeek(data, 44, year),
          EBRPerWeek(data, 45, year),
          EBRPerWeek(data, 46, year),
          EBRPerWeek(data, 47, year),
          EBRPerWeek(data, 48, year),
          EBRPerWeek(data, 49, year),
          EBRPerWeek(data, 50, year),
          EBRPerWeek(data, 51, year),
          EBRPerWeek(data, 52, year),
          EBRPerWeek(data, 53, year),
          EBRPerWeek(data, 54, year),
          EBRPerWeek(data, 55, year),
          EBRPerWeek(data, 56, year),
        ],
      },
    ],
  };

  const FCRRate = {
    labels: [
      'w1',
      'w2',
      'w3',
      'w4',
      'w5',
      'w6',
      'w7',
      'w8',
      'w9',
      'w10',
      'w11',
      'w12',
      'w13',
      'w14',
      'w15',
      'w16',
      'w17',
      'w18',
      'w19',
      'w20',
      'w21',
      'w22',
      'w23',
      'w24',
      'w25',
      'w26',
      'w27',
      'w28',
      'w29',
      'w30',
      'w31',
      'w32',
      'w33',
      'w34',
      'w35',
      'w36',
      'w37',
      'w38',
      'w39',
      'w40',
      'w41',
      'w42',
      'w43',
      'w44',
      'w45',
      'w46',
      'w47',
      'w48',
      'w49',
      'w50',
      'w51',
      'w52',
      'w53',
      'w54',
      'w55',
      'w56',
    ],
    datasets: [
      {
        label: 'FCR',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        data: [
          FCRPerWeek(data, 1, year),
          FCRPerWeek(data, 2, year),
          FCRPerWeek(data, 3, year),
          FCRPerWeek(data, 4, year),
          FCRPerWeek(data, 5, year),
          FCRPerWeek(data, 6, year),
          FCRPerWeek(data, 7, year),
          FCRPerWeek(data, 8, year),
          FCRPerWeek(data, 8, year),
          FCRPerWeek(data, 9, year),
          FCRPerWeek(data, 10, year),
          FCRPerWeek(data, 11, year),
          FCRPerWeek(data, 12, year),
          FCRPerWeek(data, 13, year),
          FCRPerWeek(data, 14, year),
          FCRPerWeek(data, 15, year),
          FCRPerWeek(data, 16, year),
          FCRPerWeek(data, 17, year),
          FCRPerWeek(data, 18, year),
          FCRPerWeek(data, 19, year),
          FCRPerWeek(data, 20, year),
          FCRPerWeek(data, 21, year),
          FCRPerWeek(data, 22, year),
          FCRPerWeek(data, 23, year),
          FCRPerWeek(data, 24, year),
          FCRPerWeek(data, 25, year),
          FCRPerWeek(data, 25, year),
          FCRPerWeek(data, 26, year),
          FCRPerWeek(data, 27, year),
          FCRPerWeek(data, 28, year),
          FCRPerWeek(data, 29, year),
          FCRPerWeek(data, 30, year),
          FCRPerWeek(data, 31, year),
          FCRPerWeek(data, 32, year),
          FCRPerWeek(data, 33, year),
          FCRPerWeek(data, 34, year),
          FCRPerWeek(data, 35, year),
          FCRPerWeek(data, 36, year),
          FCRPerWeek(data, 37, year),
          FCRPerWeek(data, 38, year),
          FCRPerWeek(data, 39, year),
          FCRPerWeek(data, 40, year),
          FCRPerWeek(data, 41, year),
          FCRPerWeek(data, 42, year),
          FCRPerWeek(data, 43, year),
          FCRPerWeek(data, 44, year),
          FCRPerWeek(data, 45, year),
          FCRPerWeek(data, 46, year),
          FCRPerWeek(data, 47, year),
          FCRPerWeek(data, 48, year),
          FCRPerWeek(data, 49, year),
          FCRPerWeek(data, 50, year),
          FCRPerWeek(data, 51, year),
          FCRPerWeek(data, 52, year),
          FCRPerWeek(data, 53, year),
          FCRPerWeek(data, 54, year),
          FCRPerWeek(data, 55, year),
          FCRPerWeek(data, 56, year),
        ],
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      y: {
        beginAtZero: true,
      },
    },
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <div className="all-charts">
        <Col className="single-chart">
          <p className="chart-title">Hatching Rate</p>
          <Line data={hatchingRateData} options={options} />
        </Col>
        <Col className="single-chart">
          <p className="chart-title">Mean Larval Weight (mg)</p>
          <Line data={larvalRate} options={options} />
        </Col>
      </div>
      <div className="all-charts">
        <Col className="single-chart">
          <p className="chart-title">EBR (g of eggs/bin)</p>
          <Bar data={EBRRate} options={options} />
        </Col>
        <Col className="single-chart">
          <p className="chart-title">FCR</p>
          <Bar data={FCRRate} options={options} />
        </Col>
      </div>
    </>
  );
}

ChartsPerWeek.propTypes = {
  data: PropTypes.array,
  year: PropTypes.string,
};
