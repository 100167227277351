/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_ENDPOINT } from '../common/config';

/* -------------------------------------------------------------------------- */
/*                                 Feed Entry                                 */
/* -------------------------------------------------------------------------- */

// fetch Feed Entries
export const fetchFeedEntries = createAsyncThunk('nextFeed/fetchFeedEntries', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/feed-entries`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch Feed Entry by id
export const fetchFeedEntriesById = createAsyncThunk('nextFeed/fetchFeedEntryById'
, async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/feed-entries/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});



// create Feed Entry
export const createFeedEntry = createAsyncThunk('nextFeed/createFeedEntry', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/feed-entries`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// update lots
export const updateFeedEntry = createAsyncThunk('nextFeed/updateFeedEntry', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/feed-entries/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Delete a Batch
export const deleteFeedEntry = createAsyncThunk('nextFeed/deleteFeedEntry', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/feed-entries/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                  Suppliers                                 */
/* -------------------------------------------------------------------------- */

// fetch suppliers
export const fetchSuppliers = createAsyncThunk('nextFeed/fetchSuppliers', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/suppliers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Create suppliers
export const createSupplier = createAsyncThunk('nextFeed/createSupplier', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/suppliers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// delete suppliers
export const deleteSupplier = createAsyncThunk('nextFeed/deleteSupplier', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/suppliers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                  grinding                                  */
/* -------------------------------------------------------------------------- */

// create Grinding
export const createGrinding = createAsyncThunk('nextFeed/createGrinding', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/feed-grindings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                 Dewatering                                 */
/* -------------------------------------------------------------------------- */

export const createDewatering = createAsyncThunk('nextFeed/createDewatering', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/feed-dewaterings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                 categories                                 */
/* -------------------------------------------------------------------------- */

// fetch suppliers
export const fetchCategories = createAsyncThunk('nextFeed/fetchCategories', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/categories`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                     Mix                                    */
/* -------------------------------------------------------------------------- */

// fetch Mixes
export const fetchMixes = createAsyncThunk('nextFeed/fetchMixes', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/mixes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch Mixes
export const createMixes = createAsyncThunk('nextFeed/createMixes', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/mixes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// update mix
export const updateMixes = createAsyncThunk('nextFeed/updateMixes', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/mixes/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// delete suppliers
export const deleteMix = createAsyncThunk('nextFeed/deleteMix', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/mixes/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                   BrMix                                    */
/* -------------------------------------------------------------------------- */

// fetch BrMixes
export const fetchBrMixes = createAsyncThunk('nextFeed/fetchBrMixes', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/br-mixes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// create BrMixes
export const createBrMixes = createAsyncThunk('nextFeed/createBrMixes', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/br-mixes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// update BrMix
export const updateBrMixes = createAsyncThunk('nextFeed/updateBrMixes', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/br-mixes/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteBrMix = createAsyncThunk('nextFeed/deleteBrMix', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/br-mixes/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                              Feed Entry Mixes                              */
/* -------------------------------------------------------------------------- */

export const updateBulkFeedEntryMixes = createAsyncThunk('nextFeed/updateFeedEntryMixes',
 async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/feed-entry-mixes-bulk`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                   Comment                                  */
/* -------------------------------------------------------------------------- */

// Create a new incubation measurment
export const createComment = createAsyncThunk('nextFeed/createComment', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/comments`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                   outpus                                   */
/* -------------------------------------------------------------------------- */
// fetch outputs
export const fetchOutputs = createAsyncThunk('nextFeed/fetchOutputs', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/outputs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const createOutput = createAsyncThunk('nextFeed/createOutput', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/outputs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// NextFeed Slice
const NextFeed = createSlice({
  name: 'NextFeed',
  initialState: {
    suppliers: [],
    feedEntries: [],
    categories: [],
    mixes: [],
    outputs: [],
    brMixes: [],
    selectedFeedEntry : null
  },
  reducers: {},
  extraReducers: {
    [fetchSuppliers.fulfilled]: (state, action) => {
      state.suppliers = action.payload;
    },
    [fetchFeedEntries.fulfilled]: (state, action) => {
      state.feedEntries = action.payload;
    },
    [fetchOutputs.fulfilled]: (state, action) => {
      state.outputs = action.payload;
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
    },
    [fetchMixes.fulfilled]: (state, action) => {
      state.mixes = action.payload;
    },
    [fetchBrMixes.fulfilled]: (state, action) => {
      state.brMixes = action.payload;
    },
    [fetchFeedEntriesById.fulfilled]: (state, action) => {
      state.selectedFeedEntry = action.payload;
    },  
  },
});

export default NextFeed.reducer;

// Selectors
export const selectSuppliers = (state) => state.NextFeed.suppliers;
export const selectFeedEntries = (state) => state.NextFeed.feedEntries;
export const selectCategories = (state) => state.NextFeed.categories;
export const selectMixes = (state) => state.NextFeed.mixes;
export const selectBrMixes = (state) => state.NextFeed.brMixes;
export const selectOutputs = (state) => state.NextFeed.outputs;
