/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

/* -------------------------------------------------------------------------- */
/*                           Larvae Measurement Form                          */
/* -------------------------------------------------------------------------- */
function LarvaeRoomForm({ onSubmit, onChange, onlyFormItems, batch }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [viewMode] = useState(false);
  const [form] = Form.useForm();
  const rooms = ['nL1', 'nL2', 'nL3'];
  const larvaeMeasurementFields = [
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      widget: 'number',
      initialValue: batch.identifier,
      disabled: true,
    },
    {
      key: 'room',
      label: 'Room',
      placeholder: 'Room',
      widget: 'select',
      options: rooms.map((item) => ({ label: item, value: item })),
      initialValue: batch.room && batch.room,
      rules: [
        {
          required: true,
          message: 'Room is required',
        },
      ],
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={larvaeMeasurementFields} viewMode={viewMode} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

LarvaeRoomForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
  batch: PropTypes.object,
};

export default LarvaeRoomForm;
