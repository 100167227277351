/* eslint-disable react/display-name */
/* eslint-disable radix */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from 'react';

// UI Components
import { Tabs, notification, Modal, Row, Col, Typography, Table, Button } from 'antd';


// redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  fetchAllProducts,
  // selectOilProducts,
  // selectMealProducts,
  createOutput,
  selectBigBag,
  selectNextMeal,
  selectNextOil
  // selectAllNextProcess
} from '../../../reducers/NextProcess.slice';
import { fetchOutputs } from '../../../reducers/NextFeed.slice';

// Local components
import { momentFoo } from '../../Shared/helper';
import CommentAction from '../../Shared/CommentAction';
import StockOutputForm from './StockOutputForm';
import WeightForm from './WeightForm';
import { dateFormat } from '../../../common/const';


/* -------------------------------------------------------------------------- */
/*                                    Stock                                   */
/* -------------------------------------------------------------------------- */
function Stock() {
  /* ---------------------------------- CONST --------------------------------- */
  const { Title } = Typography;

  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const [showModalAction ,setShowModalAction] = useState({
    outputAction: false,
    isVisible: false
  });
  const dispatch = useDispatch();
  const bigBags = useSelector(selectBigBag);
  const nextOils = useSelector(selectNextOil);
  const nextMeals = useSelector(selectNextMeal);
  
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const availableWieght = (item) => {    
    // eslint-disable-next-line no-nested-ternary
    const DRIED_LARVAE = item.process_second_dryings ?
      item.process_second_dryings.map((i) => i.finalWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0) > 0 ?
        item.process_second_dryings.map((i) => i.finalWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0
      : 0;
    // eslint-disable-next-line no-nested-ternary
    const presses = item.presses ?
      item.presses.map((i) => i.weight).reduce((a, b) => parseInt(a) + parseInt(b), 0) > 0 ?
        item.presses.map((i) => i.weight).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0
      : 0;
    return ((DRIED_LARVAE - presses) > 0 ? (DRIED_LARVAE - presses) : 0);
  };

  const NextMealWeight = (item) => {
    return (
      // eslint-disable-next-line no-nested-ternary
      item.presses ?
        item.presses.map((i) => i.nextMealWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0) > 0 ?
          item.presses.map((i) => i.nextMealWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0
        : 0
    )
  }
  const NextOilWeight = (item) => {
    return (
      // eslint-disable-next-line no-nested-ternary
      item.presses ?
        item.presses.map((i) => i.nextOilWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0) > 0 ?
          item.presses.map((i) => i.nextOilWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0
        : 0
    )
  }
  const factoredBatches = bigBags && bigBags.map((item) => (
    {
      date: momentFoo(item.created_at).format('DD-MM-YYYY'),
      batch: item.bigBagId,
      weight: availableWieght(item),
      currentWeight: item.currentWeight ? item.currentWeight : availableWieght(item),
      id: item.id,
      // comments: item.process_second_dryings.map((p) => p.comment),
      context: 'process_drying_batch',
    }
  ));

  const factoredOilProducts = nextOils && nextOils.map((i) => (
    {

      date: momentFoo(i.created_at).format('DD-MM-YYYY'),
      batch: i.nextOilBatchId,
      weight: NextOilWeight(i),
      // comments: i.presses.map((p) => p.comment),
      context: 'product',
    }
  ));

  const factoredMealProducts = nextMeals?.map((i) => (
    {

      date: momentFoo(i.created_at).format('DD-MM-YYYY'),
      batch: i.nextMealBatchId,
      weight: NextMealWeight(i),
      // comments: i.presses.map((p) => p.comment),
      context: 'product',
    }

  ));

  const closeActionModal = ()=>{
    setShowModalAction({
      ...showModalAction,
      isVisible:false
    });
  }

  useEffect(() => {
    dispatch(fetchAllProducts());
    dispatch(fetchOutputs());
  }, []);

  const load = () => {
    dispatch(fetchOutputs());
  };

  const onSubmit = (data) => {
    dispatch(
      createOutput({
        ...data,
        product: data.nextMeal || data.nextOil,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Create Output',
          description: 'Output successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchOutputs());
      })
      .catch(() =>
        notification.error({
          message: 'Create Output',
          description: 'An error occured',
        }),
      );
  };

  /* -------------------------------- VARIABLES ------------------------------- */
  const driedHead = [
    {
      title: 'BigBag Id',
      dataIndex: 'batch',
      key: 'batch',
      width: '10%'
    },
    {
      title: 'Initital weight',
      dataIndex: 'weight',
      key: 'weight',
      width: '10%'
    },
    {
      title: 'Current weight',
      dataIndex: 'currentWeight',
      key: 'currentWeight',
      width: '10%'
    },
    {
      title: 'Comments',
      key: 'comment',
      width: '10%',
      render: (record) =>  <CommentAction item={record} context={record.context} onSubmit={() => load()} />
    },
  ];

  const head = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => momentFoo(a.date) - momentFoo(b.date),
    },
    {
      title: 'BigBag Id',
      dataIndex: 'batch',
      key: 'batch',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      key: 'comment',
      render: (record) => <CommentAction item={record} context={record.context} onSubmit={() => load()} />
    },
  ];

  const headOutput = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => momentFoo(a.created_at) - momentFoo(b.created_at),
      render: (text) => momentFoo(text).format(dateFormat),
    },
    {
      title: 'Big Bag Id',
      dataIndex: 'bigBagId',
      key: 'bigBagId',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Output (kg)',
      dataIndex: 'output',
      key: 'output',
      render: (value) => value || '-',
    },
    {
      title: 'New weight (kg)',
      dataIndex: 'weight',
      key: 'weight',
      render: (value) => value || '-',
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
      render: (value) => value || '-',
    },
  ];
  /* -------------------------------- callbacks -------------------------------- */
  const getTotal = (data) => {
    return data.map((item) => item.weight).reduce((a, b) => parseInt(a) + parseInt(b), 0);
  };

  const renderInput = () => {
    return (
      <>
        <Row gutter={24}>
          <Col span={20}>
            <Title level={3}>Dried larvae</Title>
          </Col>
          <Col span={4}>
            <Title level={3}>Total: {getTotal(factoredBatches)}</Title>
          </Col>
        </Row>

        <Row >
        <Button
          type='primary'
          onClick={()=>
          setShowModalAction({outputAction:false,isVisible:true})}
        >
        Inventory
        </Button>
        <Button
          type='primary'
          className='ml-20'
          onClick={()=>
          setShowModalAction({outputAction:true,isVisible:true})}
        >
        Output
        </Button>

        <Modal
          style={{ minHeight: '1500px !important' }}
          title= {showModalAction.outputAction ? 'Output' : 'Inventory'}
          width={1000}
          visible={showModalAction.isVisible}
          footer={null}
          maskClosable={false}
          closable
          destroyOnClose
          onCancel={closeActionModal}
        >
        <WeightForm
          isOutputForm={showModalAction.outputAction}
          bigBags={factoredBatches}
          onSubmit={closeActionModal}
        />
      </Modal>
      </Row>
        <Table title={() => 'Dried larvae'} columns={driedHead} dataSource={factoredBatches} />
        <Row gutter={24}>
          <Col span={20}>
            <Title level={3}>nextMeal</Title>
          </Col>
          <Col span={4}>
            <Title level={3}>Total: {getTotal(factoredMealProducts)}</Title>
          </Col>
        </Row>
        <Table title={() => 'nextMeal'} columns={head} dataSource={factoredMealProducts} />
        <Row gutter={24}>
          <Col span={20}>
            <Title level={3}>nextOil</Title>
          </Col>
          <Col span={4}>
            <Title level={3}>Total: {getTotal(factoredOilProducts)}</Title>
          </Col>
        </Row>
        <Table title={() => 'nextOil'} columns={head} dataSource={factoredOilProducts} />
      </>
    );
  };


  const renderOutput = () => {
    const [historic,setHistoric] = useState([]);

    useEffect(() =>{      
      if(bigBags.length)
      bigBags.forEach(({bigBagId, inventories = [], outputs : _outputs = []}) => 
      {     
        setHistoric([
          ...inventories.map((item)=>({...item,bigBagId,reason: 'Update weight'})),
          ..._outputs.map(({weight,...rest})=>({...rest,bigBagId,output:weight})),
        ]);
      });       
    },[bigBags]);

    return (
        <Table 
          title={()=> 'Historic of inventories and outputs :'} 
          columns={headOutput} 
          dataSource={historic}   
        />
    );
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Tabs defaultActiveKey="Input">
        <Tabs.TabPane tab={<span>Stock</span>} key="Input">
          {renderInput()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Historic</span>} key="Output">
          {renderOutput()}
          <Button type="primary" onClick={() => setShowModal(!showModal)} className="fab-button">
            +
          </Button>
          <Modal
            title="New output"
            width={1000}
            visible={showModal}
            footer={null}
            destroyOnClose
            maskClosable={false}
            onCancel={() => setShowModal(false)}
          >
            <StockOutputForm onSubmit={onSubmit} />
          </Modal>
        </Tabs.TabPane>
      </Tabs>

    </div>
  );
}

Stock.propTypes = {};

export default Stock;
