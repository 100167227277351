/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// redux
import { useDispatch, useSelector } from 'react-redux';

// reducers
import { fetchAllNextMeal, selectNextMeal } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                               Next Meal Form                               */
/* -------------------------------------------------------------------------- */
function NextMealForm({ onSubmit, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const nextMeals = useSelector(selectNextMeal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllNextMeal());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const dryingFields = [
    {
      key: 'nextMealBatchId',
      label: 'nextMeal id',
      placeholder: 'nextMeal id',
      extra: 'nextMeal Id should be unique',
      hasFeedback: true,
      required: true,
      rules: [
        {
          validator: () => {
            const identifierValue = form.getFieldValue('nextMealBatchId');
            return new Promise((resolve, reject) => {
              if (nextMeals.find((b) => b.nextMealBatchId === identifierValue)) {
                reject(new Error(`nextMealBatchId ${identifierValue} already exists.`));
              } else {
                resolve();
              }
            });
          },
        },
      ],
    },
  ];
  // force update form
  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} form={form} onValuesChange={forceUpdate}>
      <FormBuilder form={form} meta={dryingFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

NextMealForm.propTypes = {
  onSubmit: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

export default NextMealForm;
