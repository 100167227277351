/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { Row, Col, DatePicker } from 'antd';
import { selectBatches } from '../../reducers/Batch.slice';

// UI local components
import DashboardTable from './DashboardTable';
import ChartsPerWeek from './ChartsPerWeek';

// style
import './index.less';

/* -------------------------------------------------------------------------- */
/*                             Next Feed Dashboard                            */
/* -------------------------------------------------------------------------- */
export default function NextFeedDashboard() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { RangePicker } = DatePicker;

  const data = useSelector(selectBatches);
  
  // eslint-disable-next-line no-unused-vars
  const [filteredData, setFilteredData] = useState([]);
  const [tableYear, setTableYear] = useState(new Date().getFullYear());

  function onTableYearChange(_, dateString) {
    setTableYear(dateString)
  }

  function onChartsDateChange(_, dateString) {
    const parseDate = (date, num) => parseInt(date?.split('-')[num], 10);

    const monthRange = dateString.map((date) => parseDate(date, 1));
    const DaysRange = dateString.map((date) => parseDate(date, 2));

    const firstMonth = monthRange[0];
    const lastMonth = monthRange[1];
    const firstDay = DaysRange[0];
    const lastDay = DaysRange[1];

    const fltrData = data.filter(({ entryDate }) => {
      return parseDate(entryDate, 1) >= firstMonth
        && parseDate(entryDate, 2) >= firstDay
        && parseDate(entryDate, 1) <= lastMonth
        && parseDate(entryDate, 2) <= lastDay;
    });

    setFilteredData(fltrData);    
  }

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="dashbaord-chart">
      <Row>
        <Col className="dashbaord-table" span={24}>
          <DashboardTable data={data} year={tableYear} onTableYearChange={onTableYearChange} />
        </Col>
        <Col className="dashbaord-filter" span={24}>
          <RangePicker onChange={onChartsDateChange} />
        </Col>
        <Col className="dashbaord-table" span={24}>
          <DashboardTable data={data} year={tableYear} onTableYearChange={onTableYearChange} showYear={false} />
        </Col>
        <ChartsPerWeek data={data} year={tableYear} />
      </Row>
    </div>
  );
}
