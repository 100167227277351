import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// route components
import App from './App';
import LoginPage from './pages/LoginPage';

import DashboardBatchId from './pages/dashboardBatchId';

import NextIncubatorPage from './pages/nextIncubator';
import NextLarvaePage from './pages/nextLarvae';
import NextFeedPage from './pages/nextFeed';
import NextProcessPage from './pages/nextProcess';
import NextFlyPage from './pages/nextFly';

import NextIncubatorForBreedingPage from './pages/NextBreeding';

import DriedLarvaeDetails from './pages/nextProcess/DriedLarvaeDetails';
import BatchDetailsPage from './pages/nextIncubator/BatchDetailsPage';

import Logistics from './pages/Logistics/Logistics';

import SupplierDetails from './components/NextFeed/Suppliers/SupplierDetails';
import LotDetails from './components/NextFly/Lots/LotDetails';

import Export from './pages/ExportPage/Export';

import NotFoundPage from './pages/shared/NotFoundPage';


const getSessionToken = () => localStorage.getItem('access_token');

const AuthRoute = (props) => {
  if (!getSessionToken()) {
    return <Redirect to="/login" />;
  }
  return <Route {...props} />;
};
/*eslint-disable */
function LayoutRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <AuthRoute
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export const renderRoutes = () => (
  <Router>
    <Switch>
      <LayoutRoute exact path="/" component={() => <div>Landing page</div>} layout={App} />
      <LayoutRoute exact path="/nextIncubator" component={NextIncubatorPage} layout={App} />
      <LayoutRoute exact path="/dashboardBatchId" component={DashboardBatchId} layout={App} />
      <LayoutRoute exact path="/nextProcess/driedLarvaeDetails" component={DriedLarvaeDetails} layout={App} />
      <LayoutRoute exact path="/nextBreeding" component={NextIncubatorForBreedingPage} layout={App} />
      <LayoutRoute exact path="/nextLarvae" component={NextLarvaePage} layout={App} />
      <LayoutRoute exact path="/nextFeed" component={NextFeedPage} layout={App} />
      <LayoutRoute exact path="/logistics" component={Logistics} layout={App} />
      <LayoutRoute exact path="/nextProcess" component={NextProcessPage} layout={App} />
      <LayoutRoute exact path="/nextFly" component={NextFlyPage} layout={App} />
      <LayoutRoute exact path="/batch/:id/details" component={BatchDetailsPage} layout={App} />
      <LayoutRoute exact path="/supplier/:id/details" component={SupplierDetails} layout={App} />
      <LayoutRoute exact path="/nextFly/lot/:id/details" component={LotDetails} layout={App} />
      <LayoutRoute exact path="/export" component={Export} layout={App} />
      <Route
        exact
        path="/login"
        render={() =>
          getSessionToken() && getSessionToken() !== null ? <Redirect exact from="/login" to="/" /> : <LoginPage />
        }
      />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);
