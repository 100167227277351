/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Redux
import { useSelector } from 'react-redux';
import { Table, Row, Col, DatePicker } from 'antd';
import { selectStatistics } from '../../reducers/Batch.slice';
import { sortByMonth } from '../../components/Shared/helper';

/* -------------------------------------------------------------------------- */
/*                          Next Incubator Dashboard                          */
/* -------------------------------------------------------------------------- */
export default function NextIncubatorDashboard() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { RangePicker } = DatePicker;
  const data = useSelector(selectStatistics);

  const columns = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'Total Feed',
      dataIndex: 'feed',
    },
    {
      title: 'Total Biomass',
      dataIndex: 'biomass',
    },
    {
      title: 'Total Bins',
      dataIndex: 'bins',
    },
    {
      title: 'Average Mean Larvae weight',
      dataIndex: 'nIMeanLarvalWeight'
    }
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="dashbaord-chart">
      <Row>
        <Col className="dashbaord-table" span={24}>
          <Table columns={columns} dataSource={sortByMonth(data)} />
        </Col>
        <Col className="dashbaord-filter" span={24}>
          <RangePicker picker="month" />
        </Col>
        <Col span={12} className="dashbaord-table">
          <BarChart
            width={600}
            height={500}
            data={sortByMonth(data)}
            margin={{
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="feed" name="Total Feeding (g)" fill="#D6C8EE" />
          </BarChart>
        </Col>
        <Col span={12} className="dashbaord-table">
          <BarChart
            width={600}
            height={500}
            data={sortByMonth(data)}
            margin={{
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="biomass" name="Total Biomass (g)" fill="#97d0a3" />
          </BarChart>
        </Col>
        <Col span={12} className="dashbaord-table">
          <BarChart
            width={600}
            height={500}
            data={sortByMonth(data)}
            margin={{
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="bins" name="Total Bins" fill="#ddd" />
          </BarChart>
        </Col>
      </Row>
    </div>
  );
}
