/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// Reducers
import { fetchAllBatches } from '../../../reducers/Batch.slice';
import { createLarvaeMeasurment } from '../../../reducers/NextLarvae.slice';
import LarvaeMeasurementForm from './LarvaeMeasurmentForm';

/* -------------------------------------------------------------------------- */
/*                          Larvae Measurement Action                         */
/* -------------------------------------------------------------------------- */
function LarvaeMeasurementAction({ batch, disabled }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      createLarvaeMeasurment({
        ...data,
        batch: batch.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Batch Measure',
          description: 'Measure successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Batch Measure',
          description: 'An error occured',
        }),
      );
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        className={disabled ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={disabled} onClick={() => setShowModal(!showModal)}>
        Measure
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="nextLarvae - Measure"
        width={1000}
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <LarvaeMeasurementForm batch={batch} onSubmit={onClickSubmit} />
      </Modal>
    </div>
  );
}

export default LarvaeMeasurementAction;
