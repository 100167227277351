/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import { createInventory, createOutput, updateBigBag,
   updateNextGrow,
   updateNextMeal, updateNextOil } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                                Weight Form                                */
/* -------------------------------------------------------------------------- */
const WeightForm = ({ onSubmit, isOutputForm ,bigBags, onlyFormItems, productType }) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedBigBag,setSelectedBigBag] = useState(null);
  const forceUpdate = FormBuilder.useForceUpdate();
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  // Static reasons until being specified by the client
  const reasons = [ 'reason 1','reason 2','reason 3'];

  const setBigBagCurrentWeight = (selectedBigBagId) =>{
    const { currentWeight, weight: initialWeight } = bigBags.find((bigBag) => bigBag.id === selectedBigBagId);
    setSelectedBigBag({initialWeight,currentWeight});
    form.setFieldsValue({currentWeight});
  }

  const onFinish =  (values)=>{
    const { initialWeight,currentWeight: previousCurrentWeight } = selectedBigBag;

    if(initialWeight === 0 || previousCurrentWeight === 0 )
    return notification.error({
      message: 'Dried larvae update', 
      description:  `${initialWeight ? 'current' : 'initial'} weight is zero`,
    });
    
    // Each action (inventory or output) must be saved in order to be displayed in historic tab
    const trace = {
      ...values,
      productType,
      weight: values.currentWeight,
    };

    // Link output to product 
    trace[productType] = values.id;
    
    switch (productType) {
      case 'next_meal':
        dispatch(
          isOutputForm?
            updateNextMeal({
              id: values.id,
              currentWeight: previousCurrentWeight -values.currentWeight
            })
          : updateNextMeal({
              id: values.id,
              currentWeight: values.currentWeight
          })
        );
        break;
      case 'next_oil':
        dispatch(
          isOutputForm?
            updateNextOil({
              id: values.id,
              currentWeight: previousCurrentWeight -values.currentWeight
            })
          : updateNextOil({
              id: values.id,
              currentWeight: values.currentWeight
          })
        );
        break;
      case 'next_grow': 
      dispatch(
        isOutputForm?
          updateNextGrow({
            id: values.id,
            currentWeight: previousCurrentWeight -values.currentWeight
          })
        : updateNextGrow({
            id: values.id,
            currentWeight: values.currentWeight
        })
      );
        break;
      
      default:
        dispatch(  
          updateBigBag(
          isOutputForm ?
          {
            ...values,  
            currentWeight : previousCurrentWeight-values.currentWeight,
          }
          :
          values 
          )
          )  
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Dried larvae updated',
              description: 'Updated successfully',
            });
            onSubmit();
          })
          .catch(() =>
            notification.error({
            message: 'Dried larvae update',
            description: 'An error occured',
          }),
         )
    }

    dispatch(
      isOutputForm ? 
        createOutput(trace)
      : 
        createInventory(trace)
    )   .then(unwrapResult)
    .then(() => {
      notification.success({
        message: isOutputForm ? 'New output' :'New Inventory',
        description: isOutputForm ? 'Output added successfully':'Inventory added successfully',
      });
      onSubmit();
    })
    .catch(() =>
      notification.error({
      message: isOutputForm ? 'New output' :'New Inventory',
      description: 'An error occured',
    }));


    return null;
  }

  const outputFields = [
    {
      key: 'id',
      label: 'Big bag',
      dataIndex: 'bigBagId',
      placeholder: 'big Bag Id',
      widget: 'select',
      options: bigBags.map(({id:bigBagId,batch:bigBagLabel}) => ({ label:bigBagLabel, value: bigBagId })),
      onChange : (selectedBigBagId) => setBigBagCurrentWeight(selectedBigBagId),
      rules: [
        {
          required: true,
          message: 'Big bag id is required',
        },
      ],
    },
    {
      key: 'currentWeight',
      label: 'Weight (kg)',
      placeholder: 'weight',
      widget: 'number',
      extra: `max value is ${selectedBigBag?.currentWeight|| 0}`,
      widgetProps: {
        min: 0,
        max: selectedBigBag?.currentWeight || 0
      },
      rules: [
        {
          required: true,
          message: 'Weight (kg) is required',
        },
      ],
    },
    {
      key: 'reason',
      label: 'Reasons',
      placeholder: 'Reasons',
      widget: 'select',
      options: reasons.map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'Reasons is required',
        },
      ],
    },
    { 
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];

  const updateWeightFields = [
    {
      key: 'id',
      label: 'Big bag',
      dataIndex: 'bigBagId',
      placeholder: 'big Bag Id',
      widget: 'select',
      options: bigBags.map(({id:bigBagId,batch:bigBagLabel}) => ({ label:bigBagLabel, value: bigBagId })),
      onChange : (selectedBigBagId) => setBigBagCurrentWeight(selectedBigBagId),
      rules: [
        {
          required: true,
          message: 'Big bag id is required',
        },
      ],
    },
    {
      key: 'currentWeight',
      label: 'Current weight (kg)',
      placeholder: 'Current weight (kg)',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Weight (kg) is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onFinish} form={form} onValuesChange={forceUpdate}>
      <FormBuilder form={form} meta={isOutputForm ? outputFields : updateWeightFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

WeightForm.propTypes = {
  onSubmit: PropTypes.func,
  isOutputForm: PropTypes.number.isRequired,
  bigBags: PropTypes.array.isRequired,
  productType: PropTypes.string,
  onlyFormItems: PropTypes.bool,
};

WeightForm.defaultProps = {
  productType: 'big_bag',
}

export default WeightForm;