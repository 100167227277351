/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// UI Components
import { Layout, Row, Typography, Col, Tabs, Button, Modal } from 'antd';

// Components
import CageForm from '../../components/Cages/CageForm';

// reducers
import { $setStage, selectSessionUser } from '../../reducers/Session.slice';
import {
  fetchLots,
  fetchCages,
  selectCages,
  getEggPool,
  fetchWaterTypes,
  fetchAttractantTypes,
} from '../../reducers/NextFly.slice';
import CageList from '../../components/Cages/CageList';
import EggCollection from '../../components/NextFly/EggsCollection/EggCollection';
import { fetchAllBatches } from '../../reducers/Batch.slice';
import { stages } from '../../common/const';

// styles
import './index.less';

/* -------------------------------------------------------------------------- */
/*                                Next Fly Page                               */
/* -------------------------------------------------------------------------- */
function NextFlyPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { Title } = Typography;
  const { TabPane } = Tabs;

  const [showModalCage, setShowModalCage] = useState(false);
  const dispatch = useDispatch();

  const cages = useSelector(selectCages);
  const { t } = useTranslation('stages');

  const user = useSelector(selectSessionUser);

  useEffect(() => {
    dispatch($setStage(stages.NEXTFLY));
    dispatch(fetchLots());
    dispatch(fetchCages());
    dispatch(fetchAllBatches());
    dispatch(getEggPool());
    dispatch(fetchWaterTypes());
    dispatch(fetchAttractantTypes());
  }, []);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content next-fly">
      <Row>
        <Col span={22} className="first-title">
          <Title level={1}>{t('nextFly.title')}</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey={t('Breeding Batch')}>
        <TabPane tab={<span>{t('Cages')}</span>} key={t('Cages')}>
          <CageList list={cages} />
          {getUserAccessPrivilege?.nextFlyMakeAction && (
            <>
              <Button 
               className="plus-button"
               type="primary"
               shape="square"
               onClick={() => setShowModalCage(!showModalCage)}
               >
                +
              </Button>
              <Modal
                style={{ minHeight: '1500px !important' }}
                title="New Cage"
                width={1000}
                visible={showModalCage}
                maskClosable={false}
                footer={null}
                destroyOnClose
                onCancel={() => setShowModalCage(false)}
              >
                <CageForm onSubmit={() => setShowModalCage(!showModalCage)} />
              </Modal>
            </>
          )}
        </TabPane>
        <TabPane tab={<span>{t('Egg Collection')}</span>} key={t('Egg Collection')}>
          <EggCollection />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default NextFlyPage;
