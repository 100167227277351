/* eslint-disable react/prop-types */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// redux
// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import AttractantForm from './AttractantForm';

// Reducers
import { fetchCages, createAttranctant } from '../../../reducers/NextFly.slice';


/* -------------------------------------------------------------------------- */
/*                              Attractant Action                             */
/* -------------------------------------------------------------------------- */
function AttractantAction({ cage, disabled }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      createAttranctant({
        ...data,
        cage: cage.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Cage Attractant',
          description: 'Attractant successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchCages());
      })
      .catch(() =>
        notification.error({
          message: 'Cage Attractant',
          description: 'An error occured',
        }),
      );
  };

  return (
    <div>
      <Button
        type='primary'
        className='card-item-action-btn'
        disabled={disabled} 
        onClick={() => setShowModal(!showModal)}
      >
        Attractant
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Change Attractant"
        width={1000}
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <AttractantForm cage={cage} onSubmit={onClickSubmit} />
      </Modal>
    </div>
  );
}

export default AttractantAction;
