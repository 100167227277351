/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';

// reducers
import { useTranslation } from 'react-i18next';
import { Layout, Row, Col, Tabs, Typography } from 'antd';
import { fetchAllBatches, fetchStatistics } from '../../reducers/Batch.slice';
import { $setStage } from '../../reducers/Session.slice';
import { stages } from '../../common/const';
import { fetchMixes } from '../../reducers/NextFeed.slice';
import { getEggPool } from '../../reducers/NextFly.slice';

// local components
import BatchList from '../../components/Batches/BatchList';
import NextLarvaeDashboard from './NextLarvaeDashboard';

// helpers
import { nextLarvaeActions } from '../../common/nextLarvea.helper';

// style
import './index.less';

/* -------------------------------------------------------------------------- */
/*                              Next Larvae Page                              */
/* -------------------------------------------------------------------------- */
function NextLarvaePage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const { Content } = Layout;
  const { t } = useTranslation('stages');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStatistics());
    dispatch($setStage(stages.NEXTLARVAE));
    dispatch(fetchAllBatches());
    dispatch(fetchMixes());
    dispatch(getEggPool());
  }, []);
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content next-larvae-page">
      <Row>
        <Col span={22} className="first-title">
          <Title level={1}>{t('nextLarvae.title')}</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey={t('Batches')}>
        <TabPane tab={<span>{t('Dashboard')}</span>} key={t('Dashboard')}>
          <NextLarvaeDashboard />
        </TabPane>
        <TabPane tab={<span>{t('Batches')}</span>} key={t('Batches')}>
          <BatchList actions={nextLarvaeActions()} context="next-larvae" />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default NextLarvaePage;
