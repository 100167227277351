/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';
import { momentFoo } from '../../Shared/helper';

// Style
import './index.less';

/* -------------------------------------------------------------------------- */
/*                                Transfer Form                               */
/* -------------------------------------------------------------------------- */
function TransferForm({ onSubmit, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const transferFormFields = [
    {
      key: 'nLTransferDate',
      label: 'Transfer Date',
      placeholder: 'Transfer Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && current > momentFoo().endOf('day'),
      },
      rules: [
        {
          required: true,
          message: 'Transfer Date is required',
        },
      ],
    },
    {
      key: 'biomass',
      label: 'Biomass(g)',
      placeholder: 'Biomass',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Biomass is required',
        },
      ],
    },
    {
      key: 'meanLarvalWeight',
      label: 'Mean larval weight (mg)',
      placeholder: 'Mean larval weight',
      widget: 'number',
      widgetProps: {
        min: 0.0,
        max: 10.0,
      },
      help: '*Mean larval weight should be between 0 and 10',
      rules: [
        {
          required: true,
          message: 'Mean larval weight is required',
        },
      ],
    },
    {
      key: 'substrate',
      label: 'Substrate (%)',
      placeholder: 'Substrate (%)',
      widget: 'number',
      widgetProps: {
        min: 0,
        max: 100,
      },
      rules: [
        {
          required: true,
          message: 'Substrate is required',
        },
      ],
    },
    /* ------------------------------ STD bins OUT ------------------------------ */
    {
      key: 'label 1',
      render() {
        return <p className="transfer-form-p">STD bins OUT </p>;
      },
    },
    {
      key: 'std_bins_out',
      label: 'Number',
      placeholder: 'Number',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'STD Bins (number) is required',
        },
      ],
    },
    {
      key: 'std_weight_out',
      label: 'Weight',
      placeholder: ' Weight (g/bin)',
      widget: 'number',
      widgetProps: {
        min: 0.0,
      },
      rules: [
        {
          required: true,
          message: 'Weight is required',
        },
      ],
    },
    {
      key: 'std_density',
      label: 'Density  (larvae/bin)',
      widget: 'number',
      disabled: true,
      placeholder: ' Density ',
    },
    /* ---------------------------- NON STD bins OUT ---------------------------- */
    {
      key: 'label 2',
      render() {
        return <p className="transfer-form-p">NON STD bins OUT </p>;
      },
    },
    {
      key: 'non_std_bins_out',
      label: 'Number',
      placeholder: 'Bins (number)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Bins (number) is required',
        },
      ],
    },
    {
      key: 'non_std_weight_out',
      label: 'Weight (g/bin)',
      placeholder: 'Weight (g/bin)',
      widget: 'number',
      widgetProps: {
        min: 0.0,
      },
      rules: [
        {
          required: true,
          message: 'Weight is required',
        },
      ],
    },
    {
      key: 'non_std_density',
      label: 'Density (larvae/bin)',
      placeholder: 'Density ',
      widget: 'number',
      disabled: true,
    },
    {
      key: 'content',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];

  const BIOMASS = form.getFieldValue('biomass') || 0;
  const NON_STD_BINS_OUT = form.getFieldValue('non_std_bins_out') || 0;
  const NON_STD_BINS_OUT_WEIGHT = form.getFieldValue('non_std_weight_out') || 0;
  const SUBSTRATE = form.getFieldValue('substrate') || 0;
  const MEAN_LARVAL_WEIGHT = form.getFieldValue('meanLarvalWeight') || 0;
  const STD_BINS_OUT = form.getFieldValue('std_bins_out') || 0;

  form.setFieldsValue({
    std_density: parseInt(
      ((((BIOMASS - NON_STD_BINS_OUT * NON_STD_BINS_OUT_WEIGHT) * (100 - SUBSTRATE)) / MEAN_LARVAL_WEIGHT) * 1000) /
        STD_BINS_OUT /
        100,
      10,
    ),

    non_std_density: Math.floor(parseInt(
      (((NON_STD_BINS_OUT_WEIGHT * (100 - SUBSTRATE)) / MEAN_LARVAL_WEIGHT) * 1000) / NON_STD_BINS_OUT,
      10,
    )/100),
  });

  const forceUpdate = FormBuilder.useForceUpdate();

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={forceUpdate} form={form}>
      <FormBuilder form={form} meta={transferFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

TransferForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

export default TransferForm;
