/* eslint-disable func-names */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// UI Components
import { Card, Typography, Row, Col } from 'antd';
import TransferToFeed from './TransferToFeed';
import StockOutput from './StockOutput';

// reduceres
import { selectSessionUser } from '../../../reducers/Session.slice';
import { selectFeedEntries } from '../../../reducers/NextFeed.slice';

import './RmStockCard.less';
/* -------------------------------------------------------------------------- */
/*                                  Cage Item                                 */
/* -------------------------------------------------------------------------- */
function RmStockCard() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const user = useSelector(selectSessionUser);
  const feedEntries = useSelector(selectFeedEntries);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ---------------------------------- CONST --------------------------------- */
  //  Total Fruits/vegetables = sum(current weight) of all input where category ="FV Grinded"
  const FRUITS_VEGETABLES =
    feedEntries
      .map((f) => f.category.name === 'FV Grinded' && parseFloat(f.currentWeight))
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0) / 1000;

  //  Total Potato by products = sum(current weight) of all input where category ="Potato products"
  const POTATO_BY_PRODUCTS =
    feedEntries
      .map((f) => f.category.name === 'Potato products' && parseFloat(f.currentWeight))
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0) / 1000;

  //  Total spent grains = sum(current weight) of all input where SUBcategory ="Spent grain"
  const SPENT_GRAINS =
    feedEntries
      .map((f) => f.sub_category.name === 'Spent grain' && parseFloat(f.currentWeight))
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0) / 1000;

  // Total dry products except CF =
  // sum(current weight) of all input where category ="Dry ingredients" - sum(current weight)
  // of all input where SUBcategory ="Chicken Feed"
  const DRY_PRODUCTS =
    (feedEntries
      .map((f) => f.category.name === 'Dry ingredients' && parseFloat(f.currentWeight))
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0) -
      feedEntries
        .map((f) => f.sub_category.name === 'Chicken Feed' && parseFloat(f.currentWeight))
        .reduce(function (previousValue, currentValue) {
          return Number(previousValue) + Number(currentValue);
        }, 0)) /
    1000;

  // * Total Chicken Feed = sum(current weight) of all input where SUBcategory ="Chicken Feed"
  const CHICKEN_FEED =
    feedEntries
      .map((f) => f.sub_category.name === 'Chicken Feed' && parseFloat(f.currentWeight))
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0) / 1000;

  // * Total Yeast = sum(current weight) of all input where SUBcategory ="Yeast"
  const YEAST =
    feedEntries
      .map((f) => f.sub_category.name === 'Yeast' && parseFloat(f.currentWeight))
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0) / 1000;

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="card-item-container-stock card-purple">
      <Card bordered className="card-item">
        <Row justify="space-between" className="card-item-content">
          <Row className="card-item-header" justify="space-between">
            <Col>
              <span className="card-item-title">Stock (T)</span>
            </Col>
          </Row>
          <Col span={24} className="content-card-details">
            <Title level={5}>
              total Fruits/Vegetables:
              <span style={{ fontWeight: 300 }}>{FRUITS_VEGETABLES}</span>
            </Title>
          </Col>
          <Col span={24} className="content-card-details">
            <Title level={5}>
              Total Potato by Products:
              <span style={{ fontWeight: 300 }}>{POTATO_BY_PRODUCTS}</span>
            </Title>
          </Col>
          <Col span={24} className="content-card-details">
            <Title level={5}>
              Total Spent grains:
              <span style={{ fontWeight: 300 }}>{SPENT_GRAINS}</span>
            </Title>
          </Col>
          <Col span={24} className="content-card-details">
            <Title level={5}>
              Total Dry Products (excl. CF):
              <span style={{ fontWeight: 300 }}>{DRY_PRODUCTS}</span>
            </Title>
          </Col>
          <Col span={24} className="content-card-details">
            <Title level={5}>
              Total chicken Feed:
              <span style={{ fontWeight: 300 }}>{CHICKEN_FEED}</span>
            </Title>
          </Col>
          <Col span={24} className="content-card-details">
            <Title level={5}>
              Total Yeast:
              <span style={{ fontWeight: 300 }}>{YEAST}</span>
            </Title>
          </Col>
        </Row>
        {getUserAccessPrivilege?.logisticsMakeAction && (
          <Row className="mb-20">
            <Col>
              <TransferToFeed />
            </Col>
            <Col className="ml-20">
              <StockOutput />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
}

RmStockCard.propTypes = {
  cage: PropTypes.object,
};

export default RmStockCard;
