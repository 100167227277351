/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Card, Typography, Menu, Row, Dropdown, notification, Modal, Col, Button } from 'antd';
import {
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchCages, deleteCage } from '../../reducers/NextFly.slice';

// reducers
import { selectSessionUser } from '../../reducers/Session.slice';

// local components
import { momentFoo } from '../Shared/helper';
import CleaningAction from '../NextFly/Cleaning/CleaningAction';
import WateringAction from '../NextFly/Watering/WateringAction';
import AttractantAction from '../NextFly/Attractant/AttractantAction';

// const
import { dateFormat } from '../../common/const';

/* -------------------------------------------------------------------------- */
/*                                  Cage Item                                 */
/* -------------------------------------------------------------------------- */
function CageItem(props) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const { confirm } = Modal;
  const { cage } = props;
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const menu = (
    <Menu>
      <Menu.Item key="2" icon={<EditOutlined />}>
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          confirm({
            title: 'Do you want to delete Cage ?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              dispatch(deleteCage(cage.id))
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: 'Delete Cage',
                    description: 'Cage was Deleted successfully',
                  });
                  dispatch(fetchCages());
                })
                .catch(() =>
                  notification.error({
                    message: 'Delete Cage',
                    description: 'An error occured',
                  }),
                );
            },
          })
        }
        key="1"
        icon={<DeleteOutlined />}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  // eslint-disable-next-line camelcase
  const { lastEggCollection, lastCleaning, lastWatering, lastAttractant, cage_intorducings } = cage;
  const pupaAmount = cage_intorducings.length
    ? cage_intorducings.reduce((acc, c) => parseFloat(acc) + parseFloat(c.pupaeTotalWeight),0)
    : 0;
  
    const lastReintroductionDate = momentFoo(cage_intorducings.at(-1)?.updated_at).format(dateFormat);
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="card-item-container">
      <Card bordered className="card-item">
          <Row justify="end" span={24}  className="card-item-actions">
            {getUserAccessPrivilege?.nextFlyModification && (
              <div>
                <Dropdown overlay={menu} placement="topRight">
                  <EllipsisOutlined style={{ fontSize: 26, color: '#E1E1E1' }} />
                </Dropdown>
              </div>
            )}
          </Row>

          <Row className="card-item-header mb-20">
            <Col span={12}>
              <span className="card-item-title">{cage.ref}</span>
            </Col>
          </Row>

        <Row className="content-card-details">
          <Col span={24}>
            <Title level={5}>
              Last eggs collected (g):
              <span style={{ fontWeight: 300 }}>{lastEggCollection && lastEggCollection.eggQuantity}</span>
            </Title>
          </Col>
          {/* {cage.chambre && (
            <Col span={24}>
              <Title level={5}>
                Chamber: <span style={{ fontWeight: 300 }}>{cage.chambre}</span>
              </Title>
            </Col>
          )} */}

          {/* {lastIntorduction && (
            <Col span={24}>
              <Title level={5}>
                Date:
                <span style={{ fontWeight: 300 }}>
                  {lastIntorduction && lastIntorduction.introducingDate ? lastIntorduction.introducingDate : ' - '}
                </span>
              </Title>
            </Col>
          )} */}
          <Col span={24}>
            <Title level={5}>
              Last quantity reintroduced (kg):
              <span style={{ fontWeight: 300 }}>{pupaAmount ? pupaAmount.toFixed(2) : '-'}</span>
            </Title>
          </Col>
          <Col span={24}>
            <Title level={5}>
              Last reintroduction:
              <span style={{ fontWeight: 300 }}>{lastReintroductionDate}</span>
            </Title>
          </Col>
          {expanded && (
            <Row>
              <Col span={24}>
                <Title level={5}>
                  Last egg collection:
                  <span style={{ fontWeight: 300 }}>
                    {lastEggCollection && lastEggCollection.hour
                      ? momentFoo(lastEggCollection.hour).format(dateFormat)
                      : '-'}
                  </span>
                </Title>
              </Col>
              <Col span={24} >
                <Title level={5}>
                  Last change of towel:{' '}
                  <span style={{ fontWeight: 300 }}>
                    {lastWatering && lastWatering.date
                      ? momentFoo(lastWatering.date).format(dateFormat)
                      : '-'}
                  </span>
                </Title>
              </Col>
              <Col span={24}>
                <Title level={5}>
                  Last cleaning:{' '}
                  <span style={{ fontWeight: 300 }}>
                    {lastCleaning && lastCleaning.date
                      ? momentFoo(lastCleaning.date).format(dateFormat)
                      : '-'}
                  </span>
                </Title>
              </Col>

              <Col span={24}>
                <Title level={5}>
                  Last change of attractant:
                  <span style={{ fontWeight: 300 }}>
                    {lastAttractant && lastAttractant.date ? lastAttractant.date : '-'}
                  </span>
                </Title>
              </Col>
              
              {
                lastWatering?.water_type &&
                <Col span={24}>
                  <Title level={5}>
                    Water type used: <span style={{ fontWeight: 300 }}> {lastWatering.water_type}</span>
                  </Title>
                </Col>
              }
              <Col span={24}>
                <Title level={5}>
                  Attractant type used:
                  <span style={{ fontWeight: 300 }}> {lastAttractant && lastAttractant.attractant_type}</span>
                </Title>
              </Col>
            </Row>
          )}
      
          <Button
            type="text"
            onClick={() => setExpanded(!expanded)}
            className="card-item-footer-btn-more"
          >
            {expanded ? <MinusOutlined style={{ fontSize: 13 }} /> : <PlusOutlined style={{ fontSize: 13 }} />}
            {expanded ? 'SEE LESS' : 'SEE MORE'}
          </Button>
        
        </Row>
      
   

        {getUserAccessPrivilege?.nextFlyMakeAction && (
          <Row className="card-item-actions" align="middle" justify="space-between">
            <Col className="mt-10">
              <CleaningAction {...props} />
            </Col>
            <Col className="mt-10">
              <WateringAction {...props} />
            </Col>
            <Col className="mt-10">
              <AttractantAction {...props} />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
}

CageItem.propTypes = {
  cage: PropTypes.object,
};

export default CageItem;
