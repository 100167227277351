/* -------------------------------------------------------------------------- */
/*                                   stages                                   */
/* -------------------------------------------------------------------------- */
const stages = {
    NEXTINCUBATOR: 'nextIncubator',
    NEXTLARVAE: 'nextLarvae',
    NEXTPROCESS: 'nextProcess',
    NEXTINCUBATOR_FOR_BREEDING: 'nextIncubatorForBreeding',
    GROWTHSTAGE_FOR_BREEDING : 'growthStageForBreeding',
    NEXTFLY: 'nextFly',
    NEXTFEED: 'nextFeed',
    LOGISTICS: 'logistics',
    DASHBOARD_BATCH_ID: 'dashboardBatchId',
};

const subStages= {
    NEXTBREEDING: {
      INCUBATOR: 'incubator',
      GROWTHSTAGE :'growthStage',
      PUPAESTOCK :'PupaeStock',
    }
}

/* -------------------------------------------------------------------------- */
/*                                 date Format                                */
/* -------------------------------------------------------------------------- */
const dateFormat = 'DD-MM-YYYY';

/* -------------------------------------------------------------------------- */
/*                                   Fields                                   */
/* -------------------------------------------------------------------------- */
const biometricDataFields = {
    nextIncubation: ['biomass', 'hatchingDate'],
    nextLarvea: ['meanLarvaeWeight', 'frass'],
}

const excludedFields = ['createdAt',
    'updatedAt', 'feed_inputs', 'transferred',
    'nLTransferDate', 'collectionName', 'lastFeedDate'
];


export {
    stages,
    excludedFields,
    biometricDataFields,
    dateFormat,
    subStages
}
