/* eslint-disable no-shadow */
/* eslint-disable react/jsx-key */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'antd-form-builder';

// components
import { Card, Row, Col, Button, Modal, Typography, notification, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  selectCages,
  extractEggs,
  getEggPool,
  selectEggPool,
  fetchCages,
  resetEggPool,
} from '../../../reducers/NextFly.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';

// local component
import BatchForm from '../../Batches/BatchForm';
import EditableTable from '../../../sharedComponents/EditableTable';
import { momentFoo } from '../../Shared/helper';
import { dateFormat } from '../../../common/const';

/* -------------------------------------------------------------------------- */
/*                               Eggs Collection                              */
/* -------------------------------------------------------------------------- */
function EggsCollection() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const [showEggCollectionModal, setShowEggCollectionModal] = useState(false);
  const [showBatchFormModal, setShowBatchFormModal] = useState(false);
  const dispatch = useDispatch();
  const cages = useSelector(selectCages);
  const eggs = useSelector(selectEggPool);
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  const [extractionDate,setExtractionDate] = useState(momentFoo(new Date()));
  const [formData, setFormData] = useState();
  const forceUpdate = FormBuilder.useForceUpdate();
  /* ---------------------------------- CONST --------------------------------- */
  const head = [
    {
      title: 'Cage',
      dataIndex: 'chamber',
      key: 'chamber',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Eggs collected (g)',
      dataIndex: 'eggQuantity',
      key: 'eggQuantity',
      editable: true,
      type: 'number'
    },
  ];
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const onClickReset = () => {
    Modal.confirm({
      title: 'Do you want to Reset?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(resetEggPool())
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Reset Egg Pool',
              description: 'Egg Pool was reset successfully',
            });
            dispatch(getEggPool());
          })
          .catch(() =>
            notification.error({
              message: 'Reset Cage',
              description: 'An error occured',
            }),
          );
      },
    });
  };
  const [list,setList] = useState(cages.map((item) => ({
    id: item.id,
    chamber: item.chambre,
    name: item.ref,
    cage: item.id,
    eggQuantity: item.egg_extractions[0] && item.egg_extractions[0].eggQuantity,
  })));

  useEffect(() => {
    dispatch(getEggPool());
  }, []);
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Row>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Eggs Collection"
        width={1000}
        visible={showEggCollectionModal}
        destroyOnClose
        maskClosable={false}
        onOk={() => {
          setShowEggCollectionModal(false);
          dispatch(
            extractEggs({
              data: formData,
              extractionDate
            }),
          ).then(() => {
            dispatch(getEggPool());
            dispatch(fetchCages());
          });
        }}
        onCancel={() => setShowEggCollectionModal(false)}
      >
        <EditableTable
          list={[...list]}
          getFiltered={true}
          columns={head}
          number={500}
          pagination={false}
          handleDate={setExtractionDate}
          onChange={(values) => {
            setList(values);
            forceUpdate();
            setFormData(values);
          }}
        />
      </Modal>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="New Batch"
        width={1000}
        visible={showBatchFormModal}
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={() => setShowBatchFormModal(false)}
      >
        <BatchForm
          onSubmit={() => {
            setShowBatchFormModal(false);
          }}
        />
      </Modal>
      <Col>
        <Card
          className="egg-collection-card"
          actions={
            getUserAccessPrivilege?.nextFlyMakeAction && [
              <Row align="middle" justify="space-around">
                <Col>
                  <Button onClick={() => setShowEggCollectionModal(true)}>Extract Eggs</Button>
                </Col>
                <Col>
                  <Button onClick={() => setShowBatchFormModal(true)}>Add New Batch</Button>
                </Col>
                <Col>
                  <Button onClick={() => onClickReset()}>Reset</Button>
                </Col>
              </Row>,
            ]
          }
        >
          <Title level={2}>Available Eggs (g) :</Title>
          <Title level={2}>{eggs ? eggs.quantity.toFixed(2) : 0}</Title>
          <Divider />
          <Row align='middle'>
            <Title className='marginZero' level={5}>Egg collection date :</Title>
            <Title  className='marginZero ml-10' level={5}>
              {momentFoo(eggs.lastExtactionDate).format(dateFormat)}
            </Title>
          </Row>
  
        </Card>
      </Col>
    </Row>
  );
}

EggsCollection.propTypes = {
  lots: PropTypes.object,
};

export default EggsCollection;
