/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Input, Select, Checkbox, TimePicker, Form, Typography, DatePicker, InputNumber } from 'antd';

import { momentFoo } from '../components/Shared/helper';

const dateFormat = 'DD/MM/YYYY';
export const widgets = {
  NUMBER: 'number',
  STRING: 'string',
  DATE: 'date',
  BOOLEAN: 'boolean',
  SELECT: 'select',
  TEXTAREA: 'textarea',
  TIME: 'time-picker',
};

const Widget = ({ type, value, disabled, options, onChange, max, defaultValue, note, label, level }) => {
  const renderForm = () => {
    let component;
    switch (type) {
      case widgets.STRING:
        component = (
          <Form.Item style={{ marginRight: '24px', minWidth: '200px' }} key={label} label={label}>
            <Input disabled={disabled} value={value} onChange={(e) => onChange(e.target.value)} />
          </Form.Item>
        );
        break;
      case widgets.NUMBER:
        component = (
          <Form.Item required style={{ marginRight: '24px', minWidth: '200px' }} key={label} label={label}>
            <InputNumber min={0} max={max || 200000} disabled={disabled} value={defaultValue} onChange={onChange} />
            <br />
            <span>{note}</span>
          </Form.Item>
        );
        break;
      case widgets.TEXTAREA:
        component = (
          <Form.Item style={{ marginRight: '24px' }} key={label} label={label} required>
            <Input.TextArea
              disabled={disabled}
              value={defaultValue}
              autoSize
              onChange={(e) => onChange(e.target.value)} />
          </Form.Item>
        );
        break;
      case widgets.BOOLEAN:
        component = (
          <Form.Item required style={{ marginRight: '24px' }} key={label} label={label}>
            <Checkbox disabled={disabled} checked={defaultValue} onChange={(e) => onChange(e.target.checked)} />
          </Form.Item>
        );
        break;
      case widgets.DATE:
        component = (
          <Form.Item required style={{ marginRight: '24px' }} key={label} label={label}>

            <DatePicker onChange={onChange} defaultPickerValue={momentFoo()} format={dateFormat} />

          </Form.Item>
        );
        break;
      case widgets.TIME:
        component = (
          <Form.Item required style={{ marginRight: '24px' }} key={label} label={label}>
            <TimePicker showTime onSelect={onChange} value={value} />
          </Form.Item>
        );
        break;
      case widgets.SELECT:
        component = (
          <Form.Item required style={{ marginRight: '24px' }} key={label} label={label}>
            <Select style={{ minWidth: '200px' }} options={options} value={value} />
          </Form.Item>
        );
        break;

      default:
        component = <Typography.Title level={level || 3}>{label}</Typography.Title>;
        break;
    }
    return component;
  };
  return <Row>{renderForm()}</Row>;
};

Widget.propTypes = {
  type: PropTypes.string,
  options: PropTypes.any,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  head: PropTypes.any,
  title: PropTypes.string,
  values: PropTypes.array,
  label: PropTypes.string,
  level: PropTypes.number,
};

export default Widget;
