/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Ui Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Local Components
import { momentFoo } from '../../Shared/helper';

/* -------------------------------------------------------------------------- */
/*                                 Water Form                                 */
/* -------------------------------------------------------------------------- */
function WaterForm({ onSubmit, cage, onChange, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  /* ---------------------------------- CONST --------------------------------- */
  const flyFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && cage && current > momentFoo().endOf('day'),
      },
      rules: [
        {
          required: true,
          message: 'Date is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={flyFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

WaterForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  cage: PropTypes.object,
  onlyFormItems: PropTypes.bool,
};

export default WaterForm;
