/* eslint-disable react/prop-types */
// Packages
import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

// i18n
import { useTranslation } from 'react-i18next';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useSelector } from 'react-redux';

import { momentFoo } from '../../Shared/helper';
import { selectFeedTypes } from '../../../reducers/NextIncubator.slice';
/**
 * Add New batch Form
 */
function IncubatorInputForm({ onSubmit, batch, onChange, onlyFormItems, feed }) {
  const { t } = useTranslation(['stages', 'common']);

  const [form] = Form.useForm();
  const feedTypes = useSelector(selectFeedTypes);

  const incubatorFormFields = [
    {
      key: 'feedDate',
      label: t('nextIncubator.batch.input.fields.date.label'),
      placeholder: t('nextIncubator.batch.input.fields.date.label'),
      widget: 'date-picker',
      initialValue: feed ? momentFoo(feed.feedDate) : momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: t('nextIncubator.batch.input.fields.date.required'),
        },
      ],
    },
    {
      key: 'feed',
      label: t('nextIncubator.batch.input.fields.quantity.label'),
      placeholder: t('nextIncubator.batch.input.fields.quantity.label'),
      widget: 'number',
      initialValue: feed ? feed.feed : null,
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: t('nextIncubator.batch.input.fields.quantity.required'),
        },
      ],
    },
    {
      key: 'incubator_feed_type',
      label: t('nextIncubator.batch.input.fields.type.label'),
      placeholder: t('nextIncubator.batch.input.fields.type.label'),
      widget: 'select',
      options: feedTypes.map((item) => ({ label: item.title, value: item.id })),
      initialValue: feed ? feed.incubator_feed_type : null,
      rules: [
        {
          required: true,
          message: t('nextIncubator.batch.input.fields.type.required'),
        },
      ],
    },
    {
      key: 'humidity',
      label: t('nextIncubator.batch.input.fields.humidity.label'),
      placeholder: t('nextIncubator.batch.input.fields.humidity.label'),
      widget: 'number',
      initialValue: feed ? feed.humidity : null,
      widgetProps: {
        min: 0,
        max: 100,
      },
    },
    {
      key: 'extra_cf',
      label: t('nextIncubator.batch.input.fields.extra_cf.label'),
      placeholder: t('nextIncubator.batch.input.fields.extra_cf.label'),
      widget: 'number',
      initialValue: feed ? feed.extra_cf : null,
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'comment',
      label: t('nextIncubator.batch.input.fields.comment.label'),
      placeholder: t('nextIncubator.batch.input.fields.comment.placeholder'),
      widget: 'textarea',
    },
  ];
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={incubatorFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            {t('common:submit')}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

IncubatorInputForm.propTypes = {
  onSubmit: PropTypes.func,
  batch: PropTypes.object,
  onlyFormItems: PropTypes.bool,
  onChange: PropTypes.func,
};

export default IncubatorInputForm;
