// Packages
import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

import { momentFoo } from '../../Shared/helper';
import { selectAttractantTypes } from '../../../reducers/NextFly.slice';

/**
 * Add New cage Form
 */
function AttractantForm({ onSubmit, onChange, onlyFormItems }) {
  const [form] = Form.useForm();
  const attractants = useSelector(selectAttractantTypes);
  const flyFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && current > momentFoo().endOf('day'),
      },
      rules: [
        {
          required: true,
          message: 'Date is required',
        },
      ],
    },
    {
      key: 'attractant_type',
      label: 'Attractant Type',
      placeholder: 'Attractant Type',
      widget: 'select',
      options: attractants.map((item) => ({ label: item.title, value: item.id })),
      rules: [
        {
          required: true,
          message: 'Attractant Type is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={flyFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

AttractantForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  cage: PropTypes.object,
  onlyFormItems: PropTypes.bool,
};

export default AttractantForm;
