/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Form, Button, notification, InputNumber, Alert, Modal } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// Local Components
import EditableTable from '../../../sharedComponents/EditableTable';
import { findAvailableID, momentFoo } from '../../Shared/helper';

// reducers
import {
  selectFeedEntries,
  createMixes,
  createBrMixes,
  fetchFeedEntries,
  fetchMixes,
  selectCategories,
  selectMixes,
  updateFeedEntry,
  fetchBrMixes,
  selectBrMixes,
  updateBulkFeedEntryMixes,
} from '../../../reducers/NextFeed.slice';
import { phValidator } from '../../Shared/Validators';

/* -------------------------------------------------------------------------- */
/*                                  Mix Form                                  */
/* -------------------------------------------------------------------------- */
function MixForm({ onSubmit, onlyFormItems, isBrMix, isUpdate, currentMix, onUpdate}) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const dispatch = useDispatch();
  const entries = useSelector(selectFeedEntries);
  const mixes = useSelector(selectMixes);
  const brMixes = useSelector(selectBrMixes);
  const categories = useSelector(selectCategories);

  const [weight, setWeight] = useState(currentMix?.actualWeight||0);
  const [errorMsg, setErrorMsg] = useState({
    isVisible: false,
    title: '',
    msg: '',
  });

  useEffect(() => {
    if(isUpdate)
      dispatch(fetchFeedEntries());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const factoredList = entries
    .filter((e) => e.endOfBatch !== true)
    .map((item) => ({
      id: item.id,
      identifier: item.identifier,
      category: item.category.id,
      categoryName: item.category.name,
      percentage: currentMix?.feedEntriesInfos.find((feed) => feed.id === item.id)?.percentage || 0,
      availableWeight: item.availableWeight,
      checked: currentMix?.feedEntriesInfos?.some((feed)=> feed.id === item.id) || false ,
      endOfBatch: item.endOfBatch,
      weight: null,
      entryMixId: currentMix?.entryMixes.find((entry)=> entry.feed_entry === item.id) || null,
      subCategory: item.sub_category.name,
      igredientBatchId: item.identifier,
    }))
    .filter((i) => i.availableWeight > 0);
  /* ---------------------------------- STATE --------------------------------- */
  const [list, setList] = useState(factoredList);

  const [form] = Form.useForm();
  /* -------------------------------- CALLBACKS ------------------------------- */
  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  const validateValues = (checkedList) => {

    if (weight <= 0) {
      setErrorMsg({
        isVisible: true,
        title: 'Total Weight',
        msg: 'Max total weight should be higher then 0',
      });
      return 0;
    }

    // sumAvailableWeight
    let sumAvailableWeight = 0;
    for (let i = 0; i < checkedList.length; i += 1) {
      const element = checkedList[i];
      sumAvailableWeight += element.availableWeight;
    }

    if (sumAvailableWeight < weight) {
      setErrorMsg({
        isVisible: true,
        title: 'Total Weight',
        msg: `Max total weight is ${sumAvailableWeight}`,
      });
      return 0;
    }

    // sumPercentage
    let sumPercentage = 0;
    for (let i = 0; i < checkedList.length; i += 1) {
      const element = checkedList[i];
      sumPercentage += element.percentage;
    }

    if (checkedList.length === 0) {
      setErrorMsg({
        isVisible: true,
        title: 'Batch Selection',
        msg: 'Please select at least one batch!',
      });
      return 0;
    }

    if (sumPercentage !== 100) {
      setErrorMsg({
        isVisible: true,
        title: 'Sum Percentage',
        msg: 'Sum of percentages should equal to 100!',
      });
      return 0;
    }

    if (weight === 0) {
      return 0;
    }

    let checker = false;
    for (let i = 0; i < checkedList.length; i += 1) {
      const element = checkedList[i];
      const purcentageReste = element.availableWeight - (element.percentage * weight) / 100;
      if (purcentageReste < 0) {
        setErrorMsg({
          isVisible: true,
          title: `Percentage error for ${element.identifier}`,
          msg: `Percentage should be more then 0! max Percentage is
          ${percentage(element.availableWeight, weight)}`,
        });
        checker = true;
      }
    }

    if (checker) {
      return 0;
    }

    if (sumPercentage !== 100) {
      setErrorMsg({
        isVisible: true,
        title: 'Sum Percentage',
        msg: 'Sum of percentages should equal to 100!',
      });
      return 0;
    }
    return 1;
  }
  const handleFinish = useCallback((values) => {
    const checkedList = list.filter((item) => item.checked === true);
    if (weight <= 0) {
      setErrorMsg({
        isVisible: true,
        title: 'Total Weight',
        msg: 'Max total weight should be higher then 0',
      });
      return 0;
    }

    // sumAvailableWeight
    let sumAvailableWeight = 0;
    for (let i = 0; i < checkedList.length; i += 1) {
      const element = checkedList[i];
      sumAvailableWeight += element.availableWeight;
    }

    if (sumAvailableWeight < weight) {
      setErrorMsg({
        isVisible: true,
        title: 'Total Weight',
        msg: `Max total weight is ${sumAvailableWeight}`,
      });
      return 0;
    }

    // sumPercentage
    let sumPercentage = 0;
    for (let i = 0; i < checkedList.length; i += 1) {
      const element = checkedList[i];
      sumPercentage += element.percentage;
    }

    if (checkedList.length === 0) {
      setErrorMsg({
        isVisible: true,
        title: 'Batch Selection',
        msg: 'Please select at least one batch!',
      });
      return 0;
    }

    if (sumPercentage !== 100) {
      setErrorMsg({
        isVisible: true,
        title: 'Sum Percentage',
        msg: 'Sum of percentages should equal to 100!',
      });
      return 0;
    }

    if (weight === 0) {
      return 0;
    }

    let checker = false;
    for (let i = 0; i < checkedList.length; i += 1) {
      const element = checkedList[i];
      const purcentageReste = element.availableWeight - (element.percentage * weight) / 100;
      if (purcentageReste < 0) {
        setErrorMsg({
          isVisible: true,
          title: `Percentage error for ${element.identifier}`,
          msg: `Percentage should be more then 0! max Percentage is
          ${percentage(element.availableWeight, weight)}`,
        });
        checker = true;
      }
    }

    if (checker) {
      return 0;
    }

    if (sumPercentage !== 100) {
      setErrorMsg({
        isVisible: true,
        title: 'Sum Percentage',
        msg: 'Sum of percentages should equal to 100!',
      });
      return 0;
    }
      // eslint-disable-next-line mdx/no-unused-expressions
      isBrMix
        ? dispatch(
            createBrMixes({
              ...values,
              currentWeight: weight,
              feed_entries: checkedList.map((entry) => ({
                id: entry.id,
                weight: entry.weight
              })),
            }),
          )
        : dispatch(
            createMixes({
              ...values,
              currentWeight: weight,
              feed_entries: checkedList.map((entry) => ({
                id: entry.id,
                weight: entry.weight,
              })),
            }),
          );
      for (let i = 0; i < checkedList.length; i += 1) {
        const element = checkedList[i];
        dispatch(
          updateFeedEntry({
            id: element.id,
            availableWeight: element.availableWeight - (element.percentage * weight) / 100,
          }),
        )
          .then(unwrapResult)
          .then(() => {
            if (i === checkedList.length - 1) {
              notification.success({
                message: isBrMix ? 'Add New BrMix' : 'Add New Mix',
                description: isBrMix ? 'A new BrMix was added successfully' : 'A new Mix was added successfully',
              });
              onSubmit();
              form.resetFields();
              dispatch(fetchFeedEntries());
              if(isBrMix)
                dispatch(fetchBrMixes());
              else 
                dispatch(fetchMixes());
            }
          })
          .catch(() =>
            notification.error({
              message: 'Add New Mix',
              description: 'An error occured',
            }),
          );
      }
  
      return 0;

  });

  const handleUpdate = () =>{
    const checkedList = list.filter((item) => item.checked === true);
    const isValidForm = validateValues(checkedList);
  
    if(isValidForm)
    {
      const deletedEntries = [];
      const updatedEntries = [];
      const newEntries = list.filter((item)=> item.checked && !item.entryMixId);

      list.filter((item)=> !!item.entryMixId).forEach( (entry) =>  {
        if( !checkedList.find(({entryMixId = null })=> entryMixId?.id === entry?.entryMixId?.id ))
        {
          const { entryMixId ,...rest} = entry;
          deletedEntries.push({
            ...rest,
            weight: entryMixId.weight ,
            feedEntryMixId: entryMixId.id
          });
        }
      });


      checkedList.forEach((item)=>{

        if(item.entryMixId && item.weight && item.entryMixId.weight !== item.weight)
        updatedEntries.push({  
          availableWeight: item.availableWeight + item.entryMixId.weight - item.weight,
          feedEntryId : item.id,
          id: item.entryMixId.id,
          weight: item.weight,
        });
      });

      dispatch(
        updateBulkFeedEntryMixes({
          currentMixId: currentMix.id,
          newEntries,
          deletedEntries,
          updatedEntries
        })
      )
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchFeedEntries());
        if(updatedEntries.length || deletedEntries.length)
        {
          let updatedFeedEntries  = [];
          updatedFeedEntries = list.filter((item) => item.checked && !deletedEntries.some(({id})=>item.id===id));
          onUpdate(updatedFeedEntries);
        }
        
        notification.success({
          message: 'Update weight',
          description: 'Weight updated successfully',
        });
      })
      .catch(() =>
        notification.error({
          message: 'Update weight',
          description: 'An error occured',
        }),
      );
    }    
  }

  const brMixFields = [
    {
      key: 'date',
      label: 'Preparation date',
      placeholder: 'Preparation date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      rules: [
        {
          required: true,
          message: 'Preparation date is required',
        },
      ],
    },
    {
      key: 'palox',
      label: 'N° BrMix',
      placeholder: 'N° BrMix',
      extra: 'Note: N° BrMix should be unique',
      hasFeedback: true,
      required: true,
      initialValue: findAvailableID(brMixes, 'palox'),
      rules: [
        {
          validator: (value) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (brMixes.find((b) => b.palox === value)) {
                  reject(new Error(`N° Mix"${value}" already exists.`));
                } else {
                  resolve();
                }
              }, 1000);
            });
          },
        },
      ],
    },
    {
      key: 'humidity',
      label: 'Humidity',
      placeholder: 'Humidity',
      widget: 'number',
    },
    {
      key: 'ph',
      label: 'pH',
      placeholder: 'pH',
      rules: [{
        validator: phValidator
      }]
    },
  ];

  const mixFields = [
    {
      key: 'date',
      label: 'Preparation date',
      placeholder: 'Preparation date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      rules: [
        {
          required: true,
          message: 'Preparation date is required',
        },
      ],
    },
    {
      key: 'palox',
      label: 'N° Mix',
      placeholder: 'N° Mix',
      extra: 'Note: N° Mix should be unique',
      hasFeedback: true,
      required: true,
      initialValue: findAvailableID(mixes, 'palox'),
      rules: [
        {
          validator: (value) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (mixes.find((b) => b.palox === value)) {
                  reject(new Error(`N° Mix"${value}" already exists.`));
                } else {
                  resolve();
                }
              }, 1000);
            });
          },
        },
      ],
    },
    {
      key: 'humidity',
      label: 'Humidity',
      placeholder: 'Humidity',
      widget: 'number',
    },
    {
      key: 'ph',
      label: 'pH',
      placeholder: 'pH',
    },
  ];

  const head = [
    {
      title: '',
      dataIndex: 'checked',
      key: 'checked',
      editable: true,
      type: 'boolean',
    },
    {
      title: 'Batch',
      dataIndex: 'identifier',
      key: 'identifier',
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      filters: categories.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.categoryName.indexOf(value) === 0,
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      editable: true,
      type: 'number',
    },
    {
      title: 'Weight (kg)',
      dataIndex: 'weight',
      key: 'weight',
      editable: false,
      type: 'number',
      width: '12%'
    },
    {
      title: 'Available Weight',
      dataIndex: 'availableWeight',
      key: 'availableWeight',
      width: '12%'
    },
  ];

  const forceUpdate = FormBuilder.useForceUpdate();

  const handleTotalWeight = (e) => {
    setWeight(e);
  };

  const formSwitcher = () =>
    isUpdate ?        
      <>
        <Form.Item label="Total weight">
          <InputNumber value={weight} onChange={handleTotalWeight} />
        </Form.Item>
        
        <EditableTable
          list={list}
          columns={head}
          number={500}
          pagination={false}
          onRow={(record)=> {
            const { percentage : percent } = record;
            const updatedRecord = record;
            if(percentage)
                updatedRecord.weight = percent * weight / 100;
          }}
          onChange={(values) => {
            setList(values);
            forceUpdate();
          }}
        />
      </>
    :
     <>
      <FormBuilder form={form} meta={isBrMix ? brMixFields : mixFields} />
      {list.length === 0 && (
        <Alert message="Warning" description="There is no more Feed entries" type="warning" showIcon closable />
      )}

      <Modal
        title={errorMsg.title}
        footer={[
          <Button key="back" onClick={() => setErrorMsg({ isVisible: false, title: '', msg: '' })}>
            Ok
          </Button>,
        ]}
        centered
        visible={errorMsg.isVisible}
        closable
      >
        <p>{errorMsg.msg}</p>
      </Modal>
      <Form.Item label="Total weight">
        <InputNumber value={weight} onChange={handleTotalWeight} />
      </Form.Item>
      <EditableTable
        list={[...list]}
        columns={head}
        number={500}
        pagination={false}
        onRow={(record)=> {
          const { percentage : percent } = record;
          const updatedRecord = record;
          if(percentage)
            updatedRecord.weight = percent * weight / 100;
        }}
        onChange={(values) => {
          setList(values);
          forceUpdate();
        }}
      />
      </>
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form 
      layout="horizontal"
      onFinish={isUpdate ? handleUpdate : handleFinish} 
      onValuesChange={forceUpdate} 
      form={form}
    >

    {formSwitcher()}
    {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

MixForm.propTypes = {
  onSubmit: PropTypes.func,
  batch: PropTypes.object,
  onlyFormItems: PropTypes.bool,
  isBrMix: PropTypes.bool,
  isUpdate: PropTypes.bool,
  currentMix: PropTypes.object,
  onUpdate: PropTypes.func
};

export default MixForm;
