// Headers are the columns of csv file 
// Label is the column name
// Key is for mapping data with the corresponding column

export const NEXTFLY_HEADERS = [
    { label:'date', key: 'date' },
    { label:'Operation', key: 'operation' },
    { label:'Cage', key: 'ref' },
    { label:'Chamber', key: 'chamber' },
    { label:'Eggs (g)', key: 'eggQuantity' },
    { label:'Quantity reintroduced (kg)', key: 'quantityReintroduced' },
    { label:'Av. pupae weight (mg)', key: 'pupaeWeight' },
    { label:'From batch', key: 'tempAfterDrying' },
    { label:'Attractant Type', key: 'attractantType' },
    { label:'Evacuation of dead flies', key: 'evacuation' },
    { label:'Operator', key: 'tempAfterDrying' },
    { label:'Comments', key: 'comment' },
  ];