/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Layout, Row, Typography, Col, Tabs, Button, Modal } from 'antd';

// translation
import { useTranslation } from 'react-i18next';

// Local components
import BatchList from '../../components/Batches/BatchList';
import BatchForm from '../../components/Batches/BatchForm';
import { nextIncubationActions } from '../../common/nextIncubation.helper';
import NextBreedingDashboard from './NextBreedingDashboard';
import BreedingList from '../../components/NextBreeding/BreedingList';
import PupaeStockList from '../../components/NextBreeding/PupaeStockList';

// reducers
import { fetchAllBatches } from '../../reducers/Batch.slice';
import { getEggPool } from '../../reducers/NextFly.slice';
import { $setStage, selectSessionUser } from '../../reducers/Session.slice';
import { $setSubStage, selectSubStage } from '../../reducers/NextBreeding.slice';

// Helpers
import { stages, subStages } from '../../common/const';

// styles
import './index.less';

/* -------------------------------------------------------------------------- */
/*                             Next Breeding Page                             */
/* -------------------------------------------------------------------------- */
function NextBreedingPage() {
  /* ---------------------------------- HOOKS --------------------------------- */

  // States
  const [showNextBreedingModal, setShowNextBreedingModal] = useState(false);

  // Dispatcher
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectSessionUser);
  const subStage = useSelector(selectSubStage);

  // Localisation
  const { t } = useTranslation('stages');

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const getUserAccessPrivilege = user.privilege && user.privilege;

  const onTabClick = (selectedTab) => dispatch($setSubStage(selectedTab));

  const { Content } = Layout;
  const { Title } = Typography;
  const { TabPane } = Tabs;

  /* --------------------------------- EFFECTS -------------------------------- */

    useEffect(() => {
      dispatch($setStage(stages.NEXTINCUBATOR_FOR_BREEDING));
      dispatch(fetchAllBatches());
      dispatch(getEggPool());
    }, []);   

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content next-breeding">
      <Row>
        <Col span={22} className="first-title">
          <Title level={1}>{t('nextBreeding.title')}</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey={subStage}  onTabClick={onTabClick} >
        <TabPane tab={t('Dashboard')} key={t('Dashboard')}>
          <NextBreedingDashboard />
        </TabPane>
        <TabPane tab="Incubator" key={subStages.NEXTBREEDING.INCUBATOR}>
          <BatchList actions={nextIncubationActions()} />
          {getUserAccessPrivilege?.nextBreedingMakeAction && (
            <>
              <Button
                className="plus-button"
                type="primary"
                shape="square"
                onClick={() => setShowNextBreedingModal(!showNextBreedingModal)}
              >
                +
              </Button>
              <Modal
                style={{ minHeight: '1500px !important' }}
                title="nextBreeding - New batch"
                width={1000}
                visible={showNextBreedingModal}
                maskClosable={false}
                footer={null}
                destroyOnClose
                onCancel={() => setShowNextBreedingModal(false)}
              >
                <BatchForm onSubmit={() => setShowNextBreedingModal(false)} />
              </Modal>
            </>
          )}
        </TabPane>
        <TabPane tab={<span>Growth stage</span>} key={subStages.NEXTBREEDING.GROWTHSTAGE}>
          <Row className="next-incubation-title">
            <Title level={3}>{t('Batches')}</Title>
          </Row>
          <BreedingList isGrowthStage={true} actions={nextIncubationActions()} />
        </TabPane>
        <TabPane tab={<span>{t('Pupae stock')}</span>} key={subStages.NEXTBREEDING.PUPAESTOCK}>
          <Row className="next-incubation-title">
            <Title level={3}>{t('Batches')}</Title>
          </Row>
          <PupaeStockList />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default NextBreedingPage;
