/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';
import { measureDays } from '../../Shared/Contants';

/* -------------------------------------------------------------------------- */
/*                           Larvae Measurement Form                          */
/* -------------------------------------------------------------------------- */
function LarvaeMeasurementForm({ onSubmit, onChange, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [viewMode] = useState(false);
  const [form] = Form.useForm();
  const larvaeMeasurementFields = [

    {
      key: 'day',
      label: 'Measure',
      placeholder: 'Measure',
      widget: 'select',
      options: measureDays.map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'measure day is required',
        },
      ],
    },
    {
      key: 'larvaeWeight',
      label: 'Measure larvae weight',
      placeholder: 'weight',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Mean larval weight 4th day (mg) is required',
        },
      ],
    },
    {
      key: 'larvae_measurement_comment',
      label: 'Comment',
      placeholder: 'comment',
      widget: 'textarea',
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={larvaeMeasurementFields} viewMode={viewMode} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

LarvaeMeasurementForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

export default LarvaeMeasurementForm;
