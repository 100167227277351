/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */
import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// translation
import { useTranslation } from 'react-i18next';

// UI Components
import { Table, Form, Select, Row, DatePicker, Col } from 'antd';
import Widget from './Widget';
import { dateFormat } from '../common/const';
import { momentFoo } from '../components/Shared/helper';



/* ---------------------------- Editable Context ---------------------------- */
const EditableContext = React.createContext();

/* ------------------------------ Editable Row ------------------------------ */
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

EditableRow.propTypes = {
  index: PropTypes.number,
};


/* ------------------------------ Editable Cell ----------------------------- */
const EditableCell = ({
  title,
  display,
  type,
  options,
  editable,
  excluded,
  children,
  note,
  dataIndex,
  record,
  max,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // toggleEdit();
      handleSave({ ...record, [dataIndex]: values[dataIndex] });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Widget
          ref={inputRef}
          onChange={() => save(dataIndex)}
          type={type}
          defaultValue={childNode[1]}
          options={options}
          max={max}
          note={note}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          minWidth: '200px',
          minHeight: '24px',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

EditableCell.propTypes = {
  editable: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  display: PropTypes.string,
  dataIndex: PropTypes.any,
  children: PropTypes.any,
  handleSave: PropTypes.func,
  record: PropTypes.any,
  excluded: PropTypes.any,
  options: PropTypes.any,
};

/* -------------------------------------------------------------------------- */
/*                               Editable Table                               */
/* -------------------------------------------------------------------------- */
function EditableTable({ columns, list, onChange, pagination, number, 
  getFiltered, onRow,handleDate,isPupaeStock}) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Option } = Select;
  const [dataSource, setDataSource] = useState(list);
  const { t } = useTranslation('stages');
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const [searchChamber, setSearchChamber] = useState({
    chamber: '',
  });
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
    onChange(newData);
  };

  const columnsFactory = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: 'col.title',
        type: col.type || 'string',
        max: record.max,
        note: record.note,
        options: col.options || [],
        handleSave
      }),
    };
  });

  const filteredCages = list
    .filter((item) => item.chamber?.toLowerCase().indexOf(searchChamber.chamber?.toLowerCase()) !== -1)

  // Get Uniq chamber name for filter use
  const chamber = []
  list.map((p) => chamber.push(p.chamber))
  const Uniqchamber = [...new Set(chamber)]

  function getSelectedChamber() {
    return (
      <div>
        <Select
          className="date-picker-input"
          placeholder="Search Chamber"
          onChange={(value) =>
            setSearchChamber({
              ...searchChamber,
              chamber: value,
            })
          }
        >
          {Uniqchamber.map((p) =>
            <Option key={p} value={p}>
              {p}
            </Option>
          )}
        </Select>
      </div>
    )
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
     {getFiltered && (
      <Row className="mb-20" align="middle" justify="space-between">
            <Col>
              {getSelectedChamber()} 
            </Col>

            {!isPupaeStock &&
              <Col>
                <DatePicker
                  placeholder={t('Select Date')}
                  defaultValue={momentFoo(new Date())}
                  onChange={(value) => handleDate(value)}
                  format ={(value) => `Egg collection date : ${value.format(dateFormat)}`}
                />
              </Col>
            }
      </Row>
     )}
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        scroll={{ x: 'auto', y: (number !== null ? number : 0) }}
        dataSource={getFiltered ? filteredCages : dataSource}
        columns={columnsFactory}
        pagination={
          pagination ? {
            defaultPageSize: 5,
            pageSizeOptions: ['10', '20', '30']
          } : false}
        bordered
        onRow={onRow}
      />
    </>
  );
}

EditableTable.propTypes = {
  columns: PropTypes.any,
  dataSource: PropTypes.any,
  title: PropTypes.string,
  pagination: PropTypes.bool,
  number: PropTypes.number,
  getFiltered: PropTypes.bool,
  isPupaeStock: PropTypes.bool,
  onRow: PropTypes.func,
  handleDate: PropTypes.func
};
EditableTable.defaultProps = {
  pagination: true,
  getFiltered: false,
  isPupaeStock: false,
  onRow: null,
  handleDate: null,
}

export default EditableTable;
