/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../common/config';
import { subStages } from '../common/const';

export const fetchBreedingSieving = createAsyncThunk(
  'nextBreeding/fetchBreedingSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/breeding-sievings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createBreedingSieving = createAsyncThunk(
  'nextBreeding/createBreedingSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/breeding-sievings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


export const updateBreedingSieving = createAsyncThunk(
  'nextBreeding/updateBreedingSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/breeding-sievings/${data.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


const NextBreeding = createSlice({
  name: 'NextBreeding',
  initialState: {
    breedingSievings: [],
    subStage : subStages.NEXTBREEDING.INCUBATOR,
  },
  reducers: {
    $setSubStage(state, action) {
      state.subStage = action.payload
  }
  },
  extraReducers: {
    [fetchBreedingSieving.fulfilled]: (state, action) => {
      state.breedingSievings = action.payload;
    },
  },
});

export default NextBreeding.reducer;

// Simple actions
export const { $setSubStage } = NextBreeding.actions;

// Selectors
export const selectSubStage = (state) => state.NextBreeding.subStage;
export const selectBreedingSievings = (state) => state.NextBreeding.breedingSievings;
