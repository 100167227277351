/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect } from 'react';

// Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { createCage, fetchLots, fetchCages, selectCages } from '../../reducers/NextFly.slice';
import { findAvailableID } from '../Shared/helper';

/* -------------------------------------------------------------------------- */
/*                                  Cage Form                                 */
/* -------------------------------------------------------------------------- */
function CageForm({ onSubmit }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const cages = useSelector(selectCages);


  useEffect(() => {
    dispatch(fetchLots());
    dispatch(fetchCages())
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const CageFormFields = [

    {
      key: 'chambre',
      label: 'Room',
      placeholder: 'Room',
      rules: [
        {
          required: true,
          message: 'Room is required',
        },
      ],
    },
    {
      key: 'ref',
      label: 'Name',
      placeholder: 'Name',
      extra: 'Note: Cage Name should be unique',
      hasFeedback: true,
      required: true,
      initialValue: findAvailableID(cages, 'ref'),
      rules: [
        {
          validator: (value) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (cages.find((b) => b.ref === value)) {
                  reject(new Error(`Name "${value}" already exists.`))
                } else {
                  resolve()
                }
              }, 1000)
            })
          },
        },
      ]
    },

    {
      key: 'height',
      label: 'Height (cm)',
      placeholder: 'Height (cm)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Height (cm) is required',
        },
      ],
    },
    {
      key: 'width',
      label: 'Width (cm)',
      placeholder: 'Width (cm)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Width (cm) is required',
        },
      ],
    },
    {
      key: 'cageLength',
      label: 'Length (cm)',
      placeholder: 'Length (cm)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Length (cm) is required',
        },
      ],
    },
    {
      key: 'volume',
      label: 'Volume (m3)',
      placeholder: 'Volume (m3)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      disabled: true,
    },
  ];

  /* ---------------------------------- CONST --------------------------------- */
  const HEIGHT = form.getFieldValue('height') || 0;

  const WIDTH = form.getFieldValue('width') || 0;

  const LENGTH = form.getFieldValue('cageLength') || 0;

  form.setFieldsValue({
    volume: ((HEIGHT * WIDTH * LENGTH) / 1000000)
  })


  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form
      layout="horizontal"
      form={form}
      onValuesChange={forceUpdate}
      onFinish={(values) => {
        dispatch(createCage(values))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Add New Cage',
              description: 'A new Cage was added successfully',
            });
            onSubmit();
            form.resetFields();
            dispatch(fetchCages())
          })
          .catch(() => {
            onSubmit();
            notification.error({
              message: 'Add New Cage',
              description: 'An error occured',
            });
          });
      }}
    >
      <FormBuilder form={form} meta={CageFormFields} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

CageForm.propTypes = {};

export default CageForm;
