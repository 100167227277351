/* eslint-disable react/prop-types */
// Packages
import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useSelector } from 'react-redux';
import { selectDryingBatch, selectMealProducts, selectOilProducts } from '../../../reducers/NextProcess.slice';
/**
 * Add New batch Form
 */
function StockOutputForm({ onSubmit, onlyFormItems }) {
  const [form] = Form.useForm();
  const forceUpdate = FormBuilder.useForceUpdate();
  const nextMealProducts = useSelector(selectMealProducts);
  const nextOilProducts = useSelector(selectOilProducts);
  const dryingBatches = useSelector(selectDryingBatch);

  const dryingFields = [
    {
      key: 'type',
      label: 'Product Type',
      widget: 'radio-group',
      options: ['nextMeal', 'nextOil', 'Dried Larvae'],
      initialValue: 'nextMeal',
    },
    {
      key: 'weight',
      label: 'weight',
      placeholder: 'weight',
      widget: 'number',
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'comment',
    },
  ];

  if (form.getFieldValue('type') === 'Dried Larvae') {
    dryingFields.push({
      key: 'process_drying_batch',
      label: 'Dried Larvae Batch',
      placeholder: 'Batch',
      widget: 'select',
      options: dryingBatches.map((item) => ({ label: item.identifier, value: item.id })),
    });
  }
  if (form.getFieldValue('type') === 'nextMeal') {
    dryingFields.push({
      key: 'nextMeal',
      label: 'Product',
      placeholder: 'nextMeal',
      widget: 'select',
      options: nextMealProducts.map((item) => ({ label: `${item.batchId}`, value: item.id })),
    });
  }

  if (form.getFieldValue('type') === 'nextOil') {
    dryingFields.push({
      key: 'nextOil',
      label: 'Product',
      placeholder: 'nextOil',
      widget: 'select',
      options: nextOilProducts.map((item) => ({ label: `${item.batchId}`, value: item.id })),
    });
  }

  return (
    <Form layout="horizontal" onFinish={onSubmit} form={form} onValuesChange={forceUpdate}>
      <FormBuilder form={form} meta={dryingFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

StockOutputForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default StockOutputForm;
