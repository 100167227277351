/* eslint-disable func-names */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Local Component
import { momentFoo } from '../../Shared/helper';

/* -------------------------------------------------------------------------- */
/*                                Sieving Form                                */
/* -------------------------------------------------------------------------- */
function SievingForm({ onSubmit, onChange, onlyFormItems, batch, nextBreeding }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const getTotalBinsSieved = batch?.larvae_sievings
    .map((item) => item.bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);
  const getTotalNonBinsSieved = batch?.larvae_sievings
    .map((item) => item.non_bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const sievingFormFields = [
    {
      key: 'batchIdentifier',
      label: 'Batch Id',
      widget: 'number',
      disabled: true,
      initialValue: batch.identifier,
    },
    {
      key: 'sievingDate',
      label: 'Sieving Date',
      placeholder: 'sieving date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Sieving Date is required',
        },
      ],
    },

    {
      key: 'bins_sieved',
      label: 'STD bins sieved (nb)',
      placeholder: 'STD bins sieved (nb)',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'STD bins sieved (nb) is required',
        },
      ],
      extra: `Already sieved :
       ${getTotalBinsSieved && getTotalBinsSieved}
       so max is : ${batch.bins - getTotalBinsSieved}`,
      widgetProps: {
        min: 0,
        max: batch.bins - getTotalBinsSieved > 0 ? batch.bins - getTotalBinsSieved : 0,
      },
    },
    {
      key: 'non_bins_sieved',
      label: 'non STD bins sieved (nb)',
      placeholder: 'non STD bins sieved (nb)',
      widget: 'number',
      extra: `Already sieved :
       ${getTotalNonBinsSieved && getTotalNonBinsSieved}
       so max is : ${batch.non_std_bins_out - getTotalNonBinsSieved}`,
      widgetProps: {
        min: 0,
        max: batch.non_std_bins_out - getTotalBinsSieved > 0 ? batch.non_std_bins_out - getTotalBinsSieved : 0,
      },
      rules: [
        {
          required: true,
          message: 'non STD bins sieved (nb)',
        },
      ],
    },
    {
      key: 'larvalWeight',
      label: 'Exit Larval weight (mg)',
      placeholder: 'Exit Larval weight (mg)',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Exit Larval weight is required',
        },
      ],
    },
  ];

  const nextBreeadingSievingFormFields = [
    {
      key: 'sievingDate',
      label: 'Sieving Date',
      placeholder: 'sieving date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Sieving Date is required',
        },
      ],
    },

    {
      key: 'biomass',
      label: 'Biomass (kg)',
      placeholder: '0 Kg',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Biomass(kg) is required',
        },
      ],

      widgetProps: {
        min: 0,
        max: batch.bins - getTotalBinsSieved > 0 ? batch.bins - getTotalBinsSieved : 0,
      },
    },
    {
      key: 'frass',
      label: 'Frass (kg)',
      placeholder: 'Quantity',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Frass(kg) is required',
        },
      ],
    },
    {
      key: 'meanPrepupaeWeight',
      label: 'Mean prepupae weight (g)',
      placeholder: '1',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Mean prepupae weight(g) is required',
        },
      ],
    },

    {
      key: 'comment',
      label: 'Comment (optional)',
      placeholder: 'comment',
      widget: 'textarea',
    },
  ];

  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={nextBreeding ? nextBreeadingSievingFormFields : sievingFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

SievingForm.propTypes = {
  batch: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
  nextBreeding: PropTypes.bool,
};

export default SievingForm;
