/* eslint-disable react/prop-types */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// Components
// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import SievingForm from './SievingForm';

// Reducers
import { fetchAllBatches, updateBatch } from '../../../reducers/Batch.slice';
import { createLarvaeSieving } from '../../../reducers/NextLarvae.slice';
import { createBreedingSieving, fetchBreedingSieving } from '../../../reducers/NextBreeding.slice';

function SievingAction({ batch, disabled, nextBreeding }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    if (nextBreeding) {
      dispatch(
        updateBatch({
          fields: { sieved: true},
          batchId: batch.id,
        }),
      );
      dispatch(
        createBreedingSieving({
          ...data,
          batch: batch.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Sieving',
            description: 'Sieving successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
          dispatch(fetchBreedingSieving());
        })
        .catch(() =>
          notification.error({
            message: 'Batch Sieving',
            description: 'An error occured',
          }),
        );
    } else {
      dispatch(
        createLarvaeSieving({
          ...data,
          batch: batch.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Sieving',
            description: 'Sieving successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: 'Batch Sieving',
            description: 'An error occured',
          }),
        );
    }
  };

  return (
    <div>
      {nextBreeding ? (
        <Button
          className={batch.sieved ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
          disabled={batch.sieved}
          onClick={() => setShowModal(!showModal)}
        >
          Sieving
        </Button>
      ) : (
        <Button
          className={disabled ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
          disabled={disabled}
          onClick={() => setShowModal(!showModal)}
        >
          Sieving
        </Button>
      )}
      <Modal
        style={{ minHeight: '1500px !important' }}
        title={nextBreeding ? 'nextBreeding Growth stage - Sieving' : 'nextLarvae - Sieving'}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <SievingForm nextBreeding={nextBreeding} batch={batch} onSubmit={onClickSubmit} />
      </Modal>
    </div>
  );
}

export default SievingAction;
