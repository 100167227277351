/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Components
import { Layout, Row, Col, Tabs, Typography } from 'antd';

// reducers
import { fetchAllBatches, fetchStatistics, selectNextProcessBatches } from '../../reducers/Batch.slice';
import { $setStage } from '../../reducers/Session.slice';
import { stages } from '../../common/const';

// Local components
import SievedBatchList from '../../components/NextProcess/Killing/SievedBatchList';
import {
  fetchKilledBatches,
  fetchDriedLarvaeBatches,
  fetchAllPress,
  fetchAllBigBag,
  fetchAllNextOil,
  fetchAllNextMeal,
} from '../../reducers/NextProcess.slice';

import KilledBatchList from '../../components/NextProcess/Killing/KilledBatchList';
import DriedLarvaeBatchList from '../../components/NextProcess/Pressing/PressList';
import Stock from '../../components/NextProcess/Stock/Stock';
import NextGrow from '../../components/NextProcess/NextGrow/NextGrow';

// styles
import './index.less';

/* -------------------------------------------------------------------------- */
/*                              Next Process Page                             */
/* -------------------------------------------------------------------------- */
function NextProcessPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const { Content } = Layout;
  const { t } = useTranslation('stages');

  const batches = useSelector(selectNextProcessBatches);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStatistics());
    dispatch($setStage(stages.NEXTPROCESS));
    dispatch(fetchAllBatches());
    dispatch(fetchKilledBatches());
    dispatch(fetchDriedLarvaeBatches());
    dispatch(fetchAllPress());
    dispatch(fetchAllBigBag());
    dispatch(fetchAllNextOil());
    dispatch(fetchAllNextMeal());
  }, []);

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content next-process-page">
      <Row>
        <Col span={22} className="first-title">
          <Title level={1}>{t('nextProcess.title')}</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey={t('Killing & Drying')}>
        <TabPane tab={<span>{t('Killing & Drying')}</span>} key={t('Killing & Drying')}>
          <Row className="next-incubation-title">
            <Title level={3}>List of batches in nextLarvae</Title>
          </Row>
          <SievedBatchList batches={batches} />
          <KilledBatchList />
        </TabPane>
        <TabPane tab={<span>{t('Press')}</span>} key={t('Press')}>
          <Row className="next-incubation-title">
            <Title level={3}>Dried Larvae Batches</Title>
          </Row>
          <DriedLarvaeBatchList />
        </TabPane>
        <TabPane tab={<span>{t('Dried larvae management')}</span>} key={t('Stock managment')}>
          <Stock />
        </TabPane>
        <TabPane tab={<span>{t('NextGrow management')}</span>} key={t('NextGrow managment')}>
          <NextGrow/>
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default NextProcessPage;
