/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes, { object } from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Table, Row, notification, Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import { deleteFeedEntry, fetchFeedEntries } from '../../../reducers/NextFeed.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';

// local components
import FeedEntryUpdate from './FeedEntryUpdate';

// styles
import '../../NextFeed/index.less';
import { momentFoo } from '../../Shared/helper';

/* -------------------------------------------------------------------------- */
/*                               Feed Entry Item                              */
/* -------------------------------------------------------------------------- */
function FeedEntryItem({ feedEntries }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  // remove Feed
  const removeFeed = (feed) => {
    confirm({
      title: 'Do you Want to delete Feed Entry?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteFeedEntry(feed._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Feed Entry',
              description: 'Feed Entry was Deleted successfully',
            });
            dispatch(fetchFeedEntries());
          })
          .catch(() =>
            notification.error({
              message: 'Delete Feed Entry',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  const dataSourceFeedInput =
    feedEntries &&
    feedEntries.map((batch) => ({
      /* ----------------- data for making actions (Edit, Delete) ----------------- */
      batch,
      _id: batch && batch.id,
      /* --------------------------- data for the table --------------------------- */
      receptionDate: batch.date ? batch.date : '-',
      id: batch.identifier ? batch.identifier : '-',
      category: batch.category?.name ? batch.category?.name : '-',
      subcategory: batch.sub_category?.name ? batch.sub_category?.name : '-',
      supplier: batch.supplier?.name ? batch.supplier?.name : '-',
      carrier: batch.carrier ? batch.carrier : '-',
      billedWeight: batch.billedWeight ? batch.billedWeight : '-',
      receivedWeight: batch.weight ? batch.weight : '-',
      unitPrice: batch.unitPrice ? batch.unitPrice : '-',
      rmCoste: batch.price ? batch.price : '-',
      transportCost: batch.carriageCost ? batch.carriageCost : '-',
      totalPriceTnd: batch.price && batch.carriageCost && batch.price + batch.carriageCost,
      totalPriceEuro: '',
    }));

  const columnsFeedInput = [
    {
      title: 'Reception Date',
      dataIndex: 'receptionDate',
      key: 'receptionDate',
      fixed: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => momentFoo(a.receptionDate).diff(b.receptionDate, 'days'),
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      key: 'subcategory',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
    },
    {
      title: 'Billed weight (kg)',
      dataIndex: 'billedWeight',
      key: 'billedWeight',
    },
    {
      title: 'Received weight (kg)',
      dataIndex: 'receivedWeight',
      key: 'receivedWeight',
    },
    {
      title: 'Unit price (dt/T)',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'RM cost (dt)',
      dataIndex: 'rmCoste',
      key: 'rmCoste',
    },
    {
      title: 'Transport cost (dt)',
      dataIndex: 'transportCost',
      key: 'transportCost',
    },
    {
      title: 'Total price (dt)',
      dataIndex: 'totalPriceTnd',
      key: 'totalPriceTnd',
    },
    {
      title: 'Total price (£)',
      dataIndex: 'totalPriceEuro',
      key: 'totalPriceEuro',
    },
    {
      render: (record) =>
        getUserAccessPrivilege?.logisticsModification && (
          <Row>
            <FeedEntryUpdate feed={record} />
            <Button className="ml-20" onClick={() => removeFeed(record)} danger>
              Delete
            </Button>
          </Row>
        ),
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return <Table columns={columnsFeedInput} dataSource={dataSourceFeedInput} scroll={{ x: 2400 }} />;
}

FeedEntryItem.propTypes = {
  feedEntries: PropTypes.arrayOf(object),
};

export default FeedEntryItem;
