// Headers are the columns of csv file 
// Label is the column name
// Key is for mapping data with the corresponding column 
export const NEXTINCUBATOR_HEADERS = [
    { label:'Date', key: 'date' },
    { label:'Operation', key: 'operation'},
    { label:'Batch', key: 'identifier'},
    { label:'Eggs Qty introduced (g)', key: 'eggQty' },
    { label:'N° STD Bins IN', key: 'bins' },
    { label:'Weight/STD Bin IN (g/bin)', key: 'std_weight' },
    { label:'Weight nonSTD Bin IN(g)', key: 'non_std_weight' },
    { label:'Feed Qty (gr)', key: 'feed' },
    { label:'Feed type', key: 'feedType' },
    { label:'Extra CF(g)', key: 'extra_cf' },
    { label:'Hatching day', key: 'hatchingDate' },
    { label:'IncubationTime (day)', key: 'incubationTime' },
    { label:'N° STD Bins OUT', key: 'std_bins_out' },
    { label:'Weight STD Bins OUT (g/bin)', key: 'std_weight_out' },
    { label:'N° nonSTD Bins OUT', key: 'non_std_bins_out' },
    { label:'Weight nonSTD Bins OUT (g/bin)', key: 'non_std_weight_out' },
    { label:'Total Biomass(g)', key: 'biomass' },
    { label:'% of substrate', key: 'substrate' },
    { label:'Mean Larval weight (mg)', key: 'larvaeWeight' },
    { label:'Density / STD bin (larvae/bin)', key: 'std_density' },
    { label:'Density / nSTD bin (larvae/bin)', key: 'non_std_density' },
    { label:'Hatching Rate', key: 'hatchingRate' },
    { label:'FCR', key: 'fcr' },
    { label:'Operator', key: 'operator' },
    { label:'Comments', key: 'comment' },
  ];