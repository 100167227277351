/* eslint-disable react/display-name */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Modal, Button, notification, Table, Empty } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// local Component
import SupplierForm from './SupplierForm';


// reducers
import { fetchSuppliers, selectSuppliers, deleteSupplier } from '../../../reducers/NextFeed.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';


// styles
import './Supplier.less';

/* -------------------------------------------------------------------------- */
/*                                Supplier List                               */
/* -------------------------------------------------------------------------- */
function SupplierList() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const location = useLocation();
  const list = useSelector(selectSuppliers);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege
  /* -------------------------------- CALLBACKS ------------------------------- */
  useEffect(() => {
    dispatch(fetchSuppliers());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  // delete Item
  const deleteItem = (item) => {
    confirm({
      title: 'Do you want to delete Supplier ?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteSupplier(item.id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Supplier',
              description: 'Supplier was Deleted successfully',
            });
            dispatch(fetchSuppliers());
          })
          .catch(() =>
            notification.error({
              message: 'Delete Supplier',
              description: 'An error occured',
            }),
          );
      },
    });
  };


  const head = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link
          to={{ pathname: `/supplier/${record.id}/details`, state: { supplier: record, location: location.pathname } }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Abbreviation',
      dataIndex: 'abbreviation',
      key: 'abbreviation',
    },
    {
      title: 'action',
      key: 'action',
      render: (text, record) => (
        getUserAccessPrivilege?.logisticsModification && (
          <Button onClick={() => deleteItem(record)}>
            <DeleteOutlined /> Remove
          </Button>
        )
      ),
    },
  ];

  const FormModal = () => {
    return (
      <Modal
        style={{ minHeight: '500px !important' }}
        title="New Supplier"
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        destroyOnClose
        onCancel={() => setShowModal(false)}
      >
        <SupplierForm onSubmit={() => setShowModal(!showModal)} />
      </Modal>
    );
  };

  if (list.length === 0) {
    return (
      <div className="empty-data-container">
        <Empty description={<span>Empty list</span>} className="empty-data-content">
          <Button onClick={() => setShowModal(true)} type="primary">
            Create New Supplier
          </Button>
        </Empty>
        {FormModal()}
      </div>
    );
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Table columns={head} dataSource={list} />
      {getUserAccessPrivilege?.logisticsMakeAction && (
        <Button
          type="primary"
          onClick={() => setShowModal(!showModal)}
          className="fab-button"
        >
          +
        </Button>
      )}
      {FormModal()}
    </div>
  );
}

SupplierList.propTypes = {};

export default SupplierList;
