/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Component
import {
  Button, Modal,
  notification,
  Form
} from 'antd';
import FormBuilder from 'antd-form-builder';


// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { momentFoo } from '../../Shared/helper';

// Reducers
import {
  fetchFeedEntries,
  selectFeedEntries,
  fetchOutputs,
  updateFeedEntry
} from '../../../reducers/NextFeed.slice';
import { createOutput } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                               Transfer Action                              */
/* -------------------------------------------------------------------------- */
function TransferToFeed({ onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const feedEntries = useSelector(selectFeedEntries);
  const identifierStock = []

  if (feedEntries) {
    identifierStock.push(
      feedEntries
        .filter((item) => item.currentWeight > 0)
        .map((e) => e.identifier)
    )
  }
  const FEED_IDENTIFIER = form.getFieldValue('identifier');
  const getWeightByIdentifier = feedEntries?.find((b) => (b.identifier).toString() === FEED_IDENTIFIER)

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */

  const onClickSubmit = (data) => {
    const outputValues = {
      feed_entry: getWeightByIdentifier.id,
      currentWeight: data.currentWeight,
    }

    const transferedWeight = getWeightByIdentifier.transferedWeight === 0 ? data.currentWeight :
      (getWeightByIdentifier.transferedWeight + data.currentWeight);

    const feedEntryValue = {
      currentWeight: (getWeightByIdentifier.currentWeight) - (data.currentWeight),
      transferedWeight,
      availableWeight: transferedWeight,
    }

    dispatch(createOutput({ ...outputValues }))
    dispatch(updateFeedEntry({
      ...feedEntryValue,
      id: getWeightByIdentifier.id
    }
    ))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Create Output',
          description: 'Output successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchFeedEntries());
        dispatch(fetchOutputs())
      })
      .catch(() =>
        notification.error({
          message: 'Output',
          description: 'An error occured',
        }),
      );
      
  };
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const outputFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && (current > momentFoo().endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Date is required',
        },
      ],
    },
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      widget: 'select',
      options: identifierStock[0].map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'Identifier is required',
        },
      ],
    },
    {
      key: 'currentWeight',
      label: 'Current weight (kg)',
      placeholder: 'Current weight (kg)',
      widget: 'number',
      initialValue: getWeightByIdentifier?.currentWeight,
      widgetProps: {
        min: 0,
        max: getWeightByIdentifier?.currentWeight
      },
      extra: `max value is ${getWeightByIdentifier?.currentWeight}`,
      rules: [
        {
          required: true,
          message: 'Current weight (kg) is required',
        },
      ],
    },
  ];


  const forceUpdate = FormBuilder.useForceUpdate();

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        type="primary"
        onClick={() => setShowModal(!showModal)}
      >
        Transfer to nextFeed
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Transfer to nextFeed"
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form
          layout="horizontal"
          onFinish={onClickSubmit}
          onValuesChange={forceUpdate}
          form={form}
        >
          <FormBuilder form={form} meta={outputFormFields} />
          {
            !onlyFormItems &&
            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
              <Button htmlType="submit" type="primary">Submit</Button>
            </Form.Item>
          }
        </Form>
      </Modal>
    </div>
  );
}
TransferToFeed.propTypes = {
  onlyFormItems: PropTypes.bool,
};
export default TransferToFeed;
