/* eslint-disable func-names */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Translation
import { useTranslation } from 'react-i18next';
  

// UI Components
import { Card, Typography, Row, Col, Button} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

/* -------------------------------------------------------------------------- */
/*                                  List Item                                 */
/* -------------------------------------------------------------------------- */
function ListItem(props) {
  /* ---------------------------------- HOOKS --------------------------------- */

  const [expanded,setExpanded] = useState();
  const { t } = useTranslation('stages');

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const { Title } = Typography;

  const { actions, extraData,data} = props;

  const {identifier,sievingDate, biomass, frass, meanPrepupaeWeight,reintroduced} = data;


  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0 ? n.toFixed(2) : n;
  }


  const cardDetails = () => (
    <Row className="card-item-content">
      <Row className="card-item-detail">

          <Title level={5}>
            Sieving Date: <span style={{ fontWeight: 300 }}> {sievingDate} </span>
          </Title>
     
        {extraData?.map((item) => (
          <Col span={24} key={item.label}>
            {item.value !== null && item.value !== undefined && (
              <Title level={5}>
                <>
                  {item.label}: <span style={{ fontWeight: 300 }}> {isFloat(6)}</span>
                </>
              </Title>
            )}
          </Col>
        ))}
        <Col span={24}></Col>
        <Col span={24}>
          <Title level={5}>
            Biomass Sieved (kg): <span style={{ fontWeight: 300 }}> {biomass} </span>
          </Title>
        </Col>
    
   
        <Col span={24}>
          <Title level={5}>
            Frass (kg): <span style={{ fontWeight: 300 }}> {frass} </span>
          </Title>
        </Col>

        <Col span={24}>
          <Title level={5}>
              Mean prepupae weight (g): <span style={{ fontWeight: 300 }}>
              {meanPrepupaeWeight}
               </span>
          </Title>
        </Col>

        {
          expanded && reintroduced && 
          <>

          <Col span={24}>
            <Title level={5}>
              Reintroduction date(kg): <span style={{ fontWeight: 300 }}> {data.reintroductionDate} </span>
            </Title>
          </Col>

          <Col span={24}>
            <Title level={5}>
              Biomass introduced(kg): <span style={{ fontWeight: 300 }}> {data.biomassIntroduced} </span>
            </Title>
          </Col>

          <Col span={24}>
            <Title level={5}>
              Impurities (kg): <span style={{ fontWeight: 300 }}> {data.impurities} </span>
            </Title>
          </Col>

          <Col span={24}>
            <Title level={5}>
              ImpuritiesWhileIntroduction (%): <span style={{ fontWeight: 300 }}> 
              {data.impuritiesWhileIntroduction} 
              </span>
            </Title>
          </Col>

          <Col span={24}>
            <Title level={5}>
              Pupae Weight (g): <span style={{ fontWeight: 300 }}> {data.pupaeWeight} </span>
            </Title>
          </Col>

          <Col span={24}>
            <Title level={5}>
              Pupae (%): <span style={{ fontWeight: 300 }}> {data.pupae} </span>
            </Title>
          </Col>

          <Col span={24}>
            <Title level={5}>
              RealDensity (kg): <span style={{ fontWeight: 300 }}> {data.realDensity} </span>
            </Title>
          </Col>
          </>
        }
          
      
            <Button type="text" onClick={() => setExpanded(!expanded)} className="card-item-footer-btn-more">
              {expanded ? <MinusOutlined style={{ fontSize: 13 }} /> : <PlusOutlined style={{ fontSize: 13 }} />}
              {expanded ? t('common:seeLess') : t('common:seeMore')}
            </Button>

        


 
      </Row>
    </Row>
  );



  return (
    <div className="card-item-container">
      <Card bordered className="card-item">

        <Row className="card-item-header">
          <Col span={12}>
            <span className="card-item-title">
                {identifier}
            </span>
          </Col>
          <Col className="card-item-tag" span={12}>
            {/* {badges} */}
          </Col>
        </Row>
        {cardDetails()}
        <Row justify="space-between" className="card-item-actions">
          {actions}
        </Row>
      </Card>
    </div>
  );
}

ListItem.propTypes = {
  identifier: PropTypes.string,
  date: PropTypes.object,
  pathname: PropTypes.object,
  data: PropTypes.array,
  extraData: PropTypes.array,
  actions: PropTypes.any,
  badges: PropTypes.any,
  onClickRemove: PropTypes.func,
  onClickEdit: PropTypes.func,
  batch: PropTypes.object,
};

export default ListItem;
