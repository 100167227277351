/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components UI
import { Alert, Button, Modal, notification } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import EndOfBatchForm from './EndOfBatchForm';


// Reducers
import { fetchAllBatches } from '../../../reducers/Batch.slice';
import { updateLarvaeSieving } from '../../../reducers/NextLarvae.slice';

/* -------------------------------------------------------------------------- */
/*                             End Of Batch Action                            */
/* -------------------------------------------------------------------------- */
function EndOfBatchAction({ batch, label }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();


  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      updateLarvaeSieving({
        id: batch[0].larvae_sievings[0].id,
        fields: {
          ...data,
        },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'End of batch',
          description: 'End of batch successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'End of batch',
          description: 'An error occured',
        }),
      );
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setShowModal(!showModal)}
        danger
      >
        {label || 'END OF BATCH'}
      </Button>

      <Modal
        style={{ minHeight: '1500px !important' }}
        title="End Of Batch"
        width={1000}
        maskClosable={false}
        visible={showModal}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        {batch[0].transferred ?
          <EndOfBatchForm batch={batch[0]} onSubmit={onClickSubmit} />
          :
          <Alert
            message="Warning"
            description={`Batch with identifier { ${batch[0].identifier} } is not seived yet`}
            type="warning"
            showIcon
          />
        }
      </Modal>
    </div>
  );
}

EndOfBatchAction.propTypes = {
  batch: PropTypes.object,
  label: PropTypes.string
};

export default EndOfBatchAction;
