/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';

// UI Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  fetchSuppliers,
  createFeedEntry,
  selectSuppliers,
  fetchCategories,
  selectCategories,
  fetchFeedEntries,
  selectFeedEntries,
} from '../../../reducers/NextFeed.slice';

// helpers
import { findAvailableID, momentFoo } from '../../Shared/helper';


/* -------------------------------------------------------------------------- */
/*                               Feed Entry Form                              */
/* -------------------------------------------------------------------------- */
function FeedEntryForm({ onSubmit }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [subCategories] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const suppliers = useSelector(selectSuppliers);
  const categories = useSelector(selectCategories);
  const feedEntries = useSelector(selectFeedEntries);

  useEffect(() => {
    dispatch(fetchSuppliers());
    dispatch(fetchCategories());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const feedEntryFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'date',
      widget: 'date-picker',
      colSpan: 2,
      initialValue: momentFoo(),
    },
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      colSpan: 2,
      extra: 'Note: Feed Entry should be unique',
      hasFeedback: true,
      required: true,
      initialValue: findAvailableID(feedEntries, 'identifier'),
      rules: [
        {
          validator: (value) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (feedEntries.find((b) => b.identifier === value)) {
                  reject(new Error(`Feed Entry Identifier "${value}" already exists.`));
                } else {
                  resolve();
                }
              }, 1000);
            });
          },
        },
      ],
    },
    {
      key: 'carrier',
      label: 'carrier',
      placeholder: 'carrier',
      colSpan: 2,
    },
    {
      key: 'storage',
      label: 'storage',
      placeholder: 'storage',
      colSpan: 2,
    },
    {
      key: 'supplier',
      label: 'Supplier',
      placeholder: 'Supplier',
      widget: 'select',
      colSpan: 4,
      options: suppliers.map((item) => ({ label: item.name, value: item.id })),
      rules: [
        {
          required: true,
          message: 'Supplier is required',
        },
      ],
    },
    {
      key: 'category',
      label: 'category',
      placeholder: 'category',
      widget: 'select',
      colSpan: 2,
      options: categories.map((item) => ({ label: item.name, value: item.id })),
      onChange: () => {
        form.setFieldsValue({ sub_category: '' });
      },
      rules: [
        {
          required: true,
          message: 'Category is required',
        },
      ],
    },
    {
      key: 'sub_category',
      label: 'SubCategory',
      placeholder: 'SubCategory',
      widget: 'select',
      colSpan: 2,
      options: subCategories,
      rules: [
        {
          required: true,
          message: 'SubCategorie is required',
        },
      ],
    },
    {
      key: 'weight',
      label: 'Received weight (kg)',
      placeholder: 'Received weight (kg)',
      widget: 'number',
      colSpan: 2,
      onChange: (value) => {
        const total = form.getFieldValue('total') || 0;
        if (total > 0) {
          form.setFieldsValue({ pricePerTon: total / (value / 1000) });
        }
      },
      rules: [
        {
          required: true,
          message: 'Weight is required',
        },
      ],
    },
    {
      key: 'price',
      label: 'RM Cost (dt)',
      placeholder: 'RM Cost (dt)',
      widget: 'number',
      colSpan: 2,
      disabled: true,
      onChange: (value) => {
        const carriageCost = form.getFieldValue('carriageCost') || 0;
        const weight = form.getFieldValue('weight') || 0;

        if (value + carriageCost > 0) {
          form.setFieldsValue({
            total: value + carriageCost,
            pricePerTon: (value + carriageCost) / (weight / 1000)
          });
        }
      },
      rules: [
        {
          required: true,
          message: 'RM Cost is required',
        },
      ],
    },
    {
      key: 'billedWeight',
      label: 'Billed weight (kg)',
      placeholder: 'Billed weight (kg)',
      widget: 'number',
      colSpan: 2,
      rules: [
        {
          required: true,
          message: 'Billed weight is required',
        },
      ],
    },
    {
      key: 'total',
      label: 'Total (dt)',
      placeholder: 'Total (dt)',
      widget: 'number',
      colSpan: 2,
      disabled: true,
      rules: [
        {
          required: true,
          message: 'Total (dt) is required',
        },
      ],
    },

    {
      key: 'unitPrice',
      label: 'Unit Price (dt /T)',
      placeholder: 'Unit Price (dt /T)',
      widget: 'number',
      colSpan: 2,
      rules: [
        {
          required: true,
          message: 'Unit Price is required',

        },
      ],
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'pricePerTon',
      label: 'Price per ton (dt)',
      placeholder: 'Price per ton (dt)',
      widget: 'number',
      colSpan: 2,
      disabled: true,
      rules: [
        {
          required: true,
          message: 'Price per ton is required',
        },
      ],
    },
    {
      key: 'carriageCost',
      label: 'Transport cost',
      placeholder: 'Transport cost',
      widget: 'number',
      colSpan: 2,
      rules: [
        {
          required: true,
          message: 'Transport cost is required',
        },
      ],
      onChange: (value) => {
        const price = form.getFieldValue('price') || 0;
        const weight = form.getFieldValue('weight') || 0;
        if (price + value > 0) {
          form.setFieldsValue({
            total: price + value,
            pricePerTon: (price + value) / (weight / 1000)
          });
        }
      },
    },


    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'comment',
      widget: 'textarea',
      colSpan: 4,
    },
  ];

  const dataFactory = (data, label) => {
    if (data) {
      return data.map((item) => ({ label: item[label], value: item.id }));
    }
    return [];
  };

  if (form.getFieldValue('category')) {
    const category = categories.find((item) => item.id === form.getFieldValue('category'));
    if (category) {
      const index = feedEntryFormFields.findIndex((i) => i.key === 'sub_category');

      feedEntryFormFields[index] = {
        ...feedEntryFormFields[index],
        options: dataFactory(category.sub_categories, 'name'),
      };
    }
  }


  const billedWeight = form.getFieldValue('billedWeight') || 0;
  const unitPrice = form.getFieldValue('unitPrice') || 0;
  if (unitPrice === 0) {
    form.setFieldsValue({
      price: 0
    })
  } else if (billedWeight >= 0 && unitPrice >= 0) {
    form.setFieldsValue({
      price: billedWeight * unitPrice / 1000
    })
  }

  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form
      onValuesChange={forceUpdate}
      layout="horizontal"
      form={form}
      onFinish={(values) => {
        const data = {
          ...values,
          currentWeight: values.weight
        }
        dispatch(createFeedEntry(data))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Add New Feed Entry',
              description: 'A new Feed Entry was added successfully',
            });
            onSubmit();
            form.resetFields();
            dispatch(fetchFeedEntries());
          })
          .catch(() => {
            onSubmit();
            notification.error({
              message: 'Add New Feed Entry',
              description: 'An error occured',
            });
          });
      }}
    >
      <FormBuilder
        form={form}
        meta={{
          columns: 4, formItemLayout: [16, 16],
          fields: feedEntryFormFields
        }}
      />
      <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

FeedEntryForm.propTypes = {};

export default FeedEntryForm;