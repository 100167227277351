/* eslint-disable radix */
/* eslint-disable react/jsx-key */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// UI Components
import { Row, Col, List, DatePicker, Input, Switch, Button, Modal, notification } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  selectKilledBatches,
  selectBigBag,
  createBigBag,
  fetchKilledBatches,
  fetchDriedLarvaeBatches,
  fetchAllBigBag,
} from '../../../reducers/NextProcess.slice';
// local components
import { momentFoo } from '../../Shared/helper';
import KilledBatchCardDetails from './KilledBatchCardDetails';
import BigBagForm from './BigBagForm';

/* -------------------------------------------------------------------------- */
/*                              Killed Batch List                             */
/* -------------------------------------------------------------------------- */
function KilledBatchList() {
  /* ---------------------------------- hooks --------------------------------- */
  const { Search } = Input;
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dateFormat = 'YYYY-MM-DD';
  const killedBatches = useSelector(selectKilledBatches);
  const bigBags = useSelector(selectBigBag);
  const dispatch = useDispatch();
  const [searchFormData, setSearchFormData] = useState({
    searchTitle: '',
    searchOperation: [],
    killingDate: '',
  });
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const filtredBatches = checked
    ? killedBatches.filter((item) => item.process_second_drying !== null)
    : killedBatches.filter((item) => item.process_second_drying === null);
  /**
   * Check if Batch is compatible with filters
   * @param {object} batch Batch object
   * @param {*} formData FormData object containes
   * filters(searchTitle, searchKillingDate, searchDryingDate)
   */
  const checkBatch = (batch, formData) => {
    let query = true;
    if (formData.searchTitle !== '') {
      query = query && batch.batch.identifier.toLowerCase().indexOf(formData.searchTitle.toLowerCase()) !== -1;
    }

    if (formData.killingDate) {
      query = query && momentFoo(batch.entryDate).isSame(momentFoo(formData.killingDate), 'date');
    }
    if (formData.searchDryingDate) {
      query =
        query &&
        batch.process_second_drying &&
        batch.process_second_drying.updated_at &&
        momentFoo(batch.process_second_drying.updated_at).isSame(momentFoo(formData.searchDryingDate), 'date');
    }
    return query;
  };

  const onClickSubmit = (data) => {
    dispatch(
      createBigBag({
        ...data,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Big Bag',
          description: 'Big Bag Added successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchDriedLarvaeBatches());
        dispatch(fetchAllBigBag());
        dispatch(fetchKilledBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Big Bag',
          description: 'An error occured',
        }),
      );
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="batch-list">
      <Row className="batch-list-filter card-list-filter-bar" align="middle" justify="space-between">
        <Col>
          <Row align="middle" justify="start">
            <Col>
              <DatePicker
                className="date-picker-input"
                placeholder={'Select Killing Date'}
                format={dateFormat}
                onChange={(value) =>
                  setSearchFormData({
                    ...searchFormData,
                    killingDate: value,
                  })
                }
              />
            </Col>
            <Col className="ml-20">
              <DatePicker
                className="date-picker-input"
                placeholder={'Select Drying Date'}
                format={dateFormat}
                onChange={(value) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchDryingDate: value,
                  })
                }
              />
            </Col>
            <Col className="ml-20">
              <Search
                className="search-input"
                placeholder="Search Batch Id"
                onChange={(e) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchTitle: e.target.value,
                  })
                }
                allowClear
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <span className="switch-input">
            <Switch title="show dried batches" checked={checked} onChange={(value) => setChecked(value)} />
            show dried batches
          </span>
        </Col>
      </Row>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={filtredBatches
          .filter((item) => checkBatch(item, searchFormData))
          .sort((a, b) => momentFoo(b.killingDate) - momentFoo(a.killingDate))}
        renderItem={(item) => <KilledBatchCardDetails item={item} />}
      />

      <Button className="plus-button" type="primary" shape="square" onClick={() => setShowModal(!showModal)}>
        ADD Big Bag
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Add new Big Bag Id"
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        destroyOnClose
        onCancel={() => setShowModal(false)}
      >
        <BigBagForm onSubmit={onClickSubmit} bigBags={bigBags} />
      </Modal>
    </div>
  );
}

export default KilledBatchList;
