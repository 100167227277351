/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';

// Proptypes
import PropTypes from 'prop-types';

// UI Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Local Components
import { momentFoo } from '../../Shared/helper';

/* -------------------------------------------------------------------------- */
/*                           AerationForm                          */
/* -------------------------------------------------------------------------- */

function AerationForm({ onSubmit, batch }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [viewMode] = useState(false);
  const [aerationTime, setAerationTime] = useState(0);
  const [form] = Form.useForm();

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  form.setFieldsValue({ aerationTime });
  const onChange = (value) => {
    const entryDate = momentFoo(batch.entryDate);
    setAerationTime(value.aerationDate ? value.aerationDate.diff(entryDate, 'days') : 0);
  };
  const BreedingFormFields = [
    {
      key: 'aerationDate',
      label: 'Aeration Date',
      placeholder: 'Aeration Date',
      widget: 'date-picker',
      widgetProps: {
        disabledDate: (current) => current && batch && current > momentFoo().endOf('day'),
      },
      initialValue: momentFoo(),
      rules: [
        {
          required: true,
          message: 'Aeration Date is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment',
      widget: 'textarea',
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={BreedingFormFields} viewMode={viewMode} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

AerationForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default AerationForm;
