/* eslint-disable radix */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import moment from 'moment-timezone';

/**
 * Sum a property in an array of objects
 * @param {*} data array of object
 * @param {*} key property key
 */
export const sum = (data, key) => {
    if (data && data.length) {
        return data.reduce((acc, c) => acc + c[key], 0);
    }
    return 0;
};

export const findAvailableID = (entries, key) => {
    const entryIDs = entries.map((item) => item[key]).sort((a, b) => b - a);
    const index = entryIDs.shift() || '99';
    return (parseInt(index) + 1).toString();
};


export const getAvailableWeightForDriedLarvae = (item) => {    
    const { process_second_dryings:dryings, presses: _presses } = item;
    const driedLarvae = dryings?.reduce((a, b) => parseInt(a) + parseInt(b.finalWeight), 0) || 0; 
    const presses = _presses?.reduce((a, b) => parseInt(a) + parseInt(b.weight), 0) || 0;
    return ((driedLarvae -presses) > 0 ? (driedLarvae - presses) : 0);
};


export const sortByMonth = (array) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return array && array.slice().sort((a, b) => {
        return months.indexOf(a.name) - months.indexOf(b.name);
    }) || []
};

export const momentFoo = (date) => moment(date).tz('Europe/Paris');