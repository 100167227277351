/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Card, Menu, Button, Dropdown, notification, Tooltip, Comment, Modal, Row, Col, Typography } from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  deletePress,
  fetchAllPress,
  deleteNextMealProduct,
  deleteNextOilProduct,
} from '../../../reducers/NextProcess.slice';

// local components
import { momentFoo } from '../../Shared/helper';
import PressForm from './PressForm';

// Scoped components
const { confirm } = Modal;
const { Title } = Typography;

/* -------------------------------------------------------------------------- */
/*                                 Press Item                                 */
/* -------------------------------------------------------------------------- */
function PressItem({ item }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const menu = (
    <Menu>
      <Menu.Item key="2" icon={<EditOutlined />} onClick={() => setShowModal(true)}>
        Edit
      </Menu.Item>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Update Press"
        width={1000}
        maskClosable={false}
        visible={showModal}
        footer={null}
        destroyOnClose
        onCancel={() => setShowModal(false)}
      >
        <PressForm batch={item} onSubmit={() => setShowModal(false)} />
      </Modal>
      <Menu.Item
        onClick={() =>
          confirm({
            title: 'Do you want to delete Press ?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              dispatch(deletePress(item.id));
              dispatch(deleteNextMealProduct(item.nextMealBatchId));
              dispatch(deleteNextOilProduct(item.nextOilBatchId))
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: 'Delete Press',
                    description: 'Press was Deleted successfully',
                  });
                  dispatch(fetchAllPress());
                })
                .catch(() =>
                  notification.error({
                    message: 'Delete Batch',
                    description: 'An error occured',
                  }),
                );
            },
          })
        }
        key="1"
        icon={<DeleteOutlined />}
      >
        Delete
      </Menu.Item>
    </Menu>
  );
  const extraActions = () => (
    <div>
      <Dropdown.Button overlay={menu} placement="bottomCenter" icon={<EllipsisOutlined />}></Dropdown.Button>
    </div>
  );
  /* -------------------------------- rendring -------------------------------- */
  return (
    <Card bordered className="card-item" extra={extraActions()} actions={[]}>
      <Row>
        <Col span={24} className="card-item-detail">
          <Title level={3}>{item.pressDate}</Title>
        </Col>
        <Col span={24} className="card-item-detail">
          <Title level={5}>
            Dried larvae from:
            <span style={{ fontWeight: 300 }}>{item.big_bags.map((i) => i.bigBagId).join(',')}</span>
          </Title>
        </Col>
        <Col span={24} className="card-item-detail">
          <Title level={5}>
            Weight of dried Larvae (kg): <span style={{ fontWeight: 300 }}>{item.weight}</span>
          </Title>
        </Col>
        <Col span={24} className="card-item-detail">
          <Title level={5}>
            Press period: <span style={{ fontWeight: 300 }}>{item.period}</span>
          </Title>
        </Col>
        <Col span={24} className="card-item-detail">
          <Title level={5}>
            nextMeal (kg): <span style={{ fontWeight: 300 }}>{item.nextMealWeight}</span>
          </Title>
        </Col>
        <Col span={24} className="card-item-detail">
          <Title level={5}>
            nextOil (kg): <span style={{ fontWeight: 300 }}>{item.nextOilWeight}</span>
          </Title>
        </Col>
      </Row>
      {expanded && (
        <Row>
          <Col span={24} className="card-item-detail">
            <Title level={5}>
              nextMeal Batch Id:
              <span style={{ fontWeight: 300 }}>{item.next_meal && item.next_meal.nextMealBatchId}</span>
            </Title>
          </Col>
          <Col span={24} className="card-item-detail">
            <Title level={5}>
              nextOil Batch Id:
              <span style={{ fontWeight: 300 }}>{item.next_oil && item.next_oil.nextOilBatchId}</span>
            </Title>
          </Col>
          <Col span={24} className="card-item-detail">
            <Title level={5}>
              Larvae waste (kg): <span style={{ fontWeight: 300 }}>{item.larvaeWaste}</span>
            </Title>
          </Col>
          <Col span={24} className="card-item-detail">
            <Title level={5}>
              nextMeal waste (kg): <span style={{ fontWeight: 300 }}>{item.nextMealWaste}</span>
            </Title>
          </Col>
          <Col span={24} className="card-item-detail">
            <Title level={5}>
              nextOil waste (kg): <span style={{ fontWeight: 300 }}>{item.nextOilWaste}</span>
            </Title>
          </Col>
        </Row>
      )}
      <Row justify="center">
        <Button
          type="text"
          onClick={() => setExpanded(!expanded)}
          style={{ color: '#46ae5b', textAlign: 'start', padding: 0 }}
        >
          {expanded ? <MinusOutlined style={{ fontSize: 13 }} /> : <PlusOutlined style={{ fontSize: 13 }} />}
          {expanded ? 'See Less' : 'See More'}
        </Button>
      </Row>
      <Row className="mt-20 mb-20" justify="end">
        {item && item.comment && (
          <>
            <Button onClick={() => setShowCommentModal(!showCommentModal)}>See comments</Button>

            <Modal
              style={{ minHeight: '1500px !important', border: 'none' }}
              title="Comments"
              width={1000}
              visible={showCommentModal}
              footer={null}
              closable
              maskClosable={false}
              destroyOnClose
              onCancel={() => setShowCommentModal(!showCommentModal)}
            >
              <Comment
                content={<p>{item.comment}</p>}
                datetime={
                  <Tooltip title={momentFoo(item.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{momentFoo(item.created_at).format('MMMM YYYY HH:mm')}</span>
                  </Tooltip>
                }
              />
            </Modal>
          </>
        )}
      </Row>
    </Card>
  );
}

PressItem.propTypes = {
  item: PropTypes.object,
};

export default PressItem;
