/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

import { AllAverageIncubation, AllEBR, AllFCR, AllHatchingRate, AllMeanLarvalWeight } from './allMeasurements';

/* -------------------------------------------------------------------------- */
/*                           Rates Per Week Component                         */
/* -------------------------------------------------------------------------- */
export default function RatesPerMonth({ data, year }) {
  /* ---------------------------------- HOOKS --------------------------------- */

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="rates-container">
      <div className="single-row">
        <div className="single-rate">
          <span>{`${AllHatchingRate(data, year)} %`}</span>
          <p>Hatching Rate</p>
        </div>
        <div className="single-rate">
          <span>{`${AllMeanLarvalWeight(data, year)} mg`}</span>
          <p>Mean Larval weight</p>
        </div>
        <div className="single-rate">
          <span>{AllAverageIncubation(data, year)}</span>
          <p>larvae/bin STD Density</p>
        </div>
      </div>
      <div className="single-row">
        <div className="single-rate">
          <span>{AllAverageIncubation(data, year)}</span>
          <p>Incubation period</p>
        </div>
        <div className="single-rate">
          <span>{AllEBR(data, year)}</span>
          <p>EBR</p>
        </div>
        <div className="single-rate">
          <span>{AllFCR(data, year)}</span>
          <p>FCR</p>
        </div>
      </div>
    </div>
  );
}

RatesPerMonth.propTypes = {
  data: PropTypes.array,
  year: PropTypes.string,
};
