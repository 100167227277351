/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// i18n
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// Local Components
import BreedingInputFeedingForm from './BreedingInputFeedingForm';

// Reducers
import { fetchAllBatches, fetchBatch } from '../../../reducers/Batch.slice';
import { createLarvaeInput, updateLarveaInput } from '../../../reducers/NextLarvae.slice';
import { fetchMixes, selectBrMixes, updateBrMixes } from '../../../reducers/NextFeed.slice';

/* -------------------------------------------------------------------------- */
/*                        Breeding Input Feeding Action                       */
/* -------------------------------------------------------------------------- */
function BreedingInputFeedingAction({ batch, disabled, feed, label }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(['stages', 'common']);
  const dispatch = useDispatch();
  const brMixes = useSelector(selectBrMixes);
  const getMixDataByID = (MIX_ID) => brMixes?.find((b) => b.id === MIX_ID);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    const MIX_ID = data.mix;
    if (feed) {
      dispatch(
        updateBrMixes({
          id: data.mix,
          currentWeight: getMixDataByID(MIX_ID).currentWeight - data.feed,
        }),
      );
      dispatch(
        updateLarveaInput({
          id: feed.id,
          fields: {
            ...data,
            batch: batch.id,
          },
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: t('nextLarvae.batch.input.update.message'),
            description: t('nextLarvae.batch.input.update.success'),
          });
          setShowModal(!showModal);
          dispatch(fetchBatch(batch.id));
          dispatch(fetchMixes());
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: t('nextLarvae.batch.input.update.message'),
            description: t('nextLarvae.batch.input.update.error'),
          }),
        );
    } else {
      dispatch(
        updateBrMixes({
          id: data.mix,
          currentWeight: getMixDataByID(MIX_ID).currentWeight + feed?.feed - data.feed,
        }),
      );
      dispatch(
        createLarvaeInput({
          ...data,
          batch: batch.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Feed',
            description: 'Feed successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
          dispatch(fetchMixes());
        })
        .catch(() =>
          notification.error({
            message: 'Batch Feed',
            description: 'An error occured',
          }),
        );
    }
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        className={disabled ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        onClick={() => setShowModal(!showModal)}
        disabled={disabled}
      >
        {label || <>Feeding</>}
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="nextBreeding Growth stage - Feeding"
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <BreedingInputFeedingForm batch={batch} onSubmit={onClickSubmit} feed={feed} />
      </Modal>
    </div>
  );
}

export default BreedingInputFeedingAction;
