/* eslint-disable func-names */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Modal, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Local Component
import { momentFoo } from '../../Shared/helper';
import { fetchLarvaeSieving, updateLarvaeSieving } from '../../../reducers/NextLarvae.slice';
import { fetchAllBatches, fetchBatch } from '../../../reducers/Batch.slice';
import { fetchMixes } from '../../../reducers/NextFeed.slice';
import { fetchIncubatorFeedTypes } from '../../../reducers/NextIncubator.slice';

/* -------------------------------------------------------------------------- */
/*                                Sieving Form                                */
/* -------------------------------------------------------------------------- */
function UpdateSievingForm({ onChange, feed, onlyFormItems, batch }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLarvaeSieving());
    dispatch(fetchAllBatches());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      updateLarvaeSieving({
        id: feed.id,
        fields: {
          ...data,
        },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Batch Sieving',
          description: 'Sieving successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchBatch(batch.id));
        dispatch(fetchMixes());
        dispatch(fetchIncubatorFeedTypes());
        dispatch(fetchLarvaeSieving());
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Batch Sieving',
          description: 'An error occured',
        }),
      );
  };

  /* ---------------------------------- CONST --------------------------------- */
  const [form] = Form.useForm();
  const getTotalBinsSieved = batch?.larvae_sievings
    .map((item) => item.bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);
  const getTotalNonBinsSieved = batch?.larvae_sievings
    .map((item) => item.non_bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);
  const sievingFormFields = [
    {
      key: 'sievingDate',
      label: 'Sieving Date',
      placeholder: 'sieving date',
      widget: 'date-picker',
      initialValue: momentFoo(feed?.sievingDate),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Sieving Date is required',
        },
      ],
    },

    {
      key: 'bins_sieved',
      label: 'STD bins sieved (nb)',
      placeholder: 'STD bins sieved (nb)',
      initialValue: feed?.bins_sieved,
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'STD bins sieved (nb) is required',
        },
      ],
      extra: `Max is : ${batch.bins - (getTotalBinsSieved - feed.bins_sieved)}`,
      widgetProps: {
        min: 0,
        max: batch.bins - (getTotalBinsSieved - feed.bins_sieved),
      },
    },
    {
      key: 'non_bins_sieved',
      label: 'non STD bins sieved (nb)',
      placeholder: 'non STD bins sieved (nb)',
      widget: 'number',
      initialValue: feed?.non_bins_sieved,
      extra: `Max is : ${batch.non_std_bins_out - (getTotalNonBinsSieved - feed.non_bins_sieved)}`,
      widgetProps: {
        min: 0,
        max: batch.non_std_bins_out - (getTotalNonBinsSieved - feed.non_bins_sieved),
      },
      rules: [
        {
          required: true,
          message: 'non STD bins sieved (nb)',
        },
      ],
    },
    {
      key: 'larvalWeight',
      label: 'Exit Larval weight (mg)',
      placeholder: 'Exit Larval weight (mg)',
      initialValue: feed?.larvalWeight,
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Exit Larval weight is required',
        },
      ],
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        Edit
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="nextLarvae - Update Sieving"
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form layout="horizontal" onFinish={onClickSubmit} onValuesChange={onChange} form={form}>
          <FormBuilder form={form} meta={sievingFormFields} />
          {!onlyFormItems && (
            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
}

UpdateSievingForm.propTypes = {
  batch: PropTypes.object,
  feed: PropTypes.object,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

export default UpdateSievingForm;
