/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { useHistory } from 'react-router-dom';

// Proptypes
import PropTypes from 'prop-types';

// UI Components
import { Form, Button, Layout, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import { fetchIncubatorFeedTypes } from '../../reducers/NextIncubator.slice';
import { $login } from '../../reducers/Session.slice';

// style
import './LoginPage.less';

/* -------------------------------------------------------------------------- */
/*                                 Login Page                                 */
/* -------------------------------------------------------------------------- */
function LoginPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginFormFields = [
    {
      key: 'identifier',
      label: 'Email',
      placeholder: 'Email',
      rules: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ],
    },
    {
      key: 'password',
      label: 'Password',
      placeholder: 'Password',
      widget: 'password',
      rules: [
        {
          required: true,
          message: 'Please input your password!',
        },
      ],
    },
  ];
  const onSubmit = (values) => {
    dispatch($login(values))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'User Login',
          description: 'User successfully logged in',
        });
        history.push('/');
        dispatch(fetchIncubatorFeedTypes());
      })
      .catch(() =>
        notification.error({
          message: 'User Login',
          description: 'An error occured',
        }),
      );
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Layout className="login-page-layout">
      <Layout.Content className="login-page-content">
        <img id="logo" src="/logo-horizontal-white.png" alt="nextProtein logo" />
        <Form className="login-page-form" layout="horizontal" onFinish={onSubmit} form={form}>
          <FormBuilder form={form} meta={loginFormFields} />
          <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
            <Button htmlType="submit" type="primary">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
}

LoginPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginPage;
