/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState, useEffect } from 'react';
import { Button, Modal, notification } from 'antd';

// i18n
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

/* ----------------------------- Local Component ---------------------------- */
import IncubatorInputForm from './IncubatorInputForm';

// Reducers
import { fetchAllBatches, fetchBatch } from '../../../reducers/Batch.slice';
import {
  createIncubatorInput,
  fetchIncubatorFeedTypes,
  updateIncubatorInput,
} from '../../../reducers/NextIncubator.slice';

/* -------------------------------------------------------------------------- */
/*                           Incubator Input Action                           */
/* -------------------------------------------------------------------------- */
function IncubatorInputAction({ batch, feed }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(['stages', 'common']);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIncubatorFeedTypes());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    if (feed) {
      dispatch(
        updateIncubatorInput({
          id: feed.id,
          fields: {
            ...data,
            batch: batch.id,
          },
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: t('nextIncubator.batch.input.update.message'),
            description: t('nextIncubator.batch.input.update.success'),
          });
          setShowModal(!showModal);
          dispatch(fetchBatch(batch.id));
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: t('nextIncubator.batch.input.update.message'),
            description: t('nextIncubator.batch.input.update.error'),
          }),
        );
    } else {
      dispatch(
        createIncubatorInput({
          ...data,
          batch: batch.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: t('nextIncubator.batch.input.create.message'),
            description: t('nextIncubator.batch.input.create.success'),
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: t('nextIncubator.batch.input.create.message'),
            description: t('nextIncubator.batch.input.create.error'),
          }),
        );
    }
  };
  const hasFeedDate = batch.transferred && !batch.isForBreeding

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        className={hasFeedDate ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={hasFeedDate}
        onClick={() => setShowModal(!showModal)}
      >
        Feeding
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title={t('nextIncubator.batch.input.title')}
        width={1000}
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <IncubatorInputForm batch={batch} onSubmit={onClickSubmit} feed={feed} />
      </Modal>
    </div>
  );
}

export default IncubatorInputAction;
