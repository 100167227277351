/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../common/config';

// create lots
export const createLot = createAsyncThunk(
    'nextFly/createLot',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/lots`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// update lots
export const updateLot = createAsyncThunk(
    'nextFly/updateLot',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'put',
                url: `${API_ENDPOINT}/lots/${data.lotId}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch lots
export const sieveLot = createAsyncThunk(
    'nextFly/sieveLot',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'put',
                url: `${API_ENDPOINT}/lots/sieve/${data.lot}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch lots
export const createFlyStockMeasurement = createAsyncThunk(
    'nextFly/sieveLot',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/fly-stock-measurements`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// Delete a Batch
export const deleteLot = createAsyncThunk(
    'nextFly/deleteLot',
    async(id, { rejectWithValue }) => {
        try {
            const config = {
                method: 'delete',
                url: `${API_ENDPOINT}/lots/${id}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// Delete a Batch
export const deleteCage = createAsyncThunk(
    'nextFly/deleteCage',
    async(id, { rejectWithValue }) => {
        try {
            const config = {
                method: 'delete',
                url: `${API_ENDPOINT}/cages/${id}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// Delete a Batch
export const createCage = createAsyncThunk(
    'nextFly/createCage',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/cages`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// Delete a Batch
export const createCageIntorducing = createAsyncThunk(
    'nextFly/createCageIntorducing',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/cage-intorducings`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);


// fetch Lots
export const fetchLots = createAsyncThunk(
    'nextFly/fetchLots',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/lots`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch Lots
export const fetchCages = createAsyncThunk(
    'nextFly/fetchCages',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/cages`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);


export const fetchCagesForCsv = createAsyncThunk(
    'nextFly/fetchCagesForCsv',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/cages/csv`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);


// Perform bulk actions(attractants,cleaning,watering) to cage(s)
export const createBulkActions = createAsyncThunk(
    'nextFly/createBulkActions',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/cages/bulk`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch feed types
export const fetchFlyFeedTypes = createAsyncThunk(
    'nextFly/fetchFlyFeedTypes',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/fly-feed-types`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch water types
export const fetchWaterTypes = createAsyncThunk(
    'nextFly/fetchWaterTypes',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/water-types`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch attractant types
export const fetchAttractantTypes = createAsyncThunk(
    'nextFly/fetchAttractantTypes',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/attractant-types`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch lots
export const createFlyInput = createAsyncThunk(
    'nextFly/createFlyInput',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/fly-inputs`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

// fetch lots
export const createFlyMeasurement = createAsyncThunk(
    'nextFly/createFlyMeasurement',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/fly-measurements`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

export const createWatering = createAsyncThunk(
    'nextFly/createWatering',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/watering-inputs`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

export const createAttranctant = createAsyncThunk(
    'nextFly/createAttranctant',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/attractants`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

export const createCleaning = createAsyncThunk(
    'nextFly/createCleaning',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/cleanings`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

export const extractEggs = createAsyncThunk(
    'nextFly/extractEggs',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'post',
                url: `${API_ENDPOINT}/bulk-egg-extractions`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

export const resetEggPool = createAsyncThunk(
    'nextFly/resetEggPool',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'put',
                url: `${API_ENDPOINT}/reset-egg-pool`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                data,
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);

export const getEggPool = createAsyncThunk(
    'nextFly/getEggPool',
    async(data, { rejectWithValue }) => {
        try {
            const config = {
                method: 'get',
                url: `${API_ENDPOINT}/egg-pool`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            };
            const payload = await axios(config);
            return payload.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    },
);
// NextFly Slice
const NextFly = createSlice({
    name: 'NextFly',
    initialState: {
        lots: [],
        cages: [],
        feedTypes: [],
        attractantTypes: [],
        waterTypes: [],
        eggPool: null,
        eggPoolCSV: null,
        
    },
    reducers: {},
    extraReducers: {
        [fetchLots.fulfilled]: (state, action) => {
            state.lots = action.payload;
        },
        [fetchWaterTypes.fulfilled]: (state, action) => {
            state.waterTypes = action.payload;
        },
        [fetchAttractantTypes.fulfilled]: (state, action) => {
            state.attractantTypes = action.payload;
        },
        [fetchFlyFeedTypes.fulfilled]: (state, action) => {
            state.feedTypes = action.payload;
        },
        [fetchCages.fulfilled]: (state, action) => {
            state.cages = action.payload;
        },
        [getEggPool.fulfilled]: (state, action) => {
            state.eggPool = action.payload;
        },
        [fetchCagesForCsv.fulfilled]: (state, action) => {
            state.eggPoolCSV = action.payload;
        },
       
    },
});

export default NextFly.reducer

// Selectors
export const selectLots = (state) => state.NextFly.lots;
export const selectCages = (state) => state.NextFly.cages;
export const selectFeedTypes = (state) => state.NextFly.feedTypes;
export const selectAttractantTypes = (state) => state.NextFly.attractantTypes;
export const selectWaterTypes = (state) => state.NextFly.waterTypes;
export const selectEggPool = (state) => state.NextFly.eggPool;
export const selectEggPoolForCsv = (state) => state.NextFly.eggPoolCSV;
export const selectnextFlyLots = (state) => state.NextFly.lots.filter((item) => item.transferred);

