/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Translation
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Card, Button, Row, Typography, Form, Col, Menu, Dropdown, notification, Modal } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EditOutlined,
} from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import {
  deleteMix,
  fetchMixes,
  updateMixes,
  updateBrMixes,
  fetchBrMixes,
  deleteBrMix,
  selectFeedEntries,
} from '../../../reducers/NextFeed.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';
import { momentFoo } from '../../Shared/helper';

// Validators
import { phValidator } from '../../Shared/Validators';



/* -------------------------------------------------------------------------- */
/*                                  Mix Item                                  */
/* -------------------------------------------------------------------------- */
function MixItem({ mix, onlyFormItems, isBrMix }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const { Title } = Typography;
  const [expanded, setExpanded] = useState(false);
  const [updateMixModal, setUpdateMixModal] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  const [isEdit,setIsEdit] = useState(false);

  const feedEntries = useSelector(selectFeedEntries);

  // translation
  const { t } = useTranslation(['stages', 'common']);

  /* ---------------------------------- CONST --------------------------------- */
  const menu = () => (
    <Menu>
      <Menu.Item 
        onClick={() =>
          confirm({
            title: isBrMix ? 'Do you Want to delete BrMix?' : 'Do you Want to delete Mix?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              if (isBrMix)
                dispatch(deleteBrMix(mix.id))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: 'Delete BrMix',
                      description: 'Batch was Deleted successfully',
                    });
                    dispatch(fetchBrMixes());
                  })
                  .catch(() =>
                    notification.error({
                      message: 'Delete BrMix',
                      description: 'An error occured',
                    }),
                  );
              else
                dispatch(deleteMix(mix.id))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: 'Delete Mix',
                      description: 'Batch was Deleted successfully',
                    });
                    dispatch(fetchMixes());
                  })
                  .catch(() =>
                    notification.error({
                      message: 'Delete Mix',
                      description: 'An error occured',
                    }),
                  );
            },
          })
        }
        key="1"
        icon={<DeleteOutlined />}
      >
        Delete
      </Menu.Item>

      <Menu.Item
        key="2"
        icon={<EditOutlined/>}
        onClick={() => {setIsEdit(true);setUpdateMixModal(!updateMixModal)}}
      >
        Edit
      </Menu.Item>
    </Menu>
  );

  const editMixField = [
    {
      key: 'palox',
      label: 'N° Mix',
      widget: 'number',
      initialValue: mix.palox && mix.palox,
      disabled: true,
    },
    {
      key: 'date',
      label: 'Preparation date',
      widget: 'date-picker',
      initialValue: momentFoo(mix.date),
      rules: [
        {
          required: true,
          message: 'Preparation date is required',
        },
      ],
    },
    {
      key: 'humidity',
      label: 'humidity (%)',
      placeholder: '%:',
      widget: 'number',
      initialValue: mix.humidity && mix.humidity,
    },
    {
      key: 'ph',
      label: 'ph',
      placeholder: 'ph:',
      widget: 'number',
      initialValue: mix.ph && mix.ph,
      rules: [
      {
        validator: phValidator
      },
     
    ]
    },
  ];

  const mixFields = [
    {
      key: 'palox',
      label: 'N° Mix',
      widget: 'number',
      initialValue: mix.palox && mix.palox,
      disabled: true,
    },
    {
      key: 'currentWeight',
      label: 'Current weight (kg):',
      placeholder: 'Current weight (kg):',
      widget: 'number',
      initialValue: mix.currentWeight && mix.currentWeight,
      widgetProps: {
        min: 0
      },
    },
  ];
  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- CALLBACKS ------------------------------- */
  const handleFinish = (values) => {

    const formValues ={...values,
      id: mix.id,
      ph: `${values.ph}`
    };
  
    dispatch(
      isBrMix
        ? updateBrMixes(formValues)
        : updateMixes(formValues),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success(
        isEdit ?
          {
            message: 'Edit Mix',
            description:'A new Mix was added successfully',
          }:   
          {
          message: isBrMix ? 'Update BrMix Weight' : 'Update Mix Weight',
          description: isBrMix ? 'A new BrMix was added successfully' : 'A  was added successfully',
          }
        );
        setUpdateMixModal(!updateMixModal);
       // form.resetFields();

        // eslint-disable-next-line mdx/no-unused-expressions
        isBrMix ? dispatch(fetchBrMixes()) : dispatch(fetchMixes());
      })
      .catch(() =>
        notification.error(
        isEdit ?
        {
          message:  'Update Mix Weight',
          description: 'An error occured',
        }:
        {
          message: isBrMix ? 'Update Mix Weight' : 'Update Mix Weight',
          description: 'An error occured',
        }),
      );
  };

  const viewMixDetails = (currentMix)=>{
    const {id,actualWeight,palox} = currentMix;
    const feedEntriesIds = currentMix.feed_entry_mixes.map((item)=> item.feed_entry);
    const feedEntriesInfos = feedEntries.filter((feedEntry) => 
    feedEntriesIds.includes(feedEntry.id)).map((feed) => {
      let feedWeight = 1;
      if(feed.feed_entry_mixes.filter((item)=>item.mix === id)?.length)
      feedWeight = feed.feed_entry_mixes.filter((item)=>item.mix === id)[0]?.weight;
 
      const percentage =  feedWeight*100/actualWeight;
      return{
      id: feed.id,
      category: feed.category.name,
      subCategory : feed.sub_category.name,
      igredientBatchId : feed.identifier,
      weight: Number(feed.availableWeight).toFixed(2) || 0,
      percentage 
      }
    });
    return {pathname:`/batch/${palox}/details`,state:{
      currentMix:{id,palox,actualWeight,feedEntriesInfos,entryMixes:currentMix.feed_entry_mixes}
    }};

  }

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="card-item-container">
      <Card bordered className="card-item">
        <Row className="card-item-header">
          <Col span={20}>
            <span className="card-item-title">{mix.palox}</span>
          </Col>
          <Col className="card-item-actions" span={4}>
            {getUserAccessPrivilege?.nextFeedModification && (
              <div>
                <Dropdown overlay={menu} placement="topRight">
                  <EllipsisOutlined style={{ fontSize: 26, color: '#E1E1E1' }} />
                </Dropdown>
              </div>
            )}
          </Col>
        </Row>
        <Row justify="space-between" className="card-item-content">
          <Row className="card-item-detail">
            <Col span={24}>
              <Title level={5}>
                Preparation date:
                <span style={{ fontWeight: 300 }}>{` ${mix.date}`}</span>
              </Title>
            </Col>

            <Col span={24}>
              <Title level={5}>
                Initial weight (kg):
                <span style={{ fontWeight: 300 }}>{` ${mix.actualWeight}`}</span>
              </Title>
            </Col>

            <Col span={24}>
              <Title level={5}>
                Current weight (kg):
                <span style={{ fontWeight: 300 }}>{` ${mix.currentWeight}`}</span>
              </Title>
            </Col>

            {expanded && (
              <>
                {mix?.humidity && (
                  <Col span={24}>
                    <Title level={5}>
                      Humidity (%):
                      <span style={{ fontWeight: 300 }}>{` ${mix.humidity}`}</span>
                    </Title>
                  </Col>
                )}
                {mix?.ph && (
                  <Col span={24}>
                    <Title level={5}>
                      pH :<span style={{ fontWeight: 300 }}>{` ${mix.ph}`}</span>
                    </Title>
                  </Col>
                )}
              </>
            )}

            <Row align='middle' justify='center'> 
            <Col>
          <Button type="text" onClick={() => setExpanded(!expanded)} style={{ color: '#46ae5b' }}>
            {expanded ? <MinusOutlined style={{ fontSize: 13 }} /> : <PlusOutlined style={{ fontSize: 13 }} />}
            {expanded ? 'SEE LESS' : 'SEE MORE'}
          </Button>
          </Col>
          </Row>
          </Row>
          
        </Row>

        <Row align="middle" justify="space-between" className="mb-20" >
            <Col>
              <Link to={()=>viewMixDetails(mix)}>
                <Button className="card-item-footer-btn-details marginZero" >{t('common:viewDetails')}</Button>
              </Link>
            </Col>

          <Col>
          {getUserAccessPrivilege?.nextFeedMakeAction && (
            <Button 
              type="primary" 
              onClick={() => {
                setIsEdit(false);
                setUpdateMixModal(!updateMixModal)}}
            >
              Update Weight
            </Button>
          )}
          </Col>
          </Row>
      </Card>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Update Weight"
        width={1000}
        visible={updateMixModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setUpdateMixModal(!updateMixModal)}
      >
        <Form layout="horizontal" onFinish={handleFinish} onValuesChange={forceUpdate} form={form}>
          <FormBuilder form={form} meta={isEdit ? editMixField : mixFields}/>

          {!onlyFormItems && (
            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
}

MixItem.propTypes = {
  mix: PropTypes.object,
  onlyFormItems: PropTypes.bool,
  isBrMix: PropTypes.bool,
};

export default MixItem;
