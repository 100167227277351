/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Components UI
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

/* -------------------------------------------------------------------------- */
/*                              End Of Batch Form                             */
/* -------------------------------------------------------------------------- */
function EndOfBatchForm({ onSubmit, onlyFormItems, batch }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();

  const handleFinish = useCallback((values) => {
    onSubmit(values);
  });
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const endOfBatchFormFields = [
    {
      key: 'identifier',
      label: 'Batch id',
      widget: 'number',
      initialValue: batch.identifier && batch.identifier,
      disabled: true,
    },
    {
      key: 'biomass',
      label: 'Biomass sieved (kg)',
      placeholder: 'Biomass sieved (kg)',
      widget: 'number',
      initialValue:
        // eslint-disable-next-line no-nested-ternary
        batch.killed_batches
          ? batch.killed_batches.map((i) => i.biomass).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) > 0
            ? batch.killed_batches.map((i) => i.biomass).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)
            : 0
          : 0,
      disabled: true,
    },
    {
      key: 'frass',
      label: 'Frass (kg)',
      placeholder: 'Frass (kg)',
      widget: 'number',
      initialValue: batch.larvae_sieving && batch.larvae_sieving.frass,
      rules: [
        {
          required: true,
          message: 'Frass (kg) is required',
        },
      ],
    },
    {
      key: 'larvalWeight',
      label: 'Sieving waste (kg)',
      placeholder: 'Sieving waste (kg)',
      widget: 'number',
      initialValue: batch.larvae_sieving && batch.larvae_sieving.larvalWeight,
      rules: [
        {
          required: true,
          message: 'Sieving waste (kg) is required',
        },
      ],
    },
  ];
  const forceUpdate = FormBuilder.useForceUpdate();
  return (
    <Form layout="horizontal" onFinish={handleFinish} onValuesChange={forceUpdate} form={form}>
      <FormBuilder form={form} meta={endOfBatchFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

EndOfBatchForm.propTypes = {
  batch: PropTypes.object,
  onSubmit: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

export default EndOfBatchForm;
