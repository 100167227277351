/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch } from 'react-redux';

// UI Components
import { Modal, Button,Table, Select, Row, Col } from 'antd';

// local components
import { historicColumns, nextGrowColumns, nextMealColumns,
   nextOilColumns, nextGrubColumns } from './Products.metaData.';
import UpdateInfosForm from './UpdateInfosForm';
import WeightForm from '../../NextProcess/Stock/WeightForm';

// Helpers
import { momentFoo } from '../../Shared/helper';
import { dateFormat } from '../../../common/const';

// Slices
import { fetchAllBigBag, fetchAllNextGrow, fetchAllNextMeal,
  fetchNextOilForLogistics } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                              EndProductsTable                              */
/* -------------------------------------------------------------------------- */
function EndProductsTable({tab,dataSource}) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const dispatch = useDispatch();
  // States
  const [data,setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [columns,setColumns] = useState();
  const [isOutputAction,setIsOutputAction] = useState(true);
  const [showUpdateInfosModal,setUpdateInfosModal] = useState(false);
  const [isHistoricTable,setIsHistoricTable] = useState(false);
  
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const { Option } = Select;

  const setProductColumns = () =>{
    switch (tab) {
      case 'nextMeal':
        setColumns(nextMealColumns);
      break;
      case 'nextOil':
        setColumns([...nextOilColumns]);
      break;
      case 'nextGrow':
        setColumns(nextGrowColumns);
      break;
      case 'nextGrub':
        setColumns(nextGrubColumns);
      break;
      default:
        break;
    }
  }
  
  const FormModal = () => {

    let productType = 'next_meal';

    const bigBagsFormatted = ()=>{
      if(tab==='nextOil')
      { 
        productType = 'next_oil';
        return dataSource.map(({nextOilBatchId,initialWeight,...rest})=>({
          ...rest,
          batch: nextOilBatchId,
          weight: initialWeight
        }));
      }

      if(tab==='nextGrow')
      { 
        productType = 'next_grow';
        return dataSource.map((item)=>({
          batch: item.bigBagId,
          ...item
        }));
      }
        
      return dataSource;
    }

    return (
      <Modal
        style={{ minHeight: '500px !important' }}
        title= {isOutputAction ? 'New Output' : 'New Inventory'}
        width={1000}
        visible={showModal}
        footer={null}
        destroyOnClose
        maskClosable={false}
        onCancel={() => setShowModal(false)}
      >
      <WeightForm
        bigBags={bigBagsFormatted()} 
        isOutputForm={isOutputAction}
        onSubmit={()=> setShowModal(false)}
        productType= {productType}
      />
      </Modal>
    );
  };

  const UpdateInfosModal = () => {

    const bigBagsFormatted = ()=>{
      if(tab==='nextOil')
        return dataSource.map(({id,nextOilBatchId,expiryDate,compliant})=>({
          id,
          batch: nextOilBatchId,
          expiryDate,
          compliant
        }));
      return dataSource;
    }

    const productTypeSelector = () =>{
      switch (tab) {
        case 'nextMeal':
          return 'next-meals';
        case 'nextOil':
          return 'next-oils';
        default:
          return 'big-bags';
      }
    }

    return (
      <Modal
        style={{ minHeight: '500px !important' }}
        title= 'Update infos'
        width={1000}
        visible={showUpdateInfosModal}
        footer={null}
        destroyOnClose
        maskClosable={false}
        onCancel={() => setUpdateInfosModal(false)}
      >
      <UpdateInfosForm
        bigBags={bigBagsFormatted()} 
        onSubmit={()=> setUpdateInfosModal(false)}
        productType={productTypeSelector()}
      />
      </Modal>
    );
  };

  // Extract Inventories and Outputs from product
  const getProductHistoric=()=>{ 

      setColumns(historicColumns);
    let historic = [];
    const bigBagIdSelector = tab === 'nextOil' ? 'nextOilBatchId' :'bigBagId' ;
    
    dataSource.forEach(({inventories = [],outputs = [],...rest}) => 
    {
      historic = [
        ...historic,
        ...inventories?.map((item)=>{
            const inventory = {
              ...item,
              reason: 'Update weight',
              date: momentFoo(item.createdAt).format(dateFormat)
            }
            inventory.bigBagId = rest[bigBagIdSelector];
            return inventory;
          }),
        ...outputs?.map(({weight,reason,created_at:createdAt })=>
          {
            const output = {
              reason,
              output:weight,
              date: momentFoo(createdAt).format(dateFormat) 
            }
            output.bigBagId = rest[bigBagIdSelector];
            return output;
          }),
      ];
    });  
  
    if(historic.length)
      historic.sort((a,b)=> new Date(b.date) - new Date(a.date));
    setData(historic);
  }

  // Historic actions 
  const performStockAction = (actionType)=> {
    switch (actionType) {
      // Inventory action
      case 0:
        setIsOutputAction(false);
        setShowModal(true);
        break;
      // Output action
      case 1:
        setIsOutputAction(true);
        setShowModal(true);
        break;
      // Update Info action
      case 3: 
        setUpdateInfosModal(true);
        break;
      default:
        break;
    }
  }
    
  const renderStockActions = ()=>{
        return(
          <Row className='ml-40' >
              { tab!=='nextGrub' && 
                <Button 
                  type='primary'
                  onClick={()=> performStockAction(0)}
                >
                  Inventory
                </Button>
              }
              <Button
                type='primary' 
                className='ml-20' 
                onClick={()=> performStockAction(1)}
              >
                Output
              </Button>
              {  !isHistoricTable && tab!=='nextGrow' &&     
                <Button 
                  type='primary' 
                  className='ml-20'
                  onClick={()=> performStockAction(3)}
                >
                  Update infos
                </Button>
              }
          </Row>
         )
  }
 /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    switch (tab) {
      case 'nextMeal':
        dispatch(fetchAllNextMeal());
      break;
      case 'nextOil':
        dispatch(fetchNextOilForLogistics());
      break;
      case 'nextGrow':
        dispatch(fetchAllNextGrow());
      break;
      case 'nextGrub':
        dispatch(fetchAllBigBag());
      break;
      default:
        break;
    }
    setProductColumns();
  }, [tab]);

  useEffect(() => {
      setProductColumns();
      if(dataSource?.length) 
      if(isHistoricTable) getProductHistoric();
      else setData(dataSource);
  }, [dataSource,isHistoricTable]);


  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
    <Row align='middle' className='mb-20'>
      <Col>
        <Select 
          defaultValue="stock"  
          onChange={()=>setIsHistoricTable(!isHistoricTable)}
        >
          <Option value="stock">Stock</Option>
          <Option value="historic">Historic</Option>
        </Select>
      </Col>
      <Col>
      {renderStockActions()}
      </Col>
    </Row>
     <FormModal/>
     <UpdateInfosModal />
    <Table columns={columns} dataSource={data} />
    </div>
  );
}

EndProductsTable.propTypes = {
  tab: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired
};

export default EndProductsTable;