import { Table } from 'antd';
import React,{ useState } from 'react';
import PropTypes from 'prop-types';


const BulkTable = ({cages, selectCages})=> {

    const columns = [
        {
          title: 'Chamber',
          dataIndex: 'chambre',
          filters: cages.map((item)=>{return {text: item.chambre,value: item.id}}),
          onFilter: (value, record) => record.id === value,
        },
        {
          title: 'Cage',
          dataIndex: 'ref',
        },
      ];
   

  const [selectedRowKeys,setSelectedRowKeys] = useState([]);

    const onSelectChange = (_selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(_selectedRowKeys);
        selectCages(selectedRows);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,

    };

    const cagesData = cages.map((item)=>{ return {...item,key:item.id} })

    return ( 
        <Table 
          rowSelection={rowSelection} 
          columns={columns}
          dataSource={cagesData}
          
        />
    )
}

BulkTable.propTypes = {
    cages: PropTypes.array.isRequired,
    selectCages: PropTypes.func,
  };
  

export default BulkTable;
