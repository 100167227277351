/* eslint-disable react/prop-types */
// Packages
import React, { useState } from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

import { momentFoo } from '../../Shared/helper';

/**
 * Add New batch Form
 */

function IncubationMeasurementForm({ onSubmit, batch, measure }) {
  const [viewMode] = useState(false);
  const [incubationTime, setIncubationTime] = useState(0);
  const [form] = Form.useForm();
  form.setFieldsValue({ incubationTime });
  const onChange = (value) => {
    const entryDate = momentFoo(batch.entryDate);
    setIncubationTime(value.hatchingDate ? value.hatchingDate.diff(entryDate, 'days') : 0);
  };
  const incubationFormFields = [
    {
      key: 'hatchingDate',
      label: 'Hatching Date',
      placeholder: 'Hatching Date',
      widget: 'date-picker',
      widgetProps: {
        disabledDate: (current) => current && batch && current > momentFoo().endOf('day'),
      },
      initialValue: measure ? momentFoo(measure.hatchingDate) : null,
      rules: [
        {
          required: true,
          message: 'Hatching Date is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment',
      widget: 'textarea',
    },
  ];
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={incubationFormFields} viewMode={viewMode} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

IncubationMeasurementForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default IncubationMeasurementForm;
