/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// UI Components
import { Modal, Button, Empty } from 'antd';

// reducers
import { fetchMixes, selectMixes, fetchBrMixes, selectBrMixes } from '../../../reducers/NextFeed.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';

// local components
import MixForm from './MixForm';
import MixList from './MixList';

/* -------------------------------------------------------------------------- */
/*                                     Mix                                    */
/* -------------------------------------------------------------------------- */
function Mix({ isBrMix }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const mixes = useSelector(selectMixes);
  const brMixes = useSelector(selectBrMixes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isBrMix) dispatch(fetchBrMixes());
    else dispatch(fetchMixes());
  }, []);

  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const formModal = () => {
    return (
      <Modal
        style={{ minHeight: '500px !important' }}
        title={isBrMix ? 'New BrMix' : 'New Mix'}
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        destroyOnClose
        onCancel={() => setShowModal(false)}
      >
        <MixForm 
          isBrMix={isBrMix} 
          onSubmit={() => setShowModal(!showModal)}  
        />
      </Modal>
    );
  };

  if (mixes.length === 0) {
    return (
      <div className="empty-data-container">
        <Empty description={<span>Empty list</span>} className="empty-data-content">
          <Button onClick={() => setShowModal(true)} type="primary">
            Create New Mix
          </Button>
        </Empty>
        {formModal()}
      </div>
    );
  }

  const sortByPreparationDate = (data)=>{
    const orderedData = [...data];
    orderedData.sort((a,b)=> new Date(b.date) - new Date(a.date));
    return orderedData;
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <MixList isBrMix={isBrMix} mixes={isBrMix ? sortByPreparationDate(brMixes) : sortByPreparationDate(mixes)} />
      {getUserAccessPrivilege?.nextFeedMakeAction && (
        <Button type="primary" onClick={() => setShowModal(!showModal)} className="fab-button">
          +
        </Button>
      )}
      {formModal()}
    </>
  );
}

Mix.propTypes = {
  isBrMix: PropTypes.bool.isRequired,
};

export default Mix;
