/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Button,Modal} from 'antd';
import PupaeStockFrom from '../PupaeStockForm';

/* -------------------------------------------------------------------------- */
/*                               PupaeStock Actions                           */
/* -------------------------------------------------------------------------- */
function PupaeStockAction({batch}) {
  /* ---------------------------------- HOOKS --------------------------------- */
 
  const [showModal, setShowModal] = useState(false);

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>  
      <Button
        className={batch.reintroduced ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={batch.reintroduced}
        onClick={() => setShowModal(!showModal)}
      >
        Reintroduction
      </Button>
      <Modal
        style={{ minHeight: '1500px !important'}}
        title={'Pupae stock - Reintroduction'}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >      
        <PupaeStockFrom batch={batch} onSubmit={() => setShowModal(!showModal)}/>
      </Modal>

    </div>
  );
}
PupaeStockAction.propTypes = {
  disabled: PropTypes.bool,
  batch: PropTypes.object
};
export default PupaeStockAction;
