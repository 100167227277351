/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../common/config';

/* -------------------------------------------------------------------------- */
/*                               Killed Batches                               */
/* -------------------------------------------------------------------------- */

// fetch origins
export const fetchKilledBatches = createAsyncThunk(
  'nextProcess/fetchKilledBatches',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/killed-batches`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createKilledBatch = createAsyncThunk(
  'nextProcess/createKilledBatch',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/killed-batches`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateKilledBatch = createAsyncThunk(
  'nextProcess/updateKilledBatch',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/killed-batches/${data.id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Delete a Batch
export const deleteKilledBatch = createAsyncThunk(
  'nextProcess/deleteKilledBatch',
  async (batchId, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/killed-batches/${batchId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* -------------------------------------------------------------------------- */
/*                             Drying Phase Second                            */
/* -------------------------------------------------------------------------- */
export const fetchDryingPhaseSecond = createAsyncThunk(
  'nextProcess/fetchDryingPhaseSecond',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/process-second-dryings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createDryingPhaseSecond = createAsyncThunk(
  'nextProcess/createDryingPhaseSecond',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/process-second-dryings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDryingPhaseSecond = createAsyncThunk(
  'nextProcess/updateDryingPhaseSecond',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/process-second-dryings/${data.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteDryingPhaseSecond = createAsyncThunk(
  'nextProcess/deleteDryingPhaseSecond',
  async (batchId, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/process-second-dryings/${batchId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* -------------------------------------------------------------------------- */
/*                            Dried Larvae Batches                            */
/* -------------------------------------------------------------------------- */

export const fetchDriedLarvaeBatches = createAsyncThunk(
  'nextProcess/fetchDriedLarvaeBatches',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/process-second-dryings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


/* -------------------------------------------------------------------------- */
/*                                   Inventories                              */
/* -------------------------------------------------------------------------- */

export const createInventory = createAsyncThunk(
  'nextProcess/createInventory',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/inventories`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      const { productType } = data;
      return { ...payload.data, productType };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


export const fetchInventories = createAsyncThunk(
  'nextProcess/fetchInventories',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/inventories`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* -------------------------------------------------------------------------- */
/*                                   OUTPUT                                   */
/* -------------------------------------------------------------------------- */

export const fetchOutputs = createAsyncThunk('nextProcess/fetchOutputs', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/outputs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createOutput = createAsyncThunk('nextProcess/createOutput',
 async (data,{ rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/outputs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    const { productType } = data;
    return {...payload.data,productType};
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                    Press                                   */
/* -------------------------------------------------------------------------- */

export const createPress = createAsyncThunk('nextProcess/createPress', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/presses`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updatePress = createAsyncThunk('nextProcess/updatePress', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/presses/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const fetchAllPress = createAsyncThunk('nextProcess/fetchAllPress', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/presses`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const deletePress = createAsyncThunk('nextProcess/deletePress', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/presses/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const createPressComment = createAsyncThunk(
  'nextProcess/createPressComment',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/comments`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* -------------------------------------------------------------------------- */
/*                                  Products                                  */
/* -------------------------------------------------------------------------- */

export const fetchAllProducts = createAsyncThunk('nextProcess/fetchAllProducts', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/products`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                              NextMeal Product                              */
/* -------------------------------------------------------------------------- */
export const fetchAllNextMeal = createAsyncThunk('nextProcess/fetchAllNextMeal', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/next-meals`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createNextMeal = createAsyncThunk('nextProcess/createNextMeal', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/next-meals`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateNextMeal = createAsyncThunk('nextProcess/updateNextMeal', 
async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/next-meals/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateNextOil = createAsyncThunk('nextProcess/updateNextOil',
 async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/next-oils/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const deleteNextMealProduct = createAsyncThunk(
  'nextProcess/deleteNextMealProduct',
  async (batchId, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/product/deleteByBatchId/${batchId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* -------------------------------------------------------------------------- */
/*                               NextOil Product                              */
/* -------------------------------------------------------------------------- */
export const fetchAllNextOil = createAsyncThunk('nextProcess/fetchAllNextOil', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/next-oils`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchNextOilForLogistics = createAsyncThunk('nextProcess/fetchNextOilForLogistics',
 async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/next-oils/logistics`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createNextOil = createAsyncThunk('nextProcess/createNextOil', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/next-oils`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteNextOilProduct = createAsyncThunk(
  'nextProcess/deleteNextOilProduct',
  async (batchId, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/product/deleteByBatchId/${batchId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
/* -------------------------------------------------------------------------- */
/*                               NextGrow Product                             */
/* -------------------------------------------------------------------------- */
export const fetchAllNextGrow = createAsyncThunk('nextProcess/fetchAllNextGrow', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/next-grows`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createNextGrow = createAsyncThunk('nextProcess/createNextGrow', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/next-grows`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateNextGrow = createAsyncThunk('nextProcess/updateNextGrow', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/next-grows/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteNextGrow = createAsyncThunk('nextProcess/deleteNextGrow', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/next-grows/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                   Big Bag                                  */
/* -------------------------------------------------------------------------- */
export const fetchAllBigBag = createAsyncThunk('nextProcess/fetchAllBigBag', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/big-bags`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createBigBag = createAsyncThunk('nextProcess/createBigBag', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/big-bags`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const updateBigBag = createAsyncThunk('nextProcess/updateBigBag',
 async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/big-bags/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateProduct = createAsyncThunk('nextProcess/updateProduct', 
async (data, { rejectWithValue }) => {
  try {
  
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/${data.productType}/${data.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

/* -------------------------------------------------------------------------- */
/*                                    Slice                                   */
/* -------------------------------------------------------------------------- */

// NextProcess Slice
const NextProcess = createSlice({
  name: 'NextProcess',
  initialState: {
    killedBatches: [],
    driedLarvaeBatches: [],
    dryingBatches: [],
    outputs: [],
    presses: [],
    products: [],
    bigbags: [],
    nextOils: [],
    nextMeals: [],
    nextGrows: [],
    nextGrubs: [],
    nextOilLogistics: [],
    inventories: [],
  
  },
  reducers: {},
  extraReducers: {
    [fetchKilledBatches.fulfilled]: (state, action) => {
      state.killedBatches = action.payload;
    },
    [fetchDriedLarvaeBatches.fulfilled]: (state, action) => {
      state.driedLarvaeBatches = action.payload;
    },
    [fetchDryingPhaseSecond.fulfilled]: (state, action) => {
      state.dryingBatches = action.payload;
    },
    [fetchAllPress.fulfilled]: (state, action) => {
      state.presses = action.payload;
    },
    [fetchOutputs.fulfilled]: (state, action) => {
      state.outputs = action.payload;
    },
    [fetchAllProducts.fulfilled]: (state, action) => {
      state.products = action.payload;
    },
    [fetchAllBigBag.fulfilled]: (state, action) => {
      state.bigbags = action.payload;
    },
    [fetchAllNextMeal.fulfilled]: (state, action) => {
      state.nextMeals = action.payload;
    },
    [fetchAllNextOil.fulfilled]: (state, action) => {
      state.nextOils = action.payload;
    },
    [fetchNextOilForLogistics.fulfilled]: (state, action) => {
      state.nextOilLogistics = action.payload;
    },
    [fetchInventories.fulfilled]: (state, action) => {
      state.inventories = action.payload;
    },
    [updateProduct.fulfilled]: (state, action) => {
      const { payload: updatedItem,meta: {arg: {productType}}} = action;
      
      switch (productType) {
        case 'next-meals':
          state.nextMeals = state.nextMeals.map((item) => item.id === updatedItem?.id ? updatedItem : item);
          break;
        case 'next-oils':
          state.nextOilLogistics = state.nextOilLogistics.map((item) => 
          item.id === updatedItem?.id ? updatedItem : item);
          break;
        case 'big-bags':
        case 'next-grows':
          state.bigbags = state.bigbags.map((item) => item.id === updatedItem?.id ? updatedItem : item);
          break;
        default:
          break;
      }
      
    },
    [fetchAllNextGrow.fulfilled]: (state, action) => {
      state.nextGrows = action.payload;
    },
    [createNextGrow.fulfilled]: (state, action) => {
      state.nextGrows = [...state.nextGrows,action.payload];
    },
    [deleteNextGrow.fulfilled]: (state, action) => {
      const { id: deletedItemId } = action.payload;
      state.nextGrows = state.nextGrows.filter((item) => item.id!==deletedItemId);
    },
    [createOutput.fulfilled]: (state, action) => {

      const { payload } = action;
      const { productType } = payload;
      let selector = 'bigbags';

      switch (productType) {
        case 'next_meal':
          selector = 'nextMeals';
          break;
        case 'next_oil':
          selector = 'nextOilLogistics';
          break;
        case 'next_grub':
          selector = 'nextGrubs';
          break;
        case 'next_grow':
          selector = 'nextGrows';
          break;
        default:
          break;
      }
      state[selector] = state[selector].map((item) => {
        if(item.id === payload[productType]?.id)  
        {
          item?.outputs.push(payload); 
          item.currentWeight-=action.payload.weight;
        }
        return item;
      });

    },
    [createInventory.fulfilled]: (state, action) => {
      const { payload } = action;
      const { productType } = payload;
      let selector = 'bigbags';

      switch (productType) {
        case 'next_meal':
          selector = 'nextMeals';
          break;
        case 'next_oil':
          selector = 'nextOilLogistics';
          break;
        case 'next_grow':
          selector = 'nextGrows';
          break;
        default:
          break;
      }

      state[selector] = state[selector].map((item) => {
        if(item.id === payload[productType]?.id)  
        {
          item?.inventories.push(payload);
          item.currentWeight = action.payload.weight
        }
        return item;
      });
    },
  },
});

export default NextProcess.reducer;

// Selectors
export const selectKilledBatches = (state) => state.NextProcess.killedBatches;
export const selectPresses = (state) => state.NextProcess.presses;
export const selectBigBag = (state) => state.NextProcess.bigbags;
export const selectNextOil = (state) => state.NextProcess.nextOils;
export const selectNextMeal = (state) => state.NextProcess.nextMeals;
export const selectNextGrow = (state) => state.NextProcess.nextGrows;
// export const selectOutputs = (state) => state.NextProcess.outputs;
export const selectDryingBatch = (state) => state.NextProcess.dryingBatches;
export const selectDriedLarvalBatches = (state) => state.NextProcess.driedLarvaeBatches;
export const selectProducts = (state) => state.NextProcess.products;
export const selectMealProducts = (state) => state.NextProcess.products.filter((i) => i.type === 'nextmeal');
export const selectOilProducts = (state) => state.NextProcess.products.filter((i) => i.type === 'nextoil');
export const selectNextOilLogistics  = (state) => state.NextProcess.nextOilLogistics;
export const selectFetchInventories = (state) => state.NextProcess.inventories;