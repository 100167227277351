/* eslint-disable react/prop-types */
/* eslint-disable radix */
/* eslint-disable react/jsx-key */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';


// UI Components
import { Row, Col, Card, Menu, Typography, notification, Dropdown, Button, Tooltip, Comment, Modal } from 'antd';
import {
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// local components
import DryingAction from '../Drying/DryingAction';

// reducers
import {
  deleteKilledBatch,
  fetchKilledBatches,
  deleteDryingPhaseSecond,
  selectBigBag,
} from '../../../reducers/NextProcess.slice';

// local components
import { momentFoo } from '../../Shared/helper';
import UpdateKilledForm from './UpdateKilledForm';
import UpdateDryingForm from './UpdateDryingForm';

/* -------------------------------------------------------------------------- */
/*                              Killed Batch List                             */
/* -------------------------------------------------------------------------- */
function KilledBatchCardDetails({ item }) {
  /* ---------------------------------- hooks --------------------------------- */
  const { Title } = Typography;
  const [showKillingModal, setShowKillingModal] = useState(false);
  const [showDryingModal, setShowDryingModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const bigBags = useSelector(selectBigBag);
  const { confirm } = Modal;
  const dispatch = useDispatch();
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const batchActions = () => [
    <DryingAction batch={item} disabled={item.process_second_drying} />,
  ];

  const getBigBagById = item.process_second_drying && bigBags.find((b) => b.id === item.process_second_drying.big_bag);

  // menu
  const menu = (batch) => (
    <Menu>
      <Menu.Item
        key="2"
        icon={<EditOutlined />}
        onClick={() => setShowKillingModal(true)}
      >
        Edit Killing
      </Menu.Item>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Update"
        width={1000}
        visible={showKillingModal}
        maskClosable={false}
        footer={null}
        destroyOnClose
        onCancel={() => setShowKillingModal(false)}
      >
        <UpdateKilledForm
          batch={batch}
          onSubmit={() => setShowKillingModal(false)}
        />
      </Modal>
      {batch.process_second_drying !== null && (
        <>
          <Menu.Item
            key="3"
            icon={<EditOutlined />}
            onClick={() => setShowDryingModal(true)}
          >
            Edit Drying
          </Menu.Item>
          <Modal
            style={{ minHeight: '1500px !important' }}
            title="Update"
            width={1000}
            visible={showDryingModal}
            maskClosable={false}
            footer={null}
            destroyOnClose
            onCancel={() => setShowDryingModal(false)}
          >
            <UpdateDryingForm
              batch={batch}
              onSubmit={() => setShowDryingModal(false)}
            />
          </Modal>
        </>
      )}

      <Menu.Item
        onClick={() =>
          confirm({
            title: 'Do you Want to delete killing Batch  ?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              if (batch.process_second_drying !== null) {
                dispatch(deleteDryingPhaseSecond(batch.process_second_drying.id))
                dispatch(deleteKilledBatch(batch.id))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: 'Delete Batch',
                      description: 'Batch was Deleted successfully',
                    });
                    dispatch(fetchKilledBatches());
                  })
                  .catch(() =>
                    notification.error({
                      message: 'Delete Batch',
                      description: 'An error occured',
                    }),
                  );
              } else {
                dispatch(deleteKilledBatch(batch.id))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: 'Delete Batch',
                      description: 'Batch was Deleted successfully',
                    });
                    dispatch(fetchKilledBatches());
                  })
                  .catch(() =>
                    notification.error({
                      message: 'Delete Batch',
                      description: 'An error occured',
                    }),
                  );
              }

            },
          })
        }
        key="1"
        icon={<DeleteOutlined />}
      >
        Delete
      </Menu.Item>
    </Menu>
  );
  function getCardDetails() {

    return (
      <div>
        <Row>
          <Col span={24}>
            <Title level={5}>
              Killing Date: <span style={{ fontWeight: 300 }}> {item.killingDate}</span>
            </Title>
          </Col>
          <Col span={24}>
            {item && item.totalBiomass &&
              <Title level={5}>
                Biomass(kg):
                <span style={{ fontWeight: 300 }}>
                  {item.totalBiomass}
                </span>
              </Title>
            }
          </Col>
          <Col span={24}>
            {item && item.period !== null &&
              <Title level={5}>
                Killing Period(MIN): <span style={{ fontWeight: 300 }}> {item.period}</span>
              </Title>
            }
          </Col>
          <Col span={24}>
            {item && item.temperature !== null &&
              <Title level={5}>
                Temperature (C): <span style={{ fontWeight: 300 }}> {item.temperature}</span>
              </Title>
            }
          </Col>

          <Col span={24}>
            {item && item.drainingTime !== null &&
              <Title level={5}>
                Draining Time (MIN):<span style={{ fontWeight: 300 }}> {item.drainingTime}</span>
              </Title>
            }
          </Col>
          <Row>
            <Col span={24}>
              {item && item.process_second_drying &&
                item.process_second_drying.created_at !== null &&
                <Title level={5}>
                  Drying Date: <span style={{ fontWeight: 300 }}>
                    {momentFoo(item.process_second_drying.created_at).format('YYYY-MM-DD')}
                  </span>
                </Title>
              }
            </Col>
            <Col span={24}>
              {item && item.process_second_drying && item.process_second_drying.equipement &&
                <Title level={5}>
                  Equipement :<span style={{ fontWeight: 300 }}> {item.process_second_drying.equipement}</span>
                </Title>
              }
            </Col>
            <Col span={24}>
              {item && item.process_second_drying && item.process_second_drying.period !== null &&
                <Title level={5}>
                  Drying Period(H):<span style={{ fontWeight: 300 }}> {item.process_second_drying.period}</span>
                </Title>
              }
            </Col>

            <Col span={24}>
              {item && item.process_second_drying && item.process_second_drying.finalWeight !== null &&
                <Title level={5}>
                  Dried Larvae Weight(KG):
                  <span style={{ fontWeight: 300 }}> {item.process_second_drying.finalWeight}</span>
                </Title>
              }
            </Col>
            <Col span={24}>
              {item && item.process_second_drying !== null &&
                <Title level={5}>
                  BigBag : <span style={{ fontWeight: 300 }}>
                    {getBigBagById && getBigBagById.bigBagId}
                  </span>
                </Title>
              }
            </Col>
            <Col span={24}>
              {item && item.process_second_drying && item.process_second_drying.dryingWaste !== null &&
                <Title level={5}>
                  Drying Waste (KG):<span style={{ fontWeight: 300 }}> {item.process_second_drying.dryingWaste}</span>
                </Title>
              }
            </Col>
            <Col span={24}>
              {item && item.process_second_drying && item.process_second_drying.finalHumidity !== null &&
                <Title level={5}>
                  Final Humidity (%):
                  <span style={{ fontWeight: 300 }}> {item.process_second_drying.finalHumidity}</span>
                </Title>
              }
            </Col>
          </Row>
        </Row>
        <Row justify="space-between">
          <Col>
            {batchActions(item)}
          </Col>
          <Col>
            {item.process_second_drying && item.process_second_drying.comment && (
              <>
                <Button
                  onClick={() => setShowCommentModal(!showCommentModal)}>
                  See comments
                </Button>

                <Modal
                  style={{ minHeight: '1500px !important', border: 'none' }}
                  title="Comments"
                  width={1000}
                  visible={showCommentModal}
                  maskClosable={false}
                  footer={null}
                  closable
                  destroyOnClose
                  onCancel={() => setShowCommentModal(!showCommentModal)}
                >

                  <Comment
                    content={<p>{item.process_second_drying.comment}</p>}
                    datetime={
                      <Tooltip title={momentFoo(item.process_second_drying.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{momentFoo(item.process_second_drying.created_at).format('MMMM YYYY HH:mm')}</span>
                      </Tooltip>
                    }
                  />

                </Modal>
              </>
            )}
          </Col>
        </Row>
      </div>

    )
  }

  // Delete/Update actions
  const extraActions = () => (
    <div>
      <Dropdown overlay={menu(item)} placement="topRight">
        <EllipsisOutlined style={{ fontSize: 26, color: '#E1E1E1' }} />
      </Dropdown>
    </div>
  );
  const getBatchStatus = () => {
    if (item.process_first_drying || item.process_second_drying) {
      return 'Dried';
    }
    return 'killed';
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="batch-card-item-content">
      <Card bordered className="batch-card-item process-killed-batch">
        <Col className="col-right">{extraActions(item)}</Col>
        <Row>
          <Col span={16}>
            <Title level={5}>
              From Batch {item.batch && item.batch.identifier}
              {item.newbatch && `and Batch ${item.newbatch.identifier}`}
            </Title>
          </Col>
          <Col span={8} className="col-right">
            <span className={`process-killing-batch-status ${getBatchStatus(item)}`}>{getBatchStatus(item)}</span>
          </Col>
        </Row>
        {getCardDetails()}
      </Card>
    </div>
  );
}

export default KilledBatchCardDetails;
