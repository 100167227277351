// Packages
import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

import { momentFoo } from '../../Shared/helper';

/**
 * Add New cage Form
 */
function AttractantForm({ onSubmit, cage, onChange, onlyFormItems }) {
  const [form] = Form.useForm();

  const flyFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && cage && (current > momentFoo().endOf('day') || current < momentFoo(cage.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Date is required',
        },
      ],
    },
    {
      key: 'evacuation',
      label: 'Evacuation of dead flies',
      widget: 'checkbox',
      initialValue: true,
    },
    // {
    //   key: 'cleaningProduct',
    //   label: 'Cleanning Product used',
    //   placeholder: 'Cleanning Product used',
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Cleanning Product used is required',
    //     },
    //   ],
    // },
    // {
    //   key: 'pupaeAmount',
    //   label: 'Amount of pupae',
    //   placeholder: 'Amount of pupae',
    //   widget: 'number',
    //   widgetProps: {
    //     min: 0,
    //   },
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Amount of pupae is required',
    //     },
    //   ],
    // },
    // {
    //   key: 'deadFlies',
    //   label: 'dead flies extracted',
    //   placeholder: 'dead flies extracted',
    //   widget: 'number',
    //   widgetProps: {
    //     min: 0,
    //   },
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Dead flies extracted is required',
    //     },
    //   ],
    // },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={flyFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

AttractantForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  cage: PropTypes.object,
  onlyFormItems: PropTypes.bool,
};

export default AttractantForm;
