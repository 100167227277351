

import _ from 'lodash';
import { momentFoo } from '../../Shared/helper';
import { feedingsFORMATTER, growthStageFORMATTER } from '../Data.formatters';
import { formatDate, renameKeys } from '../Data.helpers';

// Restructure json data in order to be filled in csv columns
  export const restructureDataForGrowthStage = (data,onFinish) => {
    // Extract Growth stage batches
    // const structuredData = data.filter((item)=> item.transferred && item.breeding_sieving === null);

    let structuredData = [];

    data.forEach((item)=>{
    // Extract BatchIN operations
    if(item.transferred && item.breeding_sieving)
    {
      const row = renameKeys(item,growthStageFORMATTER);
      row.operation = 'Batch IN';
      structuredData.push(_.pick(row,[
      'date',
      'operation',
      'identifier',
      'std_bins_out',
      'non_std_bins_out',
      'std_density',
      'non_std_density',
      'meanLarvalWeight'
      ])
      );
    }

    // Extract feeding operations from batch item
    if(item.larvae_inputs?.length)
    {    
      item.larvae_inputs.forEach((feed)=>{
          const feedRow = renameKeys(feed,feedingsFORMATTER);
          feedRow.date = formatDate(feedRow.date);
          feedRow.operation = 'Feeding';
          feedRow.identifier  = item.identifier;   
          structuredData.push(feedRow);
      });
    }

    if(item.incbuator_measurements?.length)
    {
      // Extract mesasure oprations from barch item
      const measureRows = item.incbuator_measurements.map((
        {created_at:createdAt,breedingMeasure,meanLarvalWeight,comment,updated_by:updatedBy})=>({
          date :  momentFoo(createdAt).format('YYYY/MM/DD'),
          operation: 'Measure',
          identifier: item.identifier,
          breedingMeasure,
          meanLarvalWeight,
          comment,
          operator: updatedBy || 'user',
        })
      );

      // Add measure rows to main array 
      structuredData = [...structuredData,...measureRows];
    }

    // Extract BatchOUT (sieving) operation 
    if(item.breeding_sieving)
    {
      const { sievingDate,biomass,frass,meanPrepupaeWeight,comment,updatedBy} = item.breeding_sieving;
      structuredData.push({
        date: formatDate(sievingDate),
        identifier: item.identifier,
        operation: 'Batch OUT',
        biomass,
        frass,
        meanPrepupaeWeight,
        comment,
        updatedBy: updatedBy || 'user',
      });

      // Extract reintroduction operation located in breeding sieving field
      if(item.breeding_sieving.reintroduced)
      {
        const { reintroductionDate,biomassIntroduced,impuritiesWhileIntroduction,
          pupaeWeight,pupae,realDensity} = item.breeding_sieving;

        structuredData.push({
          date: reintroductionDate,
          identifier:item.identifier,
          operation: 'Reintroduction',
          biomassIntroduced,
          frass,
          impuritiesWhileIntroduction,
          pupaeWeight,
          pupae,
          realDensity,
          comment,
          updatedBy: updatedBy || 'user',
        })
      }

    }
  });

    onFinish(structuredData);

  }