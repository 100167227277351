/* eslint-disable react/jsx-key */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
// UI Components
import { Modal, notification, Tag } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// translation
import { useTranslation } from 'react-i18next';

// local components
import TransferAction from '../NextIncubation/Transfer/TransferAction';
import IncubatorMeasurementAction from '../NextIncubation/Measurement/IncubatorMeasurementAction';
import IncubatorInputAction from '../NextIncubation/Inputs/IncubatorInputAction';
import LarvaeInputAction from '../NextLarvae/Inputs/LarvaeInputAction';
import LarvaeMeasurementAction from '../NextLarvae/Measurement/LarvaeMeasurementAction';
import SievingAction from '../NextLarvae/Seiving/SievingAction';
import BatchForm from './BatchForm';
import ListItem from '../Shared/List/ListItem';

// reducers
import { selectStage, selectSessionUser } from '../../reducers/Session.slice';
import { deleteBatch, fetchAllBatches } from '../../reducers/Batch.slice';

// const
import { stages } from '../../common/const';
import BreadingMeasurementAction from '../NextBreeding/Measurement/BreedingMeasurementAction';
import LarvaeRoomAction from '../NextLarvae/Room/LarvaeRoomAction';
import AerationAction from '../NextBreeding/Aeration/AerationAction';
import BreedingInputFeedingAction from '../NextBreeding/FeedingInput/BreedingInputFeedingAction';
import ReintroductionStepOneAction from '../NextBreeding/PupaeStock/PupaeStockActions.js/ReintroductionStepOneAction';
import ReintroductionStepTwoAction from '../NextBreeding/PupaeStock/PupaeStockActions.js/ReintroductionStepTwoActions';
import PupaeItem from '../Shared/List/PupaeItem';
import PupaeStockForm from '../NextBreeding/PupaeStock/PupaeStockForm';

/* -------------------------------------------------------------------------- */
/*                                 Batch Item                                 */
/* -------------------------------------------------------------------------- */
function BatchItem(props) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(['stages', 'common']);
  const dispatch = useDispatch();
  const location = useLocation();
  const stage = useSelector(selectStage);
  const user = useSelector(selectSessionUser);
  const { batch,isPupaeStock, isGrowthStage} = props;

  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  // Card should be not transferred
  const nextIncubatorContext = !batch.transferred || stage === stages.NEXTINCUBATOR;


  const onClickRemove = () => {
    dispatch(deleteBatch(batch.id))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: t(`${stage}.batch.form.delete.message`),
          description: t(`${stage}.batch.form.delete.success`),
        });
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: t(`${stage}.batch.form.delete.message`),
          description: t(`${stage}.batch.form.delete.error`),
        }),
      );
  };

  const checkDate = moment(batch.entryDate).diff(moment()) >= 0;
  const getDate =
    stage === 'nextIncubator'
      ? batch.nLTransferDate
        ? moment(batch.nLTransferDate).diff(moment(batch.entryDate), 'days')
        : moment().diff(moment(batch.entryDate), 'days')
      : stage === 'nextLarvae'
      ? batch.larvae_sieving && batch.larvae_sieving.sievingDate
        ? moment(batch.larvae_sieving.sievingDate).diff(moment(batch.entryDate), 'days')
        : moment().diff(moment(batch.entryDate), 'days')
      : '';

  const status = (item) => (item ? <Tag className={checkDate ? 'tag-time' : 'tag-time-off'}>{item} days</Tag> : null);

  /**
   * nextIncubation actions
   */
  const nextIncubatorActions = getUserAccessPrivilege?.nextIncubatorMakeAction && [
    <TransferAction {...props} />,
    <IncubatorInputAction {...props} />,
    <IncubatorMeasurementAction {...props} />,
  ];

  const nextBreedingActions = getUserAccessPrivilege?.nextBreedingMakeAction && [
    batch.transferred && <AerationAction {...props} />,
    !batch.transferred && <TransferAction {...props} />,
    !batch.transferred && <IncubatorInputAction {...props} />,
    batch.transferred && <BreedingInputFeedingAction {...props} />,
    <BreadingMeasurementAction {...props} />,
    batch.transferred && <SievingAction {...props} nextBreeding={true} />,
  ];

  const getTotalBinsSieved = batch?.larvae_sievings
    .map((item) => item.bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);
  const getTotalNonBinsSieved = batch?.larvae_sievings
    .map((item) => item.non_bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);

  /**
   * nextLarvae actions
   */
  const nextLarvaeActions = getUserAccessPrivilege?.nextLarvaeMakeAction && [
    <LarvaeRoomAction
      disabled={nextIncubatorContext || batch.larvae_measurements.length !== 0 || batch.larvae_sieving}
      {...props}
    />,
    <LarvaeMeasurementAction
      disabled={batch.bins - getTotalBinsSieved === 0 && batch.non_std_bins_out - getTotalNonBinsSieved === 0}
      {...props}
    />,
    <LarvaeInputAction {...props} disabled={nextIncubatorContext || batch.larvae_sieving} />,
    <SievingAction
      {...props}
      disabled={batch.bins - getTotalBinsSieved === 0 && batch.non_std_bins_out - getTotalNonBinsSieved === 0}
    />,
  ];

  const PupaeStockActions = [
    <ReintroductionStepOneAction
      batch ={{identifier:batch.identifier,
                ...batch.breeding_sieving }}
      />,
    <ReintroductionStepTwoAction
      batch ={{
        identifier:batch.identifier,
        batchId: batch.id,
        ...batch.breeding_sieving }}
      stepTwoFinished ={ batch?.cage_intorducings?.length }
    />
  ]
  ;

  const batchActions = () => {
    let component = null;

    switch (stage) {
      case stages.NEXTINCUBATOR:
        component = nextIncubatorActions;
        break;
      case stages.NEXTINCUBATOR_FOR_BREEDING:
        component = nextBreedingActions;
        break;
      case stages.NEXTLARVAE:
        component = nextLarvaeActions;
        break;
      default:
        component = null;
        break;
    }
    return component;
  };
  const fieldsByStage = {
    pupaeStock: {
      entryDate: 'entryDate',
      default: ['sievingDate','frass','biomass','meanPrepupaeWeight'],
      extra: [],
    },
    nextIncubator: {
      entryDate: 'entryDate',
      default: ['eggQty', 'bins', 'std_weight', 'non_std_weight', 'totalFeedQuantity'],
      extra: [
        'hatchingDate',
        'substrate',
        'std_bins_out',
        'non_std_bins_out',
        'std_density',
        'non_std_density',
        'std_weight_out',
        'non_std_weight_out',
        'nLTransferDate',
        'biomass',
        'meanLarvalWeight',
      ],
    },
    nextIncubatorForBreeding: batch.transferred
      ? // Gowth stage
        {
          entryDate: 'nLTransferDate',
          default: [
            'bins',
            'non_std_bins_out',
            'std_density',
            'non_std_density',
            'biomass',
            'larvae_sieving.frass',
            'larvae_sieving.larvalWeight',
            'larvae_sieving.impurity',
            'totalLarvaeFeedQuantity',
            'larvaefeedDate',
          ],
          extra: ['std_weight', 'non_std_weight'],
        }
      : // Incubator
        {
          entryDate: 'entryDate',
          default: ['eggQty', 'bins', 'std_weight', 'non_std_weight', 'totalFeedQuantity'],
          extra: [
            'hatchingDate',
            'substrate',
            'std_bins_out',
            'non_std_bins_out',
            'std_density',
            'non_std_density',
            'std_weight_out',
            'non_std_weight_out',
            'nLTransferDate',
            'biomass',
            'meanLarvalWeight',
          ],
        },
    nextLarvae: {
      entryDate: 'nLTransferDate',
      default: [
        'bins',
        'non_std_bins_out',
        'std_density',
        'non_std_density',
        'room',
        'biomass',
        'larvae_sieving.frass',
        'larvae_sieving.larvalWeight',
        'larvae_sieving.impurity',
        'totalLarvaeFeedQuantity',
        'larvaefeedDate',
      ],
      extra: ['std_weight', 'non_std_weight', 'larvaeWeight'],
    },
  };

  const badgeList = () => (
    <span>
      {status(getDate)}
      {batch.larvae_sievings && getTotalBinsSieved >= batch.bins && getTotalNonBinsSieved >= batch.non_std_bins_out ? (
        <Tag className="sieved-tag">Sieved</Tag>
      ) : getTotalBinsSieved <= batch.bins &&
        getTotalBinsSieved !== 0 &&
        (getTotalNonBinsSieved <= batch.non_std_bins_out || getTotalNonBinsSieved !== 0) ? (
        <Tag className="sieved-tag">Partially sieved</Tag>
      ) : (
        ''
      )}
    </span>
  );

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
    {!isPupaeStock ?
      <ListItem
        data={fieldsByStage[stage].default.map((item) => ({
          label: t(`${stage}.batch.item.${item}`),
          value: batch[item] ,
        }))}
        batch={batch}
        extraData={fieldsByStage[stage].extra.map((item) => ({
          label: t(`${stage}.batch.item.${item}`),
          value: batch[item],
        }))}
        onClickRemove={() => onClickRemove()}
        actions={batchActions()}
        badges={badgeList()}
        date={{
          label: t(`${stage}.batch.item.${fieldsByStage[stage].entryDate}`),
          value: batch[fieldsByStage[stage].entryDate],
        }}
        pathname={{ 
          pathname: `/batch/${batch.identifier}/details`, 
          state:{ 
              batch, location: location.pathname ,
              subStage: isGrowthStage ? 'growthStage' : 'incubator'
          } 
        }}
        identifier={batch.identifier}
        onClickEdit={() => setShowModal(true)}
        isGrowthStage={isGrowthStage}
      />
      :
      <PupaeItem
        actions={PupaeStockActions}
        data={{
          identifier:batch.identifier,
          ...batch.breeding_sieving
          }}
      />
      }
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Update batch"
        width={1000}
        visible={showModal}
        footer={null}
        destroyOnClose
        maskClosable={false}
        onCancel={() => setShowModal(false)}
      >
        {isPupaeStock ?
          <PupaeStockForm/>
         :
          <BatchForm batch={batch} onSubmit={() => setShowModal(false)} />
        }
      </Modal>
    </>
  );
}

BatchItem.propTypes = {
  batch: PropTypes.object,
  isPupaeStock: PropTypes.bool,
  isGrowthStage : PropTypes.bool
};

export default BatchItem;
