/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Form, Button, notification, Modal } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  fetchSuppliers,
  updateFeedEntry,
  selectSuppliers,
  fetchCategories,
  selectCategories,
  fetchFeedEntries,
} from '../../../reducers/NextFeed.slice';
import { momentFoo } from '../../Shared/helper';



/* -------------------------------------------------------------------------- */
/*                               Feed Entry Form                              */
/* -------------------------------------------------------------------------- */
function FeedEntryForm({ feed,isUpdateWeight }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [subCategories] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const suppliers = useSelector(selectSuppliers);
  const categories = useSelector(selectCategories);

  const [showEditModal, setShowEditModal] = useState(false);
  const batch = feed?.batch
  useEffect(() => {
    dispatch(fetchSuppliers());
    dispatch(fetchCategories());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const feedEntryFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'date',
      widget: 'date-picker',
      colSpan: 2,
      initialValue: batch ? momentFoo(batch.date) : momentFoo()
    },
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      colSpan: 2,
      initialValue: batch.identifier && batch.identifier,
      hasFeedback: true,
      required: true,
      disabled: true,
    },
    {
      key: 'carrier',
      label: 'carrier',
      placeholder: 'carrier',
      colSpan: 2,
      initialValue: batch.carrier && batch.carrier,
    },
    {
      key: 'storage',
      label: 'storage',
      placeholder: 'storage',
      colSpan: 2,
      initialValue: batch.storage && batch.storage,
    },
    {
      key: 'supplier',
      label: 'Supplier',
      placeholder: 'Supplier',
      widget: 'select',
      colSpan: 4,
      initialValue: batch.supplier && batch.supplier.name,
      options: suppliers.map((item) => ({ label: item.name, value: item.id })),
      rules: [
        {
          required: true,
          message: 'Supplier is required',
        },
      ],
    },
    {
      key: 'category',
      label: 'category',
      placeholder: 'category',
      widget: 'select',
      colSpan: 2,
      initialValue: batch.category && batch.category.name,
      options: categories.map((item) => ({ label: item.name, value: item.id })),
      onChange: () => {
        form.setFieldsValue({ sub_category: '' });
      },
      rules: [
        {
          required: true,
          message: 'Category is required',
        },
      ],
    },
    {
      key: 'sub_category',
      label: 'SubCategory',
      placeholder: 'SubCategory',
      widget: 'select',
      colSpan: 2,
      initialValue: batch.sub_category && batch.sub_category.name,
      options: subCategories,
      rules: [
        {
          required: true,
          message: 'SubCategorie is required',
        },
      ],
    },
    {
      key: 'weight',
      label: 'Received weight (kg)',
      placeholder: 'Received weight (kg)',
      widget: 'number',
      colSpan: 2,
      initialValue: batch.weight && batch.weight,
      onChange: (value) => {
        const total = form.getFieldValue('total') || 0;
        if (total > 0) {
          form.setFieldsValue({ pricePerTon: total / (value / 1000) });
        }
      },
      rules: [
        {
          required: true,
          message: 'Weight is required',
        },
      ],
    },
    {
      key: 'price',
      label: 'Cost (dt)',
      placeholder: 'Cost (dt)',
      widget: 'number',
      colSpan: 2,
      disabled: true,
      initialValue: batch.price && batch.price,
      onChange: (value) => {
        const carriageCost = form.getFieldValue('carriageCost') || 0;
        const weight = form.getFieldValue('weight') || 0;

        if (value + carriageCost > 0) {
          form.setFieldsValue({ total: value + carriageCost, pricePerTon: (value + carriageCost) / (weight / 1000) });
        }
      },
      rules: [
        {
          required: true,
          message: 'Cost is required',
        },
      ],
    },
    {
      key: 'billedWeight',
      label: 'Billed weight (kg)',
      placeholder: 'Billed weight (kg)',
      widget: 'number',
      colSpan: 2,
      initialValue: batch.billedWeight && batch.billedWeight,
      rules: [
        {
          required: true,
          message: 'Billed weight is required',
        },
      ],
    },
    {
      key: 'total',
      label: 'Total (dt)',
      placeholder: 'Total (dt)',
      widget: 'number',
      colSpan: 2,
      disabled: true,
      initialValue: batch.total && batch.total,
      rules: [
        {
          required: true,
          message: 'Total (dt) is required',
        },
      ],
    },

    {
      key: 'unitPrice',
      label: 'Unit Price (dt / kg)',
      placeholder: 'Unit Price (dt /T)',
      widget: 'number',
      colSpan: 2,
      initialValue: batch.unitPrice && batch.unitPrice,
      rules: [
        {
          required: true,
          message: 'Unit Price is required',

        },
      ],
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'pricePerTon',
      label: 'Price per ton (dt)',
      placeholder: 'Price per ton (dt)',
      widget: 'number',
      colSpan: 2,
      disabled: true,
      initialValue: batch.pricePerTon && batch.pricePerTon,
      rules: [
        {
          required: true,
          message: 'Price per ton is required',
        },
      ],
    },
    {
      key: 'carriageCost',
      label: 'Cost of carriage (dt)',
      placeholder: 'Cost of carriage (dt)',
      widget: 'number',
      colSpan: 2,
      initialValue: batch.carriageCost && batch.carriageCost,
      rules: [
        {
          required: true,
          message: 'Cost of carriage is required',
        },
      ],
      onChange: (value) => {
        const price = form.getFieldValue('price') || 0;
        const weight = form.getFieldValue('weight') || 0;
        if (price + value > 0) {
          form.setFieldsValue({ total: price + value, pricePerTon: (price + value) / (weight / 1000) / 1000 });
        }
      },
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'comment',
      widget: 'textarea',
      colSpan: 4,
    },
  ];

  const dataFactory = (data, label) => {
    if (data) {
      return data.map((item) => ({ label: item[label], value: item.id }));
    }
    return [];
  };

  if (form.getFieldValue('category')) {
    const category = categories.find((item) => item.id === form.getFieldValue('category'));
    if (category) {
      const index = feedEntryFormFields.findIndex((i) => i.key === 'sub_category');

      feedEntryFormFields[index] = {
        ...feedEntryFormFields[index],
        options: dataFactory(category.sub_categories, 'name'),
      };
    }
  }

  const billedWeight = form.getFieldValue('billedWeight') || 0;
  const unitPrice = form.getFieldValue('unitPrice') || 0;
  if (billedWeight >= 0 && unitPrice >= 0) {
    form.setFieldsValue({ price: (billedWeight * unitPrice)/1000 });
  }
  const getSupplierId = form.getFieldValue('supplier')
  const getCategoryId = form.getFieldValue('category')
  const getSubCategoryId = form.getFieldValue('sub_category')
  const forceUpdate = FormBuilder.useForceUpdate();
  const updateStockFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'date',
      widget: 'date-picker',
      colSpan: 2,
      initialValue: batch ? momentFoo(batch.date) : momentFoo()
    },
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      colSpan: 2,
      initialValue: batch.identifier && batch.identifier,
      hasFeedback: true,
      required: true,
      disabled: true,
    },
    {
      key: 'currentWeight',
      label: 'Updated weight(kg)',
      placeholder: 'Received weight (kg)',
      widget: 'number',
      colSpan: 2,
      initialValue: batch.currentWeight && batch.currentWeight,
      rules: [
        {
          required: true,
          message: 'Weight is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'comment',
      widget: 'textarea',
      colSpan: 4,
    }
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <Button
        type="primary"
        onClick={() => setShowEditModal(!showEditModal)}
      >
      {isUpdateWeight ? 'Update weight' : 'Edit'}
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title={isUpdateWeight ? 'Inventory':'Update Feed Entry'}
        width={1000}
        visible={showEditModal}
        maskClosable={false}
        footer={null}
        destroyOnClose
        onCancel={() => setShowEditModal(false)}
      >
        <Form
          onValuesChange={forceUpdate}
          layout="horizontal"
          form={form}
          onFinish={(values) => {
            
            const data = {
              ...values,
              id: batch.id,
              category: typeof(values.category) === 'string' ? batch.category.id : getCategoryId,
              sub_category: typeof(values.sub_category) === 'string' ? batch.sub_category.id : getSubCategoryId,
              supplier: typeof(values.supplier) === 'string' ? batch.supplier.id : getSupplierId
            }

            dispatch(updateFeedEntry(data))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: 'Update Feed Entry',
                  description: 'Feed Entry was Updated successfully',
                });
                setShowEditModal(!showEditModal)
                dispatch(fetchFeedEntries());
              })
              .catch(() => {
                setShowEditModal(!showEditModal)
                notification.error({
                  message: 'Update Feed Entry',
                  description: 'An error occured',
                });
              });
          }}
        >
          <FormBuilder
            form={form}
            meta={{
              columns: 4, formItemLayout: [16, 16],
              fields: isUpdateWeight ? updateStockFields: feedEntryFormFields
            }}
          />
          <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

FeedEntryForm.propTypes = {

  isUpdateWeight: PropTypes.bool.isRequired
};

export default FeedEntryForm;
