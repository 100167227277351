import { momentFoo } from '../../Shared/helper';
import { feedingsFORMATTER, measurmentsFORMATTER, sievingsFORMATTER } from '../Data.formatters';
import { formatDate, renameKeys } from '../Data.helpers';


// Restructure json data in order to be filled in csv columns
export const restructureDataForNextLarvae = (data,onFinish) =>{
    // this array contain the feeding operations extracted from Larvae batches
    let structuredData = []; 
    data.forEach((item) =>{
      // BatchIn 
    
      structuredData.push({
        ...item,
        date: formatDate(item.nLTransferDate),
        operation: 'batch IN'
      })

      // larvare measurments 
      if(item.larvae_measurements?.length)
      {

        const measureRow = renameKeys(item.larvae_measurements[0],measurmentsFORMATTER);
        measureRow.date =  momentFoo(measureRow.date).format('YYYY/MM/DD');
        measureRow.identifier = item.identifier;
        measureRow.operation = 'measure';
        structuredData.push(measureRow);
      }
       
      // larvare feedings 
      if(item.larvae_inputs?.length)  
      {

         let { larvae_inputs : feedRows } = item;

         feedRows = feedRows.map((input) => {  
          // feed fields extraction
          const { std_bins_qty : binQTY , std_bins_rationed : binRat, 
            non_std_bins_qty :nBinQTY ,non_std_bins_rationed: nBinRat} = input;
          const inputFormatted = renameKeys(input,feedingsFORMATTER);
          inputFormatted.date =  formatDate(inputFormatted.date);
          inputFormatted.TFeedConsumed =  (binQTY * binRat + nBinQTY * nBinRat) || 0;
          inputFormatted.operation = 'feeding';
          inputFormatted.identifier = item.identifier;
          return inputFormatted

      });
        
        if(feedRows.length)
          structuredData = [...structuredData,...feedRows];
      }

      // larvae sievings ( sieved batch = "batch OUT" column )
      if(item.larvae_sievings)
      {

     

        let {larvae_sievings : sievingsRows} = item;  
        sievingsRows = sievingsRows.map((input) => {  
        const inputFormatted = renameKeys(input,sievingsFORMATTER);
        inputFormatted.date =  formatDate(inputFormatted.date);
        inputFormatted.operation = 'batch OUT';
        inputFormatted.identifier = item.identifier;
        return inputFormatted
      });
        if(sievingsRows.length)
          structuredData = [...structuredData, ...sievingsRows];
      }
    });
    onFinish(structuredData);
   // setCsvData(structuredData);
  }