
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import {Button, Comment} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';

/* -------------------------------------------------------------------------- */
/*                                Comment Modal                               */
/* -------------------------------------------------------------------------- */

function CommentModal({comment}) {

  /* ---------------------------------- HOOKS --------------------------------- */
  const [showComment,setShowComment] = useState(false);
  const { t } = useTranslation('stages');
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <Button
        onClick={() => setShowComment(!showComment)}>
          {t('commonActions.seeComment')}
      </Button>
      <Modal
          style={{ minHeight: '1500px !important', border: 'none' }}
          title="Comment"
          width={1000}
          visible={showComment}
          maskClosable={false}
          footer={null}
          closable
          destroyOnClose
          onCancel={() => setShowComment(!showComment)}
      >
          <Comment
            content={<p className='centred'>{comment}</p>}
          />
      </Modal>
    </>
  );
}

CommentModal.propTypes = {
    comment: PropTypes.string
};

CommentModal.defaultProps = {
    comment: 'No Comments',
};

export default CommentModal;