/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Form, Button, notification, InputNumber, Alert } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import {
  createPress,
  fetchDriedLarvaeBatches,
  fetchAllPress,
  selectBigBag,
  selectNextMeal,
  selectNextOil,
  updatePress,
  fetchAllBigBag,
  fetchAllNextMeal,
  fetchAllNextOil,
} from '../../../reducers/NextProcess.slice';

// local components
import { momentFoo } from '../../Shared/helper';
import EditableTable from '../../../sharedComponents/EditableTable';

/* -------------------------------------------------------------------------- */
/*                                 Press Form                                 */
/* -------------------------------------------------------------------------- */
function PressForm({ onSubmit, batch, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const dispatch = useDispatch();
  const bigBags = useSelector(selectBigBag);
  const nextMeals = useSelector(selectNextMeal);
  const nextOils = useSelector(selectNextOil);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const availableWieght = (item) => {
    // eslint-disable-next-line no-nested-ternary
    const DRIED_LARVAE = item.process_second_dryings
      ? item.process_second_dryings.map((i) => i.finalWeight).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) > 0
        ? item.process_second_dryings.map((i) => i.finalWeight).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)
        : 0
      : 0;
    // eslint-disable-next-line no-nested-ternary
    const presses = item.presses
      ? item.presses.map((i) => i.weight).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) > 0
        ? item.presses.map((i) => i.weight).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)
        : 0
      : 0;
    return DRIED_LARVAE - presses > 0 ? DRIED_LARVAE - presses : 0;
  };

  const batches = bigBags
    .map((item) => ({
      id: item.id,
      // DRIED_LARVAE_Batch: item.identifier,
      name: item.bigBagId,
      checked: false,
      weight: availableWieght(item),
      note: `Max is  ${availableWieght(item)}`,
    }))
    .filter((f) => f.weight > 0);

  /* ---------------------------------- STATE --------------------------------- */
  const [list, setList] = useState(batches);
  const [weight, setWeight] = useState(batch ? batch.weight : 0);
  const [form] = Form.useForm();
  const DRIED_LARVAE_BATCHES = list.filter((item) => item.checked === true).map((i) => i.id);
  /* -------------------------------- callbacks ------------------------------- */

  const handleFinish = useCallback((values) => {
    if (batch) {
      const  { nextMealBatchId, nextOilBatchId } = values;
      const dataEntry = {
        ...values,
        id: batch?.id,
        next_meal: typeof(nextMealBatchId) === 'string' ? batch.next_meal.id : nextMealBatchId,
        next_oil: typeof(nextOilBatchId) === 'string' ? batch.next_oil.id : nextOilBatchId,
      };
      dispatch(updatePress(dataEntry))
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Update Press',
            description: 'Press Updated successfully',
          });
          onSubmit();
          form.resetFields();
          dispatch(fetchDriedLarvaeBatches());
          dispatch(fetchAllPress());
          dispatch(fetchAllBigBag());
          dispatch(fetchAllNextMeal());
          dispatch(fetchAllNextOil());
        })
        .catch(() =>
          notification.error({
            message: 'Update Press',
            description: 'An error occured',
          }),
        );
    } else {
      dispatch(
        createPress({
          ...values,
          weight,
          big_bags: DRIED_LARVAE_BATCHES,
          next_meal: values.nextMealBatchId,
          next_oil: values.nextOilBatchId,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Add New Press',
            description: 'A new Press was added successfully',
          });
          onSubmit();
          form.resetFields();
          dispatch(fetchDriedLarvaeBatches());
          dispatch(fetchAllPress());
          dispatch(fetchAllBigBag());
          dispatch(fetchAllNextMeal());
          dispatch(fetchAllNextOil());
        })
        .catch(() =>
          notification.error({
            message: 'Add New Press',
            description: 'An error occured',
          }),
        );
    }
  });

  const pressFields = [
    {
      key: 'pressDate',
      label: 'Press Date',
      placeholder: 'Press Date',
      widget: 'date-picker',
      initialValue: batch ? momentFoo(batch.pressDate) : momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Press Date is required',
        },
      ],
    },
    {
      key: 'period',
      label: ' Period (h) ',
      placeholder: 'Period (h)  ',
      widget: 'number',
      initialValue: batch && batch.period,
      rules: [
        {
          required: true,
          message: 'Period is required',
        },
      ],
    },
    {
      key: 'nextMealWeight',
      label: 'Weight of nextMeal (kg)',
      placeholder: 'Weight of nextMeal (kg)',
      widget: 'number',
      initialValue: batch && batch.nextMealWeight,
      rules: [
        {
          required: true,
          message: 'Weight of nextMeal (kg) is required',
        },
      ],
    },
    {
      key: 'nextOilWeight',
      label: 'Weight of nextOil',
      placeholder: 'nextOil weight',
      widget: 'number',
      initialValue: batch && batch.nextOilWeight,
      rules: [
        {
          required: true,
          message: 'nextOil weight is required',
        },
      ],
    },
    {
      key: 'nextMealBatchId',
      label: 'nextMeal Batch Id',
      placeholder: 'nextMeal Batch Id',
      widget: 'select',
      initialValue: batch && batch.next_meal.nextMealBatchId,
      options: nextMeals && nextMeals.map((item) => ({ label: item.nextMealBatchId, value: item.id })),
      rules: [
        {
          required: true,
          message: 'nextMeal Batch Id is required',
        },
      ],
    },
    {
      key: 'nextOilBatchId',
      label: 'nextOil Batch Id',
      placeholder: 'nextOil Batch Id',
      widget: 'select',
      initialValue: batch && batch.next_oil.nextOilBatchId,
      options: nextOils && nextOils.map((item) => ({ label: item.nextOilBatchId, value: item.id })),
      rules: [
        {
          required: true,
          message: 'nextOil Batch Id is required',
        },
      ],
    },
    {
      key: 'larvaeWaste',
      label: 'Larvae waste (kg)',
      placeholder: 'Larvae waste (kg)',
      widget: 'number',
      initialValue: batch && batch.larvaeWaste,
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'nextMealWaste',
      label: 'nextMeal waste (kg)',
      placeholder: 'nextMeal waste (kg)',
      widget: 'number',
      initialValue: batch && batch.nextMealWaste,
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'nextOilWaste',
      label: 'nextOil waste (kg)',
      placeholder: 'nextOil waste (kg)',
      widget: 'number',
      initialValue: batch && batch.nextOilWaste,
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
      initialValue: batch && batch.comment,
    },
  ];

  const head = [
    {
      title: '',
      dataIndex: 'checked',
      key: 'checked',
      editable: true,
      type: 'boolean',
    },
    {
      title: 'Big Bag Id',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Dried larvae weight',
      dataIndex: 'weight',
      key: 'weight',
      editable: true,
      type: 'number',
    },
  ];
  const forceUpdate = FormBuilder.useForceUpdate();

  return (
    <Form layout="horizontal" onFinish={handleFinish} onChangeValues={forceUpdate} form={form}>
      <FormBuilder form={form} meta={pressFields} />
      <Form.Item label="Dry larvae input for mixing (kg)">
        <InputNumber value={weight} disabled />
      </Form.Item>

      {list.length === 0 && (
        <Alert message="Warning" description="There is no more available larvae" type="warning" showIcon closable />
      )}
      {!batch && (
        <EditableTable
          list={list}
          columns={head}
          onChange={(values) => {
            setList(values);
            setWeight(
              values
                .filter((i) => i.checked === true)
                .map((item) => item.weight)
                .reduce((acc, c) => parseInt(acc, 10) + parseInt(c, 10)),
            );
            forceUpdate();
          }}
        />
      )}

      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary" disabled={!batch && DRIED_LARVAE_BATCHES.length === 0}>
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

PressForm.propTypes = {
  onlyFormItems: PropTypes.bool,
  onSubmit: PropTypes.func,
  batch: PropTypes.object,
};

export default PressForm;
