/* eslint-disable no-unused-vars */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useRef, useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';

// Redux
import { useSelector } from 'react-redux';

// UI Components
import { Table, Button } from 'antd';

// Components
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import { momentFoo } from '../../Shared/helper';
import FeedEntryUpdate from '../FeedEntry/FeedEntryUpdate';

// reducers
import { selectFeedEntries } from '../../../reducers/NextFeed.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';

// styles
import '../../NextFeed/index.less';
import { RM_STOCK_HEADERS } from '../../CSVExport/logistics/CSV.header';

/* -------------------------------------------------------------------------- */
/*                               Feed Entry Item                              */
/* -------------------------------------------------------------------------- */
function RmStockTable() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const feedEntries = useSelector(selectFeedEntries);
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  const [dataSourceFeedInput,setDataSourceFeedInput] = useState();
  const exportFileRef = useRef();

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  useEffect(()=>{
    
    if(feedEntries.length)
    { 
      const entries = feedEntries.map((batch) => ({
      /* ----------------- data for making actions (Edit, Delete) ----------------- */
      batch,
      _id: batch && batch.id,
      /* --------------------------- data for the table --------------------------- */
      receptionDate: batch.date ? batch.date : '-',
      id: batch.identifier ? batch.identifier : '-',
      category: batch.category?.name ? batch.category?.name : '-',
      subcategory: batch.sub_category?.name ? batch.sub_category?.name : '-',
      supplier: batch.supplier?.name ? batch.supplier?.name : '-',
      receivedWeight: batch.weight ? batch.weight : '-',
      currentWeight: batch.currentWeight ? batch.currentWeight : '-'
      }))

      setDataSourceFeedInput(entries);
    }
  },[feedEntries]);

  const columnsFeedInput = [
    {
      title: 'Reception Date',
      dataIndex: 'receptionDate',
      key: 'receptionDate',
      fixed: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => momentFoo(a.receptionDate).diff(b.receptionDate, 'days'),
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      key: 'subcategory',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: 'Received weight (kg)',
      dataIndex: 'receivedWeight',
      key: 'receivedWeight',
    },
    {
      title: 'Current weight',
      dataIndex: 'currentWeight',
      key: 'currentWeight',
    },

    {
      // eslint-disable-next-line react/display-name
      render: (record) => (
        getUserAccessPrivilege?.logisticsModification && (

          <FeedEntryUpdate isUpdateWeight={true} feed={record} />

        )
      ),
    },
  ];

  const exportCSV = () => {
    if (exportFileRef && exportFileRef.current && exportFileRef?.current?.link) 
      exportFileRef.current.link.click();
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <Table
        columns={columnsFeedInput}
        dataSource={dataSourceFeedInput}
        scroll={{ x: 1900 }}
        footer={()=>
          <Button 
            type='primary'
            className='export-btn'
            onClick={exportCSV}
          >
            <DownloadOutlined/>
            Export csv
          </Button>
        }
      />

      {  
        dataSourceFeedInput && dataSourceFeedInput.length &&
           <CSVLink 
            ref={exportFileRef}
            headers={RM_STOCK_HEADERS}
            data={dataSourceFeedInput} 
            filename='RMstock-export.csv'    
          />
      }
    </>
  );
}



export default RmStockTable;
