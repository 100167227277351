/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { Divider } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { ExportForm } from '../../components/CSVExport/ExportForm';

// style
import './Export.less';

/* -------------------------------------------------------------------------- */
/*                                 Export Page                                */
/* -------------------------------------------------------------------------- */
const Export = () => {
  /* ---------------------------------- HOOKS --------------------------------- */

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="export-page-layout">
    <Divider orientation="left">Export</Divider>
      <ExportForm />
    </Content>
  );
}

export default Export;