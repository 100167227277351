/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';

/* ------------------------------ UI Component ------------------------------ */
import { Button, Modal, notification } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import TransferForm from './TransferForm';

// Reducers
import { fetchAllBatches, updateBatch } from '../../../reducers/Batch.slice';
import { createComment } from '../../../reducers/NextIncubator.slice';

/* -------------------------------------------------------------------------- */
/*                               Transfer Action                              */
/* -------------------------------------------------------------------------- */
function TransferAction({ batch }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    const fields = {
      ...data,
      transferred: true,
    };
    dispatch(
      updateBatch({
        fields,
        batchId: batch.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Batch Transfer',
          description: 'Transferred successfully',
        });
        setShowModal(!showModal);
        if (data.content) {
          dispatch(
            createComment({
              content: data.content,
              batch: batch.id,
            }),
          );
        }
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Batch Transfer',
          description: 'An error occured',
        }),
      );
  };

  const hasTransfered = batch.transferred;

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        className={hasTransfered ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={hasTransfered}
        onClick={() => setShowModal(!showModal)}
      >
        Transfer
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Transfer"
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <TransferForm batch={batch} onSubmit={onClickSubmit} />
      </Modal>
    </div>
  );
}

export default TransferAction;
