// Headers are the columns of csv file 
// Label is the column name
// Key is for mapping data with the corresponding column 
export const NEXTLARVAE_HEADERS = [
    { label:'Date', key: 'date' },
    { label:'Operation', key: 'operation'},
    { label:'Batch', key: 'identifier'},
    { label:'Room', key: 'room'},
    { label:'STD Bins (nb)', key: 'bins' },
    { label:'NSTD Bins (nb)', key: 'non_bins_sieved' },
    { label:'N° Rationed STD Bins', key: 'std_bins_rationed' },
    { label:'n° NSTD bins rationed', key: 'non_std_bins_rationed'},
    { label:'Feed Qty / STD Bins(kg)', key: 'std_bins_qty' },
    { label:'Feed Qty/ NSTD bins (kg)', key: 'non_std_bins_qty' },
    { label:'TFeed Consumed (kg)', key: 'TFeedConsumed' },
    { label:'n° Mix', key: 'mix' },
    { label:'LW 4th day (mg)', key: 'larvaeWeight' },
    { label:'Mean Larval weight (mg)', key: 'meanLarvalWeight' },
    { label:'Biomass (kg)', key: 'biomass' },
    { label:'Frass (kg)', key: 'frass' },
    { label:'Waste (kg)', key: 'waste' },
    { label:'Operator', key: 'username' },
    { label:'Comments', key: 'comment' },
  ];