/* eslint-disable react/display-name */
/* eslint-disable radix */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from 'react';

// UI Components
import { notification, Modal, Row, Table, Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import { deleteNextGrow,fetchAllNextGrow,selectNextGrow,} from '../../../reducers/NextProcess.slice';

import { fetchOutputs } from '../../../reducers/NextFeed.slice';

// Local components

import CommentAction from '../../Shared/CommentAction';
import BigBagForm from './BigbagForm';


/* -------------------------------------------------------------------------- */
/*                                    Stock                                   */
/* -------------------------------------------------------------------------- */
const NextGrow = () => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const [formInitialValues,setFromInitialValues] = useState(null);
  const dispatch = useDispatch();
  const nextGrows = useSelector(selectNextGrow);
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  useEffect(() => {
    dispatch(fetchAllNextGrow());
  }, []);

  const load = () => {
    dispatch(fetchOutputs());
  };

  const onCancelModal = () => {
    setFromInitialValues();
    setShowModal(false);
  }

  const onDelete = (id,bigBagId) => {
    confirm({
      title: `Do you Want to delete bigbag Id : ${bigBagId}`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          deleteNextGrow(id)
        )
          .then(unwrapResult)
          .then(() => {
            notification.success({
                message: 'delete Big bag',
                description: 'Big bag deleted successfully',
            });
          })
          .catch(() =>
            notification.error({
                message: 'Add Big bag',
                description: 'An error occured',
            }),
          );
      },
    });
  };


  const onEdit = (data) => {
      setFromInitialValues(data);
      setShowModal(!showModal);
  };



  /* ---------------------------------- CONST --------------------------------- */
  const driedHead = [
    {
      title: 'Bigbag Id',
      dataIndex: 'bigBagId',
      key: 'bigBagId',
      width: '10%'
    },
    {
      title: 'Initial weight',
      dataIndex: 'weight',
      key: 'weight',
      width: '10%'
    },
    {
      title: 'Comments',
      key: 'comment',
      width: '10%',
      render: (record) =>  
      <CommentAction item={record} context={record.context} onSubmit={() => load()} />
    },
    {
        render: (record) =>
         <>
            <Button 
              type='primary' 
              onClick={()=>  onEdit(record) }
            >
              Edit
            </Button>

            <Button 
              type='danger'
              className='ml-20'
              onClick={()=> onDelete(record.id,record.bigBagId) }
            >
              Delete
            </Button>
         </>
      },
  ];


  /* -------------------------------- callbacks -------------------------------- */


  
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>       
        <Row >
        <Button
          type='primary'
          className='mb-20'
          onClick={()=> setShowModal(!showModal)}
        >
          Add Big Bag Id
        </Button>
        <Modal
          style={{ minHeight: '1500px !important' }}
          title= {'NextGrow - add new Bigbag'}
          width={1000}
          visible={showModal}
          footer={null}
          maskClosable={false}
          closable
          destroyOnClose
          onCancel={onCancelModal}
        >
        <BigBagForm 
          initialValues={formInitialValues} 
          onSubmit={()=>setShowModal(!showModal)} 
          clearValues ={()=> setFromInitialValues()}
        />
      </Modal>
      </Row>
        <Table
          columns={driedHead}
          dataSource={nextGrows}
        />
    </div>
  );
}

NextGrow.propTypes = {};

export default NextGrow;
