/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { AllBinsIn, AllBinsOut, BinsIn, BinsOut } from './allMeasurements';

/* -------------------------------------------------------------------------- */
/*                                Next Larvae Table                           */
/* -------------------------------------------------------------------------- */
export default function DashboardTable({ data, year, onTableYearChange }) {
  /* ---------------------------------- HOOKS --------------------------------- */

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <table>
      <tr>
        <th>
          <DatePicker picker="year" onChange={onTableYearChange} defaultValue={moment(year, 'YYYY-MM-DD')} />
        </th>
        <th>Jan</th>
        <th>Feb</th>
        <th>Mar</th>
        <th>Apr</th>
        <th>May</th>
        <th>Jun</th>
        <th>Jul</th>
        <th>Aug</th>
        <th>Sep</th>
        <th>Oct</th>
        <th>Nov</th>
        <th>Dec</th>
        <th>Tot</th>
      </tr>
      <tr>
        <td>Total bins IN</td>
        <td>{BinsIn(1, year, data)}</td>
        <td>{BinsIn(2, year, data)}</td>
        <td>{BinsIn(3, year, data)}</td>
        <td>{BinsIn(4, year, data)}</td>
        <td>{BinsIn(5, year, data)}</td>
        <td>{BinsIn(6, year, data)}</td>
        <td>{BinsIn(7, year, data)}</td>
        <td>{BinsIn(8, year, data)}</td>
        <td>{BinsIn(9, year, data)}</td>
        <td>{BinsIn(10, year, data)}</td>
        <td>{BinsIn(11, year, data)}</td>
        <td>{BinsIn(12, year, data)}</td>
        <td>{AllBinsIn(data, year)}</td>
      </tr>
      <tr>
        <td>Total bins OUT</td>
        <td>{BinsOut(1, year, data)}</td>
        <td>{BinsOut(2, year, data)}</td>
        <td>{BinsOut(3, year, data)}</td>
        <td>{BinsOut(4, year, data)}</td>
        <td>{BinsOut(5, year, data)}</td>
        <td>{BinsOut(6, year, data)}</td>
        <td>{BinsOut(7, year, data)}</td>
        <td>{BinsOut(8, year, data)}</td>
        <td>{BinsOut(9, year, data)}</td>
        <td>{BinsOut(10, year, data)}</td>
        <td>{BinsOut(11, year, data)}</td>
        <td>{BinsOut(12, year, data)}</td>
        <td>{AllBinsOut(data, year)}</td>
      </tr>
      <tr>
        <td>Biomass OUT (kg)</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>BBR (kg/bin)</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Mean Larval weight (g)</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Mean days spent in nL</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>B/E (kg of biomass/g of eggs)</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>% biomass/bin</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Feed reveived (kg)</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>FCR</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{'% FCR < 4'}</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
        <td>0</td>
      </tr>
    </table>
  );
}

DashboardTable.propTypes = {
  data: PropTypes.array,
  year: PropTypes.string,
  onTableYearChange: PropTypes.func,
};
