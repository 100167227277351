/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../common/config';

// Fetch nextIncubation Batches
export const fetchAllBatches = createAsyncThunk('batch/fetchAllBatches', async () => {
  const config = {
    method: 'get',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    url: `${API_ENDPOINT}/batches`,
  };
  const payload = await axios(config);
  return payload.data;
});

// fetch All Comments
export const fetchAllComments = createAsyncThunk('comments/fetchAllComments', async () => {
  const config = {
    method: 'get',
    url: `${API_ENDPOINT}/comments`,
  };
  const payload = await axios(config);
  return payload.data;
});

// Create a new Batch
export const createBatch = createAsyncThunk('batch/createBtach', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/batches`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch single Batch
export const fetchBatch = createAsyncThunk('batch/fetchBatch', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/batches/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Transfer Batch
export const TransferBatch = createAsyncThunk('batch/transferBatch', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/batches/transfer/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// bulk Transfer Batch
export const bulkTransferBatch = createAsyncThunk('batch/bulktransferBatch', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      data,
      url: `${API_ENDPOINT}/batches/transfer`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// bulk Comment
export const bulkComment = createAsyncThunk('batch/bulkComment', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      data,
      url: `${API_ENDPOINT}/comments/bulk`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Feed Batch
export const feedBatch = createAsyncThunk('batch/feedBatch', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/batches/feed/${data.batch}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Update Batch
export const updateBatch = createAsyncThunk('batch/updateBatch', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/batches/${data.batchId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: data.fields,
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Delete a Batch
export const deleteBatch = createAsyncThunk('batch/deleteBtach', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/batches/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch Statistics
export const fetchStatistics = createAsyncThunk('batch/fetchStatistics', async ({ rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/batches/statistics`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getRooms = createAsyncThunk('batch/getRooms', async ({ rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/larvae-rooms`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Batches Slice
const Batch = createSlice({
  name: 'Batch',
  initialState: {
    batches: [],
    paloxes: [],
    comments: [],
    loading: false,
    statistics: [],
    selectedBatch: null,
  },
  reducers: {},
  extraReducers: {
    [fetchAllBatches.fulfilled]: (state, action) => {
      state.batches = action.payload;
      state.loading = false;
    },
    [fetchAllBatches.pending]: (state) => {
      state.loading = true;
    },
    [fetchStatistics.fulfilled]: (state, action) => {
      state.statistics = action.payload;
    },
    [fetchAllComments.fulfilled]: (state, action) => {
      state.comments = action.payload;
    },
    [fetchBatch.fulfilled]: (state, action) => {
      state.selectedBatch = action.payload;
    },
  },
});

export default Batch.reducer;

// Selectors
export const selectBatches = (state) => state.Batch.batches;
export const selectNextIncubatorBatches = (state) => state.Batch.batches.filter((item) => !item.isForBreeding);
export const selectBreedingBatches = (state) => state.Batch.batches.filter((item) => item.isForBreeding);

export const selectNextLarvaeBatches = (state) =>
  state.Batch.batches.filter((item) => item.transferred && !item.isForBreeding);
export const selectNextFlyBatches = (state) =>
  state.Batch.batches.filter((item) => item.transferred && item.isForBreeding);
export const selectNextProcessBatches = (state) =>
  state.Batch.batches.filter((item) => item.transferred && !item.isForBreeding && !item.killed);
export const selectPloxes = (state) => state.Batch.paloxes;
export const selectStatistics = (state) => state.Batch.statistics;
export const selectComments = (state) => state.Batch.comments;
export const selectBatch = (state) => state.Batch.selectedBatch;
