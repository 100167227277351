/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';

import PropTypes from 'prop-types';

// Redux
import { useSelector } from 'react-redux';

// UI Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';
import { momentFoo } from '../../Shared/helper';

// Reducers
import { selectBrMixes } from '../../../reducers/NextFeed.slice';

/* -------------------------------------------------------------------------- */
/*                              Larvae Input Form                             */
/* -------------------------------------------------------------------------- */
function BreedingInputFeedingForm({ onSubmit, batch, feed, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const brMixes = useSelector(selectBrMixes);
  const paloxes = brMixes.filter((item) => item.currentWeight > 0);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const breedingInputFormFields = [
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      widget: 'number',
      initialValue: batch.identifier,
      disabled: true,
    },
    {
      key: 'feedDate',
      label: 'Date',
      placeholder: 'Date',
      widget: 'date-picker',
      initialValue: feed ? momentFoo(feed.updated_at) : momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Date is required',
        },
      ],
    },
    {
      key: 'mix',
      label: 'n°BrMix',
      placeholder: 'Select Palox',
      widget: 'select',
      initialValue: feed?.mix,
      options: paloxes.map((item) => ({ label: item.palox, value: item.id })),
      rules: [
        {
          required: true,
          message: 'n°BrMix is required',
        },
      ],
    },
    { key: 'end_of_mix', label: 'End of Mix', widget: 'switch', initialValue: false },
    {
      key: 'std_bins_rationed',
      label: 'Number of STD bins rationed (nb)',
      placeholder: 'Number of STD bins rationed (nb)',
      widget: 'number',
      initialValue: feed ? feed.std_bins_rationed : batch?.bins,
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'std_bins_qty',
      label: ' Quantity per STD bin (kg)',
      placeholder: ' Quantity per STD bin (kg)',
      widget: 'number',
      initialValue: feed ? feed.std_bins_qty : batch?.std_bins_qty,
    },
    {
      key: 'non_std_bins_rationed',
      label: 'Number of non STD bins rationed (nb)',
      placeholder: 'Number of non STD bins rationed (nb)',
      widget: 'number',
      initialValue: feed ? feed.non_std_bins_rationed : batch?.non_std_bins_out,
    },
    {
      key: 'non_std_bins_qty',
      label: ' Quantity per non STD bin (kg)',
      placeholder: ' Quantity per non STD bin (kg)',
      widget: 'number',
      initialValue: feed ? feed.non_std_bins_qty : batch?.non_std_bins_qty,
    },
    {
      key: 'feed',
      label: ' Total quantity (kg) ',
      placeholder: 'Total quantity (kg) ',
      widget: 'number',
      disabled: true,
      initialValue: feed ? feed.feed : 0,
    },
    {
      key: 'larvae_comment',
      label: 'Comment (optional)',
      placeholder: 'comment',
      widget: 'textarea',
    },
  ];

  if (form.getFieldValue('mix')) {
    const palox = paloxes.find((item) => item.id === form.getFieldValue('mix'));
    if (palox) {
      const index = breedingInputFormFields.findIndex((i) => i.key === 'feed');
      breedingInputFormFields[index] = {
        ...breedingInputFormFields[index],
        extra: `max value is ${palox.currentWeight}`,
        widgetProps: {
          max: palox.currentWeight,
        },
      };
    }
  }
  if (form.getFieldValue('std_bins_qty')) {
    const STD_BINS_RATIONED = form.getFieldValue('std_bins_rationed') || 0;
    const STD_BIN_QTY = form.getFieldValue('std_bins_qty') || 0;
    const NON_STD_BINS_RATIONED = form.getFieldValue('non_std_bins_rationed') || 0;
    const NON_STD_BIN_QTY = form.getFieldValue('non_std_bins_qty') || 0;
    form.setFieldsValue({ feed: STD_BINS_RATIONED * STD_BIN_QTY + NON_STD_BINS_RATIONED * NON_STD_BIN_QTY });
  }
  const total = form.getFieldValue('feed');
  const forceUpdate = FormBuilder.useForceUpdate();

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={forceUpdate} form={form}>
      <FormBuilder form={form} meta={breedingInputFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button
            htmlType="submit"
            type="primary"
            disabled={
              total === 0 ||
              (total === undefined && true) ||
              form.getFieldValue('feed') >= brMixes.find((item) => item.id === form.getFieldValue('mix')).currentWeight
            }
          >
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

BreedingInputFeedingForm.propTypes = {
  onSubmit: PropTypes.func,
  batch: PropTypes.object,
  feed: PropTypes.object,
  onlyFormItems: PropTypes.bool,
};

export default BreedingInputFeedingForm;
