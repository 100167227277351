/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';

// UI Components
import Icon, { ExportOutlined } from '@ant-design/icons';

// assets
import { ReactComponent as FoodIcon } from '../assets/fruits.svg';

/* -------------------------------------------------------------------------- */
/*        An iteratable array listing all nextIncubation bulk actions.        */
/* -------------------------------------------------------------------------- */
export const nextIncubationActions = () => [
  {
    name: 'nextIncubation-feed',
    label: 'Feed',
    icon: <Icon component={FoodIcon} />,
    context: 'nextIncubator-feed'
  },
  { name: 'nextIncubator-transfer', label: 'Transfer', icon: <ExportOutlined />, context: 'nextIncubator-transfer', },
];

export const actions = () => ({
  nextIncubation: [
    { label: 'Transfer', icon: <ExportOutlined /> },
    { label: 'Feed', icon: <Icon component={FoodIcon} /> },
    { label: 'measure', icon: <ExportOutlined /> },
  ],
  nextLarevea: [
    { label: 'Transfer', icon: <ExportOutlined /> },
    { label: 'Feed', icon: <Icon component={FoodIcon} /> },
    { label: 'measure', icon: <ExportOutlined /> },
  ],
});

export const NIBiometricDataFields = ['biomass', 'hatchingDate'];
