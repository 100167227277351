/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// Reducers
import { fetchAllBatches } from '../../../reducers/Batch.slice';
import { createIncubatorMeasurment, updateIncubatorMeasurement } from '../../../reducers/NextIncubator.slice';

// Local Components
import BreedingMeasurementForm from './BreedingMeasurmentForm';

/* -------------------------------------------------------------------------- */
/*                         Breading Measurement Action                        */
/* -------------------------------------------------------------------------- */
function BreadingMeasurementAction({ batch, measure }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    if (measure) {
      dispatch(
        updateIncubatorMeasurement(
          {
            id: measure.id,
            fields: {
              ...data,
              batch: batch.id,
            },
          }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Measure updated',
            description: 'Measure updated successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: 'Batch Measure update',
            description: 'An error occured',
          }),
        );


    } else {
      dispatch(
        createIncubatorMeasurment({
          ...data,
          batch: batch.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Measure',
            description: 'Measure successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: 'Batch Measure',
            description: 'An error occured',
          }),
        );
    };
  }

 

  // const hasHatchingDate = (batch.hatchingDate !== null || batch.transferred !== null) && !batch.isForBreeding

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button
        className={batch.hatchingDate ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={batch.hatchingDate}
        onClick={() => setShowModal(!showModal)}
      >
        Measure
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title={(batch.transferred !== null) ?
          'Breeding Growth stage Larvae measurement' : 'Breeding Incubator Larvae measurement'}
        width={1000}
        destroyOnClose
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        onCancel={() => setShowModal(!showModal)}
      >
        <BreedingMeasurementForm batch={batch} onSubmit={onClickSubmit} measure={measure} />
      </Modal>
    </div>
  );
}

export default BreadingMeasurementAction;
