export const feedingsFORMATTER = {
  'feedDate' : 'date',
  'larvae_comment' : 'comment',
};
  
export const sievingsFORMATTER = {
  'sievingDate' : 'date',
  'bins_sieved':  'bins',
  'non_bins_sieved':  'non_bins_sieved',
};
  
export const growthStageFORMATTER = {
  'nLTransferDate' : 'date',
};
  
export const measurmentsFORMATTER = {
  created_at: 'date' ,
  larvae_measurement_comment: 'comment',
};