import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINT } from '../common/config';

/* -------------------------------------------------------------------------- */
/*                                Measurements                                */
/* -------------------------------------------------------------------------- */

// Create a new incubation measurment
export const createLarvaeMeasurment = createAsyncThunk(
  'nextLarvae/createLarvaeMeasurment',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/larvae-measurements`,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Upadte Larvae input
export const updateLarveaMeasurement = createAsyncThunk(
  'nextIncubation/updateLarveaMeasurement',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/larvae-measurements/${data.id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Remove incubator input
export const deleteLarvaeMeasurement = createAsyncThunk(
  'nextIncubation/deleteLarvaeMeasurement',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/larvae-measurements/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* -------------------------------------------------------------------------- */
/*                                Larvae Input                                */
/* -------------------------------------------------------------------------- */

// Create a new larvae input
export const createLarvaeInput = createAsyncThunk('nextLarvae/createLarvaeInput', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/larvae-inputs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch feed types
export const fetchLarvaeFeedTypes = createAsyncThunk(
  'nextLarvae/fetchLarvaeFeedTypes',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/feed-types`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Upadte Larvae input
export const updateLarveaInput = createAsyncThunk(
  'nextIncubation/updateLarveaInput',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/larvae-inputs/${data.id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Remove incubator input
export const deleteLarvaeInput = createAsyncThunk(
  'nextIncubation/deleteLarvaeInput',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/larvae-inputs/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Bulk  Larvae Input
export const bulkCreateLarvaeInput = createAsyncThunk(
  'nextLarvae/bulkCreateLarvaeInput',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/bulk-larvae-inputs`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
/* -------------------------------------------------------------------------- */
/*                                   Sieving                                  */
/* -------------------------------------------------------------------------- */
// fetch feed types
export const fetchLarvaeSieving = createAsyncThunk(
  'nextLarvae/fetchLarvaeSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/larvae-sievings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
// Create a new incubation measurment
export const createLarvaeSieving = createAsyncThunk(
  'nextLarvae/createLarvaeSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/larvae-sievings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Upadte Larvae sievings
export const updateLarvaeSieving = createAsyncThunk(
  'nextLarvae/updateLarvaeSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/larvae-sievings/${data.id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
// Remove incubator input
export const deleteLarvaeSieving = createAsyncThunk(
  'nextIncubation/deleteLarvaeSieving',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/larvae-sievings/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Create a new incubation measurment
export const bulkCreateLarvaeSieving = createAsyncThunk(
  'nextLarvae/bulkCreateLarvaeSieving',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/bulk-larvae-sieving`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// NextLarvae Slice
const NextLarvae = createSlice({
  name: 'NextLarvae',
  initialState: {
    paloxes: [],
    larvaeSievings: []
  },
  reducers: {},
  extraReducers: {
    [fetchLarvaeSieving.fulfilled]: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.larvaeSievings = action.payload;
    }
  },
});

export default NextLarvae.reducer;

// Selectors
export const selectPaloxes = (state) => state.NextLarvae.paloxes;
export const selectLarvaeSievings = (state) => state.NextLarvae.larvaeSievings;
