/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// UI Components
import { Layout ,Tabs } from 'antd';

// local Component
import EndProductsTable from './EndProducts.table';

import { selectBigBag, selectNextGrow, selectNextMeal,
   selectNextOilLogistics } from '../../../reducers/NextProcess.slice';
import { getAvailableWeightForDriedLarvae, momentFoo } from '../../Shared/helper';
/* -------------------------------------------------------------------------- */
/*                               Next Feed Page                               */
/* -------------------------------------------------------------------------- */
function EndProducts() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { TabPane } = Tabs;

  // Selectors
  const nextOils = useSelector(selectNextOilLogistics);
  const nextGrows = useSelector(selectNextGrow);
  const nextMeals = useSelector(selectNextMeal);
  const nextGrubs = useSelector(selectBigBag);

  // States
  const [nextMealsFormatted,setNextMealsFormatted] = useState(nextMeals);
  const [nextGrubsFormatted,setGrubsFormatted] = useState(nextGrubs);

  useEffect(()=>{
    if(nextGrubs?.length)
    { 
      const formattedGrubs = nextGrubs?.map(({id,created_at:createdAt,bigBagId,
        currentWeight,outputs,expiryDate,compliant,...rest}) => (
        {
          id,
          date: momentFoo(createdAt).format('DD-MM-YYYY'),
          batch: bigBagId,
          weight: getAvailableWeightForDriedLarvae(rest),
          currentWeight: currentWeight || getAvailableWeightForDriedLarvae(rest),
          bigBagId,
          outputs,
          expiryDate,
          compliant
        }
      ));
      setGrubsFormatted(formattedGrubs);
    }
  },[nextGrubs]);

  useEffect(()=>{
    if(nextMeals?.length)
     { 
       const nextMealWeight = (item) => {
        if(item?.length)
         return item.reduce((a, b) => a + +b.nextMealWeight, 0);
        return 0;
      }

       const formattedMeals = nextMeals.map(
        ({id,created_at:createdAt,nextMealBatchId,presses,inventories,outputs,currentWeight,...rest}) => (
        {
          id,
          compliant: rest.compliant,
          expiryDate: rest.expiryDate,
          date: momentFoo(createdAt).format('DD-MM-YYYY'),
          bigBagId: nextMealBatchId,
          weight: nextMealWeight(presses),
          batch: nextMealBatchId,
          inventories,
          outputs,
          currentWeight
        }
      ));
      setNextMealsFormatted(formattedMeals);
     }
  },[nextMeals]);
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content logistics">
      <Tabs defaultActiveKey="Reception of raw materials">
        <TabPane tab="nextMeal" key={1}>
          <EndProductsTable 
            tab="nextMeal"
            dataSource={nextMealsFormatted}
          />
        </TabPane>
        <TabPane tab="nextOil" key={2}>
          <EndProductsTable
            tab="nextOil"
            dataSource={nextOils}
          />
        </TabPane>
        <TabPane tab="nextGrow" key={3}>
          <EndProductsTable
            tab="nextGrow"
            dataSource={nextGrows}
          />
        </TabPane>
        <TabPane tab="nextGrub" key={4}>
          <EndProductsTable
            tab="nextGrub"
            dataSource={nextGrubsFormatted}
          />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default EndProducts;