/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */

import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { momentFoo } from '../../Shared/helper';
import { updateKilledBatch, fetchKilledBatches } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                             Update Killed Form                             */
/* -------------------------------------------------------------------------- */
function UpdateKilledForm({ onSubmit, batch, onlyFormItems }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

 

  const handleFinish = (values) => {
    dispatch(updateKilledBatch({ fields: values, id: batch.id }))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Update Batch',
          description: 'Updated successfully',
        });
        onSubmit();
        dispatch(fetchKilledBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Update Batch',
          description: 'An error occured',
        }),
      );
  };

  const killingEditFormFields = [
    {
      key: 'killingDate',
      label: 'Killing Date',
      placeholder: 'Killing Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Killing Date is required',
        },
      ],
    },
    {
      key: 'biomass',
      label: `Biomass(kg) of batch ${batch.batch.identifier}`,
      placeholder: 'Biomass',
      widget: 'number',
      initialValue: batch.biomass,
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Biomass is required',
        },
      ],
      onChange : (value) =>  form.setFieldsValue({totalBiomass: value + form.getFieldValue('newBatchBiomass')})
    },
    {
      key: 'newBatchBiomass',
      label: `Biomass(kg) of batch ${batch.newbatch.identifier}`,
      placeholder: 'Biomass',
      widget: 'number',
      initialValue: batch.newBatchBiomass,
      widgetProps: {
        min: 0,
      },

      rules: [
        {
          required: true,
          message: 'Biomass is required',
        },
      ],

      onChange : (value) =>  form.setFieldsValue({totalBiomass: value + form.getFieldValue('biomass')})
    },
    {
      key: 'totalBiomass',
      label: 'total biomass (kg)',
      placeholder: 'Biomass',
      widget: 'number',
      disabled: true,
      initialValue: 6,
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Biomass is required',
        },
      ],
    },
    {
      key: 'period',
      label: ' Period (min) ',
      placeholder: 'Period (min)  ',
      widget: 'number',
      initialValue: batch.period,
    },
    {
      key: 'temperature',
      label: 'Temperature (C°)',
      placeholder: 'Temperature ',
      initialValue: batch.temperature,
    },

    {
      key: 'drainingTime',
      label: ' Draining time (min) ',
      placeholder: 'Draining time (min)  ',
      widget: 'number',
      initialValue: batch.drainingTime,
    },
  ];

  const forceUpdate = FormBuilder.useForceUpdate();
  return (
    <Form layout="horizontal" onFinish={handleFinish} onValuesChange={forceUpdate} form={form}>
      <FormBuilder form={form} meta={killingEditFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

UpdateKilledForm.propTypes = {
  onSubmit: PropTypes.func,
  onlyFormItems: PropTypes.bool,
  batch: PropTypes.object,
};

export default UpdateKilledForm;
