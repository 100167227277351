/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// local components
import FeedEntryItem from './FeedEntryItem';

/* -------------------------------------------------------------------------- */
/*                               Feed Entry List                              */
/* -------------------------------------------------------------------------- */
function FeedEntryList(props) {
  /* ---------------------------------- CONST --------------------------------- */
  const { feedEntries } = props;
  /* -------------------------------- RENDERING ------------------------------- */
  return <FeedEntryItem feedEntries={feedEntries} />;
}

FeedEntryList.propTypes = {
  feedEntries: PropTypes.object,
};

export default FeedEntryList;
