/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// UI Components
import { Layout, Typography, Tabs, Button, Modal } from 'antd';

// translation
import { useTranslation } from 'react-i18next';

// Local components
import BatchList from '../../components/Batches/BatchList';
import NextIncubatorDashboard from './Dashboard';
import BatchForm from '../../components/Batches/BatchForm';
import { nextIncubationActions } from '../../common/nextIncubation.helper';

// reducers
import { fetchAllBatches } from '../../reducers/Batch.slice';
import { getEggPool } from '../../reducers/NextFly.slice';
import { $setStage, selectSessionUser } from '../../reducers/Session.slice';
import { stages } from '../../common/const';

// styles
import './index.less';

/* -------------------------------------------------------------------------- */
/*                             Next Incubator Page                            */
/* -------------------------------------------------------------------------- */
function NextIncubatorPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const user = useSelector(selectSessionUser);

  useEffect(() => {
    dispatch($setStage(stages.NEXTINCUBATOR));
    dispatch(fetchAllBatches());
    dispatch(getEggPool())
  }, []);

  const { t } = useTranslation('stages');
  const [showModal, setShowModal] = useState(false);
  const getUserAccessPrivilege = user.privilege && user.privilege
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content next-incubator">
      <Content className="first-title">
        <Title level={1}>{t('nextIncubator.title')}</Title>
      </Content>
      <Tabs defaultActiveKey={t('nextIncubator.batch.tabs.batches')}>
        <TabPane tab={t('nextIncubator.batch.tabs.dashboard')} key={t('nextIncubator.batch.tabs.dashboard')}>
          <NextIncubatorDashboard />
        </TabPane>
        <TabPane tab={t('nextIncubator.batch.tabs.batches')} key={t('nextIncubator.batch.tabs.batches')}>

          <BatchList
            actions={nextIncubationActions()}
          />
          {getUserAccessPrivilege?.nextIncubatorMakeAction && (
            <>
              <Button
                className="plus-button"
                type="primary"
                shape="square"
                onClick={() => setShowModal(!showModal)}
              >
                +
              </Button>
              <Modal
                style={{ minHeight: '1500px !important' }}
                title={t('nextIncubator.batch.form.title')}
                width={1000}
                visible={showModal}
                maskClosable={false}
                footer={null}
                destroyOnClose
                onCancel={() => setShowModal(false)}
              >
                <BatchForm onSubmit={() => setShowModal(false)} />
              </Modal>
            </>
          )}
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default NextIncubatorPage;
