// Headers are the columns of csv file 
// Label is the column name
// Key is for mapping data with the corresponding column 
  
// Headers for Growth stage in nextBredding 
  export const NEXTBREEDING_GROWTHSTAGE_HEADERS = [
    // BatchIN columns
    { label:'Date', key: 'date' },
    { label:'Operation', key: 'operation' },
    { label:'Batch id', key: 'identifier' },
    { label:'STD Bins (nb)', key: 'std_bins_out' },
    { label:'Non-STD bins (nb)', key: 'non_std_bins_out' },    
    { label:'Density/ Std bin', key: 'std_density' },
    { label:'Density/ NonStd bin', key: 'non_std_density' },
    { label:'larval weight Inc (mg)', key: 'meanLarvalWeight' },
    // Feedings columns
    { label:'STD bins rationed (nb)', key: 'std_bins_rationed' },
    { label:'Qty/STD bin (kg)', key: 'std_bins_qty' },
    { label:'nSTD bins rationed (nb)', key: 'non_std_bins_rationed' },
    { label:'Qty/nSTD bin (kg)', key: 'non_std_bins_qty' },
    { label:'Total Feed served (kg)', key: 'totalFeedServed' },
    { label:'Measure', key: 'breedingMeasure' },
    { label:'Mean larval weight (mg)', key: 'meanLarvalWeight' },  
    { label:'Biomass (kg)', key: 'biomass' },
    { label:'out of which: Impurities (kg)', key: 'impurities' },
    { label:'Frass (Kg)', key: 'Frass (Kg)' },
    { label:'Biomass introduced (kg)', key: 'biomassIntroduced' },
    { label:'Impurities while intro (%)', key: 'impuritiesWhileIntroduction' },
    { label:'Estimated pure larvae (kg)', key: 'estimatedPureLarvae' },
    { label:'Mean larval weight prépupe (mg)', key: 'meanLarvalWeightPrépupe' },
    { label:'Mean larval weight pupae (mg)', key: 'meanLarvalWeightPupae' },
    { label:'% pupae', key: 'pupae' },
    { label:'Real Density', key: 'realDensity' },
    { label:'Operator', key: 'operator' },
    { label:'Comment', key: 'comment' },
  ];
