/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// UI Components
import { Row, Col, Input, List, DatePicker, Switch } from 'antd';

// local components
import { momentFoo } from '../Shared/helper';

// reducers
import { selectBreedingBatches } from '../../reducers/Batch.slice';

import { fetchBreedingSieving } from '../../reducers/NextBreeding.slice';
import BatchItem from '../Batches/BatchItem';

/* -------------------------------------------------------------------------- */
/*                                 Batch List                                 */
/* -------------------------------------------------------------------------- */
function PupaeStockList({ fields }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD';
  const [searchFormData, setSearchFormData] = useState({
    searchTitle: '',
    searchOperation: [],
    searchDate: '',
  });

  const { t } = useTranslation('stages');
  const dispatch = useDispatch();
  const nextIncubatorForBreeding = useSelector(selectBreedingBatches);

  // switch state for the filter "show reintroduced batches"
  const [checked , setChecked] = useState();

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  useEffect(() => {
    dispatch(fetchBreedingSieving());
  }, []);

  /**
   * Check if Batch is compatible with filters
   * @param {object} batch Batch object
   * @param {*} formData FormData object containes
   * filters(searchTitle, searchOperation, searchDate)
   */
  const checkBatch = (batch, formData) => {
    // query wil be false if filters are empty
    let query = true;
    if (formData.searchTitle !== '') {
      query = query && batch.identifier.toLowerCase().indexOf(formData.searchTitle.toLowerCase()) !== -1;
    }

    if (formData.searchDate) {
      query = query && momentFoo(batch.entryDate).isSame(momentFoo(formData.searchDate), 'date');
    }
    return query;
  };


  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Row className="card-list-filter-bar" align="middle" justify="space-between">
        <Col>
          <Row align="middle" justify="space-around">
            <Col>
              <DatePicker
                className="date-picker-input"
                placeholder={t('Select Date')}
                format={dateFormat}
                onChange={(value) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchDate: value,
                  })
                }
              />
            </Col>
            <Col className="ml-20">
              <Search
                className="search-input"
                placeholder="Search"
                onChange={(e) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchTitle: e.target.value,
                  })
                }
                allowClear
              />
            </Col>
          </Row>
        </Col>

        <Col>
          <span className="switch-input">
            <Switch title="Show seived Batches" checked={checked} onChange={(value) => setChecked(value)} />
           {t('nextBreeding.batch.list.showReintroducedBatches')}
          </span>
        </Col>
      </Row>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={
          checked ? 
          // filter reintroduced batches using the reintroduced attribute 
          nextIncubatorForBreeding.filter((item) =>
           checkBatch(item, searchFormData) && item.breeding_sieving !== null && 
           item.breeding_sieving.reintroduced && item.cage_intorducings?.length )
          :
          nextIncubatorForBreeding.filter((item) => 
           checkBatch(item, searchFormData) && item.breeding_sieving !== null && !item.cage_intorducings?.length )
          }
        renderItem={(item) => (
          <List.Item>
            <BatchItem 
              key={item.id}
              batch={item}
              fields={fields}
              isPupaeStock={true} 
            />
          </List.Item>
        )}
      />
    </div>
  );
}

PupaeStockList.propTypes = {
  batches: PropTypes.object,
};

export default PupaeStockList;
