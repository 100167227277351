/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// UI lib components
import { Layout, Menu } from 'antd';

// reducers
import { selectSessionUser } from '../reducers/Session.slice';

// Style
import './TopBar.less';

/* -------------------------------------------------------------------------- */
/*                                  Side Menu                                 */
/* -------------------------------------------------------------------------- */
function SideMenu() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Sider } = Layout;
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(null);
  const user = useSelector(selectSessionUser);
  const getUserAccess = user.privilege && user.privilege
  const routes = [
    {
      path: '/nextIncubator',
      name: 'nextIncubator',
      access: getUserAccess?.nextIncubatorAccess
    },
    {
      path: '/nextLarvae',
      name: 'nextLarvae',
      access: getUserAccess?.nextLarvaeAccess
    },
    {
      path: '/nextProcess',
      name: 'nextProcess',
      access: getUserAccess?.nextProcessAccess
    },
    {
      path: '/nextBreeding',
      name: 'nextBreeding',
      access: getUserAccess?.nextBreedingAccess
    },
    {
      path: '/nextFly',
      name: 'nextFly',
      access: getUserAccess?.nextFlyAccess
    },
    {
      path: '/nextFeed',
      name: 'nextFeed',
      access: getUserAccess?.nextFeedAccess
    },
    {
      path: '/logistics',
      name: 'logistics',
      access: getUserAccess?.logisticsAccess
    },
    {
      path: '/dashboardBatchId',
      name: 'dashboardBatchId',
      access: getUserAccess?.dashboardBatchIdAccess
    },
    {
      path: '/export',
      name: 'Export',
      access: getUserAccess?.dashboardBatchIdAccess
    },
  ]


  useEffect(() => {
    const routeIndex = routes.findIndex((route) => route.path === pathname);
    if (routeIndex) {
      setSelected(routeIndex);
    }
  }, [pathname]);


  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Sider className="side-menu">
      <div className="logo" />
      <Menu defaultSelectedKeys={['0']} selectedKeys={selected} mode="vertical">
        {
          routes.map((item, index) => (
            item.access && (
              <Menu.Item
                className={`menu-side-item ${pathname === item.path && `menu-side-item-active-${item.name}`}`}
                key={index}
              >
                <Link to={item.path} >
                  {item.name}
                </Link>
              </Menu.Item>
            ))
          )
        }
      </Menu>
    </Sider>
  );
}

export default SideMenu;
