export const filterPerMonth = (month, year, data) =>
  data.filter(({ entryDate }) => {
    return (
      parseInt(entryDate?.split('-')[1], 10) === month && parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10)
    );
  });

export const filterPerYear = (data, year) =>
  data.filter(({ entryDate }) => {
    return parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10);
  });

export const BinsIn = (month, year, data) => {
  let BINS_IN = 0;
  let BINS_OUT = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { bins, non_std_bins_out: NON_BINS_OUT } = filterPerMonth(month, year, data)[i];
    BINS_IN += bins;
    BINS_OUT += NON_BINS_OUT;
  }

  const result = BINS_IN + BINS_OUT;

  return result ? result.toFixed(2) : 0;
};

export const AllBinsIn = (data, year) => {
  let BINS_IN = 0;
  let BINS_OUT = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { bins, non_std_bins_out: NON_BINS_OUT } = filterPerYear(data, year)[i];
    BINS_IN += bins;
    BINS_OUT += NON_BINS_OUT;
  }

  const result = BINS_IN + BINS_OUT;

  return result ? result.toFixed(2) : 0;
};

export const BinsOut = (month, year, data) => {
  let STD_BINS_OUT = 0;
  let NON_STD_BINS_OUT = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { non_std_bins_out: NONSTDBINSOUT, std_bins_out: STDBINSOUT } = filterPerMonth(month, year, data)[i];
    NON_STD_BINS_OUT += NONSTDBINSOUT;
    STD_BINS_OUT += STDBINSOUT;
  }

  const result = STD_BINS_OUT + NON_STD_BINS_OUT;

  return result ? result.toFixed(2) : 0;
};

export const AllBinsOut = (data, year) => {
  let STD_BINS_OUT = 0;
  let NON_STD_BINS_OUT = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { non_std_bins_out: NONSTDBINSOUT, std_bins_out: STDBINSOUT } = filterPerYear(data, year)[i];
    NON_STD_BINS_OUT += NONSTDBINSOUT;
    STD_BINS_OUT += STDBINSOUT;
  }

  const result = STD_BINS_OUT + NON_STD_BINS_OUT;

  return result ? result.toFixed(2) : 0;
};
