import { Button, Col, Row, Layout, Table, Typography, Modal } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MixForm from './MixForm';

const MixDetails = ({ currentMix:mix }) => {
  /* ---------------------------------- HOOKS --------------------------------- */
    const [showModal,setShowModal] = useState();
    const [tableData,setTableData] = useState(mix.feedEntriesInfos);
    const [currentMix,setCurrentMix] = useState(mix);

  /* -------------------------------- CONSTANTS ------------------------------- */
  const NEXT_FEED_MIXES_COLUMN = [
    {
      title: 'Ingredient batch id',
      dataIndex: 'igredientBatchId',
      key: 'breeding Measure',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Sub category',
      dataIndex: 'subCategory',
      key: 'Hatching Date',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
    },
    {
      title: 'Weight (kg)',
      dataIndex: 'weight',
      key: 'weight',
    },
];
  /* ----------------------------- RENDER HELPERS ----------------------------- */
    const { Content } = Layout;
    const { Title } = Typography;
  /* -------------------------------- RENDERING ------------------------------- */
    return (
        <Content className="next-feed">
        <Row align="middle" justify="center" className="container">
        <Col>
          <Title level={2} className="next-feed-title" >
            initial Weight :
          </Title>
        </Col>

        <Col>
          <h3 className='marginZero'>{mix.actualWeight}</h3>
        </Col>

        <Col>
          <Button 
            onClick={()=>setShowModal(!showModal)}
            type="primary"
            className="next-feed-button">
            Edit
         </Button>
        </Col>

        </Row>

         <Table
           columns={NEXT_FEED_MIXES_COLUMN}
           dataSource={tableData}
         /> 

        <Modal
            style={{ minHeight: '500px !important' }}
            title={'Update Weight'}
            width={1000}
            visible={showModal}
            footer={null}
            maskClosable={false}
            destroyOnClose
            onCancel={() => setShowModal(false)}
        >
          <MixForm 
            isUpdate={true}
            onSubmit={() => setShowModal(!showModal)}
            currentMix={currentMix}
            onUpdate = {(updates)=>{
              setCurrentMix({...mix,feedEntriesInfos:updates});
              setTableData(updates);
              setShowModal(false)
            }}
          />
        </Modal>  
      </Content>
    )
}

MixDetails.propTypes = {
  currentMix: PropTypes.object.isRequired,
};

export default MixDetails
