/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */
import React, { useEffect } from 'react';

// UI Components
import { Table } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// reducers
import { momentFoo } from '../../Shared/helper';
import { fetchFeedEntries, selectFeedEntries, fetchOutputs, selectOutputs } from '../../../reducers/NextFeed.slice';
import UpdateAvailableWeight from './UpdateAvailableWeight';
import { selectSessionUser } from '../../../reducers/Session.slice';

/* -------------------------------------------------------------------------- */
/*                                Buffer Stock                                */
/* -------------------------------------------------------------------------- */
function Stock() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const dispatch = useDispatch();
  const list = useSelector(selectFeedEntries);
  const output = useSelector(selectOutputs);
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const getOutputIds = output.map((o) => o.feed_entry?.id);
  const getListDataById = list.filter((l) => getOutputIds.includes(l.id));
  /* ---------------------------------- CONST --------------------------------- */
  const feedEntries = getListDataById
    ?.map((feedEntry) => ({
      date: feedEntry.date,
      identifier: feedEntry.identifier,
      weight: feedEntry.transferedWeight,
      id: feedEntry.id,
      category: feedEntry.category.name,
      availableWeight: feedEntry.availableWeight,
    }))
    .filter((f) => f.weight > 0);

  useEffect(() => {
    dispatch(fetchFeedEntries());
    dispatch(fetchOutputs());
  }, []);

  /* -------------------------------- VARIABLES ------------------------------- */
  const head = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => momentFoo(a.date) - momentFoo(b.date),
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Transfered weight (kg)',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Available weight (kg)',
      dataIndex: 'availableWeight',
      key: 'availableWeight',
    },
    {
      title: 'Actions',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (record) => getUserAccessPrivilege?.nextFeedMakeAction && <UpdateAvailableWeight feed={record} />,
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return <Table columns={head} dataSource={feedEntries} />;
}

Stock.propTypes = {};

export default Stock;
