/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React ,{ useEffect, useRef, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select} from 'antd';
import moment from 'moment';
import { CSVLink} from 'react-csv';

// Redux hooks
import { useDispatch, useSelector } from 'react-redux';

// Slices
import { fetchAllBatches, selectBatches, selectBreedingBatches,
     selectNextIncubatorBatches, selectNextLarvaeBatches } from '../../reducers/Batch.slice';
import { fetchKilledBatches,selectKilledBatches } from '../../reducers/NextProcess.slice';
import { fetchLarvaeSieving, selectLarvaeSievings } from '../../reducers/NextLarvae.slice';
import { fetchCagesForCsv,selectEggPoolForCsv } from '../../reducers/NextFly.slice';

// CSV headers
import { NEXTINCUBATOR_HEADERS } from './nextIncubator/CSV.header';
import { NEXTLARVAE_HEADERS } from './nextLarvae/CSV.header';
import { NEXTPROCESS_HEADERS } from './nextProcess/CSV.header';
import { NEXTBREEDING_GROWTHSTAGE_HEADERS } from './nextBreeding/CSV.header';
import { NEXTFLY_HEADERS } from './nextFly/CSV.header';

// CSV data 
import { restructureDataForNextIncubator } from './nextIncubator/CSV.data';
import { restructureDataForNextLarvae } from './nextLarvae/CSV.data';
import { endOfBatchesForNextProcess, killedBatchesForNextProcess } from './nextProcess/CSV.data';
import { restructureDataForGrowthStage } from './nextBreeding/CSV.data';


/* -------------------------------------------------------------------------- */
/*                                 Export Form                                */
/* -------------------------------------------------------------------------- */

export const ExportForm = () => {

 /* ---------------------------------- HOOKS --------------------------------- */
  const batches = useSelector(selectBatches);

  // NextLarvaeSelector
  const nextLarvaeBatches = useSelector(selectNextLarvaeBatches);

  // NextIncubator selector
  const nextIncubatorBatches = useSelector(selectNextIncubatorBatches);

  // NextProcess selectors
  const endOfBatches = useSelector(selectLarvaeSievings);
  const killedBatches = useSelector(selectKilledBatches);

  // NextBreeding selector
  const nextIncubatorForBreeding = useSelector(selectBreedingBatches);

  // NextFly selector
  const cages = useSelector(selectEggPoolForCsv);

  const [form] = Form.useForm();

  const exportFileRef = useRef();

  const { Option,OptGroup } = Select;

  const dispatch = useDispatch();

  // Current stage to export
  const [selectedStage,setSelectedStage] = useState();

  // CSV file rows data
  const [ CsvData, setCsvData] = useState([]);

  // CSV file columns labels
  const [CsvHeaders,setCsvHeaders] = useState(NEXTINCUBATOR_HEADERS);

  const[killingBatches, setKillingBatches] = useState([]);

 /* ----------------------------- RENDER HELPERS ----------------------------- */
  
   const exportSelector = (sameStage)=>{
    if(sameStage && CsvData.length )
     exportFileRef.current.link.click();
    else
        switch (selectedStage) {
            case 'growthStage':
                  setCsvHeaders(NEXTBREEDING_GROWTHSTAGE_HEADERS);
                  restructureDataForGrowthStage(nextIncubatorForBreeding,setCsvData);
                break;
            case 'breedingIncubator':
                  setCsvHeaders(NEXTINCUBATOR_HEADERS);
                  restructureDataForNextIncubator(nextIncubatorForBreeding,setCsvData);
                break;
            case 'nextIncubator':
                  setCsvHeaders(NEXTINCUBATOR_HEADERS);
                  restructureDataForNextIncubator(nextIncubatorBatches,setCsvData);
                break;
            case 'nextLarvae':
                  setCsvHeaders(NEXTLARVAE_HEADERS);
                  restructureDataForNextLarvae(nextLarvaeBatches,setCsvData);
                break;
            case 'nextProcess':
                  setCsvHeaders(NEXTPROCESS_HEADERS);
                break;
            case 'nextFly':
                setCsvHeaders(NEXTFLY_HEADERS);
                setCsvData(cages);
                break;
            default:
                break;
        }
  }

  const retrieveBatches  = () => batches.length ? exportSelector() : dispatch(fetchAllBatches());
  const retrieveCages = () => cages?.length ? exportSelector() : dispatch(fetchCagesForCsv());

  const fetchDataSelector = () =>{
    switch (selectedStage) {
      case 'growthStage':
      case 'breedingIncubator':
      case 'nextIncubator':
      case 'nextLarvae':
       retrieveBatches();
       break;
     case 'nextProcess':
       retrieveBatches();
       dispatch(fetchKilledBatches());
       dispatch(fetchLarvaeSieving());
      break;
     case 'nextFly':
       retrieveCages();
     break;
   default:
       break;
   }
  };

  /* ----------------------------- Effects ----------------------------- */
    useEffect(() => {      
       if(batches.length) exportSelector();         
    }, [batches]);

    useEffect(()=>{
      fetchDataSelector();
    },[selectedStage])

    useEffect(() => {
      if (CsvData.length && exportFileRef && exportFileRef.current && exportFileRef?.current?.link) {
        setTimeout(() => {
          exportFileRef.current.link.click();
        });
      }
    }, [CsvData]);

    useEffect(()=>{
      if(killedBatches.length)
        {
          exportSelector();
          killedBatchesForNextProcess(killedBatches,setKillingBatches);
        }
    },[killedBatches]);

    useEffect(()=>{
       if(endOfBatches.length && killingBatches.length)
       {
         exportSelector();
         endOfBatchesForNextProcess(endOfBatches,
          (newData)=> setCsvData([...killingBatches,...newData]));
       }
    },[endOfBatches,killingBatches]);

    useEffect(()=>{
      if(cages?.length)
        exportSelector();
    },[cages]);

  /* -------------------------------- RENDERING ------------------------------- */
    return (
    <Form
      layout="horizontal"
      form={form}
      initialValues={{
        from: null,
        to:moment(new Date(), 'YYYY-MM-DD'),
        selectedStage:'nextIncubator'
      }}
      onFinish={({selectedStage: stage}) => 
      stage === selectedStage ? exportSelector(stage) : setSelectedStage(stage)}
      >
    <Row align="middle" gutter={[16,16]}>   
        <Col>
        <Form.Item name='from' label="From">
          <DatePicker defaultPickerValue={new Date()} />
        </Form.Item>
        </Col>
        <Col>
        <Form.Item  name='to' label="To">
          <DatePicker/>
        </Form.Item> 
        </Col>
    </Row>
        <Form.Item name='selectedStage' label="Select export"  >
        <Select  style={{ width: 200 }}>
            <OptGroup label="next Breeding">
              <Option value="breedingIncubator">Incubator</Option>
              <Option value="growthStage">Growth stage</Option>
            </OptGroup>
            <OptGroup label="Other stages :" >
            <Option value="nextIncubator">nextIncubator</Option>
            <Option value="nextLarvae">nextLarvae</Option>
            <Option value="nextProcess">nextProcess</Option>
            <Option value="nextFly">nextFly</Option>
            </OptGroup>
        </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 2, offset:2}} >
          <Button type="primary" htmlType="submit" >
            <DownloadOutlined /> Export CSV
          </Button>
        </Form.Item>
        {  CsvData && CsvData.length > 0 &&
           <CSVLink 
            ref={exportFileRef}
            headers={CsvHeaders}
            data={CsvData} 
            filename={`${selectedStage}-export.csv`} 
          />
        }
      </Form>
    )
}