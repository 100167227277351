/* eslint-disable react/prop-types */
/* ------------------------------- // Packages ------------------------------ */

import React from 'react';

/* ------------------------------ // Components ----------------------------- */

import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';



/* --------------------------------  Redux -------------------------------- */

import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

/* -------------------------------- reducers -------------------------------- */

import { createSupplier, fetchSuppliers } from '../../../reducers/NextFeed.slice';



/**
 * Add New batch Form
 */
function SupplierForm({ onSubmit }) {

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  /* -------------------------------- variables ------------------------------- */

  const SupplierFormFields = [
    {
      key: 'name',
      label: 'name',
      placeholder: 'name',
      rules: [
        {
          required: true,
          message: 'name is required',
        },
      ],
    },
    {
      key: 'abbreviation',
      label: 'Abbreviation',
      placeholder: 'abbreviation',
      rules: [
        {
          required: true,
          message: 'Abbreviation is required',
        },
      ],
    },
  ];

  /* -------------------------------- rendering ------------------------------- */

  return (
    <Form
      layout="horizontal"
      form={form}
      onFinish={(values) => {
        dispatch(createSupplier(values))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Add New Supplier',
              description: 'A new Supplier was added successfully',
            });
            onSubmit();
            form.resetFields();
            dispatch(fetchSuppliers())
          })
          .catch(() => {
            onSubmit();
            notification.error({
              message: 'Add New Supplier',
              description: 'An error occured',
            });
          });
      }}
    >
      <FormBuilder form={form} meta={SupplierFormFields} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

SupplierForm.propTypes = {};

export default SupplierForm;
