/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Layout, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

// reducers
import { selectBatches, fetchAllBatches } from '../../reducers/Batch.slice';

// Local Compoents
import { momentFoo } from '../../components/Shared/helper';
// style
import './dashboardBatchId.less';

/* -------------------------------------------------------------------------- */
/*                          Next Incubator Dashboard                          */
/* -------------------------------------------------------------------------- */
export default function NextIncubatorDashboard() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const dispatch = useDispatch();
  const batches = useSelector(selectBatches);

  const [searchText, setSearchText] = useState('')

  const [searchedColumn, setSearchedColumn] = useState('')


  useEffect(() => {
    dispatch(fetchAllBatches());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });



  /* ---------------------------------- CONST --------------------------------- */
  const dataSourceBatches = batches.map((item) => (
    {
      id: item.id,
      identifier: item.identifier,
      eggQty: item.eggQty ? item.eggQty : '-',
      entryDate: item.entryDate ? item.entryDate : '-',
      incubationPeriod: item.nLTransferDate ?
        momentFoo(item.nLTransferDate).diff(momentFoo(item.entryDate), 'days') : '-',
      totalFeedServedDuringIncubation: item.totalLarvaeFeedQuantity ? item.totalLarvaeFeedQuantity : '-',
      biomassBeforeTnL: item.biomass ? (item.biomass / 1000) : '-',
      substrate: item.substrate ? item.substrate : '-',
      meanLarvalweight: item.meanLarvalWeight ? item.meanLarvalWeight : '-',
      hatchingRate: item.biomass && item.substrate && item.meanLarvalWeight && item.eggQty ? (
        // eslint-disable-next-line max-len
        (((item.biomass * 1000 * (100 - item.substrate)) / item.meanLarvalWeight / item.eggQty * 0.000027) * 100).toFixed(2)
      ) : '-',
      bins: item.bins ? item.bins : '-',
      non_std_bins: item.non_std_bins ? item.non_std_bins : '-',
      biomass_seiving: item.killed_batches ? item.killed_batches.map((b) => b.biomass) : '-',
      frass: item.larvae_sieving ? item.larvae_sieving.frass : '-',
      sievingWaste: item.larvae_sieving ? item.larvae_sieving.larvalWeight : '-'
    }
  ));

  const columns = [
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      fixed: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.identifier - b.identifier,
      ...getColumnSearchProps('identifier')
      // onFilter: (value, record) => record.identifier.indexOf(value) === 0,
    },
    {
      title: 'Eggs collection date',
      dataIndex: 'entryDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => momentFoo(a.entryDate).diff(b.entryDate, 'days'),
    },
    {
      title: 'Eggs introduced (g)',
      dataIndex: 'eggQty',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.eggQty - b.eggQty,
    },
    {
      title: 'Incubation period',
      dataIndex: 'incubationPeriod',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.incubationPeriod - b.incubationPeriod,
    },
    {
      title: 'Total feed served during incubation (kg)',
      dataIndex: 'totalFeedServedDuringIncubation'
    },
    {
      title: 'Biomass before transfer to nL (kg)',
      dataIndex: 'biomassBeforeTnL',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.biomassBeforeTnL - b.biomassBeforeTnL,
    },
    {
      title: '% substrate',
      dataIndex: 'substrate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.substrate - b.substrate,
    },
    {
      title: 'Initial Larval weight (mg)',
      dataIndex: 'meanLarvalweight'
    },
    {
      title: 'Hatching Rate (%)',
      dataIndex: 'hatchingRate'
    },
    {
      title: 'nb STD bins in nL',
      dataIndex: 'bins'
    },
    {
      title: 'nb nSTD bins in nL',
      dataIndex: 'non_std_bins'
    },
    {
      title: 'Larval Biomass (Kg)',
      dataIndex: 'biomass_seiving'
    },
    {
      title: 'Frass (Kg)',
      dataIndex: 'frass'
    },
    {
      title: 'Sieving waste (kg)',
      dataIndex: 'sievingWaste'
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content dashboard-batch-id">
      <Table
        columns={columns}
        dataSource={dataSourceBatches}
        scroll={{ x: 4100 }}
      // pagination={{
      //   defaultPageSize: 1,
      //   pageSizeOptions: ['10', '20', '30']
      // }}
      />
    </Content>
  );
}
