/* eslint-disable react/prop-types */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// redux
// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import IncubationMeasurementForm from './IncubationMeasurmentForm';

// Reducers
import { fetchAllBatches, fetchBatch } from '../../../reducers/Batch.slice';
import { createIncubatorMeasurment, updateIncubatorMeasurement } from '../../../reducers/NextIncubator.slice';
import BreedingMeasurementForm from '../../NextBreeding/Measurement/BreedingMeasurmentForm';


function IncubatorMeasurementAction({ batch, measure, isGrowthStage, record}) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    if (measure) {
      dispatch(
        updateIncubatorMeasurement(
          {
            id: measure.id,
            fields: {
              ...data,
              batch: batch.id,
            },
          }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Measure updated',
            description: 'Measure updated successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchBatch(batch.id));
        })
        .catch(() =>
          notification.error({
            message: 'Batch Measure update',
            description: 'An error occured',
          }),
        );


    } else {
      dispatch(
        createIncubatorMeasurment({
          ...data,
          batch: batch.id,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Batch Measure',
            description: 'Measure successfully',
          });
          setShowModal(!showModal);
          dispatch(fetchAllBatches());
        })
        .catch(() =>
          notification.error({
            message: 'Batch Measure',
            description: 'An error occured',
          }),
        );
    };
  }
  const hasHatchingDate = batch.hatchingDate || batch.transferred


  return (
    <div>
      <Button
        className={hasHatchingDate ? 'card-item-action-btn-disable' : 'card-item-action-btn'}
        disabled={isGrowthStage ? false : hasHatchingDate}
        onClick={() => setShowModal(!showModal)}
      >
        {isGrowthStage ? 'Edit' : 'Measure'}
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="nextIncubator - Measurement"
        width={1000}
        destroyOnClose
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        onCancel={() => setShowModal(!showModal)}
      >
      {isGrowthStage ?
        <BreedingMeasurementForm 
          batch={batch} 
          onSubmit={onClickSubmit} 
          measure={measure}
          record={record}
       />
      :
        <IncubationMeasurementForm batch={batch} onSubmit={onClickSubmit} measure={measure} />
      }
        
      </Modal>
    </div>
  );
}

export default IncubatorMeasurementAction;
