/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';

// Proptypes
import PropTypes from 'prop-types';

// UI Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Local Components
import { momentFoo } from '../../Shared/helper';
import { measureDays } from '../../Shared/Contants';

/* -------------------------------------------------------------------------- */
/*                           BreedingMeasurementForm                          */
/* -------------------------------------------------------------------------- */

function BreedingMeasurementForm({ onSubmit, batch, measure, record}) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [viewMode] = useState(false);
  const [incubationTime, setIncubationTime] = useState(0);
  const [form] = Form.useForm();
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  form.setFieldsValue({ incubationTime });
  const onChange = (value) => {
    const entryDate = momentFoo(batch.entryDate);
    setIncubationTime(value.hatchingDate ? value.hatchingDate.diff(entryDate, 'days') : 0);
  };

  const BreedingFormFields = [
    {
      key: 'hatchingDate',
      label: 'Hatching Date',
      placeholder: 'Hatching Date',
      widget: 'date-picker',
      widgetProps: {
        disabledDate: (current) => current && batch && current > momentFoo().endOf('day'),
      },
      initialValue: measure ? momentFoo(measure.hatchingDate) : null,
      rules: [
        {
          required: true,
          message: 'Hatching Date is required',
        },
      ],
    },
  ];
  if (batch.transferred !== null) {
    BreedingFormFields.pop();
    BreedingFormFields.push(
      {
        key: 'breedingMeasure',
        label: 'Measure',
        placeholder: 'Measure',
        widget: 'select',
        options: measureDays.map((item) => ({ label: item, value: item })),
        rules: [
          {
            required: true,
            message: 'breedingMeasure is required',
          },
        ],
        initialValue: record && record.breedingMeasure
      },
      {
        key: 'meanLarvalWeight',
        label: 'Mean larval weight (mg)',
        placeholder: 'Mean larval weight',
        widget: 'number',
        rules: [
          {
            required: true,
            message: 'Mean larval weight is required',
          },
        ],
        initialValue: record && record.meanLarvalWeight
      },
      {
        key: 'comment',
        label: 'Comment',
        placeholder: 'Comment',
        widget: 'textarea',
        initialValue: record && record.comment
      },
    );
  } else {
    BreedingFormFields.push({
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment',
      widget: 'textarea',
    });
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} onValuesChange={onChange} form={form}>
      <FormBuilder form={form} meta={BreedingFormFields} viewMode={viewMode} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    
    </Form>
  );
}

BreedingMeasurementForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default BreedingMeasurementForm;