/* eslint-disable func-names */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// translation
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Card, Modal, Typography, Dropdown, Menu, Row, Col, Button, notification } from 'antd';
import {
  UndoOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';

import { fetchAllBatches, updateBatch } from '../../../reducers/Batch.slice';
import { selectSessionUser, selectStage } from '../../../reducers/Session.slice';
import { stages } from '../../../common/const';

/* -------------------------------------------------------------------------- */
/*                                  List Item                                 */
/* -------------------------------------------------------------------------- */
function ListItem(props) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const { badges, onClickRemove, onClickEdit, data, date, identifier, actions, extraData, pathname, batch } = props;
  const [expanded, setExpanded] = useState(false);
  const user = useSelector(selectSessionUser);
  const currentStage = useSelector(selectStage);
  // translation
  const { t } = useTranslation(['stages', 'common']);
  const getUserAccessPrivilege = user.privilege && user.privilege;
  /* ----------------------------- RENDER HELPERS ----------------------------- */


  const undoTransfet = () => {
    const fields = {
      transferred: null,
      nLTransferDate: null,
      biomass: null,
      meanLarvalWeight: null,
      substrate: null,
      std_bins_out: null,
      std_weight_out: null,
      std_density: null,
      non_std_bins_out: null,
      non_std_weight_out: null,
      non_std_density: null,
      content: null,
      larvae_sieving: null,
    };
    dispatch(
      updateBatch({
        fields,
        batchId: pathname.state.batch.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Batch Undo Transfer',
          description: 'Undo Transfer successfully',
        });
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Batch Undo Transfer',
          description: 'An error occured',
        }),
      );
  };

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0 ? n.toFixed(2) : n;
  }

  const menu = (
    <>
      <Menu>
        {pathname && pathname.state.batch.transferred && (
          <Menu.Item
            key="2"
            icon={<UndoOutlined />}
            onClick={() =>
              confirm({
                title: t(`do you want to return batch ${batch.identifier} to nextIncubator?`),
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  return undoTransfet();
                },
              })
            }
          >
            Undo Transfer
          </Menu.Item>
        )}
        <Menu.Item key="2" icon={<EditOutlined />} onClick={onClickEdit}>
          {t('common:edit')}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            confirm({
              title: t('common:comfirmDeleteMessage', { value: identifier }),
              icon: <ExclamationCircleOutlined />,
              onOk() {
                return onClickRemove();
              },
            })
          }
          key="1"
          icon={<DeleteOutlined />}
        >
          {t('common:delete')}
        </Menu.Item>
      </Menu>
    </>
  );

  /* ------------------------------- Next Larvae ------------------------------ */

  const filterDataForNextLarvae = (item) =>{
   if(currentStage === 'nextLarvae')
   switch (item) {
      case 'NON STD Weight (g/bin)':
        return false;
      case 'Lw(g) 4th day':
        return false;
      case 'Weight (g/bin)':
        return false;
      default:
        return true;
   }
   return true; 
  }

  const getTotalBinsSieved = batch?.larvae_sievings
    .map((item) => item.bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);
  const getTotalNonBinsSieved = batch?.larvae_sievings
    .map((item) => item.non_bins_sieved)
    .reduce(function (previousValue, currentValue) {
      return Number(previousValue) + Number(currentValue);
    }, 0);

  const FCR = () => {
    let part1 = 0;
    let part2 = 0;

    const { incubator_inputs: incInputs, biomass, substrate } = batch;
    for (let j = 0; j < incInputs.length; j += 1) {
      const extraCF = incInputs[j]?.extra_cf;
      const feed = incInputs[j]?.feed;

      part1 += extraCF + feed;
      part2 += (biomass * (100 - substrate)) / 100;
    }
    const result = part1 / part2;

    return result ? result.toFixed(2) : 0;
  };

  // Calculate the FCR attribute for nextLarvae batches 
  // FCR formula : feed/biomass
  const calculateFCRforNextLarvae = () => {
    if(batch.transferred && batch.killed_batches.length)
      return (Number(batch.totalLarvaeFeedQuantity / batch.killed_batches[0].biomass).toFixed(3) || 0);
    return 0;
  }
  
  // Calculate the BBR attribute for nextLarvae batches 
  // BBR formula : biomass/(STD + NSTD bins)
  const calculateBBRforNextLarvae =  () => {
    if(batch.transferred && batch.killed_batches.length)
      return (Number(batch.killed_batches[0].biomass/(batch.non_std_bins_out+batch.bins)).toFixed(3) || 0)
    return 0;
  }

  // eslint-disable-next-line max-len
  const HatchingRate =
    ((batch.biomass * 1000 * (100 - batch.substrate)) / batch.meanLarvalWeight / batch.eggQty) * 0.000027;
    
  const E_PER_B = batch.biomass*(100-batch.substrate)/100 / batch.eggQty;

  const BINS_OUT = batch.bins + batch.non_std_bins;
  const cardDetails = () => (
    <Row className="card-item-content">
      <Row className="card-item-detail">
        <Title level={5}>
          {date.label}:<span style={{ fontWeight: 300 }}> {date.value}</span>
        </Title>
        {data.map((item) => (
          <Col span={24} key={item.label}>
            {item.value !== null && item.value !== undefined && (
              <Title level={5}>
                <>
                  {item.label}: <span style={{ fontWeight: 300 }}>{isFloat(item.value)}</span>
                </>
              </Title>
            )}
          </Col>
        ))}
        {batch.larvae_sieving && (
          <Title level={5}>
            Bins Sieved: <span style={{ fontWeight: 300 }}> {isFloat(BINS_OUT)} </span>
          </Title>
        )}
        { extraData?.map((item) => (
          filterDataForNextLarvae(item.label) && <Col span={24} key={item.label}>
            {item.value !== null && item.value !== undefined && (
              <Title level={5}>
                <>
                  {item.label}: <span style={{ fontWeight: 300 }}> {isFloat(item.value)}</span>
                </>
              </Title>
            )}
          </Col>
        
        ))}
        <Col span={24}></Col>
        { !props.isGrowthStage && currentStage !== 'nextLarvae' &&
          <Col span={24}>
            <Title level={5}>
              Hatching Rate: <span style={{ fontWeight: 300 }}> {isFloat(HatchingRate) || '0'} </span>
            </Title>
          </Col>
        }
        {batch.transferred && (
          <>
          { !props.isGrowthStage &&
            <>
              <Col span={24}>
                <Title level={5}>
                  Total Bins sieved: <span style={{ fontWeight: 300 }}> {getTotalBinsSieved} </span>
                </Title>
              </Col>
              <Col span={24}>
                <Title level={5}>
                  Total non Bins sieved: <span style={{ fontWeight: 300 }}> {getTotalNonBinsSieved} </span>
                </Title>
              </Col>
            </>
          }
            <Col span={24}>
              <Title level={5}>
                FCR Inc: <span style={{ fontWeight: 300 }}> {
                  currentStage === 'nextLarvae' ? calculateFCRforNextLarvae() : FCR()} </span>
              </Title>
            </Col>
            { currentStage === 'nextLarvae' && batch.killed_batches.length && 
                <Col span={24}>
                  <Title level={5}>
                    BBR: <span style={{ fontWeight: 300 }}> {calculateBBRforNextLarvae()} </span>
                  </Title>
                </Col> 
            }

            {
              currentStage === stages.NEXTINCUBATOR_FOR_BREEDING && batch.transferred &&
              <>
              <Col span={24}>
                  <Title level={5}>
                    Eggs QTY introduced: <span style={{ fontWeight: 300 }}> {batch.eggQty} </span>
                  </Title>
                </Col> 

                <Col span={24}>
                  <Title level={5}>
                    Total feed served (kg): <span style={{ fontWeight: 300 }}> {batch.totalFeedQuantity}</span>
                  </Title>
                </Col>

                <Col span={24}>
                  <Title level={5}>
                    Substrate(%): <span style={{ fontWeight: 300 }}> {batch.substrate} </span>
                  </Title>
                </Col>   

              <Col span={24}>
                  <Title level={5}>
                    Transfer date: <span style={{ fontWeight: 300 }}> {batch.nLTransferDate} </span>
                  </Title>
                </Col> 
              
                <Col span={24}>
                  <Title level={5}>
                    Mean larvae weight: <span style={{ fontWeight: 300 }}> {batch.meanLarvalWeight} </span>
                  </Title>
                </Col>   
              </>
            }
            <Col span={24}>
              <Title level={5}>
                B/E: <span style={{ fontWeight: 300 }}> {isFloat(E_PER_B) || '0'} </span>
              </Title>
            </Col>
          </>
        )}

        <Button type="text" onClick={() => setExpanded(!expanded)} className="card-item-footer-btn-more">
          {expanded ? <MinusOutlined style={{ fontSize: 13 }} /> : <PlusOutlined style={{ fontSize: 13 }} />}
          {expanded ? t('common:seeLess') : t('common:seeMore')}
        </Button>
      </Row>
    </Row>
  );

  return (
    <div className="card-item-container">
      <Card bordered className="card-item">
        <Row justify="end" span={24} className="card-item-actions">
          {((getUserAccessPrivilege?.nextIncubatorModification && currentStage === 'nextIncubator') ||
            (getUserAccessPrivilege?.nextLarvaeModification && currentStage === 'nextLarvae') ||
            (getUserAccessPrivilege?.nextBreedingModification && currentStage === 'nextIncubatorForBreeding')) && (
            <Dropdown overlay={menu} placement="topRight" className="card-item-actions-dropdown">
              <EllipsisOutlined style={{ fontSize: 26 }} />
            </Dropdown>
          )}
        </Row>

        <Row className="card-item-header">
          <Col span={12}>
            <span className="card-item-title">{identifier}</span>
          </Col>
          <Col className="card-item-tag" span={12}>
            {badges}
          </Col>
        </Row>
        
        {cardDetails()}
        {((getUserAccessPrivilege?.nextIncubatorModification && currentStage === 'nextIncubator') ||
          (getUserAccessPrivilege?.nextLarvaeModification && currentStage === 'nextLarvae') ||
          (getUserAccessPrivilege?.nextBreedingModification && currentStage === 'nextIncubatorForBreeding')) && (
          <Row justify="end">
            <Link to={pathname}>
              <Button className="card-item-footer-btn-details">{t('common:viewDetails')}</Button>
            </Link>
          </Row>
        )}
        <Row justify="space-between" className="card-item-actions">
          {actions}
        </Row>
      </Card>
    </div>
  );
}

ListItem.propTypes = {
  identifier: PropTypes.string,
  date: PropTypes.object,
  pathname: PropTypes.object,
  data: PropTypes.array,
  extraData: PropTypes.array,
  actions: PropTypes.any,
  badges: PropTypes.any,
  onClickRemove: PropTypes.func,
  onClickEdit: PropTypes.func,
  batch: PropTypes.object,
  isGrowthStage: PropTypes.bool
};

export default ListItem;
