/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                  Packages                                  */
/* -------------------------------------------------------------------------- */

import React, { useCallback } from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useSelector } from 'react-redux';

import { momentFoo } from '../../Shared/helper';
import { selectNextProcessBatches } from '../../../reducers/Batch.slice';

/* -------------------------------------------------------------------------- */
/*                               Main Component                               */
/* -------------------------------------------------------------------------- */

function ProcessKillingForm({ onSubmit, batch, onlyFormItems }) {
  const batchlist = useSelector(selectNextProcessBatches);
  const [form] = Form.useForm();

  const handleFinish = useCallback((values) => {
    onSubmit(values);
  });
  const killingFormFields = [
    {
      key: 'killingDate',
      label: 'Killing Date',
      placeholder: 'Killing Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Killing Date is required',
        },
      ],
    },
    {
      key: 'batch',
      label: ' From Batch',
      placeholder: 'From Batch',
      widget: 'select',
      options: batchlist
        .filter((item) => !item.larvae_sieving?.frass || !item.larvae_sieving?.larvalWeight)
        .map((item) => ({ label: item.identifier, value: item.id })),
      rules: [
        {
          required: true,
          message: 'Batch is required',
        },
      ],
    },
    {
      key: 'biomass',
      label: 'Biomass(kg)',
      placeholder: 'Biomass',
      widget: 'number',
      initialValue: 0,
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Biomass is required',
        },
      ],
    },

    {
      key: 'period',
      label: ' Period (min) ',
      placeholder: 'Period (min)  ',
      widget: 'number',
    },
    {
      key: 'temperature',
      label: 'Temperature (C°)',
      placeholder: 'Temperature ',
    },

    {
      key: 'drainingTime',
      label: ' Draining time (min) ',
      placeholder: 'Draining time (min)  ',
      widget: 'number',
    },
    {
      key: 'content',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
    {
      key: 'isBatchEnd',
      label: '2nd Batch',
      widget: 'switch',
      dynamic: true,
    },
  ];

  // Push other input if choose others
  if (form.getFieldValue('isBatchEnd') === true) {
    killingFormFields.push({
      key: 'newbatch',
      label: 'And Batch',
      widget: 'select',
      options: batchlist
        .filter((i) => i.id !== form.getFieldValue('batch'))
        .map((item) => ({ label: item.identifier, value: item.id })),
    });
    killingFormFields.push({
      key: 'newBatchBiomass',
      label: 'Biomass (kg)',
      placeholder: 'Biomass(kg)',
      widget: 'number',
      initialValue: 0,
      rules: [
        {
          required: true,
          message: 'Biomass is required',
        },
      ],
    });
  }
  const forceUpdate = FormBuilder.useForceUpdate();

  return (
    <Form layout="horizontal" onFinish={handleFinish} onValuesChange={forceUpdate} form={form}>
      <FormBuilder form={form} meta={killingFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

ProcessKillingForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  batch: PropTypes.object,
};

export default ProcessKillingForm;
