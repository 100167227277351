/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';


// Translation
import { useTranslation } from 'react-i18next';

// Local Components
import WateringForm from './WateringForm';

// Reducers
import { fetchCages, createWatering } from '../../../reducers/NextFly.slice';


/* -------------------------------------------------------------------------- */
/*                               Watering Action                              */
/* -------------------------------------------------------------------------- */
function WateringAction({ cage, disabled }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    
    dispatch(
      createWatering({
        ...data,
        cage: cage.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Watering',
          description: 'Watering successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchCages());
      })
      .catch(() =>
        notification.error({
          message: 'Watering',
          description: 'An error occured',
        }),
      );
  };
  /* -------------------------------- RENDERING ------------------------------- */

  const { t } = useTranslation('stages');
  return (
    <div>
      <Button
       type='primary'
       className='card-item-action-btn'
       disabled={disabled} 
       onClick={() => setShowModal(!showModal)}>
        towels
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title={t('Change of towel')}
        width={1000}
        visible={showModal}
        footer={null}
        closable
        maskClosable={false}
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <WateringForm cage={cage} onSubmit={onClickSubmit} />
      </Modal>
    </div>
  );
}

export default WateringAction;
