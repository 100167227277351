/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useRef, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

// translation
import { useTranslation } from 'react-i18next';

// UI Components
import { Steps, Form, Button, List, Modal, Select
  ,notification ,Badge, Row, Tag, Col} from 'antd';

import FormBuilder from 'antd-form-builder';
import { momentFoo } from '../Shared/helper';

// reducers
import { selectAttractantTypes, createBulkActions, fetchCages } from '../../reducers/NextFly.slice';

// local components
import CageItem from './CageItem';
import BulkTable from './BulkTable';

/* -------------------------------------------------------------------------- */
/*                                  Cage List                                 */
/* -------------------------------------------------------------------------- */
function CageList({ list }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Option } = Select;
  const [searchChamber, setSearchChamber] = useState({
    chamber: '',
  });

  const { t } = useTranslation('stages');

  const [showModal,setShowModal] = useState(false);
  const [form] = Form.useForm();
  const forceUpdate = FormBuilder.useForceUpdate();
  const attractants = useSelector(selectAttractantTypes);
  const formRef = useRef();
  const dispatch = useDispatch();
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const filteredCages = list.filter(
    (item) => item.chambre?.toLowerCase().indexOf(searchChamber.chamber?.toLowerCase()) !== -1,
  );

  // Get Uniq chamber name for filter use
  const chamber = [];
  list.map((p) => chamber.push(p.chambre));
  const Uniqchamber = [...new Set(chamber)];

  const { Step } = Steps;

  const [current, setCurrent] = useState(0);

  const [ data, setData] = useState({
    selectedCages: [],
    actions: {},
  });

  const next = () => {
    setCurrent(current + 1);

  };

  const prev = () => {
    setCurrent(current - 1);
  };


  const getFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'click to select date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      rules: [
        {
          required: true,
          message: 'Transfer Date is required',
        },
      ],
    },
    {
      key: 'attractant',
      label: 'Attractant type',
      placeholder: 'type',
      widget: 'select',
      options: attractants.map((item) => ({ label: item.title, value: item.id })),
      initialValue: null
    },
    {
      key: 'cleaning',
      label: 'Cleaning',
      placeholder: 'standard bins (nb)',
      widget: 'checkbox',
      widgetProps: {
        min: 0,
      },
      initialValue: false
    },
    {
      key: 'watering',
      label: 'Water',
      placeholder: 'non standard bins (nb)',
      widget: 'checkbox',
      widgetProps: {
        min: 0,
      },
      initialValue: false
    },

  ];
  

    const selectCages = (cages) => {
      setData({
        ...data,
        selectedCages: cages
      })
    }

    // Set current step to 0 and reset form fields then close the modal
    const onModalClose = ()=>{
      setShowModal(false);
      setCurrent(0);
      form.resetFields(); 
    }

  // steps content
  const steps = [
    {
      title: 'Cage Selection',
      content: <BulkTable selectCages={selectCages} cages={filteredCages}/>,
    },
    {
      title: 'Actions',
      content: <Form 
                  layout="horizontal"
                  form={form}
                  onValuesChange={forceUpdate}
                  onFinish={(values) => {
                    setData({...data,actions: values});
                    if(!values.cleaning && !values.watering && !values.attractant)
                      notification.error({
                        message: 'No Action Selected',
                        description: 'At least select 1 action',
                      });
                    
                    else
                      next();

                  }}
                  name="control-ref"
                  ref={formRef}
               >
                  <FormBuilder form={form} meta={getFields} />
              </Form>,
    },
    {
      title: 'Validation',
      content: <>
                  <Row  justify="start" align='top'> 

                  <Col span={4}>
                    <h3>Selected cages :</h3>
                  </Col>
     
                  <Col span={20}>
           
                  <Row gutter={[10, 10]}>
                  { data.selectedCages.map((cage) =>
                      <Col key={cage.id} span={4}>
                        <Tag>{cage.ref}</Tag>
                      </Col>
                  )}
                  
                  </Row>
                  </Col>
                  
  
                  </Row>      
                  <Row align='top'>   
                  <Col span={2}>
                    <h3>Actions :</h3>
                  </Col>

                  <Col span={22}>

                  <Row>
                  { Object.entries(data.actions).map(([actionName,actionTriggred]) =>
                    actionTriggred && actionName!=='date' &&
                    
                    <Col key={actionName} span={4}>

                    <Badge
                        className="site-badge-count-109"
                        count={actionName}
                        style={{ backgroundColor: '#52c41a' }}
                      />

                    </Col>
                  )} 
                  </Row>
                  </Col>
                  </Row>
               </>,
    },
  ]


 // Every click on next step button trigger the function onSubmit
  const onSubmit = ()=>{

 // If the current step is 0 we check if the user checked at least one row from the table
    if(current === 0 && data.selectedCages.length === 0)
    {
        notification.error({
        message: 'No Cage Selected',
        description: 'At least select 1 cage',
      }); 
      return;
    }
 // If the current step is 1 we trigger a submit event to the form using the hook useRef
    if(formRef.current)
      formRef.current.submit();
    else
      next();
  }

  const dispatchBulkAction = ()=>{
    dispatch(createBulkActions(data))
    .then(unwrapResult)
    .then(() => {
      notification.success({
        message: 'Bulk actions',
        description: 'Actions performed successfully',
      });
      
      onModalClose();
      dispatch(fetchCages());
    })
    .catch(() =>
      notification.error({
        message: 'Bulk actions',
        description: 'An error occured',
      }),
    );
  }

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <div className="mb-40">
      <div className="header-container">
      <Button 
         type="primary"
         className="mb-20 bulk-actions-btn"
         onClick={()=>setShowModal(!showModal)}
      >
            {t('BULK ACTIONS')}
          </Button>
      <Row>
        <Select
          className="date-picker-input"
          placeholder="Search Chamber"
          onChange={(value) =>
            setSearchChamber({
              ...searchChamber,
              chamber: value,
            })
          }
        >
          {Uniqchamber.map((p) => (
            <Option key={p} value={p}>
              {p}
            </Option>
          ))}
        </Select>
        </Row>
      </div>

        <Modal
          style={{ minHeight: '1500px !important' }}
          title={'NextFly cages - Bulk actions'}
          width={1000}
          visible={showModal}
          maskClosable={false}
          footer={null}
          onCancel={onModalClose}
        >


      <Steps style={{marginBottom:'5%'}} current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      
      {steps[current].content}
      
      <Row style={{marginTop:'2%'}} justify={ current === 0 ? 'end' :'space-between' }>

      {current > 0 && (
          <Col>
            <Button onClick={() => prev()}>
              Previous
            </Button>
          </Col>
        )} 
        
        {current < steps.length - 1 && (
          <Col>
 
            <Button type="primary" onClick={onSubmit}>
              Next
            </Button>
          </Col>
        )}
        {current === steps.length - 1 && (
          <Col>
            <Button type="primary" onClick={dispatchBulkAction}>
              Submit
            </Button>
          </Col>
        )}

        
    </Row>       
      </Modal>
        
      </div>

      <List
        className="next-fly-Lot-list"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={filteredCages}
        renderItem={(item) => (
          <List.Item>
            <CageItem key={item.id} cage={item} />
          </List.Item>
        )}
      />
    </div>
  );
}

CageList.propTypes = {
  list: PropTypes.object,
};

export default CageList;
