/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';

// reducers
import { Layout, Row, Typography, Col, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { $setStage } from '../../reducers/Session.slice';

// UI Components

// local Component
import SupplierList from '../../components/NextFeed/Suppliers/SupplierList';
import { fetchFeedEntries, fetchCategories } from '../../reducers/NextFeed.slice';
import FeedEntry from '../../components/Logistics/FeedEntry/FeedEntry';
import RmStockCard from '../../components/Logistics/RmStock/RmStockCard'
import RmStockTable from '../../components/Logistics/RmStock/RmStockTable';
import EndProducts from '../../components/Logistics/EndProducts/EndProducts.tabs';

// CONST
import { stages } from '../../common/const';

// styles
import './Logistics.less';

/* -------------------------------------------------------------------------- */
/*                               Next Feed Page                               */
/* -------------------------------------------------------------------------- */
function Logistics() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch($setStage(stages.NEXTFEED));
    dispatch(fetchFeedEntries());
    dispatch(fetchCategories());
  }, []);

  const { t } = useTranslation('stages');
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content logistics">
      <Row>
        <Col span={22} className="first-title">
          <Title level={1}>Logistics</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey="Reception of raw materials">
        <TabPane tab={<span>{t('Suppliers')}</span>} key={t('Suppliers')}>
          <SupplierList />
        </TabPane>
        <TabPane tab="Reception of raw materials" key="Reception of raw materials">
          <FeedEntry />
        </TabPane>
        <TabPane tab="RM Stock" key="RM Stock">
          <div className="mb-40">
            <RmStockCard />
          </div>
          <RmStockTable />
        </TabPane>
        <TabPane tab="End products" key="End products">
          <EndProducts/>
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default Logistics;
