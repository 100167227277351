
import _ from 'lodash';

 /* ----------------------------- HELPERS ----------------------------- */

 // Replace all occurences of '-' with '/'
export const formatDate = (StringDate) => StringDate.replace(/-/g,'/');

export  const mapKey = (array,oldKey,newKey) =>
    array.map((item) => _.mapKeys(item, (__, key) => key === oldKey ? newKey : key));


// OldKeys : array , newKeys : array 
export  const mapKeys = (array,newkeys) =>
 array.map((item) => _.mapKeys(item, (__, key) => newkeys[key] || key));


export const renameKeys = (object,newkeys) => _.mapKeys(object, (__, key) => newkeys[key] || key);



