/* eslint-disable react/prop-types */
// Packages
import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';

// redux
// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import CleaningForm from './CleaningForm';

// Reducers
import { fetchCages, createCleaning } from '../../../reducers/NextFly.slice';


function CleaningAction({ cage, disabled }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      createCleaning({
        ...data,
        cage: cage.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Cage Cleaning',
          description: 'Cleaning successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchCages());
      })
      .catch(() =>
        notification.error({
          message: 'Cage Cleaning',
          description: 'An error occured',
        }),
      );
  };

  return (
    <div>
      <Button
       type='primary'
       className='card-item-action-btn'
       disabled={disabled} 
       onClick={() => setShowModal(!showModal)}
      >
        Cleaning
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Cage Cleaning"
        width={1000}
        visible={showModal}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <CleaningForm cage={cage} onSubmit={onClickSubmit} />
      </Modal>
    </div>
  );
}

export default CleaningAction;
