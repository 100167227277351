/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// UI Components
import { Modal, Row, Button, Empty, Col, Input } from 'antd';

// local components
import { momentFoo } from '../../Shared/helper';
import FeedEntryForm from './FeedEntryForm';
import FeedEntryList from './FeedEntryList';

import { fetchFeedEntries, selectFeedEntries } from '../../../reducers/NextFeed.slice';
import { selectSessionUser } from '../../../reducers/Session.slice';

/* -------------------------------------------------------------------------- */
/*                                 Feed Entry                                 */
/* -------------------------------------------------------------------------- */
function FeedEntry() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Search } = Input;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const feedEntries = useSelector(selectFeedEntries);
  const [searchFormData, setSearchFormData] = useState({ searchTitle: '' });
  const user = useSelector(selectSessionUser);
  const getUserAccessPrivilege = user.privilege && user.privilege
  /* ---------------------------------- CONST --------------------------------- */
  /**
   * Check if Batch is compatible with filters
   * @param {object} batch Batch object
   * @param {*} formData FormData object containes
   * filters(searchTitle, searchOperation, searchDate)
   */
  const checkBatch = (batch, formData) => {
    // query wil be false if filters are empty
    let query = true;
    if (formData.searchTitle !== '') {
      query = query && batch.identifier.toLowerCase().indexOf(formData.searchTitle.toLowerCase()) !== -1;
    }

    if (formData.searchDate) {
      query = query && momentFoo(batch.date).isSame(momentFoo(formData.searchDate), 'date');
    }
    return query;
  };

  useEffect(() => {
    dispatch(fetchFeedEntries());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const formModal = () => {
    return (
      <Modal
        style={{ minHeight: '500px !important' }}
        title="New Feed Entry"
        width={1000}
        visible={showModal}
        footer={null}
        destroyOnClose
        maskClosable={false}
        onCancel={() => setShowModal(false)}
      >
        <FeedEntryForm onSubmit={() => setShowModal(!showModal)} />
      </Modal>
    );
  };

  if (feedEntries.length === 0) {
    return (
      <div className="empty-data-container">
        <Empty description={<span>Empty list</span>} className="empty-data-content">
          <Button onClick={() => setShowModal(true)} type="primary">
            Create New FeedEntry
          </Button>
        </Empty>
        {formModal()}
      </div>
    );
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Row
        className="card-list-filter-bar mb-40"
        justify="space-between"
        align="middle"
      >
        <Col>
          <Row align="middle" justify="space-around">
            <Col>
              <Search
                allowClear
                className="search-input"
                placeholder="Search By Id"
                onChange={(e) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchTitle: e.target.value,
                  })
                }
                style={{ width: 200 }}
              />
            </Col>
            <Col className="ml-20">
              {getUserAccessPrivilege?.logisticsMakeAction && (
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => setShowModal(!showModal)}
                >
                  +
                </Button>
              )}
              {formModal()}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <FeedEntryList
          feedEntries={feedEntries.filter((item) => checkBatch(item, searchFormData))}
        />
      </Row>
    </div>
  );
}

FeedEntry.propTypes = {};

export default FeedEntry;
