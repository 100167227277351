/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { useLocation } from 'react-router-dom';

// UI Components
import { Modal, Button, notification, Table, Row, Typography, Empty, Col } from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import { fetchSuppliers, deleteSupplier } from '../../../reducers/NextFeed.slice';



function SupplierDetails(props) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const { Title } = Typography;
  const dispatch = useDispatch();
  const location = useLocation();
  const { supplier } = location.state;
  /* -------------------------------- CALLBACKS ------------------------------- */

  const deleteItem = (item) => {
    confirm({
      title: 'Do you want to delete Supplier ?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteSupplier(item.id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Supplier',
              description: 'Supplier was Deleted successfully',
            });
            dispatch(fetchSuppliers());
          })
          .catch(() =>
            notification.error({
              message: 'Delete Supplier',
              description: 'An error occured',
            }),
          );
      },
    });
  };


  const head = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Identifiant',
      dataIndex: 'identifier',
      key: 'identifier',
    },
    {
      title: 'Billed Weight (kg)',
      dataIndex: 'billedWeight',
      key: 'billedWeight',
    },
    {
      title: 'Price Per Ton (dt)',
      dataIndex: 'pricePerTon',
      key: 'pricePerTon',
    },
    {
      title: 'Total cost (dt)',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  if (!supplier) {
    return <Empty description={<span>No available Data</span>} className="empty-data-content" />;
  }
  /* -------------------------------- RENDERING -------------------------------- */
  return (
    <div>
      <Row>
        <Col span={24}>
          <Title level={1}>
            <ArrowLeftOutlined onClick={() => props.history.goBack()} />
          </Title>
        </Col>
        <Col span={18}>
          <Title level={2}>{supplier.name}</Title>
        </Col>
        <Col span={4}>
          <Row justify="space-between">
            <Button onClick={() => deleteItem(supplier)}>Remove</Button>
          </Row>
        </Col>
      </Row>
      <Table columns={head} dataSource={supplier.feed_entries} />
    </div>
  );
}
SupplierDetails.propTypes = {};

export default SupplierDetails;
