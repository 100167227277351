// Headers are the columns of csv file 
// Label is the column name
// Key is for mapping data with the corresponding column 

export const RM_STOCK_HEADERS = [  
    { label:'Reception Date', key: 'receptionDate' },
    { label:'id', key: 'id' },
    { label:'Category', key: 'category' },
    { label:'Subcategory', key: 'subcategory' },
    { label:'Supplier', key: 'supplier' },
    { label:'Received weight (kg)', key: 'receivedWeight' },
    { label:'Current weight', key: 'currentWeight' },
    ];