/* eslint-disable func-names */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Translation 
import { useTranslation } from 'react-i18next';

// Redux 
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Local Component
import { momentFoo } from '../../Shared/helper';
import { updateBreedingSieving } from '../../../reducers/NextBreeding.slice';
import { fetchAllBatches } from '../../../reducers/Batch.slice';

/* -------------------------------------------------------------------------- */
/*                                PupaeStock Form                                */
/* -------------------------------------------------------------------------- */
function PupaeStockForm({onlyFormItems,onSubmit, batch}) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  // Precalculate RealDensity field using 4 fields from form
  const caluclateRealDensityField = (fieldKey,value) =>{

    const formulaParams = {
      biomassIntroduced: form.getFieldValue('biomassIntroduced') || 0,
      impuritiesWhileIntroduction: form.getFieldValue('impuritiesWhileIntroduction') || 0,
      pupae : form.getFieldValue('pupae') || 0,
      pupaeWeight: form.getFieldValue('pupaeWeight') || 1
    }

     formulaParams[fieldKey] = value;

     const {biomassIntroduced, impuritiesWhileIntroduction, pupae, pupaeWeight} = formulaParams;

        form.setFieldsValue({
          realDensity:
          biomassIntroduced * impuritiesWhileIntroduction * pupae/ pupaeWeight / 1000
        }) 

  }

  // Perform a reintroduction action by submitting the form
  const onFormSubmit = (data)=>{

    dispatch(updateBreedingSieving({
      ...data,
      id:batch.id,
      reintroduced: true
    }))
    .then(unwrapResult)
    .then(()=>{    
      onSubmit();
      notification.success({
      message: t('batch introduced'),
      description: t('batch was reintroduced successfully')})
      dispatch(fetchAllBatches());
  })
    .catch(() =>
    notification.error({
      message: t('Reintroduction error'),
      description: t('Something went wrong'),
    }),
  );

  }


  const PupaeStockFormFields = [
    {
      key: 'reintroductionDate',
      label: 'Reintroduction Date',
      placeholder: 'Reintroduction date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) =>
          current && batch && (current > momentFoo().endOf('day') || current < momentFoo(batch.entryDate).endOf('day')),
      },
      rules: [
        {
          required: true,
          message: 'Sieving Date is required',
        },
      ],
    },

    {
      key: 'biomassIntroduced',
      label: 'Biomass introduced (kg)',
      placeholder: '0 Kg',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Biomass(kg) is required',
        },
      ],
      widgetProps: {
        min: 0,
      },

      onChange: (value) => caluclateRealDensityField('biomassIntroduced',value)
    },
    {
      key: 'impurities',
      label: 'Impurities (kg)',
      placeholder: '..kg',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Impurities is required',
        },
      ],
    },
    {
      key: 'impuritiesWhileIntroduction',
      label: 'Impurities while introduction (%)',
      placeholder: '..%',
      widget: 'number',
      widgetProps: {
        min: 0,
        max: 100
      },
      rules: [
        {
          required: true,
          message: 'Impurities while introduction(%) is required',
        },
      ],
      onChange: (value) => caluclateRealDensityField('impuritiesWhileIntroduction',value)
    },
    
    {
      key: 'pupaeWeight',
      label: 'Pupae weight (g)',
      placeholder: '1',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      rules: [
        {
          required: true,
          message: 'Pupae weight(g) is required',
        },
      ],
      onChange: (value) => caluclateRealDensityField('pupaeWeight',value)
    },
    {
      key: 'pupae',
      label: 'Pupae (%)',
      placeholder: '1',
      widget: 'number',
      widgetProps: {
        max: 100,
        min: 0,
        
      },
      rules: [
        {
          required: true,
          message: 'Pupae(%) is required',
        },
      ],
      onChange: (value) => caluclateRealDensityField('pupae',value)
    },
    {
      key: 'realDensity',
      label: 'Real density',
      placeholder: '1',
      widget: 'number',
      disabled: true,
      rules: [
        {
          required: true,
        },
      ],
    },

    {
      key: 'comment',
      label: 'Comment (optional)',
      placeholder: 'comment',
      widget: 'textarea',
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onFormSubmit} form={form}>
      <FormBuilder form={form} meta={PupaeStockFormFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

PupaeStockForm.propTypes = {
  batch: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
  nextBreeding: PropTypes.bool,
};

export default PupaeStockForm;
