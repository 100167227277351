/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

/* -------------------------------------------------------------------------- */
/*                                 Drying Form                                */
/* -------------------------------------------------------------------------- */
function DryingForm({ onSubmit, onlyFormItems, batch, bigBags }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const equipment = ['S1', 'S2', 'S3', 'S4'];

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const toHHMMSS = (secs) => {
    const SEC_NUM = parseInt(secs, 10);
    const hours = Math.floor(SEC_NUM / 3600);
    const minutes = Math.floor(SEC_NUM / 60) % 60;
    const seconds = SEC_NUM % 60;

    return (
      [hours, minutes, seconds]
        // eslint-disable-next-line prefer-template
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':')
    );
  };
  const dryingFields = [
    {
      key: 'batchIdentifier',
      label: 'Batch Id',
      disabled: true,
      initialValue: `${batch.batch?.identifier} ${batch.newbatch ? ` & ${batch.newbatch.identifier}` : ''}`,
    },
    {
      key: 'period',
      label: 'Drying period (h) ',
      placeholder: 'Drying period (h)',
      widget: 'number',
      extra: `${toHHMMSS(form.getFieldValue('period'))}`,
      rules: [
        {
          required: true,
          message: 'Drying period (h) is required',
        },
      ],
    },
    {
      key: 'equipement',
      label: 'equipment',
      placeholder: 'equipment',
      widget: 'select',
      options: equipment.map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'equipment is required',
        },
      ],
    },
    {
      key: 'finalWeight',
      label: ' Dried larvae weight (kg)',
      placeholder: 'Dried larvae weight (kg)',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'Dried larvae weight (kg) is required',
        },
      ],
    },
    {
      key: 'dryingWaste',
      label: ' Drying waste (kg)',
      placeholder: 'Drying waste (kg)',
      widget: 'number',
    },
    {
      key: 'big_bag',
      label: 'Big bag id',
      placeholder: 'Big bag id',
      widget: 'select',
      options: bigBags && bigBags.map((item) => ({ label: item.bigBagId, value: item.id })),
      rules: [
        {
          required: true,
          message: 'Big bag id is required',
        },
      ],
    },
    {
      key: 'finalHumidity',
      label: ' Final humidity',
      placeholder: 'Final humidity',
      widget: 'number',
    },
    {
      key: 'tempAfterDrying',
      label: 'Temp after drying (°C)',
      placeholder: 'temperature',
      widget: 'number',
    },
    {
      key: 'comment',
      label: 'comment',
      placeholder: 'comment',
    },
  ];
  // force update form
  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} form={form} onValuesChange={forceUpdate}>
      <FormBuilder form={form} meta={dryingFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

DryingForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  batch: PropTypes.object,
};

export default DryingForm;
