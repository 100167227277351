/* eslint-disable max-len */
import moment from 'moment';

export const filterPerMonth = (month, year, data) => data.filter(({ entryDate }) => {
  return parseInt(entryDate?.split('-')[1], 10) === month &&
    parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10);
});

export const filterPerYear = (data, year) => data.filter(({ entryDate }) => {
  return parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10);
});

export const Eggs = (month, year, data) => {
  let sum = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { eggQty } = filterPerMonth(month, year, data)[i];
    sum += eggQty;
  }

  const result = sum / 1000;

  return result ? result.toFixed(2) : 0;
};

export const AllEggs = (data, year) => {
  let sum = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { eggQty } = data[i];
    sum += eggQty;
  }

  const result = sum / 1000;

  return result ? result.toFixed(2) : 0;
};

export const Biomass = (month, year, data) => {
  let sum = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { biomass } = filterPerMonth(month, year, data)[i];
    sum += biomass;
  }

  const result = sum / 1000;

  return result ? result.toFixed(2) : 0;
};

export const AllBiomass = (data, year) => {
  let sum = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { biomass } = data[i];
    sum += biomass;
  }

  const result = sum / 1000;

  return result ? result.toFixed(2) : 0;
};

export const MeanLarvalWeight = (month, year, data) => {
  let sum = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { meanLarvalWeight } = filterPerMonth(month, year, data)[i];
    sum += meanLarvalWeight;
  }

  const result = sum / filterPerMonth(month, year, data).length;

  return result ? result.toFixed(2) : 0;
};

export const AllMeanLarvalWeight = (data, year) => {
  let sum = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { meanLarvalWeight } = data[i];
    sum += meanLarvalWeight;
  }

  const result = sum / filterPerYear(data, year).length;

  return result ? result.toFixed(2) : 0;
};

export const MeanLarvalWeightPerWeek = (data, week, year) => {
  const dataPerWeek = data.filter(({ entryDate }) => moment(entryDate, 'YYYY-MM-DD').week() === week
    && parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10));

  let sum = 0;

  for (let i = 0; i < dataPerWeek.length; i += 1) {
    const { meanLarvalWeight } = dataPerWeek[i];
    sum += meanLarvalWeight;
  }

  const result = sum / dataPerWeek.length;

  return result ? result.toFixed(2) : 0;
};

export const Substrate = (month, year, data) => {
  let total = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { substrate } = filterPerMonth(month, year, data)[i];
    total += substrate;
  }

  const result = (total / filterPerMonth(month, year, data).length);
  return result ? result.toFixed(2) : 0;
}

export const AllSubstrate = (data, year) => {
  let total = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { substrate } = data[i];
    total += substrate;
  }

  const result = total / filterPerYear(data, year).length;

  return result ? result.toFixed(2) : 0;
}


export const SumSTD = (month, year, data) => {
  let stdOut = 0;
  let nonStdOut = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const STD_OUT = filterPerMonth(month, year, data)[i].std_bins_out;
    const NON_STD_OUT = filterPerMonth(month, year, data)[i].non_std_bins_out;

    stdOut += STD_OUT;
    nonStdOut += NON_STD_OUT;
  }
  const result = stdOut + nonStdOut;

  return result ? result.toFixed(2) : 0;
}

export const SumAllSTD = (data, year) => {
  let stdOut = 0;
  let nonStdOut = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const STD_OUT = data[i].std_bins_out;
    const NON_STD_OUT = data[i].non_std_bins_out;

    stdOut += STD_OUT;
    nonStdOut += NON_STD_OUT;
  }
  const result = stdOut + nonStdOut;

  return result ? result.toFixed(2) : 0;
}

export const EBR = (month, year, data) => {
  let eggs = 0;
  let stdOut = 0;
  let nonStdOut = 0;

  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { eggQty, } = filterPerMonth(month, year, data)[i];
    const STD_OUT = filterPerMonth(month, year, data)[i].std_bins_out;
    const NON_STD_OUT = filterPerMonth(month, year, data)[i].non_std_bins_out;

    eggs += eggQty;
    nonStdOut += NON_STD_OUT;
    stdOut += STD_OUT;
  }

  const result = eggs / (stdOut + nonStdOut);
  return result ? result.toFixed(2) : 0;
}

export const AllEBR = (data, year) => {
  let eggs = 0;
  let stdOut = 0;
  let nonStdOut = 0;

  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { eggQty, } = data[i];
    const STD_OUT = data[i].std_bins_out;
    const NON_STD_OUT = data[i].non_std_bins_out;

    eggs += eggQty;
    nonStdOut += NON_STD_OUT;
    stdOut += STD_OUT;
  }

  const result = eggs / (stdOut + nonStdOut);

  return result ? result.toFixed(2) : 0;
}

export const EBRPerWeek = (data, week, year) => {
  const dataPerWeek = data.filter(({ entryDate }) => moment(entryDate, 'YYYY-MM-DD').week() === week
    && parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10));

  let eggs = 0;
  let stdOut = 0;
  let nonStdOut = 0;

  for (let i = 0; i < dataPerWeek.length; i += 1) {
    const { eggQty, } = data[i];
    const STD_OUT = data[i].std_bins_out;
    const NON_STD_OUT = data[i].non_std_bins_out;

    eggs += eggQty;
    nonStdOut += NON_STD_OUT;
    stdOut += STD_OUT;
  }

  const result = eggs / (stdOut + nonStdOut);

  return result ? result.toFixed(2) : 0;
};

export const AverageIncubation = (month, year, data) => {
  let total = 0;
  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { nLTransferDate, entryDate } = filterPerMonth(month, year, data)[i];
    const getDay = (day) => parseInt(day?.split('-')[2], 10);
    const incubationTime = getDay(nLTransferDate) - getDay(entryDate);

    total += incubationTime;
  }

  const result = total / filterPerMonth(month, year, data).length;

  return result || 0;
}

export const AllAverageIncubation = (data, year) => {
  let total = 0;
  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { nLTransferDate, entryDate } = data[i];
    const getDay = (day) => parseInt(day?.split('-')[2], 10);
    const incubationTime = getDay(nLTransferDate) - getDay(entryDate);

    total += incubationTime;
  }

  const result = total / filterPerYear(data, year).length;

  return result || 0;
}

export const totalFeedQuantity = (month, year, data) => {
  let sumquantity = 0;
  let sumExtraCF = 0;

  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { incubator_inputs: incInputs } = filterPerMonth(month, year, data)[i];
    if (incInputs.length > 0) {
      for (let j = 0; j < incInputs.length; j += 1) {
        const extraCF = incInputs[j]?.extra_cf;
        const quantity = incInputs[j]?.feed;
        if (extraCF) {
          sumExtraCF += extraCF;
        }
        if (quantity) {
          sumquantity += quantity;
        }
      }
    }
  }
  const total = (sumquantity + sumExtraCF) / 1000;

  return total ? total.toFixed(2) : 0;
}

export const allTotalFeedQuantity = (data, year) => {
  let sumquantity = 0;
  let sumExtraCF = 0;

  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { incubator_inputs: incInputs } = data[i];
    if (incInputs.length > 0) {
      for (let j = 0; j < incInputs.length; j += 1) {
        const extraCF = incInputs[j]?.extra_cf;
        const quantity = incInputs[j]?.feed;
        if (extraCF) {
          sumExtraCF += extraCF;
        }
        if (quantity) {
          sumquantity += quantity;
        }
      }
    }
  }
  const total = (sumquantity + sumExtraCF) / 1000;

  return total ? total.toFixed(2) : 0;
}

export const FCR = (month, year, data) => {
  let part1 = 0;
  let part2 = 0;

  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { incubator_inputs: incInputs, biomass, substrate } = filterPerMonth(month, year, data)[i];
    for (let j = 0; j < incInputs.length; j += 1) {
      const extraCF = incInputs[j]?.extra_cf;
      const feed = incInputs[j]?.feed;

      part1 += extraCF + feed;
      part2 += biomass * (100 - substrate) / 100;
    }
  }
  const result = part1 / part2;

  return result ? result.toFixed(2) : 0;
}

export const AllFCR = (data, year) => {
  let part1 = 0;
  let part2 = 0;

  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { incubator_inputs: incInputs, biomass, substrate } = data[i];
    for (let j = 0; j < incInputs.length; j += 1) {
      const extraCF = incInputs[j]?.extra_cf;
      const feed = incInputs[j]?.feed;

      part1 += extraCF + feed;
      part2 += biomass * (100 - substrate) / 100;
    }
  }
  const result = part1 / part2;

  return result ? result.toFixed(2) : 0;
}

export const FCRPerWeek = (data, week, year) => {
  const dataPerWeek = data.filter(({ entryDate }) => moment(entryDate, 'YYYY-MM-DD').week() === week
    && parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10));

  let part1 = 0;
  let part2 = 0;

  for (let i = 0; i < dataPerWeek.length; i += 1) {
    const { incubator_inputs: incInputs, biomass, substrate } = data[i];
    for (let j = 0; j < incInputs.length; j += 1) {
      const extraCF = incInputs[j]?.extra_cf;
      const feed = incInputs[j]?.feed;

      part1 += extraCF + feed;
      part2 += biomass * (100 - substrate) / 100;
    }
  }
  const result = part1 / part2;

  return result ? result.toFixed(2) : 0;
};

export const Feed = (month, year, data) => {
  let sumquantity = 0;
  let sumExtraCF = 0;
  let sumEggs = 0;

  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { eggQty, incubator_inputs: incInputs } = filterPerMonth(month, year, data)[i];
    sumEggs += eggQty;
    if (incInputs.length > 0) {
      for (let j = 0; j < incInputs.length; j += 1) {
        const extraCF = incInputs[j]?.extra_cf;
        const quantity = incInputs[j]?.feed;
        if (extraCF) {
          sumExtraCF += extraCF;
        }
        if (quantity) {
          sumquantity += quantity;
        }
      }
    }
  }

  const result = (sumquantity + sumExtraCF) / sumEggs;

  return result ? result.toFixed(2) : 0;
}

export const AllFeed = (data, year) => {
  let sumquantity = 0;
  let sumExtraCF = 0;
  let sumEggs = 0;

  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { eggQty, incubator_inputs: incInputs } = data[i];
    sumEggs += eggQty;
    if (incInputs.length > 0) {
      for (let j = 0; j < incInputs.length; j += 1) {
        const extraCF = incInputs[j]?.extra_cf;
        const quantity = incInputs[j]?.feed;
        if (extraCF) {
          sumExtraCF += extraCF;
        }
        if (quantity) {
          sumquantity += quantity;
        }
      }
    }

  }
  const result = (sumquantity + sumExtraCF) / sumEggs;

  return result ? result.toFixed(2) : 0;
}

export const HatchingRate = (month, year, data) => {
  let step1 = 0;
  let step2 = 0;

  for (let i = 0; i < filterPerMonth(month, year, data).length; i += 1) {
    const { biomass, substrate, meanLarvalWeight, eggQty } = filterPerMonth(month, year, data)[i];
    step1 += biomass * 1000 * (100 - substrate) / meanLarvalWeight / eggQty * 0.000027 * biomass / meanLarvalWeight;
    step2 += biomass / meanLarvalWeight;
  }

  const result = step1 / step2;

  return result ? result.toFixed(2) : 0;
}

export const AllHatchingRate = (data, year) => {
  let step1 = 0;
  let step2 = 0;

  for (let i = 0; i < filterPerYear(data, year).length; i += 1) {
    const { biomass, substrate, meanLarvalWeight, eggQty } = data[i];
    step1 += biomass * 1000 * (100 - substrate) / meanLarvalWeight / eggQty * 0.000027 * biomass / meanLarvalWeight;
    step2 += biomass / meanLarvalWeight;
  }

  const result = step1 / step2;

  return result ? result.toFixed(2) : 0;
}

export const HatchingRatePerWeek = (data, week, year) => {
  let step1 = 0;
  let step2 = 0;

  const dataPerWeek = data.filter(({ entryDate }) => moment(entryDate, 'YYYY-MM-DD').week() === week
    && parseInt(entryDate?.split('-')[0], 10) === parseInt(year, 10));

  for (let i = 0; i < dataPerWeek.length; i += 1) {
    const { biomass, substrate, meanLarvalWeight, eggQty } = dataPerWeek[i];
    step1 += biomass * 1000 * (100 - substrate) / meanLarvalWeight / eggQty * 0.000027 * biomass / meanLarvalWeight;
    step2 += biomass / meanLarvalWeight;
  }

  const result = step1 / step2;

  return result ? result.toFixed(2) : 0;
}