/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState,useEffect } from 'react';
import moment from 'moment';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { current, unwrapResult } from '@reduxjs/toolkit';

// UI Components
import {
  Table,
  Typography,
  Modal,
  notification,
  Col,
  Layout,
  Row,
  Button,
  Tabs,
  List,
  Comment,
  Avatar,
  Tooltip
} from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { selectBatch, fetchBatch, fetchAllBatches } from '../../reducers/Batch.slice';
import { selectStage } from '../../reducers/Session.slice';

// local components
import IncubatorInputAction from '../../components/NextIncubation/Inputs/IncubatorInputAction';
import NextIncubatorDetails from '../../components/BatchDetails/NextIncubator.details';
import NextLarvaeDetails from '../../components/BatchDetails/NextLarvae.details';
import NextBreedingDetails from '../../components/BatchDetails/NextBreeding.details';

// helpers
import { stages } from '../../common/const';

// reducers
import {
  fetchIncubatorFeedTypes,
  selectFeedTypes,
  deleteIncubatorInput,
  deleteIncubatorMeasurement,
} from '../../reducers/NextIncubator.slice';
import {
  deleteLarvaeInput,
  deleteLarvaeMeasurement,
  deleteLarvaeSieving,
  fetchLarvaeSieving,
} from '../../reducers/NextLarvae.slice';
import { fetchMixes, selectMixes } from '../../reducers/NextFeed.slice';
import IncubatorMeasurementAction from '../../components/NextIncubation/Measurement/IncubatorMeasurementAction';

import LarvaeInputAction from '../../components/NextLarvae/Inputs/LarvaeInputAction';
import UpdateSievingForm from '../../components/NextLarvae/Seiving/UpdateSievingForm';

import { fetchBreedingSieving, selectBreedingSievings } from '../../reducers/NextBreeding.slice';
import MixDetails from '../../components/NextFeed/Mix/MixDetails';

/* -------------------------------------------------------------------------- */
/*                             Batch Details Page                             */
/* -------------------------------------------------------------------------- */
const BatchDetailsPage = (props) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { Title } = Typography;
  const { confirm } = Modal;
  const { TabPane } = Tabs;
  const { batch,currentMix } = props.location.state;
  const dispatch = useDispatch();
  const stage = useSelector(selectStage);
  const mixes = useSelector(selectMixes);
  const selectedBatch = useSelector(selectBatch);
  const feedTypes = useSelector(selectFeedTypes);
  const BreedingSievings = useSelector(selectBreedingSievings);
  const [stageColor,setStageColor] =  useState('default-color');

  /* ----------------------------- RENDER HELPERS ----------------------------- */


  // find Feed Type
  const findFeedType = (id) => {
    if (stage === stages.NEXTINCUBATOR) {
      const feedType = feedTypes.find((item) => item.id === id);
      if (feedType) return feedType.title;
    }
    const mix = mixes.find((item) => item.id === id);
    if (mix) return mix.palox;
  };
  const getTotalFeedQuantity = (record) => {
    return record && ((record.extra_cf && record.extra_cf) + (record.feed && record.feed)) / 1000;
  };

  useEffect(() => {
    if(!currentMix)
    {   
      dispatch(fetchBatch(batch.id));
      dispatch(fetchMixes());
      dispatch(fetchIncubatorFeedTypes());
      dispatch(fetchLarvaeSieving());
      dispatch(fetchAllBatches());
      dispatch(fetchBreedingSieving());
    }    
  }, []);


  // remove Feed
  const removeFeed = (feed) => {
    confirm({
      title: 'Do you Want to delete Feed Entry?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(stage === stages.NEXTINCUBATOR ? deleteIncubatorInput(feed.id) : deleteLarvaeInput(feed.id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Feeding',
              description: 'Feeding was Deleted successfully',
            });
            dispatch(fetchBatch(batch.id));
          })
          .catch(() =>
            notification.error({
              message: 'Delete Feeding',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  // remove Measure
  const removeMeasure = (measure) => {
    //  Check if the current stage is nextIncubator 
    const isNextIncubatorStage = stage === stages.NEXTINCUBATOR || stage === stages.NEXTINCUBATOR_FOR_BREEDING;

    
    confirm({
      title: isNextIncubatorStage ?'Do you Want to delete Hatching date?':'Do you Want to delete Feed Entry?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          isNextIncubatorStage ? deleteIncubatorMeasurement(measure.id) : deleteLarvaeMeasurement(measure.id),
        )
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: isNextIncubatorStage ? 'Delete Hatching Date' : 'Delete Feeding',
              description:  isNextIncubatorStage ? 'Hatching Date was Deleted successfully':
              'Feeding was Deleted successfully',
            });
            dispatch(fetchBatch(batch.id));
          })
          .catch(() =>
            notification.error({
              message: isNextIncubatorStage ? 'Delete Hatching Date' : 'Delete Feeding',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  // remove Seiving
  const removeSeiving = (sievedLarvae) => {
    confirm({
      title: 'Do you Want to delete seiving larvae?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteLarvaeSieving(sievedLarvae.id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Sieving Larvae',
              description: 'Sieving Larvae was Deleted successfully',
            });
            dispatch(fetchBatch(batch.id));
          })
          .catch(() =>
            notification.error({
              message: 'Delete Sieving Larvae',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  const NEXT_FEED_MIXES_COLUMN = [
    {
      title: 'Ingredient batch id',
      dataIndex: 'igredientBatchId',
      key: 'breeding Measure',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Sub category',
      dataIndex: 'subCategory',
      key: 'Hatching Date',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
    },
    {
      title: 'Weight (kg)',
      dataIndex: 'weight',
      key: 'weight',
    },
  ];

 
  function getAerationComments() {
    let aerationsSortedByDate;
    if (selectedBatch && selectedBatch.aerations) {
      aerationsSortedByDate = [...selectedBatch.aerations];
      aerationsSortedByDate.sort((a, b) => new Date(a.aerationDate) - new Date(b.aerationDate));
    }

    return (
      selectedBatch &&
      selectedBatch.aerations &&
      aerationsSortedByDate.map((ae) => (
        <Comment
          author={<strong>{selectedBatch.user && selectedBatch.user.username}</strong>}
          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="Han Solo" />}
          content={<p>{ae.comment}</p>}
          datetime={
            // <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
            //   <span>{moment(ae.aerationDate).format('YYYY-MM-DD')}</span>
            // </Tooltip>
            <span>{moment(ae.aerationDate).format('YYYY-MM-DD')}</span>
          }
        />
      ))
    );
  }
  
  const renderStageDetails = () =>{
    if(currentMix)
      return <MixDetails currentMix={currentMix}/>;
    
    switch (stage) {
      case stages.NEXTINCUBATOR:
        return <NextIncubatorDetails {...props}/>;
      case stages.NEXTLARVAE:
        return <NextLarvaeDetails stageColor={stageColor} {...props}/>;
      case stages.NEXTINCUBATOR_FOR_BREEDING:
        // eslint-disable-next-line no-case-declarations 
        const {subStage} = props.location.state;
        return <NextBreedingDetails {...props} stageColor={stageColor} subStage={subStage} />;
      default:
        break;
    }
  }

  useEffect(() => {
    if(currentMix)
      setStageColor('nextFeed-color');
    else switch (stage) {
      case stages.NEXTLARVAE:
        setStageColor('nextLarvae-color');
        break;
      case stages.NEXTINCUBATOR_FOR_BREEDING:
        setStageColor('nextBreeding-color');
        break;
      default:
        break;
    }
  }, []);

  
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="next-incubator">
    <Col span={3}>
      <Title level={6}>
        <ArrowLeftOutlined onClick={() => props.history.goBack()} />
      </Title>
    </Col>
    <span className={`card-item-title ${stageColor}`} >
      {currentMix ? currentMix.palox : batch.identifier}
    </span>
      {renderStageDetails()}
  </Content>

  );
};
export default BatchDetailsPage;
