/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable radix */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// UI Components
import { Row, Table, Modal, Button, notification, Col } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// slices
import { createKilledBatch, fetchKilledBatches } from '../../../reducers/NextProcess.slice';
import { fetchAllBatches } from '../../../reducers/Batch.slice';

// Local components
import ProcessKillingForm from './ProcessKillingForm';
import EndOfBatchAction from '../Seiving/EndOfBatchAction';
import { fetchLarvaeSieving, selectLarvaeSievings } from '../../../reducers/NextLarvae.slice';

/* -------------------------------------------------------------------------- */
/*                              Sieved Batch List                             */
/* -------------------------------------------------------------------------- */
function SievedBatchList({ batches }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(fetchLarvaeSieving());
  }, []);

  useSelector(selectLarvaeSievings);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const onClickSubmit = (data) => {
    const values = {
      ...data,
      totalBiomass: data.newbatch ? data.biomass + data.newBatchBiomass : data.biomass,
    };


    dispatch(createKilledBatch(values))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Killing Batch',
          description: 'Killing successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchKilledBatches());
        dispatch(fetchAllBatches());
      })
      .catch(() =>
        notification.error({
          message: 'Killing Batch',
          description: 'An error occured',
        }),
      );
  };

  const getTotalBinsSieved = (batch) =>
    batch?.larvae_sievings
      .map((item) => item.bins_sieved)
      // eslint-disable-next-line func-names
      .reduce(function (previousValue, currentValue) {
        return (previousValue + currentValue);
      }, 0);


  
  // Each batch has two fields killed_batches (type: array of objects) and new_killed_batches (type: array of objects)
  // and each object  has a biomass attribute
  // the field Biomass sieved (kg) in the table is the sum of biomasses attribute of the two arrays 
  // this function apply the sum operator to biomass attribute of two arrays (killed_batches and new_killed_batches) 
  const caluclateBiomass = (killedBatches,newKilledBatches)=> {
    
    // eslint-disable-next-line mdx/no-unused-expressions
    const sumKilledBatches = killedBatches
    ? killedBatches.reduce((a, b) => a + b.biomass, 0) > 0
      ? killedBatches.reduce((a, b) => a + b.biomass, 0)
      : 0
    : 0

    const sumNewKilledBatches = newKilledBatches
    ? newKilledBatches.reduce((a, b) => a + b.biomass, 0) > 0
      ? newKilledBatches.reduce((a, b) => a + b.newBatchBiomass, 0)
      : 0
    : 0
    return sumKilledBatches + sumNewKilledBatches;
  } 

  const dataSource =
    batches &&
    batches
      .filter((item) => !item.larvae_sievings[0]?.frass || !item.larvae_sievings[0]?.larvalWeight)
      .map((batch) => ({
        identifier: batch.identifier,
        sievingDate: !batch.larvae_sievings[0] ? '-' : batch.larvae_sievings[0]?.sievingDate,
        bins: batch.larvae_sievings === [] ? '-' : getTotalBinsSieved(batch),
        biomass: caluclateBiomass(batch.killed_batches,batch.new_killed_batches)
      }));

  const columns = [
    {
      title: 'Batch id',
      dataIndex: 'identifier',
      key: 'identifier',
    },
    {
      title: 'First sieving date',
      dataIndex: 'sievingDate',
      key: 'sievingDate',
    },
    {
      title: 'STD bins (nb)',
      dataIndex: 'bins',
      key: 'bins',
    },
    {
      title: 'Biomass sieved (kg)',
      dataIndex: 'biomass',
      key: 'biomass',
    },
    {
      render: (text, record) => (
        <Row>
          <EndOfBatchAction batch={batches.filter((b) => b.identifier === record.identifier)}  />
        </Row>
      ),
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="batch-list">
      <Table columns={columns} dataSource={dataSource} />
      <Row>
        <Col>
          <Button className="plus-button" type="primary" shape="square" onClick={() => setShowModal(!showModal)}>
            +
          </Button>
          <Modal
            style={{ minHeight: '1500px !important' }}
            title="New Killing"
            width={1000}
            visible={showModal}
            maskClosable={false}
            footer={null}
            destroyOnClose
            onCancel={() => setShowModal(false)}
          >
            <ProcessKillingForm onSubmit={onClickSubmit} />
          </Modal>
        </Col>
        <Col className="ml-20">
          <Link to="/nextProcess/driedLarvaeDetails">
            <Button className="plus-button" type="primary" shape="square">
              See all the previous batches
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default SievedBatchList;
