/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';

// redux
import { useDispatch, useSelector } from 'react-redux';

// reducers
import { fetchAllBigBag, selectBigBag } from '../../../reducers/NextProcess.slice';

/* -------------------------------------------------------------------------- */
/*                                Big Bag Form                                */
/* -------------------------------------------------------------------------- */
function BigBagForm({ onSubmit, onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const nextOils = useSelector(selectBigBag);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllBigBag());
  }, []);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const dryingFields = [
    {
      key: 'bigBagId',
      label: 'Big bag id',
      placeholder: 'Big bag id',
      extra: 'Big bag  Id should be unique',
      hasFeedback: true,
      required: true,
      rules: [
        {
          validator: () => {
            const identifierValue = form.getFieldValue('bigBagId');
            return new Promise((resolve, reject) => {
              if (nextOils.find((b) => b.bigBagId === identifierValue)) {
                reject(new Error(`bigBagId ${identifierValue} already exists.`));
              } else {
                resolve();
              }
            });
          },
        },
      ],
    },
  ];
  // force update form
  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onSubmit} form={form} onValuesChange={forceUpdate}>
      <FormBuilder form={form} meta={dryingFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

BigBagForm.propTypes = {
  onSubmit: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

export default BigBagForm;
