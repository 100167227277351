import { combineReducers } from 'redux';

import Batch from '../reducers/Batch.slice';
import Session from '../reducers/Session.slice';
import NextIncubator from '../reducers/NextIncubator.slice';
import NextBreeding from '../reducers/NextBreeding.slice';
import NextLarvae from '../reducers/NextLarvae.slice';
import User from '../reducers/User.slice';
import NextFeed from '../reducers/NextFeed.slice';
import NextFly from '../reducers/NextFly.slice';
import NextProcess from '../reducers/NextProcess.slice';
import Product from '../reducers/Product.slice';


export default combineReducers({
  Batch,
  Session,
  NextIncubator,
  NextBreeding,
  NextLarvae,
  NextFeed,
  NextProcess,
  NextFly,
  User,
  Product,
});
