/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// UI ib components
import { Row, Col, DatePicker, Select } from 'antd';

// Redux
import { useSelector } from 'react-redux';
import { selectBatches } from '../../reducers/Batch.slice';

// UI local components
import DashboardTable from './DashboardTable';
// import RatesPerMonth from './RatesPerMonth';
// import ChartsPerMonth from './ChartsPerMonth';

/* -------------------------------------------------------------------------- */
/*                            Next Larvae Dashboard                           */
/* -------------------------------------------------------------------------- */
export default function NextLarvaeDashboard() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const data = useSelector(selectBatches);
  // eslint-disable-next-line no-unused-vars
  const [filteredData, setFilteredData] = useState([]);
  const [tableYear, setTableYear] = useState(new Date().getFullYear());
  
  function onTableYearChange(_, dateString) {
    setTableYear(dateString);
  }

  // eslint-disable-next-line no-unused-vars
  function onRoomSelectChange(value) {
    // console.log(`selected ${value}`);
  }

  function onChange(_, dateString) {
    const parseDate = (date, num) => parseInt(date?.split('-')[num], 10);

    const monthRange = dateString.map((date) => parseDate(date, 1));
    const DaysRange = dateString.map((date) => parseDate(date, 2));

    const firstMonth = monthRange[0];
    const lastMonth = monthRange[1];
    const firstDay = DaysRange[0];
    const lastDay = DaysRange[1];

    const fltrData = data.filter(({ entryDate }) => {
      return (
        parseDate(entryDate, 1) >= firstMonth &&
        parseDate(entryDate, 2) >= firstDay &&
        parseDate(entryDate, 1) <= lastMonth &&
        parseDate(entryDate, 2) <= lastDay
      );
    });

    setFilteredData(fltrData);
  }

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="dashbaord-chart">
      <Row>
        <Col className="dashbaord-table" span={24}>
          <DashboardTable data={data} year={tableYear} onTableYearChange={onTableYearChange} />
        </Col>
        <Row className="dashbaord-filters">
          <Col className="dashbaord-filter">
            <p>Choose a date:</p>
            <RangePicker onChange={onChange} />
          </Col>
          <Col className="dashbaord-filter">
            <p>Choose a room:</p>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a room"
              optionFilterProp="children"
              onChange={onRoomSelectChange}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select>
          </Col>
          <div className="single-rate">
            <span>0 bins</span>
            <p>Room load</p>
          </div>
        </Row>
        {/* <RatesPerMonth data={filteredData} year={tableYear} /> */}
        {/* <ChartsPerMonth data={filteredData} year={tableYear} /> */}
      </Row>
    </div>
  );
}
