/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import {
  Table,
  Typography,
  Modal,
  notification,
  Col,
  Layout,
  Row,
  Button,
  Tabs,
  List,
  Comment,
  Avatar,
  Tooltip,
  Switch
} from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { selectBatch, fetchBatch, fetchAllBatches, selectComments } from '../../reducers/Batch.slice';
import { selectStage } from '../../reducers/Session.slice';

// local components
import IncubatorInputAction from '../NextIncubation/Inputs/IncubatorInputAction';

// helpers
import { stages } from '../../common/const';

// reducers
import {
  fetchIncubatorFeedTypes,
  selectFeedTypes,
  deleteIncubatorInput,
  deleteIncubatorMeasurement,
} from '../../reducers/NextIncubator.slice';
import {
  deleteLarvaeInput,
  deleteLarvaeMeasurement,
  deleteLarvaeSieving,
  fetchLarvaeSieving,
} from '../../reducers/NextLarvae.slice';
import { fetchMixes, selectMixes } from '../../reducers/NextFeed.slice';
import IncubatorMeasurementAction from '../NextIncubation/Measurement/IncubatorMeasurementAction';


import { fetchBreedingSieving, selectBreedingSievings } from '../../reducers/NextBreeding.slice';


/* -------------------------------------------------------------------------- */
/*                             Batch Details Page                             */
/* -------------------------------------------------------------------------- */
const BatchDetailsPage = ({ location }) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { Title } = Typography;
  const { confirm } = Modal;
  const { batch,currentMix } = location.state;
  const dispatch = useDispatch();
  const stage = useSelector(selectStage);
  const mixes = useSelector(selectMixes);
  const selectedBatch = useSelector(selectBatch);
  const feedTypes = useSelector(selectFeedTypes);
  const [feedingComments , setFeedingComments] = useState(false);
  const [measureComments , setMeasureComments] = useState(false);
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  // find Feed Type
  const findFeedType = (id) => {
    if (stage === stages.NEXTINCUBATOR) {
      const feedType = feedTypes.find((item) => item.id === id);
      if (feedType) return feedType.title;
    }
    const mix = mixes.find((item) => item.id === id);
    if (mix) return mix.palox;
  };
  const getTotalFeedQuantity = (record) => {
    return record && ((record.extra_cf && record.extra_cf) + (record.feed && record.feed)) / 1000;
  };

  useEffect(() => {
    if(!currentMix)
    {   
      dispatch(fetchBatch(batch.id));
      dispatch(fetchMixes());
      dispatch(fetchIncubatorFeedTypes());
      dispatch(fetchLarvaeSieving());
      dispatch(fetchAllBatches());
      dispatch(fetchBreedingSieving());
    }
  }, []);


  // remove Feed
  const removeFeed = (feed) => {
    confirm({
      title: 'Do you Want to delete Feed Entry?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(stage === stages.NEXTINCUBATOR ? deleteIncubatorInput(feed.id) : deleteLarvaeInput(feed.id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Feeding',
              description: 'Feeding was Deleted successfully',
            });
            dispatch(fetchBatch(batch.id));
          })
          .catch(() =>
            notification.error({
              message: 'Delete Feeding',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  // remove Measure
  const removeMeasure = (measure) => {
    //  Check if the current stage is nextIncubator 
    const isNextIncubatorStage = stage === stages.NEXTINCUBATOR || stage === stages.NEXTINCUBATOR_FOR_BREEDING;
        
    confirm({
      title: isNextIncubatorStage ?'Do you Want to delete Hatching date?':'Do you Want to delete Feed Entry?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          isNextIncubatorStage ? deleteIncubatorMeasurement(measure.id) : deleteLarvaeMeasurement(measure.id),
        )
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: isNextIncubatorStage ? 'Delete Hatching Date' : 'Delete Feeding',
              description:  isNextIncubatorStage ? 'Hatching Date was Deleted successfully':
              'Feeding was Deleted successfully',
            });
            dispatch(fetchBatch(batch.id));
          })
          .catch(() =>
            notification.error({
              message: isNextIncubatorStage ? 'Delete Hatching Date' : 'Delete Feeding',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  // remove Seiving
  const removeSeiving = (sievedLarvae) => {
    confirm({
      title: 'Do you Want to delete seiving larvae?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteLarvaeSieving(sievedLarvae.id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Delete Sieving Larvae',
              description: 'Sieving Larvae was Deleted successfully',
            });
            dispatch(fetchBatch(batch.id));
          })
          .catch(() =>
            notification.error({
              message: 'Delete Sieving Larvae',
              description: 'An error occured',
            }),
          );
      },
    });
  };

  const NEXT_INCUBATOR_INPUT_COLUMN = [
    {
      title: 'Feed Date',
      key: 'feedDate',
      dataIndex: 'feedDate',
    },
    {
      title: 'Quantity (g)',
      key: 'feed',
      dataIndex: 'feed',
    },
    {
      title: 'Feed Type',
      key: 'incubator_feed_type',
      dataIndex: 'incubator_feed_type',
      render: (value) => <span>{findFeedType(value)}</span>,
    },
    {
      title: 'Extra CF (g)',
      key: 'extra_cf',
      dataIndex: 'extra_cf',
    },
    {
      title: 'Humidity (%)',
      key: 'humidity',
      dataIndex: 'humidity',
    },
    {
      title: 'Total feed (kg)',
      key: 'totalFeedQuantity',
      dataIndex: 'totalFeedQuantity',
      render: (value, record) => <span>{getTotalFeedQuantity(record)}</span>,
    },
    {
      render: (value, record) => (
        <Row>
          <Col>
            <IncubatorInputAction label="Edit" feed={record} batch={batch} />
          </Col>
          <Col className="ml-20">
            <Button onClick={() => removeFeed(record)}>Remove</Button>
          </Col>
        </Row>
      ),
    },
  ];

  let BREEDING_SEIVING_INPUT = {
    breedingSievingDate: '10-08-2021',
    meanPrepupaeWeight: '22',
  };

  if (selectedBatch) {
    BREEDING_SEIVING_INPUT = {
      biomass: selectedBatch.breeding_sieving?.biomass,
      frass: selectedBatch.breeding_sieving?.frass,
      breedingSievingDate: selectedBatch.breeding_sieving?.sievingDate,
      meanPrepupaeWeight: selectedBatch.breeding_sieving?.meanPrepupaeWeight,
    };
  }

 const NEXT_INCUBATOR_MEASURE_COLUMN = [
    {
      title: 'Hatching Date',
      dataIndex: 'hatchingDate',
      key: 'Hatching Date',
    },
    {
      render: (record) => (
        <Row>
          <Col>
            <IncubatorMeasurementAction
              record={record}
              isGrowthStage={true} 
              label="Edit" 
              feed={record} 
              batch={batch} 
              measure={record}
           />
          </Col>
          <Col className="ml-20">
            <Button onClick={() => removeMeasure(record)}>Remove</Button>
          </Col>
        </Row>
      ),
    },
  ];

  function getFeedingComments(action) {
      return selectedBatch  && selectedBatch[action]?.length &&
            selectedBatch[action]?.map( (input) =>
            input.comment && <Comment
            author={<strong>{'user'}</strong>}
            avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="Han Solo" />}
            content={<p>{input.comment}</p>}
            datetime={
              <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                <span>{moment(input.created_at).fromNow()}</span>
              </Tooltip>
            }
          />
        );
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="next-incubator">
    
    <Title level={5} className="next-incubator-comment">
        FEEDINGS
    </Title>
    {selectedBatch && (
      <Table
        columns={ NEXT_INCUBATOR_INPUT_COLUMN }
        dataSource={ selectedBatch.incubator_inputs }
        footer={() =>    
         <Row align='middle'>
        <Title level={1} className="batch-details-comments">
            FEEDING Comments
        </Title>   
        <Switch  onChange={()=> setFeedingComments(!feedingComments)} />
        </Row>
        }
      />
    )}
  
    {feedingComments &&  <List>{getFeedingComments('incubator_inputs')}</List>}

    <Title level={2} className="next-incubator-comment">
        MEASUREMENTS
    </Title>
    {selectedBatch && (
       <Table
          columns={NEXT_INCUBATOR_MEASURE_COLUMN}
           dataSource={
              selectedBatch.incbuator_measurements
            }
            footer={ ()=>    
            <Row align='middle'>
                <Title level={1} className="batch-details-comments">
                    MEASUREMENTS Comments
                </Title>   
                <Switch  onChange={()=> setMeasureComments(!measureComments)} />
            </Row>
            }
        />
    )}

    {measureComments && <List>{getFeedingComments('incbuator_measurements')}</List>}     
  </Content>
  );
};


BatchDetailsPage.propTypes = {
  stageColor: PropTypes.string.isRequired,
};


export default BatchDetailsPage;
