/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* ------------------------------ UI Component ------------------------------ */
import { Button, Modal, notification, Form } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { momentFoo } from '../../Shared/helper';

// Reducers
import { updateFeedEntry, fetchFeedEntries, selectFeedEntries } from '../../../reducers/NextFeed.slice';

/* -------------------------------------------------------------------------- */
/*                               Transfer Action                              */
/* -------------------------------------------------------------------------- */
function StockOutput({ onlyFormItems }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const feedEntries = useSelector(selectFeedEntries);
  const [form] = Form.useForm();
  const reasons = ['Throw away (quality issue)', 'R&D tests', 'other'];

  /* ---------------------------------- CONST --------------------------------- */
  const identifierStock = [];

  if (feedEntries) {
    identifierStock.push(feedEntries.map((e) => e.identifier));
  }

  const FEED_IDENTIFIER = form.getFieldValue('identifier');
  const getWeightByIdentifier = feedEntries && feedEntries.find((b) => b.identifier.toString() === FEED_IDENTIFIER);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    const values = {
      currentWeight: getWeightByIdentifier.currentWeight - data.currentWeight,
      reasons: data.reasons,
      comment: data.comment,
    };
    dispatch(
      updateFeedEntry({
        ...values,
        id: getWeightByIdentifier.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Update Raw material output',
          description: 'Raw material output was Updated successfully',
        });
        setShowModal(!showModal);
        dispatch(fetchFeedEntries());
      })
      .catch(() => {
        setShowModal(!showModal);
        notification.error({
          message: 'Update Raw material output',
          description: 'An error occured',
        });
      });
  };
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const outputFormFields = [
    {
      key: 'date',
      label: 'Date',
      placeholder: 'Date',
      widget: 'date-picker',
      initialValue: momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && current > momentFoo().endOf('day'),
      },
      rules: [
        {
          required: true,
          message: 'Date is required',
        },
      ],
    },
    {
      key: 'identifier',
      label: 'Identifier',
      placeholder: 'Identifier',
      widget: 'select',
      options: identifierStock[0].map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'Identifier is required',
        },
      ],
    },
    {
      key: 'currentWeight',
      label: 'Current weight (kg)',
      placeholder: 'Current weight (kg)',
      widget: 'number',
      initialValue: getWeightByIdentifier && getWeightByIdentifier.currentWeight,
      extra: `max value is ${getWeightByIdentifier && getWeightByIdentifier.currentWeight}`,
      widgetProps: {
        min: 0,
        max: getWeightByIdentifier && getWeightByIdentifier.currentWeight,
      },
      rules: [
        {
          required: true,
          message: 'Weight (kg) is required',
        },
      ],
    },
    {
      key: 'reasons',
      label: 'Reasons',
      placeholder: 'Reasons',
      widget: 'select',
      options: reasons.map((item) => ({ label: item, value: item })),
      rules: [
        {
          required: true,
          message: 'Reasons is required',
        },
      ],
    },
    {
      key: 'comment',
      label: 'Comment',
      placeholder: 'Comment (optional)',
      widget: 'textarea',
    },
  ];

  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        Output
      </Button>
      <Modal
        style={{ minHeight: '1500px !important' }}
        title="Raw material output"
        width={1000}
        visible={showModal}
        footer={null}
        maskClosable={false}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form layout="horizontal" onFinish={onClickSubmit} onValuesChange={forceUpdate} form={form}>
          <FormBuilder form={form} meta={outputFormFields} />
          {!onlyFormItems && (
            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
}
StockOutput.propTypes = {
  onlyFormItems: PropTypes.bool,
};
export default StockOutput;
