/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// Proptypes
import PropTypes from 'prop-types';

// Components
import { Form, Button, notification, Alert } from 'antd';
import FormBuilder from 'antd-form-builder';

// i18n
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers
import { fetchAllBatches, createBatch, selectBatches, updateBatch } from '../../reducers/Batch.slice';
import { selectEggPool, getEggPool } from '../../reducers/NextFly.slice';
import { findAvailableID, momentFoo } from '../Shared/helper';
import { selectStage } from '../../reducers/Session.slice';

// common
import { stages } from '../../common/const';

/* -------------------------------------------------------------------------- */
/*                             Add New batch Form                             */
/* -------------------------------------------------------------------------- */
function BatchForm({ onSubmit, batch }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { t } = useTranslation(['stages', 'common']);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const batches = useSelector(selectBatches);
  const eggs = useSelector(selectEggPool);
  const stage = useSelector(selectStage);

  /* ----------------------------- RENDER HELPERES ---------------------------- */

  const batchFormFields = [
    {
      key: 'isForBreeding',
      initialValue: false,
      label: 'Batch',
      extra: `${form.getFieldValue('isForBreeding') === true ? 'breeding batch' : 'production batch'}`,
      widget: 'switch',
    },
    {
      key: 'identifier',
      label: t('nextIncubator.batch.form.fields.identifier.label'),
      placeholder: t('nextIncubator.batch.form.fields.identifier.label'),
      extra: t('nextIncubator.batch.form.fields.identifier.note'),
      hasFeedback: true,
      required: true,
      initialValue: findAvailableID(batches, 'identifier'),
      rules: [
        {
          validator: () => {
            const identifierValue = form.getFieldValue('identifier');
            return new Promise((resolve, reject) => {
              if (batches.find((b) => b.identifier === identifierValue)) {
                reject(
                  new Error(t('nextIncubator.batch.form.fields.identifier.alert', { identifier: identifierValue })),
                );
              } else {
                resolve();
              }
            });
          },
        },
      ],
    },
    {
      key: 'eggQty',
      label: t('nextIncubator.batch.form.fields.eggQty.label'),
      placeholder: t('nextIncubator.batch.form.fields.eggQty.label'),
      widget: 'number',
      initialValue: batch ? batch.eggQty : null,
      extra: `Quantity should be low than ${
        (parseInt(eggs && eggs.quantity, 10) + (batch && batch.eggQty ? parseInt(batch.eggQty, 10) : 0)).toFixed(2) || 0
      }`,
      widgetProps: {
        min: 0,
        max: eggs && (parseInt(eggs && eggs.quantity, 10) + parseInt(batch?.eggQty, 10)).toFixed(2),
      },
      rules: [
        {
          required: true,
          message: 'Egg Qty is required',
        },
      ],
    },
    {
      key: 'bins',
      label: 'Standard bins (nb)',
      placeholder: 'standard bins (nb)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      initialValue: batch ? batch.bins : null,
      rules: [
        {
          required: true,
          message: 'Bins is required',
        },
      ],
    },
    {
      key: 'std_weight',
      label: 'Weight of a STD bin (g/bin)',
      placeholder: 'Weight of a STD bin (g/bin)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      initialValue: batch ? batch.std_weight : null,
      rules: [
        {
          required: true,
          message: 'Weight of a STD bin (g/bin) is required',
        },
      ],
    },
    {
      key: 'non_std_weight',
      label: 'non STD weight (g)',
      placeholder: 'non STD weight (g)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      initialValue: batch ? batch.non_std_weight : null,
      rules: [
        {
          required: true,
          message: 'non STD weight (g) is required',
        },
      ],
    },
    {
      key: 'entryDate',
      label: 'Entry Date',
      placeholder: 'Entry Date',
      widget: 'date-picker',
      initialValue: batch ? momentFoo(batch.entryDate) : momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && current > momentFoo().endOf('day'),
      },
      rules: [
        {
          required: true,
          message: 'Entry Date is required',
        },
      ],
    },
  ];

  /* -------------------------- NON STD WEIGHT LOGIC -------------------------- */
  const totalEggsIntroduced = form.getFieldValue('eggQty') || 0;
  const stdBins = form.getFieldValue('bins') || 0;
  const WeightOfStdBin = form.getFieldValue('std_weight') || 0;
  // fill non std bins field
  if (totalEggsIntroduced !== 0) {
    form.setFieldsValue({
      non_std_weight: (totalEggsIntroduced - stdBins * WeightOfStdBin).toFixed(2),
    });
  }
  // force update form
  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------- next Larvae Editable Form Fields -------------------- */
  const nextLarvaeEditableFormFields = [
    {
      key: 'nLTransferDate',
      label: 'Transfer Date',
      placeholder: 'Transfer Date',
      widget: 'date-picker',
      initialValue: batch ? momentFoo(batch.nLTransferDate) : momentFoo(),
      widgetProps: {
        disabledDate: (current) => current && current > momentFoo().endOf('day'),
      },
      rules: [
        {
          required: true,
          message: 'Transfer Date is required',
        },
      ],
    },
    {
      key: 'bins',
      label: 'Standard bins (nb)',
      placeholder: 'standard bins (nb)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      initialValue: batch ? batch.bins : null,
    },
    {
      key: 'non_std_bins_out',
      label: 'non Standard bins (nb)',
      placeholder: 'non standard bins (nb)',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      initialValue: batch ? batch.non_std_bins_out : null,
    },
    {
      key: 'larvaeWeight',
      label: 'mean larval weight 4th day',
      placeholder: 'mean larval weight 4th day',
      widget: 'number',
      widgetProps: {
        min: 0,
      },
      initialValue: batch ? batch.larvaeWeight : null,
    },
  ];

  // next Incubator Editable Form Fields
  const nextIncubatorEditableFormFields = batchFormFields.filter((field) => field.key !== 'identifier');

  const getFields = () => {
    if (!batch) {
      return batchFormFields;
    }
    if (stage === stages.NEXTLARVAE) {
      return nextLarvaeEditableFormFields;
    }
    return nextIncubatorEditableFormFields;
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form
      layout="horizontal"
      form={form}
      onValuesChange={forceUpdate}
      onFinish={(values) => {
        if (batch) {
          dispatch(
            updateBatch({
              fields: values,
              batchId: batch.id,
            }),
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: t('nextIncubator.batch.form.update.message', { identifier: batch.identifier }),
                description: t('nextIncubator.batch.form.update.success'),
              });
              onSubmit();
              form.resetFields();
              dispatch(fetchAllBatches());
              dispatch(getEggPool());
            })
            .catch(() =>
              notification.error({
                message: t('nextIncubator.batch.form.update.message', { identifier: batch.identifier }),
                description: t('nextIncubator.batch.form.update.error'),
              }),
            );
        } else {
          dispatch(
            createBatch({
              ...values,
            }),
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: t('nextIncubator.batch.form.create.message'),
                description: t('nextIncubator.batch.form.create.success'),
              });
              onSubmit();
              form.resetFields();
              dispatch(fetchAllBatches());
              dispatch(getEggPool());
            })
            .catch(() =>
              notification.error({
                message: t('nextIncubator.batch.form.create.message'),
                description: t('nextIncubator.batch.form.create.error'),
              }),
            );
        }
      }}
    >
      {stage !== stages.NEXTLARVAE && eggs === 0 && (
        <Alert
          message="Warning"
          description={t('nextIncubator.batch.form.noAvailableEggs')}
          type="warning"
          showIcon
          closable
        />
      )}
      <br />
      <FormBuilder form={form} meta={getFields()} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button htmlType="submit" type="primary" disabled={stage !== stages.NEXTLARVAE && eggs === 0}>
          {t('common:submit')}
        </Button>
      </Form.Item>
    </Form>
  );
}

BatchForm.propTypes = {
  batch: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default BatchForm;
