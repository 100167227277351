/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { useLocation } from 'react-router-dom';

// UI Components
import { Row, Typography, Empty, Col, Table } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

/* -------------------------------------------------------------------------- */
/*                                 Lot Details                                */
/* -------------------------------------------------------------------------- */
function LotDetails(props) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const location = useLocation();
  const { lot } = location.state;
  /* ---------------------------------- CONST --------------------------------- */
  const head1 = [
    {
      title: 'Date',
      dataIndex: 'measureDate',
      key: 'measureDate',
    },
    {
      title: 'Larvae Weight',
      dataIndex: 'larvaeWeight',
      key: 'larvaeWeight',
    },
  ];

  const head2 = [
    {
      title: 'Date',
      dataIndex: 'measureDate',
      key: 'measureDate',
    },
    {
      title: 'Biomass',
      dataIndex: 'biomass',
      key: 'biomass',
    },
    {
      title: 'Pupal Weight',
      dataIndex: 'pupalWeight',
      key: 'pupalWeight',
    },
  ];


  if (!lot) {
    return <Empty description={<span>No available Data</span>} className="empty-data-content" />;
  }
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Row>
        <Col span={24}>
          <Title level={1}>
            <ArrowLeftOutlined onClick={() => props.history.goBack()} />
          </Title>
        </Col>
        <Col span={18}>
          <Title level={2}>{lot.batch && lot.batch.id}</Title>
        </Col>
      </Row>
      <h1>Measurements</h1>
      <Table columns={head1} dataSource={lot.fly_measurements} />
      <h1>Stock Measurements </h1>
      <Table columns={head2} dataSource={lot.fly_stock_measurements} />
    </div>
  );
}
LotDetails.propTypes = {};

export default LotDetails;
