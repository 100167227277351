/* eslint-disable func-names */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useSelector } from 'react-redux';

// translation
import { useTranslation } from 'react-i18next';

// UI Components
import { Row, Col, Input, List, DatePicker, Switch, Select } from 'antd';

// local components
import BatchItem from './BatchItem';
import { momentFoo } from '../Shared/helper';

// reducers
import { selectStage } from '../../reducers/Session.slice';
import { selectNextLarvaeBatches, selectNextIncubatorBatches, selectBreedingBatches } from '../../reducers/Batch.slice';
import { stages } from '../../common/const';

/* -------------------------------------------------------------------------- */
/*                                 Batch List                                 */
/* -------------------------------------------------------------------------- */
function BatchList({ fields, actions }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD';
  const [checked, setChecked] = useState(false);
  const [searchFormData, setSearchFormData] = useState({
    searchTitle: '',
    searchDate: '',
    searchRoom: '',
  });
  const { Option } = Select;

  const rooms = ['nL1', 'nL2', 'nL3'];
  //
  const { t } = useTranslation('stages');
  const stage = useSelector(selectStage);
  // filter batch list by stage
  const nextLarvaeBatches = useSelector(selectNextLarvaeBatches);
  const nextIncubatorBatches = useSelector(selectNextIncubatorBatches);
  const nextIncubatorForBreeding = useSelector(selectBreedingBatches);
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const getFiltredBatches = () => {
    let list = [];
    switch (stage) {
      case stages.NEXTINCUBATOR:
        list = nextIncubatorBatches;
        break;
      case stages.NEXTINCUBATOR_FOR_BREEDING:
        list = nextIncubatorForBreeding;
        break;
      case stages.NEXTLARVAE:
        list = nextLarvaeBatches;
        break;
      default:
        break;
    }
    return list;
  };

  const getTotalBinsSieved = (batch) =>
    batch?.larvae_sievings
      .map((item) => item.bins_sieved)
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0);
  const getTotalNonBinsSieved = (batch) =>
    batch?.larvae_sievings
      .map((item) => item.non_bins_sieved)
      .reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0);

  const batchList = getFiltredBatches();
  let batches = [];
  if (stage === stages.NEXTINCUBATOR || stage === stages.NEXTINCUBATOR_FOR_BREEDING) {
    batches = checked ? batchList.filter((item) => item.transferred) : batchList.filter((item) => !item.transferred);

    const getTotalFeedQuantity = (record) => {
      return record && ((record.extra_cf && record.extra_cf) + (record.feed && record.feed)) / 1000;
    };

    batches = batches.map((item) =>  {
     return {    
       ...item,
       totalFeedQuantity:item.incubator_inputs ? item.incubator_inputs.reduce((a,b)=> 
      { return a+getTotalFeedQuantity(b)}, 0) : item.totalFeedQuantity
      }
    }) ;
  }
     else {
    batches = checked
      ? batchList.filter(
          (item) =>
            item.larvae_sievings &&
            getTotalBinsSieved(item) >= item.bins &&
            getTotalNonBinsSieved(item) >= item.non_std_bins_out,
        )
      : batchList.filter(
          (item) =>
            item.larvae_sievings === [] ||
            getTotalBinsSieved(item) < item.bins ||
            getTotalNonBinsSieved(item) < item.non_std_bins_out,
        );
  }

  /**
   * Check if Batch is compatible with filters
   * @param {object} batch Batch object
   * @param {*} formData FormData object containes
   * filters(searchTitle, searchOperation, searchDate)
   */
  const checkBatch = (batch, formData) => {
    // query wil be false if filters are empty

    let query = true;
    if (formData.searchTitle !== '') {
      query = query && batch.identifier.toLowerCase().indexOf(formData.searchTitle.toLowerCase()) !== -1;
    }

    if (formData.searchDate) {
      query = query && momentFoo(batch.entryDate).isSame(momentFoo(formData.searchDate), 'date');
    }

    if (formData.searchRoom) {
      // query = query && batch.room?.toLowerCase() === formData.searchRoom.toLowerCase();
      query = query && formData.searchRoom.find((item) => item.toLowerCase() === batch.room?.toLowerCase());
    }

    return query;
  };



  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Row className="card-list-filter-bar" align="middle" justify="space-between">
        <Col>
          <Row align="middle" justify="space-around">
            <Col>
              <DatePicker
                className="date-picker-input"
                placeholder={t('Select Date')}
                format={dateFormat}
                onChange={(value) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchDate: value,
                  })
                }
              />
            </Col>
            <Col className="ml-20">
              <Search
                className="search-input"
                placeholder="Search"
                onChange={(e) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchTitle: e.target.value,
                  })
                }
                allowClear
              />
            </Col>
            <Col className="ml-20">
              <Select
                mode='multiple'
                allowClear
                className="date-picker-input"
                placeholder="Search Rooms"
                onChange={(value) =>
                  setSearchFormData({
                    ...searchFormData,
                    searchRoom: value,
                  })
                }
                clearIcon
              >
                {rooms.map((p) => (
                  <Option key={p} value={p}>
                    {p}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>

        <Col>
          <span className="switch-input">
            <Switch title="Show seived Batches" checked={checked} onChange={(value) => setChecked(value)} />
            {stage === stages.NEXTLARVAE ? ' Sieved Batches ' : '  Transferred Batches '}
          </span>
        </Col>

      </Row>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={batches.filter((item) => checkBatch(item, searchFormData))}
        renderItem={(item) => (

          <List.Item>
            <BatchItem key={item.id} batch={item} fields={fields} actions={actions} />
          </List.Item>
        )}
      />
    </div>
  );
}

BatchList.propTypes = {
  batches: PropTypes.object,
};

export default BatchList;
