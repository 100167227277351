import { dateFormat } from '../../../common/const';
import { momentFoo } from '../../Shared/helper';

// incubator helpers
    const dateFormatter = (date)=> momentFoo(new Date (date)).format(dateFormat);

    const FCR = (batch) => {
        let part1 = 0;
        let part2 = 0;
        const { incubator_inputs: incInputs, biomass, substrate } = batch;
        for (let j = 0; j < incInputs.length; j += 1) {
          const extraCF = incInputs[j]?.extra_cf;
          const feed = incInputs[j]?.feed;
          part1 += extraCF + feed;
          part2 += (biomass * (100 - substrate)) / 100;
        }
        const result = part1 / part2;
        return result ? result.toFixed(2) : 0;
      };
    
    const HatchingRate = (batch)=>
        ((batch.biomass * 1000 * (100 - batch.substrate)) / batch.meanLarvalWeight / batch.eggQty) * 0.000027;
    
// Restructure json data in order to be filled in csv columns
   export  const restructureDataForNextIncubator = (data,onFinish) =>{

      let structuredData = [];
      // TODO: clean non used attributes 
      data.forEach((item) => {    
        const newItem = {
          ...item,
          feedType: null,
          feedDate: null,
          comment : item.comments[0]?.content,
          operator: item.created_by || 'user',
        }
        structuredData.push(
          item.transferred ? {
            ...newItem,
            operation: 'BatchOUT',
            date: dateFormatter(item.nLTransferDate),
            fcr: FCR(item),
            hatchingRate: `${HatchingRate(item).toFixed(2) || 0}%`
          }:{
            ...newItem,
            operation: 'BatchIN',
            date: dateFormatter(item.entryDate)
          }
        );
            
       if(item?.incbuator_measurements.length)
       {
         const measurmentsOperations = item.incbuator_measurements.map(({hatchingDate})=>({
           identifier: item.identifier,
           operation: 'Measure',
           hatchingDate
         }));
  
         structuredData = [...structuredData,...measurmentsOperations];
       }
       if(item?.incubator_inputs.length)
       { 
          const feedingOperations = item.incubator_inputs.map((feed)=>({
            ...item,
            ...feed,
            operation: 'Feeding',
            date: momentFoo(feed.created_at).format(dateFormat),
            comment : item.comment,
            operator: feed.created_by || 'user'
          }));
   
         structuredData = [...structuredData,...feedingOperations];      
       }
     }); 
     
     if(structuredData.length)
      onFinish(structuredData);
  }