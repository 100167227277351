/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Col, List, Row, Switch } from 'antd';

// local components
import MixItem from './MixItem';

/* -------------------------------------------------------------------------- */
/*                                  Mix List                                  */
/* -------------------------------------------------------------------------- */
function MixList(props) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [checked, setChecked] = useState(false);
  const { mixes, isBrMix } = props;
  const filtredMixes = checked ? mixes?.filter((m) => m.currentWeight <= 0) : mixes?.filter((m) => m.currentWeight > 0);
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      <Row className="card-list-filter-bar mb-40" align="middle" justify="end">
        <Col>
          <span className="switch-input">
            <Switch title="show previous mixes" checked={checked} onChange={(value) => setChecked(value)} />
            show previous mixes
          </span>
        </Col>
      </Row>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={filtredMixes}
        renderItem={(item) => (
          <List.Item>
            <MixItem key={item.id} isBrMix={isBrMix} mix={item} />
          </List.Item>
        )}
      />
    </>
  );
}

MixList.propTypes = {
  feedEntries: PropTypes.object,
  isBrMix: PropTypes.bool,
};

export default MixList;
