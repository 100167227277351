// Headers are the columns of csv file 
// Label is the column name
// Key is for mapping data with the corresponding column 
export const NEXTPROCESS_HEADERS = [  
    { label:'Killing date', key: 'killingDate' },
    { label:'Operation', key: 'operation' },
    { label:'Dryer', key: 'dryer' },
    { label:'Batch N°', key: 'identifier'},
    { label:'Biomass (kg)', key: 'biomass' },
    { label:'Frass (Kg)', key: 'frass' },
    { label:'Sieving waste (kg)', key: 'sievingWaste' },
    { label:'Duration (hh:mm)', key: 'duration' },
    { label:'Duration (s)', key: 'duration' },
    { label:'nLW (dried larvae) kg', key: 'finalWeight' },
    { label:'Drying waste (kg)', key: 'dryingWaste' },
    { label:'BatchId', key: 'batchId' },
    { label:'T Killing(>=62°C)', key: 'temperature' },
    { label:'Draining time (min)', key: 'drainingTime' },
    { label:'Final humidity (%)', key: 'finalHumidity' },
    { label:'T°(C) after drying', key: 'tempAfterDrying' },
    { label:'Comments', key: 'comment' },
    ];
    