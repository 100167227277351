/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React  from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// Reducers
import { updateProduct } from '../../../reducers/NextProcess.slice';
import { momentFoo } from '../../Shared/helper';


/* -------------------------------------------------------------------------- */
/*                                Weight Form                                */
/* -------------------------------------------------------------------------- */
const UpdateInfosForm = ({ onSubmit,bigBags, productType,onlyFormItems }) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const forceUpdate = FormBuilder.useForceUpdate();
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const setBigbagFormFields = (value) => {
    const currentBigbag = bigBags.find((item)=> item.id === value);   
    form.setFieldsValue({
      compliant: currentBigbag.compliant,
      expiryDate: momentFoo(currentBigbag.expiryDate)
    });
  }

  const onFinish =  (values)=>{
    dispatch(updateProduct({productType,...values}))
    .then(unwrapResult)
    .then(() => {
      notification.success({
          message: 'Information update',
          description: 'Updated successfully',
        });
        onSubmit();
    })
    .catch(() =>
    notification.error({
      message: 'Information update',
      description: 'An error occured',
    }),
  )
  
  onSubmit();
  }

  const updateInfosFields = [
    {
      key: 'id',
      label: 'Big bag',
      dataIndex: 'bigBagId',
      placeholder: 'big Bag Id',
      widget: 'select',
      options: bigBags.map(({id:bigBagId,batch:bigBagLabel}) => ({ label:bigBagLabel, value: bigBagId })),
      rules: [
        {
          required: true,
          message: 'Big bag id is required',
        },
      ],
      onChange: (value) => setBigbagFormFields(value)
    },
    {
      key: 'expiryDate',
      label: 'ExpiryDate',
      widget: 'date-picker',
      rules: [
        {
          required: true,
          message: 'Expiry date is required',
        },
      ],
    },
    {
      key: 'compliant',
      label: 'Compliant',
      widget: 'switch',
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Form layout="horizontal" onFinish={onFinish} form={form} onValuesChange={forceUpdate}>
      <FormBuilder form={form} meta={updateInfosFields} />
      {!onlyFormItems && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

UpdateInfosForm.propTypes = {
  onSubmit: PropTypes.func,
  isOutputForm: PropTypes.number.isRequired,
  bigBags: PropTypes.array.isRequired,
  productType: PropTypes.string,
  onlyFormItems: PropTypes.bool,
};

UpdateInfosForm.defaultProps = {
  productType: 'big_bag'
}

export default UpdateInfosForm;