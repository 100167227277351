/* eslint-disable prefer-destructuring */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

import {
  AllAverageIncubation,
  AllBiomass,
  AllEBR,
  AllEggs,
  AllFCR,
  AllFeed,
  AllHatchingRate,
  AllMeanLarvalWeight,
  AllSubstrate,
  allTotalFeedQuantity,
  AverageIncubation,
  Biomass,
  EBR,
  Eggs,
  FCR,
  Feed,
  HatchingRate,
  MeanLarvalWeight,
  Substrate,
  SumAllSTD,
  SumSTD,
  totalFeedQuantity,
} from './allMeasurements';

/* -------------------------------------------------------------------------- */
/*                              Next Incubator Table                          */
/* -------------------------------------------------------------------------- */
export default function DashboardTable({ data, year, onTableYearChange }) {
  /* ---------------------------------- HOOKS --------------------------------- */

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <table>
      <tr>
        <th>
          <DatePicker picker="year" onChange={onTableYearChange} defaultValue={moment(year, 'YYYY-MM-DD')} />
        </th>
        <th>Jan</th>
        <th>Feb</th>
        <th>Mar</th>
        <th>Apr</th>
        <th>May</th>
        <th>Jun</th>
        <th>Jul</th>
        <th>Aug</th>
        <th>Sep</th>
        <th>Oct</th>
        <th>Nov</th>
        <th>Dec</th>
        <th>Tot</th>
      </tr>
      <tr>
        <td>Total Eggs IN (kg)</td>
        <td>{Eggs(1, year, data)}</td>
        <td>{Eggs(2, year, data)}</td>
        <td>{Eggs(3, year, data)}</td>
        <td>{Eggs(4, year, data)}</td>
        <td>{Eggs(5, year, data)}</td>
        <td>{Eggs(6, year, data)}</td>
        <td>{Eggs(7, year, data)}</td>
        <td>{Eggs(8, year, data)}</td>
        <td>{Eggs(9, year, data)}</td>
        <td>{Eggs(10, year, data)}</td>
        <td>{Eggs(11, year, data)}</td>
        <td>{Eggs(12, year, data)}</td>
        <td>{AllEggs(data)}</td>
      </tr>
      <tr>
        <td>Total bins transfered (STD + NSTD)</td>
        <td>{SumSTD(1, year, data)}</td>
        <td>{SumSTD(2, year, data)}</td>
        <td>{SumSTD(3, year, data)}</td>
        <td>{SumSTD(4, year, data)}</td>
        <td>{SumSTD(5, year, data)}</td>
        <td>{SumSTD(6, year, data)}</td>
        <td>{SumSTD(7, year, data)}</td>
        <td>{SumSTD(8, year, data)}</td>
        <td>{SumSTD(9, year, data)}</td>
        <td>{SumSTD(10, year, data)}</td>
        <td>{SumSTD(11, year, data)}</td>
        <td>{SumSTD(12, year, data)}</td>
        <td>{SumAllSTD(data, year)}</td>
      </tr>
      <tr>
        <td>Biomass transfered (kg)</td>
        <td>{Biomass(1, year, data)}</td>
        <td>{Biomass(2, year, data)}</td>
        <td>{Biomass(3, year, data)}</td>
        <td>{Biomass(4, year, data)}</td>
        <td>{Biomass(5, year, data)}</td>
        <td>{Biomass(6, year, data)}</td>
        <td>{Biomass(7, year, data)}</td>
        <td>{Biomass(8, year, data)}</td>
        <td>{Biomass(9, year, data)}</td>
        <td>{Biomass(10, year, data)}</td>
        <td>{Biomass(11, year, data)}</td>
        <td>{Biomass(12, year, data)}</td>
        <td>{AllBiomass(data, year)}</td>
      </tr>
      <tr>
        <td>% substrate</td>
        <td>{Substrate(1, year, data)}</td>
        <td>{Substrate(2, year, data)}</td>
        <td>{Substrate(3, year, data)}</td>
        <td>{Substrate(4, year, data)}</td>
        <td>{Substrate(5, year, data)}</td>
        <td>{Substrate(6, year, data)}</td>
        <td>{Substrate(7, year, data)}</td>
        <td>{Substrate(8, year, data)}</td>
        <td>{Substrate(9, year, data)}</td>
        <td>{Substrate(10, year, data)}</td>
        <td>{Substrate(11, year, data)}</td>
        <td>{Substrate(12, year, data)}</td>
        <td>{AllSubstrate(data, year)}</td>
      </tr>
      <tr>
        <td>EBR (g/bin)</td>
        <td>{EBR(1, year, data)}</td>
        <td>{EBR(2, year, data)}</td>
        <td>{EBR(3, year, data)}</td>
        <td>{EBR(4, year, data)}</td>
        <td>{EBR(5, year, data)}</td>
        <td>{EBR(6, year, data)}</td>
        <td>{EBR(7, year, data)}</td>
        <td>{EBR(8, year, data)}</td>
        <td>{EBR(9, year, data)}</td>
        <td>{EBR(10, year, data)}</td>
        <td>{EBR(11, year, data)}</td>
        <td>{EBR(12, year, data)}</td>
        <td>{AllEBR(data, year)}</td>
      </tr>
      <tr>
        <td>Hatching rate (%)</td>
        <td>{HatchingRate(1, year, data)}</td>
        <td>{HatchingRate(2, year, data)}</td>
        <td>{HatchingRate(3, year, data)}</td>
        <td>{HatchingRate(4, year, data)}</td>
        <td>{HatchingRate(5, year, data)}</td>
        <td>{HatchingRate(6, year, data)}</td>
        <td>{HatchingRate(7, year, data)}</td>
        <td>{HatchingRate(8, year, data)}</td>
        <td>{HatchingRate(9, year, data)}</td>
        <td>{HatchingRate(10, year, data)}</td>
        <td>{HatchingRate(11, year, data)}</td>
        <td>{HatchingRate(12, year, data)}</td>
        <td>{AllHatchingRate(data, year)}</td>
      </tr>
      <tr>
        <td>Mean Larval weight (mg)</td>
        <td>{MeanLarvalWeight(1, year, data)}</td>
        <td>{MeanLarvalWeight(2, year, data)}</td>
        <td>{MeanLarvalWeight(3, year, data)}</td>
        <td>{MeanLarvalWeight(4, year, data)}</td>
        <td>{MeanLarvalWeight(5, year, data)}</td>
        <td>{MeanLarvalWeight(6, year, data)}</td>
        <td>{MeanLarvalWeight(7, year, data)}</td>
        <td>{MeanLarvalWeight(8, year, data)}</td>
        <td>{MeanLarvalWeight(9, year, data)}</td>
        <td>{MeanLarvalWeight(10, year, data)}</td>
        <td>{MeanLarvalWeight(11, year, data)}</td>
        <td>{MeanLarvalWeight(12, year, data)}</td>
        <td>{AllMeanLarvalWeight(data, year)}</td>
      </tr>
      <tr>
        <td>Mean incubation period (d)</td>
        <td>{AverageIncubation(1, year, data)}</td>
        <td>{AverageIncubation(2, year, data)}</td>
        <td>{AverageIncubation(3, year, data)}</td>
        <td>{AverageIncubation(4, year, data)}</td>
        <td>{AverageIncubation(5, year, data)}</td>
        <td>{AverageIncubation(6, year, data)}</td>
        <td>{AverageIncubation(7, year, data)}</td>
        <td>{AverageIncubation(8, year, data)}</td>
        <td>{AverageIncubation(9, year, data)}</td>
        <td>{AverageIncubation(10, year, data)}</td>
        <td>{AverageIncubation(11, year, data)}</td>
        <td>{AverageIncubation(12, year, data)}</td>
        <td>{AllAverageIncubation(data, year)}</td>
      </tr>
      <tr>
        <td>Total feed consumed (kg)</td>
        <td>{totalFeedQuantity(1, year, data)}</td>
        <td>{totalFeedQuantity(2, year, data)}</td>
        <td>{totalFeedQuantity(3, year, data)}</td>
        <td>{totalFeedQuantity(4, year, data)}</td>
        <td>{totalFeedQuantity(5, year, data)}</td>
        <td>{totalFeedQuantity(6, year, data)}</td>
        <td>{totalFeedQuantity(7, year, data)}</td>
        <td>{totalFeedQuantity(8, year, data)}</td>
        <td>{totalFeedQuantity(9, year, data)}</td>
        <td>{totalFeedQuantity(10, year, data)}</td>
        <td>{totalFeedQuantity(11, year, data)}</td>
        <td>{totalFeedQuantity(12, year, data)}</td>
        <td>{allTotalFeedQuantity(data, year)}</td>
      </tr>
      <tr>
        <td>FCR Inc (kg/kg)</td>
        <td>{FCR(1, year, data)}</td>
        <td>{FCR(2, year, data)}</td>
        <td>{FCR(3, year, data)}</td>
        <td>{FCR(4, year, data)}</td>
        <td>{FCR(5, year, data)}</td>
        <td>{FCR(6, year, data)}</td>
        <td>{FCR(7, year, data)}</td>
        <td>{FCR(8, year, data)}</td>
        <td>{FCR(9, year, data)}</td>
        <td>{FCR(10, year, data)}</td>
        <td>{FCR(11, year, data)}</td>
        <td>{FCR(12, year, data)}</td>
        <td>{AllFCR(data, year)}</td>
      </tr>
      <tr>
        <td>Feed/egg (g/g)</td>
        <td>{Feed(1, year, data)}</td>
        <td>{Feed(2, year, data)}</td>
        <td>{Feed(3, year, data)}</td>
        <td>{Feed(4, year, data)}</td>
        <td>{Feed(5, year, data)}</td>
        <td>{Feed(6, year, data)}</td>
        <td>{Feed(7, year, data)}</td>
        <td>{Feed(8, year, data)}</td>
        <td>{Feed(9, year, data)}</td>
        <td>{Feed(10, year, data)}</td>
        <td>{Feed(11, year, data)}</td>
        <td>{Feed(12, year, data)}</td>
        <td>{AllFeed(data, year)}</td>
      </tr>
    </table>
  );
}

DashboardTable.propTypes = {
  data: PropTypes.array,
  year: PropTypes.string,
  onTableYearChange: PropTypes.func,
};
