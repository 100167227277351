/* eslint-disable react/prop-types */
// Packages
import React, { useState } from 'react';
import { Button, Modal, List, Row, Form, Tooltip, Avatar, Comment } from 'antd';
import FormBuilder from 'antd-form-builder';

// Redux
import { useDispatch } from 'react-redux';

import { momentFoo } from './helper';
import { createComment } from '../../reducers/NextIncubator.slice';


function CommentAction({ item, disabled, context, onSubmit }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  /**
   *
   * @param {object} data
   */
  const onClickSubmit = (data) => {
    dispatch(
      createComment({
        content: data.comment,
        [context]: item.id,
      }),
    ).then(() => onSubmit());
    setShowModal(false);
  };


  return (
    <div>
      <Button disabled={disabled} onClick={() => setShowModal(!showModal)}>
        See comments
      </Button>
      <Modal
        style={{ minHeight: '1500px !important', border: 'none' }}
        title="Comments"
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <List
          footer={
            <Row>
              <Form layout="inline" onFinish={onClickSubmit}>
                <FormBuilder meta={{ key: 'comment', placeholder: 'Add new comment' }} />
                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    comment
                  </Button>
                </Form.Item>
              </Form>
            </Row>
          }
          bordered
          dataSource={item.comments}
          renderItem={(i) => (
            <List.Item>
              <Comment
                author={<a></a>}
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="Han Solo" />
                }
                content={<p>{i.content}</p>}
                datetime={
                  <Tooltip title={momentFoo(i.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{momentFoo(i.created_at).format('MMMM YYYY HH:mm')}</span>
                  </Tooltip>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
}

export default CommentAction;
