/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';

// translation
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch } from 'react-redux';

// UI Components
import { Layout, Row, Typography, Col, Tabs } from 'antd';

// reducers
import { $setStage } from '../../reducers/Session.slice';
import { fetchFeedEntries, fetchCategories } from '../../reducers/NextFeed.slice';

// local Component
import Mix from '../../components/NextFeed/Mix';
import Stock from '../../components/NextFeed/Stock';
import NextFeedDashboard from './Dashboard';

// CONST
import { stages } from '../../common/const';

// styles
import './index.less';

/* -------------------------------------------------------------------------- */
/*                               Next Feed Page                               */
/* -------------------------------------------------------------------------- */
function NextFeed() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Content } = Layout;
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch($setStage(stages.NEXTFEED));
    dispatch(fetchFeedEntries());
    dispatch(fetchCategories());
  }, []);

  const { t } = useTranslation('stages');
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Content className="main-layout-content next-feed">
      <Row>
        <Col span={22} className="first-title">
          <Title level={1}>{t('nextFeed.title')}</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey="Mix">
        <TabPane tab="Dashboard" key="Dashboard">
          <NextFeedDashboard />
        </TabPane>
        <TabPane tab="Buffer stock" key="Buffer stock">
          <Stock />
        </TabPane>
        <TabPane tab="Mix" key="Mix">
          <Mix isBrMix={false} />
        </TabPane>
        <TabPane tab="Breeding Mix" key="Breeding Mix">
          <Mix isBrMix={true} />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default NextFeed;
