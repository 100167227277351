/* eslint-disable react/display-name */

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { Switch } from 'antd';
import React from 'react';
import { dateFormat } from '../../../common/const';

// UI local components
import CommentModal from '../../Shared/CommentModal/CommentModal';

// Helpers
import { momentFoo } from '../../Shared/helper';

/* -------------------------------------------------------------------------- */
/*                               Product columns                              */
/* -------------------------------------------------------------------------- */

export const nextOilColumns = [
    {
      title: 'id',
      dataIndex: 'nextOilBatchId',
      key: 'id',
      fixed: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => momentFoo(a.receptionDate).diff(b.receptionDate, 'days'),
    },
    {
      title: 'Production Date',
      dataIndex: 'productionDate',
      key: 'productionDate',
      render : (value) => momentFoo(value).format(dateFormat)
    },
    {
      title: 'Initial weight',
      dataIndex: 'initialWeight',
      key: 'initialWeight',
    },
    {
      title: 'Current Weight',
      dataIndex: 'currentWeight',
      key: 'currentWeight', 
      render: (value,record) => value || record.initialWeight
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (value) => value || '-',
    },
    {
      title: 'Compliant',
      dataIndex: 'compliant',
      key: 'compliant',
      render: (value) => <Switch disabled={true} checked={value}/>
    },
];

  export const nextGrowColumns= [
    {
      title: 'BigBagId',
      dataIndex: 'bigBagId',
      key: 'bigBagId',
    },
    {
      title: 'Initial Weight (kg)',
      dataIndex: 'weight',
      key: 'weight'
    },
    {
      title: 'Current weight(kg)',
      dataIndex: 'currentWeight',
      key: 'currentWeight',
      render: (value,record) => value || record.weight
    },
  ];

  export const nextMealColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'BigBag id',
      dataIndex: 'bigBagId',
      key: 'bigBagId',
    },
    {
      title: 'Initial weight(kg)',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Current weight(kg)',
      dataIndex: 'currentWeight',
      key: 'currentWeight',
      render: (value,record) => value || record.weight
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (value) => value || '-',
    },
    {
      title: 'Compliant',
      dataIndex: 'compliant',
      key: 'compliant',
      render: (value) => <Switch disabled={true} checked={value}/>
    },

  ];

  export const nextGrubColumns = [
    {
      title: 'BigBag id',
      dataIndex: 'batch',
      key: 'batch',
    },
    {
      title: 'Initial weight(kg)',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Current weight(kg)',
      dataIndex: 'currentWeight',
      key: 'currentWeight',
      render: (value,record) => value || record.weight
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (value) => value || '-',
    },
    {
      title: 'Compliant',
      dataIndex: 'compliant',
      key: 'compliant',
      render: (value) => <Switch disabled={true} checked={value}/>
    },
  ];
  
  export const historicColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value,record) => record.output ? 'Output' : 'Inventory'
    },
    {
      title: 'BigBag id',
      dataIndex: 'bigBagId',
      key: 'bigBagId',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Output',
      dataIndex: 'output',
      key: 'output',
      render: (value) => value || '-'
    },
    {
      title: 'New weight(kg)',
      dataIndex: 'weight',
      key: 'weight',
      render: (value) => value || '-'
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (value) => value || '-',
    },
    {
      title: 'Compliant',
      dataIndex: 'compliant',
      key: 'compliant',
      render: (value) => <Switch disabled={true} checked={value}/>
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      // eslint-disable-next-line react/display-name
      render: (value) => <CommentModal comment={value}/>,
    }
  ];