/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable radix */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// UI Components
import { Row, Table, Button, Col, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// slices
import {
  fetchDriedLarvaeBatches,
  fetchKilledBatches,
  selectDriedLarvalBatches
} from '../../reducers/NextProcess.slice';
import { fetchAllBatches, selectNextProcessBatches } from '../../reducers/Batch.slice';


// styles
import EndOfBatchAction from '../../components/NextProcess/Seiving/EndOfBatchAction';

/* -------------------------------------------------------------------------- */
/*                             DriedLarvaeDetails                             */
/* -------------------------------------------------------------------------- */
function DriedLarvaeDetails() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Title } = Typography;
  const dispatch = useDispatch();
  const batches = useSelector(selectNextProcessBatches);
  const killedBatches = useSelector(selectDriedLarvalBatches);


  useEffect(() => {
    dispatch(fetchAllBatches());
    dispatch(fetchKilledBatches());
    dispatch(fetchDriedLarvaeBatches())

  }, []);

  /* ----------------------------- RENDER HELPERS----------------------------- */
  const getAllFinalWeight = (batch) => {
    const AllBatchesId = []
    if (batch.killed_batches.length > 0) {
      batch.killed_batches.map((bi) => AllBatchesId.push(bi.process_second_drying))
      const getKilledBatches = killedBatches.filter((kb) => AllBatchesId.includes(kb.id))
      return (
        getKilledBatches.map((i) => i.finalWeight).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      )
    }
    return '-';
  }
  const dataSource = batches && batches
    .filter((item) => item.larvae_sievings.length)
    .map((batch) => ({
      identifier: batch.identifier,
      killingCreatedDate:
        batch.killed_batches?.map((kb) => kb)
          .map((e) => e.killingDate)[0] || '-'
      ,
      killingUpdatedDate:
        batch.killed_batches?.map((kb) => kb)
          .map((e) => e.killingDate)[batch.killed_batches.length - 1] || '-'
      ,
      biomass: batch.killed_batches ?
        batch.killed_batches.map((i) => i.biomass).reduce((a, b) => parseInt(a) + parseInt(b), 0) > 0 ?
          batch.killed_batches.map((i) => i.biomass).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0
        : 0
      ,
      frass: batch.larvae_sieving === null ? '-' :
        batch.larvae_sievings[0].frass === null ? '-' :
          batch.larvae_sievings[0].frass
      ,
      sievingWaste: batch.larvae_sievings[0] === null ? '-' :
        batch.larvae_sievings[0].larvalWeight === null ? '-' :
          batch.larvae_sievings[0].larvalWeight
      ,
      driedLarvae: getAllFinalWeight(batch),
      FreshDriedLarvae:
        // biomass
        getAllFinalWeight(batch) !== '-' ?
        ((batch.killed_batches ?
          batch.killed_batches.map((i) => i.biomass).reduce((a, b) => parseInt(a) + parseInt(b), 0) > 0 ?
            batch.killed_batches.map((i) => i.biomass).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0
          : 0)
          /
          getAllFinalWeight(batch)).toFixed(2)
          : 
          '-'
    })).sort((a,b) => 
    {
    if(a.killingUpdatedDate === '-')
        return 1;

    if(b.killingUpdatedDate === '-')
        return -1;
        
    return new Date(b.killingUpdatedDate) - new Date(a.killingUpdatedDate);
    });


  const columns = [
    {
      title: 'Batch id',
      dataIndex: 'identifier',
      key: 'identifier',
      sorter: (a, b) => a.identifier - b.identifier,
    },
    {
      title: 'First killing date',
      dataIndex: 'killingCreatedDate',
      key: 'killingCreatedDate',
    },
    {
      title: 'Last killing date',
      dataIndex: 'killingUpdatedDate',
      key: 'killingUpdatedDate',
    },
    {
      title: 'Biomass Sieved (kg)',
      dataIndex: 'biomass',
      key: 'biomass',
    },
    {
      title: 'Frass (kg)',
      dataIndex: 'frass',
      key: 'frass',
    },
    {
      title: 'Sieving Waste (kg)',
      dataIndex: 'sievingWaste',
      key: 'sievingWaste',
    },
    {
      title: 'Dried larvae (kg)',
      dataIndex: 'driedLarvae',
      key: 'driedLarvae',
    },
    {
      title: 'Fresh/dried larvae',
      dataIndex: 'FreshDriedLarvae',
      key: 'FreshDriedLarvae',
    },
    {
      render: (text, record) => (
        <Row>
          <EndOfBatchAction
            label="Edit"
            batch={
              batches
                .filter((b) => b.identifier === record.identifier)
            }
          />
        </Row>
      ),
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="next-process-list">
      <Col span={24}>
        <Title level={1}>
          <Link to="/nextProcess">
            <ArrowLeftOutlined />
          </Link>
        </Title>
      </Col>
      <Table
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
}

export default DriedLarvaeDetails;
